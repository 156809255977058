<div class="uno-key-value-container" [ngClass]="{'uno-key-value-container-dragging': this.isDragging}">
	<div cdkDropList (cdkDropListDropped)="this.drop($event)">
		<div class="uno-key-value-title">
			<div>
				{{this.title | translate}}
				@if(this.isEmpty()) {
					<span class="uno-p2" style="font-size: 14px; font-weight: bold; font-style: italic; margin-left: 8px; color: var(--error-normal);">{{'required' | translate}}</span>
				}
			</div>
			@if (!this.disabled) {
				<uno-icon class="uno-key-value-add-icon" color="primary" src="assets/components/global-calendar/add-icon.svg" [height]="24" [width]="24" (click)="this.addItem()"></uno-icon>
			}
		</div>
		@for (item of this.items; track i; let i = $index) {
			<div cdkDrag class="uno-key-value-row" (cdkDragStarted)="this.drag()" [ariaLabel]='$any(this.items[i]).key' [cdkDragDisabled]="this.disabled || (this.row.disableSort | checkbool:false:this.items:this.row)">
				<div class="uno-key-value-drag-placeholder" *cdkDragPlaceholder></div>
				@if (!this.disabled && !(this.row.disableSort | checkbool:false:this.items:this.row)) {
					<uno-icon class="uno-key-value-drag-icon" color="gray" src="assets/icons/uno/movefield.svg" [height]="32" [width]="32"></uno-icon>
				}
				<div class="uno-key-value-input-container" (mousedown)="$event.stopPropagation()">
					@if (this.mode === 'key-value') {
						<input class="uno-key-value-input" (ngModelChange)="this.setItem(i, $event);" [disabled]="this.disabled" [ngModel]="$any(this.items[i]).value" [ngModelOptions]="{standalone: true}" fill="outline" inputmode="text" placeholder="{{'edit' | translate }}" type="text">
					} @else {
						<input class="uno-key-value-input" (ngModelChange)="this.setItem(i, $event);" [disabled]="this.disabled" [ngModel]="this.items[i]" [ngModelOptions]="{standalone: true}" fill="outline" inputmode="text" placeholder="{{'edit' | translate }}" type="text">
					}
					@if (!this.disabled && !(this.row.disableDelete | checkbool:false:this.items:this.row)) {
						<div class="uno-key-value-button-container">
							<uno-icon color="primary" src="/assets/icons/uno/bin.svg" [height]="24" [width]="24" (click)="this.removeItem(i)"></uno-icon>
						</div>
					}
				</div>
			</div>
		}
	</div>
</div>
