import {BufferGeometry, Camera, Group, Material, Mesh, Scene, Vector3, WebGLRenderer} from 'three';

/** 
 * Mesh object that is automatically resized to keep the screen space scale.
 *
 * It is resized every frame before rendering.
 */
export class ScalableMesh extends Mesh {
	/**
	 * Factor scale to apply based on distance to the camera.
	 */
	public factor: number = 30.0;

	public constructor(geometry: BufferGeometry, material: Material) {
		super(geometry, material);
	}

	// @ts-ignore
	public onBeforeRender(renderer: WebGLRenderer, scene: Scene, camera: Camera, geometry: BufferGeometry, material: Material, group: Group): void {
		const tempA = new Vector3();
		const tempB = new Vector3();

		this.getWorldPosition(tempA);
		camera.getWorldPosition(tempB);
		const distance = tempA.distanceTo(tempB);
		const scale = distance / this.factor;

		this.scale.set(scale, scale, scale);
	}
}
