import {Component, OnInit, ViewChild} from '@angular/core';
import {Environment} from 'src/environments/environment';
import {RandomUtils} from 'src/app/utils/random-utils';
import {TranslateModule} from '@ngx-translate/core';

import {UnoFormComponent} from '../../../../../../components/uno-forms/uno-form/uno-form.component';
import {ScreenComponent} from '../../../../../../components/screen/screen.component';
import {App} from '../../../../../../app';
import {UserPermissions} from '../../../../../../models/users/user-permissions';
import {Session} from '../../../../../../session';
import {Service} from '../../../../../../http/service';
import {ServiceList} from '../../../../../../http/service-list';
import {Modal} from '../../../../../../modal';
import {Locale} from '../../../../../../locale/locale';
import {UnoFormModule} from '../../../../../../components/uno-forms/uno-form.module';
import {MotExperimentLayout} from '../mot-experiment-layout';
import {UUID} from '../../../../../../models/uuid';
import {Experiment} from '../../../../../../models/pipeline-integrity/mot/experiment';
import {Channel} from '../../../../../../models/pipeline-integrity/mot/channel';
import {ChannelData} from '../../../../../../models/pipeline-integrity/mot/channel-data';
import {FileUtils} from '../../../../../../utils/file-utils';
import {ExperimentImport} from '../../../../data/mot/experiment-import';
import {UnoButtonComponent} from '../../../../../../components/uno/uno-button/uno-button.component';
import {UnoContentComponent} from '../../../../../../components/uno/uno-content/uno-content.component';
import {PermissionsPipe} from '../../../../../../pipes/permissions.pipe';


@Component({
	selector: 'mot-experiment-edit-page',
	templateUrl: 'mot-experiment-edit.page.html',
	standalone: true,
	imports: [UnoContentComponent, UnoFormModule, UnoButtonComponent, TranslateModule, PermissionsPipe]
})
export class MOTExperimentEditPage extends ScreenComponent implements OnInit {
	public app: any = App;

	public layout: any = MotExperimentLayout;

	public userPermissions: any = UserPermissions;

	public session: any = Session;

	public environment: any = Environment;

	@ViewChild('form', {static: false})
	public form: UnoFormComponent = null;

	public permissions = [UserPermissions.PIPELINE_INTEGRITY_MOT_EXPERIMENT_CREATE, UserPermissions.PIPELINE_INTEGRITY_MOT_EXPERIMENT_EDIT, UserPermissions.PIPELINE_INTEGRITY_MOT_EXPERIMENT_DELETE];

	/**
	 * Acquisition being edited/created on this screen, data is fetched from the API.
	 */
	public experiment: Experiment = null;

	/**
	 * Flag to indicate if the screen is being used to create a new entry.
	 */
	public createMode: boolean = false;

	public async ngOnInit(): Promise<void> {
		super.ngOnInit();

		this.experiment = null;
		this.createMode = false;
		
		App.navigator.setTitle('experiment');

		const data = App.navigator.getData();
		if (!data || !data.uuid && !data.createMode) {
			App.navigator.pop();
			return;
		}

		if (data.uuid && data.createMode) {
			throw Error('UUID and createMode cannot be used simultaneously.');
		}

		// Create Experiment
		if (data.createMode) {
			this.createMode = true;
			this.experiment = new Experiment();
			this.experiment.acquisitionUuid = data.acquisitionUuid;
		// Edit experiment, load specific experiment
		} else {
			this.experiment = await this.loadData(data.uuid);
		}
	}

	/**
	 * Load experiment data by UUID from API
	 * 
	 * @param experimentUuid - Experiment UUID
	 * @returns Data of the experiment obtained from the API.
	 */
	public async loadData(experimentUuid: UUID): Promise<Experiment> {
		const request = await Service.fetch(ServiceList.pipelineIntegrity.mot.experiment.get, null, null, {uuid: experimentUuid}, Session.session);
		return Experiment.parse(request.response.experiment);
	}


	/**
	 * Generate random test data for development purposes.
	 *
	 * @param channels - Number of channels to create
	 * @param dataSize - Amount of data to create in each channel
	 */
	public async generateRandomData(channels: number = 3, dataSize: number = 1e4): Promise<void> {
		if (Environment.PRODUCTION) {
			throw Error('generateRandomData() is only available in development.');
		}

		for (let c = 0; c < channels; c++) {
			const channel = new Channel();
			channel.name = 'channel_' + c;
			channel.experimentUuid = this.experiment.uuid;
	
			const request = await Service.fetch(ServiceList.pipelineIntegrity.mot.channel.create, null, null, channel, Session.session);
			channel.uuid = request.response.uuid;
			
			const data = [];
			for (let i = 0; i < dataSize; i++) {
				data.push([i, RandomUtils.randomNumber(-20, 20, 5)]);
			}

			const channelData = new ChannelData();
			channelData.data = data;
			channelData.channelUuid = channel.uuid;
			await Service.fetch(ServiceList.pipelineIntegrity.mot.channel.addDataChannels, null, null, channelData, Session.session);
		}
	}

	/**
	 * Display a modal for the user to select a .nano file to be imported.
	 */
	public async importNano(): Promise<void> {
		const files = await FileUtils.chooseFile('.nano', false);
		if (files.length > 0) {
			try {
				await ExperimentImport.importNano(this.experiment, files[0]);
				Modal.toast(Locale.get('nanoFileImportedSuccessfully'));
			} catch (e) {
				Modal.alert(Locale.get('error'), Locale.get('errorImport'));
			}
		}
	}


	/**
	 * Import data from JSON file into the experiment.
	 *
	 * A new acquisition is created to store the data of the JSON file.
	 */
	public async importJSON(): Promise<void> {
		const files = await FileUtils.chooseFile('.json', false);
		if (files.length > 0) {
			try {
				await ExperimentImport.importJSON(this.experiment, files[0]);
				Modal.toast(Locale.get('importSuccessful'));
			} catch (e) {
				Modal.alert(Locale.get('error'), Locale.get('errorImport'));
			}
		}
	}

	/**
	 * Create or update experiment data in the API.
	 * 
	 * Checks if all required form fields are filled. Display toast on success.
	 *
	 * @param stayOnPage - Flag to indicate if the system should stay on the page after changes are saved.
	 */
	public async update(stayOnPage: boolean = false): Promise<void> {
		if (!this.form.requiredFilled()) {
			Modal.alert(Locale.get('error'), Locale.get('requiredFieldsError'));
			return;
		}

		await Service.fetch(this.createMode ? ServiceList.pipelineIntegrity.mot.experiment.create : ServiceList.pipelineIntegrity.mot.experiment.update, null, null, this.experiment, Session.session);
		if (!stayOnPage) {
			App.navigator.pop();
		}

		Modal.toast(Locale.get('updatedSuccessfully'));
	}

	/**
	 * Delete experiment.
	 */
	public async delete(): Promise<void> {
		const confirm = await Modal.confirm(Locale.get('confirm'), Locale.get('confirmDelete'));
		if (confirm) {
			await Service.fetch(ServiceList.pipelineIntegrity.mot.experiment.delete, null, null, {uuid: this.experiment.uuid}, Session.session);
			Modal.toast(Locale.get('deleteSuccessfully'));
			App.navigator.pop();
		}
	}
}
