<uno-content [borders]="true">
	@if (this.observation !== null) {
		<uno-form [layout]="this.layout" [object]="this.observation" #form></uno-form>
		<!-- Save/Update -->
		<div style="display: flex; flex-direction: row-reverse; gap: 5px;" class="ion-padding">
			<uno-button (click)="this.update();">{{'save' | translate}}</uno-button>
			<uno-button (click)="this.update(true);">{{'update' | translate}}</uno-button>
			@if (this.observation.status === observationStatus.TODO) {
				<uno-button (click)="this.createRepair(); ">{{'createRepair' | translate}}</uno-button>
				<uno-button (click)="this.update(false, observationStatus.DONE);">{{'done' | translate}}</uno-button>
			}
			@if ([userPermissions.OBSERVATIONS_DELETE] | hasPermissions) {
				<uno-button (click)="this.delete();" color="error">{{'delete' | translate}}</uno-button>
			}
		</div>
	}
</uno-content>
