import {XlsxUtils} from '../../../utils/xlsx-utils';
import {Service} from '../../../http/service';
import {ServiceList} from '../../../http/service-list';
import {Session} from '../../../session';
import {APAsset} from '../../../models/asset-portfolio/asset';
import {AssetService} from '../../asset-portfolio/services/asset.service';
import {EPI, EPICableData} from '../../../models/epi/epi';

export class EPIImport {
	/**
	 * Import a XLSX file containing data about EPI entries.
	 *
	 * Missing attributes need to be filled later the import tools allows for required attributes to be left empty.
	 */
	public static async importXLSX(): Promise<void> {
		const xlsx: any[] = await XlsxUtils.chooseFileXLSX();

		for (let i = 0; i < xlsx.length; i++) {
			const row = xlsx[i];
			const epi = new EPI();

			XlsxUtils.readRowToObject(epi, row, [
				{
					attribute: 'name',
					names: ['Nome', 'Name']
				},
				{
					attribute: 'description',
					names: ['Descrição', 'Description']
				},
				{
					attribute: 'tag',
					names: ['LOOP TAG', 'INSTRUMENT TAG', 'INST TAG']
				},
				{
					attribute: 'barrierId',
					names: ['Barrier ID', 'Barrier', 'ID de Barreira']
				}
			]);

			let k = 0;
			while (true) {
				// Destination asset to the list of equipments of the EPI
				const to = XlsxUtils.readRowArrayValue(row, k, ['TO']);
				if (to !== null) {
					try {
						
						const asset: APAsset = await AssetService.getByTag(to);
						if (epi.equipments.indexOf(asset.uuid) === -1) {
							epi.equipments.push(asset.uuid);
						}
					} catch (e) {}
				}

				// Origin asset to the list of equipments of the EPI
				const from = XlsxUtils.readRowArrayValue(row, k, ['FROM']);
				if (from !== null) {
					try {
						const asset: APAsset = await AssetService.getByTag(from);
						if (epi.equipments.indexOf(asset.uuid) === -1) {
							epi.equipments.push(asset.uuid);
						}
					} catch (e) {}
				}

				// Read the properties of the cables
				const length = XlsxUtils.readRowArrayValue(row, k, ['L(m)', 'L_m']);
				const capacitySection = XlsxUtils.readRowArrayValue(row, k, ['C(nF)', 'C(uF)', 'C(nF/Km)', 'C_nF']);
				const inductanceSection = XlsxUtils.readRowArrayValue(row, k, ['L(mH)', 'L(mH/Km)', 'L_mH']);
				const resistanceSection = XlsxUtils.readRowArrayValue(row, k, ['R(Ohm)', 'R(Ohm/Km)', 'R_Ohm', 'R(Ω)']);

				if (!length || !capacitySection || !inductanceSection || !resistanceSection) {
					break;
				}

				// Set the cable attributes and calculate missing attributes
				const cable = new EPICableData();
				cable.length = Number.parseFloat(length);
				cable.capacitySection = Number.parseFloat(capacitySection);
				cable.inductanceSection = Number.parseFloat(inductanceSection);
				cable.resistanceSection = Number.parseFloat(resistanceSection);
				cable.calculateAttributes('length');

				// Additional miscellaneous row about the cable
				const cableId = XlsxUtils.readRowArrayValue(row, k, ['CABLE_ID']);
				if (cableId !== null) {
					cable.comments += cableId + '\n';
				}

				const cableCode = XlsxUtils.readRowArrayValue(row, k, ['CABLE_CODE']);
				if (cableCode !== null) {
					cable.comments += cableCode + '\n';
				}

				epi.data.cables.push(cable);
				k++;
			}

			Service.fetch(ServiceList.epi.create, null, null, epi, Session.session);
		}
	}
}
