import {Pipe, PipeTransform} from '@angular/core';
import {RepairCriticalityLevelLabel} from '../../../../models/repairs/repairs/repair-criticality';

/**
 * Pipe to map criticality number to a human readable format.
 */
@Pipe({
	name: 'repairCriticalityLabel',
	standalone: true
})
export class RepairCriticalityLabelPipe implements PipeTransform {
	public transform(n: number): string {
		return RepairCriticalityLevelLabel.get(n);
	}
}
