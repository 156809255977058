import {Component, OnInit, ViewChild} from '@angular/core';
import {cloneDeep} from 'lodash-es';
import {TranslateModule} from '@ngx-translate/core';
import {IonicModule} from '@ionic/angular';
import {NgTemplateOutlet} from '@angular/common';
import {UnoFormUtils} from '../../../../../components/uno-forms/uno-form/uno-form-utils';
import {UnoFormField} from '../../../../../components/uno-forms/uno-form/uno-form-field';
import {UnoFormFieldTypes} from '../../../../../components/uno-forms/uno-form/uno-form-field-types';
import {UnoFormComponent} from '../../../../../components/uno-forms/uno-form/uno-form.component';
import {ScreenComponent} from '../../../../../components/screen/screen.component';
import {App} from '../../../../../app';
import {UserPermissions} from '../../../../../models/users/user-permissions';
import {Session} from '../../../../../session';
import {Service} from '../../../../../http/service';
import {ServiceList} from '../../../../../http/service-list';
import {Modal} from '../../../../../modal';
import {Locale} from '../../../../../locale/locale';
import {UnoFormModule} from '../../../../../components/uno-forms/uno-form.module';
import {InspectionWorkflow} from '../../../../../models/inspections/workflow/inspection-workflow';
import {InspectionWorkflowLayout} from '../inspection-workflow-layout';
import {UUID} from '../../../../../models/uuid';
import {InspectionWorkflowStep} from '../../../../../models/inspections/workflow/inspection-workflow-step';
import {InspectionWorkflowStepAdjacency} from '../../../../../models/inspections/workflow/inspection-workflow-step-adjacency';
import {InspectionWorkflowStepAdjacencyLayout} from '../inspection-workflow-step-adjacency-layout';
import {InspectionWorkflowStepLayout} from '../inspection-workflow-step-layout';
import {ArrayUtils} from '../../../../../utils/array-utils';
import {UnoButtonComponent} from '../../../../../components/uno/uno-button/uno-button.component';
import {UnoTitleComponent} from '../../../../../components/uno/uno-title/uno-title.component';
import {UnoContentComponent} from '../../../../../components/uno/uno-content/uno-content.component';
import {InspectionWorkflowExport} from '../../../data/workflow/inspection-workflow-export';
import {PermissionsPipe} from '../../../../../pipes/permissions.pipe';
import {UnoTabComponent} from '../../../../../components/uno/uno-tab/uno-tab.component';
import {UnoTabSectionComponent} from '../../../../../components/uno/uno-tab/uno-tab-section/uno-tab-section.component';
@Component({
	selector: 'inspection-workflow-edit-page',
	templateUrl: './inspection-workflow-edit.page.html',
	standalone: true,
	imports: [NgTemplateOutlet, UnoContentComponent, UnoTitleComponent, UnoFormModule, UnoButtonComponent, IonicModule, TranslateModule, PermissionsPipe, UnoTabComponent, UnoTabSectionComponent]
})
export class InspectionWorkflowEditPage extends ScreenComponent implements OnInit {
	public app: any = App;

	public layoutWorkflow: any = InspectionWorkflowLayout;

	public layoutStep: any = InspectionWorkflowStepLayout;

	public inspectionWorkflowExport: any = InspectionWorkflowExport;

	public userPermissions: any = UserPermissions;

	public session: any = Session;

	@ViewChild('form', {static: false})
	public form: UnoFormComponent = null;

	public permissions = [UserPermissions.INSPECTION_WORKFLOW_CREATE, UserPermissions.INSPECTION_WORKFLOW_EDIT];

	/**
	 * Inspection workflow being edited/created on this screen, data is fetched from the API.
	 */
	public workflow: InspectionWorkflow = null;

	/**
	 * Flag to indicate if the screen is being used to create a new entry.
	 */
	public createMode: boolean = false;

	public layoutAdjacency: UnoFormField[] = cloneDeep(InspectionWorkflowStepAdjacencyLayout);

	public async ngOnInit(): Promise<void> {
		super.ngOnInit();

		this.workflow = null;
		this.createMode = false;

		App.navigator.setTitle('workflows');

		const data = App.navigator.getData();
		if (!data || !data.uuid && !data.createMode) {
			App.navigator.pop();
			return;
		}

		if (data.uuid && data.createMode) {
			throw Error('UUID and createMode cannot be used simultaneously.');
		}

		if (data.createMode) {
			this.createMode = true;
			this.workflow = new InspectionWorkflow();
			App.navigator.setTitle('create');
		} else {
			await this.loadData(data.uuid);
			App.navigator.setTitle(this.workflow.name);
		}

		this.updateAdjacencyStepOptions();
	}

	/**
	 * Load the inspection workflow data from the API from UUID.
	 */
	public async loadData(uuid: UUID): Promise<void> {
		const request = await Service.fetch(ServiceList.inspection.workflow.get, null, null, {uuid: uuid}, Session.session);
		this.workflow = InspectionWorkflow.parse(request.response.workflow);
	}

	/**
	 * Create/update the inspection workflow alongside with its inspection fields.
	 *
	 * @param stayOnPage - Flag to indicate if the system should stay on the page after changes are saved.
	 */
	public async update(stayOnPage: boolean = false): Promise<void> {
		if (!this.form.requiredFilled()) {
			Modal.alert(Locale.get('error'), Locale.get('requiredFieldsError'));
			return;
		}

		for (let i = 0; i < this.workflow.steps.length; i++) {
			if (this.workflow.steps[i].name === null || this.workflow.steps[i].name === '') {
				Modal.alert(Locale.get('error'), Locale.get('requiredFieldsError'));
				return;
			}
		}

		for (let i = 0; i < this.workflow.adjacencies.length; i++) {
			if (this.workflow.adjacencies[i].originUuid === null || this.workflow.adjacencies[i].originUuid === '' || this.workflow.adjacencies[i].destinationUuid === null || this.workflow.adjacencies[i].destinationUuid === '') {
				Modal.alert(Locale.get('error'), Locale.get('requiredFieldsError'));
				return;
			}
		}

		// Verify if a single default step was set for the workflow
		const defaultStepCount: number = this.workflow.steps.reduce(function(c, s) {return s.defaultStep ? c + 1 : c;}, 0);
		if (defaultStepCount === 0) {
			Modal.alert(Locale.get('error'), Locale.get('errorDefaultWorkflowStepMissing'));
			return;
		}
		
		if (defaultStepCount > 1) {
			Modal.alert(Locale.get('error'), Locale.get('errorMultipleDefaultWorkflowSteps'));
			return;
		}

		const workflow = structuredClone(this.workflow);
		await Service.fetch(this.createMode ? ServiceList.inspection.workflow.create : ServiceList.inspection.workflow.update, null, null, workflow, Session.session);

		if (!stayOnPage) {
			App.navigator.pop();
		}

		Modal.toast(Locale.get('updatedSuccessfully'));
	}

	/**
	 * Delete the inspection workflow from the database.
	 */
	public async delete(): Promise<void> {
		const confirm = await Modal.confirm(Locale.get('confirm'), Locale.get('confirmDelete'));
		if (confirm) {
			await Service.fetch(ServiceList.inspection.workflow.delete, null, null, {uuid: this.workflow.uuid}, Session.session);
			Modal.toast(Locale.get('deleteSuccessfully'));
			App.navigator.pop();
		}
	}

	/**
	 * Clones the inspection workflow.
	 */
	public async clone(): Promise<void> {
		const obj: {name: string, deep: boolean} = {
			// The name to set for the workflow clone (not applied to other cloned elements on deep clone)
			name: this.workflow.name + ' (' + Locale.get('clone') + ')',
			// If true, all the forms and fields will be cloned too
			deep: false
		};

		const layout: UnoFormField[] = [
			{
				required: true,
				attribute: 'name',
				label: 'name',
				type: UnoFormFieldTypes.TEXT
			},
			{
				attribute: 'deep',
				label: 'deepClone',
				type: UnoFormFieldTypes.CHECKBOX
			}
		];

		// Present clone options modal to the user
		await Modal.form(Locale.get('clone'), obj, layout);
		
		const request = await Service.fetch(ServiceList.inspection.workflow.clone, null, null, {uuid: this.workflow.uuid, name: obj.name, deep: obj.deep}, Session.session);
		Modal.toast(Locale.get('cloneSuccessfully'), 3000, 'success');
		
		await App.navigator.navigate('/menu/inspection/workflow/edit', {uuid: request.response.uuid});
	}

	/**
	 * Update the form step options stored in the forms used to create the steps.
	 *
	 * Writes the options to the forms directly.
	 */
	public updateAdjacencyStepOptions(): void {
		if (!this.workflow) {
			throw new Error('Workflow has to be defined to update step options.');
		}

		const options = [{value: null, label: 'none'}];
		for (let i = 0; i < this.workflow.steps.length; i++) {
			options.push({
				value: this.workflow.steps[i].uuid,
				label: this.workflow.steps[i].name
			});
		}

		const origin = UnoFormUtils.getFormFieldByAttribute(this.layoutAdjacency, 'originUuid');
		const destination = UnoFormUtils.getFormFieldByAttribute(this.layoutAdjacency, 'destinationUuid');

		destination.options = origin.options = options;
	}

	/**
	 * Move step up in the list of steps.
	 */
	public moveStepUp(step: InspectionWorkflowStep): void {
		const index = this.workflow.steps.indexOf(step);
		if (index < this.workflow.steps.length && index !== -1) {
			ArrayUtils.move(this.workflow.steps, index, index + 1);
			this.workflow.updateStepsIndex();
		}
	}

	/**
	 * Move step down in the list of steps.
	 */
	public moveStepDown(step: InspectionWorkflowStep): void {
		const index = this.workflow.steps.indexOf(step);
		if (index > 0) {
			ArrayUtils.move(this.workflow.steps, index, index - 1);
			this.workflow.updateStepsIndex();
		}
	}

	/**
	 * Add a new workflow step into the list.
	 */
	public addStep(): void {
		this.workflow.addStep();
		this.updateAdjacencyStepOptions();
	}

	/**
	 * Remove a workflow step from the list.
	 *
	 * Check if the step can be removed from the list.
	 *
	 * @param step - Step to be removed.
	 */
	public removeStep(step: InspectionWorkflowStep): void {
		this.workflow.removeStep(step);
		this.updateAdjacencyStepOptions();
	}

	/**
	 * Add a new workflow step adjacency into the list.
	 */
	public addStepAdjacency(): void {
		this.workflow.addAdjacency();
	}

	/**
	 * Remove a workflow step adjacency from the list.
	 *
	 * @param adjacency - Step to be removed.
	 */
	public removeStepAdjacency(adjacency: InspectionWorkflowStepAdjacency): void {
		this.workflow.removeAdjacency(adjacency);
	}
}
