import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {ActionPlanPage} from './screens/menu/action-plan.page';
import {ActionPlanListPage} from './screens/list/action-plan-list.page';
import {ActionPlanEditPage} from './screens/edit/action-plan-edit.page';
import {ActionPlanActionsPage} from './screens/master-data/action-plan-actions/action-plan-actions.page';

const routes: Routes = [
	{
		path: '',
		component: ActionPlanPage
	},
	{
		path: 'list',
		component: ActionPlanListPage
	},
	{
		path: 'edit',
		component: ActionPlanEditPage
	},
	{
		path: 'master-data/actions',
		component: ActionPlanActionsPage
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class ActionPlanRouterModule { }
