/**
 * List of possible states for the action plan entries.
 */
export const ActionPlanState = {
	// All states
	ALL: -1,
	// Todo 
	TODO: 0,
	// Waiting client validation
	WAITING_CLIENT_VALIDATION: 1,
	// Client accepted the entry
	CLIENT_ACCEPTED: 2,
	// Client rejected the entry
	CLIENT_REJECTED: 3,
	// Blocked waiting for dependencies
	BLOCKED: 4,
	// Waiting for reinspection of the field(s) that failed
	WAITING_REINSPECTION: 5,
	// Action plan was executed
	DONE: 6,
	// Action plan was archived
	ARCHIVED: 100
};

/**
 * Label of the action plan states, to be displayed in the GUI.
 */
export const ActionPlanStateLabel: Map<number, string> = new Map([
	[ActionPlanState.TODO, 'underWork'],
	[ActionPlanState.WAITING_CLIENT_VALIDATION, 'waitingClient'],
	[ActionPlanState.CLIENT_ACCEPTED, 'accepted'],
	[ActionPlanState.CLIENT_REJECTED, 'rejected'],
	[ActionPlanState.BLOCKED, 'blocked'],
	[ActionPlanState.WAITING_REINSPECTION, 'waitingReinspection'],
	[ActionPlanState.DONE, 'done'],
	[ActionPlanState.ARCHIVED, 'archived']
]);
