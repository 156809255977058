import {Component, EventEmitter, Input, OnChanges, Output, signal, SimpleChanges, WritableSignal} from '@angular/core';
import {QRGenerator} from 'src/app/modules/qr/data/qr-generator';
import {Session} from 'src/app/session';
import {NgClass, NgStyle} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {RangePipe} from 'src/app/pipes/range.pipe';
import {App} from '../../../../../../app';
import {Resource} from '../../../../../../models/resource';
import {StringUtils} from '../../../../../../utils/string-utils';
import {ResourceUtils} from '../../../../../../utils/resource-utils';
import {UnoIconComponent} from '../../../../../../components/uno/uno-icon/uno-icon.component';
import {AssetTreeLevelItem} from '../asset-tree-list.page';

/**
 * Asset list item is used to display an asset (with its child assets on a collapsable tree list)
 */
@Component({
	selector: 'asset-tree-list-item',
	templateUrl: 'asset-tree-list-item.component.html',
	styleUrls: ['asset-tree-list-item.component.css'],
	standalone: true,
	imports: [IonicModule, NgStyle, UnoIconComponent, RangePipe, NgClass]
})
export class AssetTreeListItemComponent implements OnChanges {
	public settings: any = Session.settings;

	public app: any = App;
	
	public resource: any = Resource;

	public generateQr: any = QRGenerator.generateFile;

	public stringUtils: any = StringUtils;

	public resourceUtils: any = ResourceUtils;

	@Input()
	public item: AssetTreeLevelItem = null;

	@Output()
	public toggleExpand: EventEmitter<void> = new EventEmitter<void>();

	/**
	 * Indicate if the asset has children and can be expanded.
	 */
	public canExpand: WritableSignal<boolean> = signal(true);

	/**
	 * Picture of the asset to be displayed.
	 */
	public pictureUrl: WritableSignal<string> = signal(null);
	
	public ngOnChanges(changes: SimpleChanges): void {
		this.canExpand.set(this.item.hasChild);
		this.pictureUrl.set(this.settings.showListPictures && this.item.pictures.length > 0 ? ResourceUtils.getURL(this.item.pictures[0], './assets/placeholder/asset.png') : './assets/placeholder/asset.png');
	}

	/**
	 * Method called when the node is clicked, to open the asset edit page.
	 */
	public open(): void {
		App.navigator.navigate('/menu/asset-portfolio/asset/edit', {uuid: this.item.uuid});
	}

	/**
	 * Toggle the value of expand var, making template expand/collapse
	 * 
	 * May require data to be loaded lazily.
	 * @returns A boolean promise to ensure reload page caused by the event listener
	 */
	// eslint-disable-next-line @typescript-eslint/require-await
	public async expand(event: MouseEvent): Promise<boolean> {
		// Toggle expanded flag
		this.toggleExpand.emit(null);

		// Cancel event bubble to prevent navigation
		if (event !== undefined) {
			event.preventDefault();
			event.stopPropagation();
		}

		return false;
	}
}
