/**
 * Possible alarm conditions applied to LDS data.
 */
export enum AlarmCondition {
	/**
	 * Trigger on values less than threshold.
	 */
	LESS = 0,

	/**
	 * Trigger on values higher than threshold.
	 */
	GREATER = 1,

	/**
	 * Values between a date range.
	 */
	IN_BETWEEN = 2
}

export const AlarmConditionLabel = new Map<number, string> ([
	[AlarmCondition.LESS, 'less'],
	[AlarmCondition.GREATER, 'greater'],
	[AlarmCondition.IN_BETWEEN, 'inBetween']
]);

