/**
 * Possible form block field types used in the forms.
 */
export const APAssetFormBlockFieldComponentType = {
	NONE: 0,
	CHECKBOX: 1,
	TEXT: 2,
	DOCUMENT_RESOURCE_MULTIPLE: 3,
	IMAGE_RESOURCE_MULTIPLE: 4,
	OPTIONS: 5,
	GEO_POSITION: 6,
	DATE: 7,
	TEAM_SELECTOR: 8,
	COMPANY_SELECTOR: 9,
	OPTIONS_MULTIPLE: 10,
	USER_SELECTOR: 11,
	NUMBER: 12,
	TEXT_MULTILINE: 13
};

/**
 * Map of the form block field component types and their corresponding label as stored in locale.
 */
export const APAssetFormBlockFieldComponentTypeLabel: Map<number, string> = new Map([
	[APAssetFormBlockFieldComponentType.NONE, 'none'],
	[APAssetFormBlockFieldComponentType.CHECKBOX, 'checkbox'],
	[APAssetFormBlockFieldComponentType.TEXT, 'text'],
	[APAssetFormBlockFieldComponentType.DOCUMENT_RESOURCE_MULTIPLE, 'documents'],
	[APAssetFormBlockFieldComponentType.IMAGE_RESOURCE_MULTIPLE, 'images'],
	[APAssetFormBlockFieldComponentType.OPTIONS, 'options'],
	[APAssetFormBlockFieldComponentType.GEO_POSITION, 'position'],
	[APAssetFormBlockFieldComponentType.DATE, 'date'],
	[APAssetFormBlockFieldComponentType.TEAM_SELECTOR, 'teamSelector'],
	[APAssetFormBlockFieldComponentType.COMPANY_SELECTOR, 'companySelector'],
	[APAssetFormBlockFieldComponentType.OPTIONS_MULTIPLE, 'multipleOptions'],
	[APAssetFormBlockFieldComponentType.USER_SELECTOR, 'userSelector'],
	[APAssetFormBlockFieldComponentType.NUMBER, 'number'],
	[APAssetFormBlockFieldComponentType.TEXT_MULTILINE, 'textMultiline']
]);
