import {Component, OnInit, ViewChildren} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {UserPermissions} from 'src/app/models/users/user-permissions';
import {App} from 'src/app/app';
import {ScreenComponent} from 'src/app/components/screen/screen.component';
import {AssetFluidFamily} from 'src/app/models/asset-portfolio/asset-fluid-family';
import {Modal} from 'src/app/modal';
import {Locale} from 'src/app/locale/locale';
import {Service} from 'src/app/http/service';
import {ServiceList} from 'src/app/http/service-list';
import {Session} from 'src/app/session';
import {UnoFormComponent} from 'src/app/components/uno-forms/uno-form/uno-form.component';
import {generateUUID} from 'src/app/models/uuid';
import {UnoFormModule} from 'src/app/components/uno-forms/uno-form.module';
import {UnoContentComponent} from '../../../../../components/uno/uno-content/uno-content.component';
import {UnoButtonComponent} from '../../../../../components/uno/uno-button/uno-button.component';
import {AssetFluidService} from '../../../services/asset-fluid.service';
import {AssetFluidFamilyLayout} from './asset-fluid-family-layout';

@Component({
	selector: 'asset-fluid-family-page',
	templateUrl: 'asset-fluid-family.page.html',
	styleUrls: ['./asset-fluid-family.page.css'],
	standalone: true,
	imports: [TranslateModule, UnoContentComponent, UnoButtonComponent, UnoFormModule]
})

export class AssetFluidFamiliesPage extends ScreenComponent implements OnInit {
	@ViewChildren('form')
	public forms: UnoFormComponent[] = null;

	public layout: any = AssetFluidFamilyLayout;

	public permissions = [UserPermissions.ASSET_PORTFOLIO_FLUID_EDIT];

	/**
	 * List of fluid families available in database.
	 */
	public fluidFamilies: AssetFluidFamily[] = [];

	public async ngOnInit(): Promise<void> {
		super.ngOnInit();
		
		App.navigator.setTitle('fluidFamily');

		this.fluidFamilies = (await AssetFluidService.listFamily()).families;
	}

	/**
	 * Delete the fluid families from the list.
	 *
	 * @param type - Element to be deleted from list.
	 */
	public async delete(type: AssetFluidFamily): Promise<void> {
		const confirm: boolean = await Modal.confirm(Locale.get('confirm'), Locale.get('confirmDelete'));
		if (confirm) {
			if (type.uuid) {
				await Service.fetch(ServiceList.assetPortfolio.fluidFamily.delete, null, null, {uuid: type.uuid}, Session.session);
			}

			const index = this.fluidFamilies.indexOf(type);
			if (index !== -1) {
				this.fluidFamilies.splice(index, 1);
			}
		}
	}

	/**
	 * Add a new fluid family to the list.
	 */
	public add(): void {
		this.fluidFamilies.push(new AssetFluidFamily());
	}

	/**
	 * Update the temporary repair values in the server.
	 */
	public async save(stayOnPage: boolean = false): Promise<void> {
		let requiredFilled: boolean = true;

		// Check if all the forms are filled.
		for (const form of this.forms) {
			requiredFilled = form.requiredFilled();
			if (!requiredFilled) {
				await Modal.alert(Locale.get('error'), Locale.get('requiredFieldsError'));
				return;
			}
		}

		for (const type of this.fluidFamilies) {
			if (type.uuid === null) {
				type.uuid = generateUUID();
			}
		}

		await Service.fetch(ServiceList.assetPortfolio.fluidFamily.updateBulk, null, null, this.fluidFamilies, Session.session);

		Modal.toast(Locale.get('updatedSuccessfully'));

		if (!stayOnPage) {
			App.navigator.pop();
		}
	}
}

