import {Component, Input, ViewEncapsulation} from '@angular/core';
import {animate, style, transition, trigger} from '@angular/animations';
import {NgStyle} from '@angular/common';
import {CssNgStyle} from '../../../utils/css-ng-style';

/**
 * Possible directions to open the tooltip.
 */
export const UnoTooltipDirection = {
	LEFT: 'left',
	RIGHT: 'right',
	TOP: 'top',
	BOTTOM: 'bottom'
};

/**
 * UNO tooltip shows a text box over elements on pointer over.
 */
@Component({
	selector: 'uno-tooltip',
	styleUrls: ['./uno-tooltip.component.css'],
	templateUrl: './uno-tooltip.component.html',
	animations: [
		trigger('tooltip', [
			transition(':enter', [
				style({opacity: 0.0}),
				animate(300, style({opacity: 1.0}))
			]),
			transition(':leave', [
				animate(300, style({opacity: 0.0}))
			])
		])
	],
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [NgStyle]
})
export class UnoTooltipComponent {
	/**
	 * Text to be displayed inside the tooltip.
	 */
	@Input('text')
	public text: string = '';

	/**
	 * Direction that the tooltip has been opened relative to the component.
	 */
	@Input('direction')
	public direction: string = UnoTooltipDirection.RIGHT;

	/**
	 * Get style of the tooltip arrow.
	 */
	public getArrowStyle(): CssNgStyle {
		if (this.direction === UnoTooltipDirection.RIGHT) {
			return {
				position: 'absolute',
				top: 'auto',
				bottom: 'auto',
				left: '-8px',
				width: '0px',
				height: '0px',
				'border-top': '8px solid transparent',
				'border-bottom': '8px solid transparent',
				'border-right': '8px solid var(--gray-12)'
			};
		} else if (this.direction === UnoTooltipDirection.LEFT) {
			return {
				position: 'absolute',
				top: 'auto',
				bottom: 'auto',
				right: '-8px',
				width: '0px',
				height: '0px',
				'border-top': '8px solid transparent',
				'border-bottom': '8px solid transparent',
				'border-left': '8px solid var(--gray-12)'

			};
		} else if (this.direction === UnoTooltipDirection.TOP) {
			return {
				position: 'absolute',
				left: 'auto',
				right: 'auto',
				bottom: '-8px',
				width: '0px',
				height: '0px',
				'border-left': '8px solid transparent',
				'border-right': '8px solid transparent',
				'border-top': '8px solid var(--gray-12)'
			};
		} else if (this.direction === UnoTooltipDirection.BOTTOM) {
			return {
				position: 'absolute',
				left: 'auto',
				right: 'auto',
				top: '-8px',
				width: '0px',
				height: '0px',
				'border-left': '8px solid transparent',
				'border-right': '8px solid transparent',
				'border-bottom': '8px solid var(--gray-12)'
			};
		}

	}
}
