<div style="display: flex; flex-direction: row-reverse; gap: 5px;" class="ion-padding">
	<!-- Add acquisition button -->
	<uno-button icon="assets/icons/uno/add.svg" (click)="app.navigator.navigate('/menu/pipeline-integrity/acquisition/edit', {createMode: true, cmpUuid: this.cmpUuid})">
		@if (app.device.isDesktop()) {
			{{'create' | translate}}
		}
	</uno-button>
	<!-- Compare acquisition-->
	<uno-button icon="assets/icons/uno/search.svg" (click)="loadAcquisitionsToCompare()">
		@if (app.device.isDesktop()) {
			{{'compare' | translate}}
		}
	</uno-button>
</div>

<uno-content [ngStyle]="{height: 'calc(100% - 65px)'}">
	<uno-responsive-table-list [layout]="this.layout" [loadMore]="this.loadMore" [totalItems]="this.totalItems" [selectable]="false" [pageSize]="this.tablePageSize" [rowClickable]="true" [labelShown]="true" (rowClick)="app.navigator.navigate('/menu/pipeline-integrity/experiment/list', {acquisitionUuid: $event.element.uuid});">
	</uno-responsive-table-list>
</uno-content>
