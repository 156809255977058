<div style="height: 110px; margin: 10px;">
	<!-- Search bar -->
	<uno-searchbar style="width: 100%;" [value]="selfStatic.filters.search" (valueChange)="onSearch($event)"></uno-searchbar>
	<!-- Filters -->
	<uno-filter-bar [filters]="selfStatic.filters" [options]="selfStatic.filterOptions" (onChange)="this.onFilterChange($event);"></uno-filter-bar>
</div>

<div style="height: calc(100% - 130px); overflow: hidden;">
	<uno-responsive-table-list [loadMore]="this.loadTableItems" [totalItems]="this.tableTotalItemsCount" [layout]="this.tableLayout" [pageSize]="this.tablePageSize" [selectable]="false" [labelShown]="true" (rowClick)="app.navigator.navigate('/menu/atex/action-plan/edit', {uuid: $event.element.uuid});" (sortChange)="this.sortChanged($event.sortBy)">
	</uno-responsive-table-list>
</div>
