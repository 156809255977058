import {ServiceMeta} from './service-meta';
import {ServiceRequest} from './service-request';
import {Service} from './service';
import {ServiceResponse} from './service-response';

/**
 * Indicates the status of the request performed to the API.
 */
// eslint-disable-next-line no-shadow
export enum ServiceRequestSyncStatus {
	/**
	 * Request was sent to the database-
	 */
	REQUESTED = 1,

	/**
	 * Data was received from the API, the response of the request should be present.
	 */
	DATA_RECEIVED = 2,

	/**
	 * The request was resolved and is completed.
	 */
	RESOLVED = 3,

	/**
	 * The request was cancelled.
	 */
	CANCELLED = 4
}

/**
 * Service request with promise that should be used to handle the response if any.
 */
export class ServiceRequestSync {
	/**
	 * Service access request object containing the ID of the request.
	 */
	public request: ServiceRequest = null;

	/**
	 * Promise resolve method, to return the content of the service request.
	 */
	public resolve: (value: ServiceResponse)=> void;

	/**
	 * Promise reject method, to report errors with the request.
	 */
	public reject: Function = null;

	/**
	 * Response received for the service request.
	 */
	public response: any = null;

	/**
	 * Indicates if there was success in the processing of the request.
	 */
	public success: boolean = true;

	/**
	 * Status of the request.
	 */
	public status: ServiceRequestSyncStatus = null;

	public constructor(request: ServiceRequest, resolve: (value: ServiceResponse)=> void, reject: Function) {
		this.request = request;
		this.resolve = resolve;
		this.reject = reject;
		this.status = ServiceRequestSyncStatus.REQUESTED;
	}

	/**
	 * Cancel the request.
	 * 
	 * Sets the state of the request to cancelled, aborts the XHR request and calls rejection method of the waiting promise.
	 */
	public cancel(): void {
		this.status = ServiceRequestSyncStatus.CANCELLED;
		this.reject();
		this.request.xhr.abort();
	}
}

/**
 * Service synchronizer can be used to synchronize the responses of API requests.
 *
 * Useful to ensure that requests are received sorted by their request order and that older requests are discarded.
 */
export class ServiceSync {
	/**
	 * Queue of requests performed from the service sync tool with their ID.
	 *
	 * The list is always sorted by ID (ascending).
	 */
	public requests: ServiceRequestSync[] = [];

	public constructor() {
		this.reset();
	}

	/**
	 * Clear the list of requests performed.
	 */
	public reset(): void {
		try {
			for (let i = 0; i < this.requests.length; i++) {
				this.requests[i].cancel();
			}
		} catch (e) {
			console.warn('EQS: Failed to cancel XHR request.', e);
		}

		this.requests = [];
	}

	/**
	 * Process responses from service calls. Starts with the requests at the beginning of the list.
	 *
	 * Check if there are waiting requests that need to be processed first or that are still waiting a response.
	 */
	public process(): void {
		while (this.requests.length > 0) {
			if (this.requests[0].status >= ServiceRequestSyncStatus.DATA_RECEIVED) {
				const req = this.requests.shift();
				req.status = ServiceRequestSyncStatus.RESOLVED;

				if (req.success) {
					req.resolve({
						response: req.response,
						status: req.request.xhr.status,
						id: req.request.id
					});
				} else {
					req.reject(req.response);
				}
			} else {
				break;
			}
		}
	}

	/**
	 * Fetch a service to the server defined in the meta object.
	 *
	 * Only returns a response when all previous waiting requests have been processed.
	 *
	 * Data is automatically converted to the format used for sending (JSON, ...) if possible.
	 *
	 * @param meta - Service metadata.
	 * @param urlData - Data to be added to the request URL.
	 * @param headerData - Data to be sent in the request header.
	 * @param bodyData - Object to be posted.
	 * @param session - Session ID to be sent to the server for this specific request.
	 * @param hideLoading - If true the loading indicator will not be shown to the user.
	 */
	public fetch(meta: ServiceMeta, urlData: any, headerData: any, bodyData: any, session?: string, hideLoading: boolean = false): Promise<ServiceResponse> {
		return new Promise<ServiceResponse>((resolve, reject) => {

			const requestSync: ServiceRequestSync = new ServiceRequestSync(null, resolve, reject);

			const request = Service.call(meta, urlData, headerData, bodyData, session, (response: any) => {
				requestSync.response = response;
				requestSync.status = ServiceRequestSyncStatus.DATA_RECEIVED;
				requestSync.success = true;

				this.process();
			}, (error: any, xhr: XMLHttpRequest): boolean => {

				if (requestSync.status === ServiceRequestSyncStatus.CANCELLED) {
					return false;
				}

				requestSync.response = error;
				requestSync.status = ServiceRequestSyncStatus.DATA_RECEIVED;
				requestSync.success = false;

				this.process();

				return true;
			}, hideLoading);

			requestSync.request = request;

			// Push request to the end of the list
			this.requests.push(requestSync);
		});
	}
}
