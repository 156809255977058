import {Component, OnInit} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';

import {ScreenComponent} from '../../../../../../components/screen/screen.component';
import {App} from '../../../../../../app';
import {RepairWeldingProcedure} from '../../../master-data/repair-welding-procedure';
import {Service} from '../../../../../../http/service';
import {UserPermissions} from '../../../../../../models/users/user-permissions';
import {Modal} from '../../../../../../modal';
import {Locale} from '../../../../../../locale/locale';
import {ServiceList} from '../../../../../../http/service-list';
import {Session} from '../../../../../../session';
import {UnoButtonComponent} from '../../../../../../components/uno/uno-button/uno-button.component';
import {UnoFormModule} from '../../../../../../components/uno-forms/uno-form.module';
import {UnoContentComponent} from '../../../../../../components/uno/uno-content/uno-content.component';
import {RepairWeldingProcedureLayout} from './repair-welding-procedure-layout';

@Component({
	selector: 'masterdata-repair-welding-procedures-page',
	templateUrl: 'repair-welding-procedures.page.html',
	standalone: true,
	imports: [UnoContentComponent, UnoFormModule, UnoButtonComponent, TranslateModule]
})
export class RepairWeldingProceduresPage extends ScreenComponent implements OnInit {
	
	public layout: any = RepairWeldingProcedureLayout;

	public permissions = [UserPermissions.REPAIR_WELDING_PROCEDURES_EDIT];

	/**
	 * List of repair welding procedures available in database.
	 */
	public procedures: RepairWeldingProcedure[] = [];

	public ngOnInit(): void {
		super.ngOnInit();
		
		App.navigator.setTitle('weldingProcedures');

		RepairWeldingProcedure.load().then((procedures: RepairWeldingProcedure[]) => {
			this.procedures = procedures;
		});
	}

	/**
	 * Add a welding procedures test to the list.
	 */
	public add(): void {
		this.procedures.push(new RepairWeldingProcedure());
	}

	/**
	 * Delete the procedure from the list.
	 *
	 * @param procedure - Element to be deleted from list.
	 */
	public delete(procedure: RepairWeldingProcedure): void {
		Modal.confirm(Locale.get('confirm'), Locale.get('confirmDelete')).then((confirm) => {
			if (confirm) {
				Service.call(ServiceList.repairs.weldingProcedure.delete, null, null, {id: procedure.id}, Session.session, (response, xhr) => {
					const index = this.procedures.indexOf(procedure);
					if (index !== -1) {
						this.procedures.splice(index, 1);
					}
				});
			}
		});
	}

	/**
	 * Update the checklist value in the server.
	 */
	public async save(): Promise<void> {
		const confirm = await Modal.confirm(Locale.get('confirm'), Locale.get('masterdataConfirm'));
		if (confirm) {
			await Service.fetch(ServiceList.repairs.weldingProcedure.updateBulk, null, null, this.procedures, Session.session);
			Modal.toast(Locale.get('updatedSuccessfully'));
			App.navigator.pop();
		}
	}

}
