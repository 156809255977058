<div class="uno-asset-selector" style="display: flex; align-items: center; gap: 16px;">
	<div class="uno-asset-selector-selectable" (click)="this.openAssetModal();">
		@if(this.value) {
			@for(option of this.options; track option) {
				<span class="uno-asset-selector-selectable-text">{{option.name}}</span>
			}
		} @else {
			<span class="uno-asset-selector-selectable-empty">{{'empty' | translate}}</span>
		}
		<div class="uno-asset-selector-select-icon">
			<uno-icon src="assets/icons/assets/expand-icon.svg" color="primary" width="32" height="32"></uno-icon>
		</div>
	</div>

	<div style="display: flex; gap: 8px;">
		<!-- Qr -->
		@if (app.device.hasCamera && ([permissions.ASSET_PORTFOLIO_QR] | hasPermissions) && !this.disabled) {
			<uno-icon src="assets/components/menu/qrcode.svg" color="primary" width="32" height="32" (click)="this.selectAssetQR();"></uno-icon>
		}
		<!-- Nfc -->
		@if (app.device.hasNFC() && ([permissions.ASSET_PORTFOLIO_NFC] | hasPermissions) && !this.disabled) {
			<uno-icon src="assets/components/menu/nfc.svg" color="primary" width="32" height="32" (click)="this.selectAssetNFC();"></uno-icon>
		}
		<!-- Clear -->
		@if (this.value && !this.disabled && this.showClear) {
			<uno-icon src="assets/icons/uno/bin.svg" color="primary" width="32" height="32" (click)="this.writeValue(null);"></uno-icon>
		}
		<!-- Create -->
		@if (([permissions.ASSET_PORTFOLIO_ASSET_CREATE] | hasPermissions) && !this.value && !this.disabled) {
			<uno-icon src="assets/icons/uno/add.svg" color="primary" width="32" height="32" (click)="this.createAssetModal();"></uno-icon>
		}
	</div>
</div>
