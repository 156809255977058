/**
 * Service status contains possible request status returned by the server according to RFC.
 *
 * These values should be used when checking for response codes.
 */
export const ServiceStatus = {
	continue: 100, // RFC 7231, 6.2.1
	switchingProtocols: 101, // RFC 7231, 6.2.2
	processing: 102, // RFC 2518, 10.1

	oK: 200, // RFC 7231, 6.3.1
	created: 201, // RFC 7231, 6.3.2
	accepted: 202, // RFC 7231, 6.3.3
	nonAuthoritativeInfo: 203, // RFC 7231, 6.3.4
	noContent: 204, // RFC 7231, 6.3.5
	resetContent: 205, // RFC 7231, 6.3.6
	partialContent: 206, // RFC 7233, 4.1
	multiStatus: 207, // RFC 4918, 11.1
	alreadyReported: 208, // RFC 5842, 7.1
	iMUsed: 226, // RFC 3229, 10.4.1

	multipleChoices: 300, // RFC 7231, 6.4.1
	movedPermanently: 301, // RFC 7231, 6.4.2
	found: 302, // RFC 7231, 6.4.3
	seeOther: 303, // RFC 7231, 6.4.4
	notModified: 304, // RFC 7232, 4.1
	useProxy: 305, // RFC 7231, 6.4.5
	temporaryRedirect: 307, // RFC 7231, 6.4.7
	permanentRedirect: 308, // RFC 7538, 3

	badRequest: 400, // RFC 7231, 6.5.1
	unauthorized: 401, // RFC 7235, 3.1
	paymentRequired: 402, // RFC 7231, 6.5.2
	forbidden: 403, // RFC 7231, 6.5.3
	notFound: 404, // RFC 7231, 6.5.4
	methodNotAllowed: 405, // RFC 7231, 6.5.5
	notAcceptable: 406, // RFC 7231, 6.5.6
	proxyAuthRequired: 407, // RFC 7235, 3.2
	requestTimeout: 408, // RFC 7231, 6.5.7
	conflict: 409, // RFC 7231, 6.5.8
	gone: 410, // RFC 7231, 6.5.9
	lengthRequired: 411, // RFC 7231, 6.5.10
	preconditionFailed: 412, // RFC 7232, 4.2
	requestEntityTooLarge: 413, // RFC 7231, 6.5.11
	requestURITooLong: 414, // RFC 7231, 6.5.12
	unsupportedMediaType: 415, // RFC 7231, 6.5.13
	requestedRangeNotSatisfiable: 416, // RFC 7233, 4.4
	expectationFailed: 417, // RFC 7231, 6.5.14
	teapot: 418, // RFC 7168, 2.3.3
	unprocessableEntity: 422, // RFC 4918, 11.2
	locked: 423, // RFC 4918, 11.3
	failedDependency: 424, // RFC 4918, 11.4
	upgradeRequired: 426, // RFC 7231, 6.5.15
	preconditionRequired: 428, // RFC 6585, 3
	tooManyRequests: 429, // RFC 6585, 4
	requestHeaderFieldsTooLarge: 431, // RFC 6585, 5
	unavailableForLegalReasons: 451, // RFC 7725, 3

	internalServerError: 500, // RFC 7231, 6.6.1
	notImplemented: 501, // RFC 7231, 6.6.2
	badGateway: 502, // RFC 7231, 6.6.3
	serviceUnavailable: 503, // RFC 7231, 6.6.4
	gatewayTimeout: 504, // RFC 7231, 6.6.5
	hTTPVersionNotSupported: 505, // RFC 7231, 6.6.6
	variantAlsoNegotiates: 506, // RFC 2295, 8.1
	insufficientStorage: 507, // RFC 4918, 11.5
	loopDetected: 508, // RFC 5842, 7.2
	notExtended: 510, // RFC 2774, 7
	networkAuthenticationRequired: 511 // RFC 6585, 6
};
