import {CommonModule} from '@angular/common';
import {Component, Input, TemplateRef} from '@angular/core';


@Component({
	selector: 'uno-options-icon-mobile-modal',
	template: '<ng-container *ngTemplateOutlet="template"></ng-container>',
	standalone: true,
	imports: [CommonModule]
})
export class UnoOptionIconMobileModal {
	/** 
	 * Holds the ng-template for the modal
	 */
	@Input()
	public template: TemplateRef<unknown>;

	/** 
	 * Index of the currently selected option
	 */
	@Input()
	public selectedOptionIndex: number;

	/** 
	 * Scrolls to currently selected option 
	 */
	public ngAfterViewInit(): void {
		const element = document.querySelector('#option' + this.selectedOptionIndex); 
		element.scrollIntoView();
	}
}
