<div style="height: 110px; margin: 10px;">
	<uno-searchbar style="width: 100%;" [value]="selfStatic.filters.search" (valueChange)="this.onSearchChange($event)"></uno-searchbar>

	<uno-filter-bar [filters]="selfStatic.filters" [options]="selfStatic.filterOptions" (onChange)="this.onFilterChange($event);"></uno-filter-bar>
</div>

<uno-content [ngStyle]="{height: 'calc(100% - 130px)'}">
	<uno-responsive-table-list [loadMore]="this.loadTableItems" [totalItems]="this.tableTotalItemsCount" [layout]="this.tableLayout" [pageSize]="selfStatic.tablePageSize" [selectable]="false" [labelShown]="true" (rowClick)="app.navigator.navigate('/menu/observations/edit', {uuid: $event.element.uuid});" (sortChange)="this.sortChanged($event.sortBy)">
	</uno-responsive-table-list>
</uno-content>
