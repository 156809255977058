import {Resource} from '../resource';
import {DL50InspectionQuestionResult} from './dl50-inspection-question-result';

/**
 * DL50 inspection field response,
 */
export class DL50InspectionFieldResponse {
	/**
	 * If true, the test assessment is not applicable.
	 */
	public notApplicable: boolean = false;

	/**
	 * The evaluation done to this test assessment.
	 */
	public evaluation: DL50InspectionQuestionResult = DL50InspectionQuestionResult.NONE;
	
	/**
	 * The photos related to the test assessment.
	 */
	public photos: Resource[] = [];
	
	/**
	 * The comments related to the test assessment.
	 */
	public comments: string = '';

	/**
	 * Parse data into the correct object format.
	 *
	 * @param data - Data to be parsed.
	 * @returns Object of the correct type.
	 */
	public static parse(data: any): DL50InspectionFieldResponse {
		const response = new DL50InspectionFieldResponse();

		response.notApplicable = data.notApplicable === true;
		response.evaluation = data.evaluation;
		response.photos = data.photos ? data.photos : [];
		response.comments = data.comments;

		return response;
	}
}
