import {Group, Camera, Object3D, Vector3} from 'three';
import {Mouse} from '../input/mouse';
import {Keyboard} from '../input/keyboard';

/**
 * Editor controls objects is used to control the camera inside the editor.
 */
export abstract class EditorControls extends Group {
	/**
	 * Camera object attached to these controls.
	 */
	public camera: Camera = null;

	/**
	 * Indicates if the editor controls are enabled.
	 *
	 * If disabled input actions are not applied.
	 */
	public enabled: boolean = true;

	/**
	 * Attach a camera to this controls object.
	 */
	// @ts-ignore
	public attach(camera: Camera): void {
		while (this.children.length > 0) {
			this.remove(this.children[0]);
		}
		this.add(camera);

		this.camera = camera;
		this.updateControls();
	}

	/**
	 * Reset the controls to its original position.
	 */
	public reset(): void {}

	/**
	 * Focus camera on an object.
	 */
	public focusObject(object: Object3D): void {}

	/** 
	 * Set controls orientation, using orientation code form OrientationCube
	 */
	public setOrientation(orientation: number): void {}

	/**
	 * Update the orbit controls position.
	 */
	public update(mouse: Mouse, keyboard: Keyboard, delta: number): void {}

	/**
	 * Update controls position and rotation.
	 *
	 * Should be called if some of its properties are changed manually.
	 */
	public updateControls(): void {}

	/**
	 * Move the controller to a point.
	 */
	public moveTo(point: Vector3): void {}

	/**
	 * Check if the controls position is changing.
	 *
	 * Used to decide if It's necessary to redraw the scene or not.
	 *
	 * If the controls are static there is no need to redraw unless a object is moving.
	 */
	public isMoving(): boolean {
		return true;
	}
}

