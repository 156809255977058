import {UnoFormField} from '../../../../../components/uno-forms/uno-form/uno-form-field';
import {UnoFormFieldTypes} from '../../../../../components/uno-forms/uno-form/uno-form-field-types';

export const MotCmpLayoutSimplified: UnoFormField[] = [
	{
		label: 'uuid',
		attribute: 'uuid',
		type: UnoFormFieldTypes.UUID
	},
	{
		required: true,
		attribute: 'name',
		label: 'name',
		type: UnoFormFieldTypes.TEXT
	},
	{
		required: false,
		attribute: 'description',
		label: 'description',
		type: UnoFormFieldTypes.TEXT_MULTILINE
	},
	{
		required: false,
		attribute: 'voltage',
		label: 'voltage',
		type: UnoFormFieldTypes.NUMBER
	}
];

export const MotCmpLayout = MotCmpLayoutSimplified.concat([
	{
		required: true,
		label: 'position',
		attribute: 'position',
		type: UnoFormFieldTypes.GEO_POSITION
	}
]);
