/**
 * Async utils contains tools for easier async usage.
 */
export class AsyncUtils {

	/**
	 * Set an await timer.
	 * 
	 * @param time - How long in ms to await.
	 */
	public static async await(time: number): Promise<void> {
		return new Promise(function(resolve, reject) {
			setTimeout(resolve, time);
		});
	}
}
