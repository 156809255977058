import {UUID, UUIDIdentification} from '../uuid';

/**
 * Asset sub-type is used to define even more specific details of an asset type. An asset type may have multiple specifications/sub-types. Do not confuse with child types of another type.
 */
export class APAssetSubType extends UUIDIdentification {
	/**
	 * Name of the asset sub-type. This name must be unique.
	 */
	public name: string = '';

	/**
	 * Description of the asset sub-type.
	 */
	public description: string = '';

	/**
	 * The UUID of the selected type parent.
	 */
	public typeUuid: UUID = null;

	/**
	 * The UUID of the selected asset sub-type parent
	 */
	public parentSubTypeUuid: UUID = null;

	/**
	 * Parse JSON data received from the API into an object of the correct type.
	 *
	 * @param data - Object received from API.
	 * @returns APAssetSubType object instance built from the data received.
	 */
	public static parse(data: any): APAssetSubType {
		const subType = new APAssetSubType();

		subType.uuid = data.uuid;
		subType.updatedAt = new Date(data.updatedAt);
		subType.createdAt = new Date(data.createdAt);

		subType.name = data.name;
		subType.description = data.description;

		subType.typeUuid = data.typeUuid;
		subType.parentSubTypeUuid = data.parentSubTypeUuid;

		return subType;
	}
}
