import {UnoFormField} from 'src/app/components/uno-forms/uno-form/uno-form-field';
import {UnoFormFieldTypes} from 'src/app/components/uno-forms/uno-form/uno-form-field-types';

export const DL50QuestionLayout: UnoFormField[] = [
	{
		label: 'uuid',
		attribute: 'uuid',
		type: UnoFormFieldTypes.UUID
	},
	{
		required: true,
		attribute: 'label',
		label: 'name',
		type: UnoFormFieldTypes.TEXT,
		isEmpty: (object: any, row: UnoFormField): boolean => {
			return !object || !object.label || object?.label?.length === 0;
		}
	},
	{
		required: false,
		attribute: 'description',
		label: 'description',
		type: UnoFormFieldTypes.TEXT_MULTILINE
	},
	{
		required: false,
		attribute: 'article',
		label: 'article',
		type: UnoFormFieldTypes.TEXT
	},
	{
		required: false,
		attribute: 'possibleGaps',
		label: 'possibleGaps',
		type: UnoFormFieldTypes.REPETITIVE_FORM,
		fields: [
			{
				label: 'uuid',
				attribute: 'uuid',
				type: UnoFormFieldTypes.UUID
			},
			{
				required: true,
				label: 'name',
				attribute: 'label',
				type: UnoFormFieldTypes.TEXT,
				isEmpty: (object: any, row: UnoFormField): boolean => {
					return !object || !object.label || object?.label?.length === 0;
				}
			},
			{
				required: false,
				label: 'description',
				attribute: 'description',
				type: UnoFormFieldTypes.TEXT_MULTILINE
			}
		]
	}
];
