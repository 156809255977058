<div [ngStyle]="this.ngStyle" class="uno-card">
	@if (this.badge) {
		<uno-badge class="uno-card-badge ion-float-end">{{this.badge}}</uno-badge>
	}

	@if (this.icon || this.title) {
		<ion-card-header>
			@if (this.icon) {
				<ion-icon name="{{this.icon}}" src="{{this.icon}}" color="light" style="width: 30px; height: 30px;"></ion-icon>
			}
			@if (this.title) {
				<ion-card-title color="medium" style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden;">{{this.title}}</ion-card-title>
			}
		</ion-card-header>
	}

	<ng-content></ng-content>
</div>
