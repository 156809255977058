import {Component, Input, forwardRef, ViewEncapsulation} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {ArrayUtils} from '../../../utils/array-utils';
import {UnoFormField} from '../uno-form/uno-form-field';
import {UnoFormUtils} from '../uno-form/uno-form-utils';

@Component({
	selector: 'uno-form-repetitive-field',
	templateUrl: './uno-form-repetitive-field.component.html',
	styleUrls: ['./uno-form-repetitive-field.component.css'],
	encapsulation: ViewEncapsulation.None,
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => { return UnoFormRepetitiveFieldComponent; }),
		multi: true
	}]
})
export class UnoFormRepetitiveFieldComponent implements ControlValueAccessor {
	/**
	 * Allow the input to be disabled.
	 */
	@Input()
	public disabled = false;

	/**
	 * Row of the form being edited in this field, used to fetch additional details.
	 */
	@Input()
	public row: UnoFormField = null;

	/**
	 * The layout used to represent each one of this object items
	 */
	@Input()
	public layout: UnoFormField[] = [];
		
	/**
	 * Flag to control if required message should be presented for required fields when they are disabled.
	 */
	@Input()
	public showRequiredUneditable: boolean = true;

	/**
	 * Array of objects to represent.
	 */
	public items: any[] = [];

	public onChange: (value: any)=> void = function(value) {};

	public registerOnChange(onChange: any): void {
		this.onChange = (v) => {onChange(this.items);};
	}

	public registerOnTouched(fn: any): void {}

	public writeValue(value: any): void {
		this.items = value;
		this.onChange(this.items);
	}

	public setDisabledState(disabled: boolean): void {
		this.disabled = disabled;
	}

	public setItem(index: number, value: any): void {
		this.items[index] = value;
		this.writeValue(this.items);
	}

	/**
	 * Remove an item from the list.
	 *
	 * @param index - Index of the item to be removed.
	 */
	public removeItem(index: number): void {
		if (index !== -1 && !UnoFormUtils.checkBool(this.row.disableDelete, false, this.items, this.row)) {
			this.items.splice(index, 1);
			this.writeValue(this.items);
		}
	}

	/**
	 * Add a new item to the list.
	 */
	public addItem(): void {
		if (!(this.items instanceof Array)) {
			this.items = [];
		}
		
		let item: any;

		if (this.row.createItem) {
			item = this.row.createItem();
		} else {
			item = {};
		}

		this.items.push(item);
		this.writeValue(this.items);
	}

	/**
	 * Move item up in the list of items.
	 */
	public moveFieldUp(index: number): void {
		if (index < this.items.length - 1 && index !== -1 && !UnoFormUtils.checkBool(this.row.disableSort, false, this.items, this.row)) {
			ArrayUtils.move(this.items, index, index + 1);
		}
	}

	/**
	 * Move item down in the list of items.
	 */
	public moveFieldDown(index: number): void {
		if (index > 0 && !UnoFormUtils.checkBool(this.row.disableSort, false, this.items, this.row)) {
			ArrayUtils.move(this.items, index, index - 1);
		}
	}
}
