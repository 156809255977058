<!-- Tree list -->
<div [ngStyle]="this.ngStyle" class="digital-twin-tree">
	@if (this.objects) {
		@for (obj of this.objects; track obj) {
			@if ($any(obj).isDigitalTwinObject) {
				<digital-twin-tree-object [editor]="this.editor" [level]="0" [object]="obj"></digital-twin-tree-object>
			}
		}
	}
</div>

<!-- Collapse button -->
<div [ngStyle]="{right: this.width + 'px'}" class="digital-twin-tree-collapse-button" (click)="this.toggleCollapsed();">
	<ion-icon style="left: 5px; top: 10px; position: absolute; font-size: 20px;" [name]="this.collapsed ? 'caret-back-outline' : 'caret-forward-outline'"></ion-icon>
</div>
