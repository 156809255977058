import {Component, Input, ViewEncapsulation, forwardRef, OnDestroy, ChangeDetectorRef, ViewChild, ElementRef, WritableSignal, signal} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import Viewer from 'viewerjs';
import {TranslateModule} from '@ngx-translate/core';
import {ResizeDetector} from 'src/app/utils/resize-detector';
import {EventManager} from 'src/app/utils/event-manager';
import {App} from 'src/app/app';
import {NgClass} from '@angular/common';
import {Service} from '../../../http/service';
import {ServiceList} from '../../../http/service-list';
import {FileUtils} from '../../../utils/file-utils';
import {Resource} from '../../../models/resource';
import {ImageUtils} from '../../../utils/image-utils';
import {Session} from '../../../session';
import {Environment} from '../../../../environments/environment';
import {UnoButtonComponent} from '../../uno/uno-button/uno-button.component';
import {UnoIconComponent} from '../../uno/uno-icon/uno-icon.component';

@Component({
	selector: 'uno-image-selector',
	templateUrl: './uno-image-selector.component.html',
	encapsulation: ViewEncapsulation.None,
	styleUrls: ['uno-image-selector.component.css'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => { return UnoImageSelectorComponent; }),
			multi: true
		}
	],
	standalone: true,
	imports: [UnoButtonComponent, TranslateModule, UnoIconComponent, NgClass]
})
export class UnoImageSelectorComponent implements ControlValueAccessor, OnDestroy {
	public app: any = App;

	@ViewChild('upload', {static: false})
	public upload: ElementRef = null;

	@ViewChild('imageContainer', {static: false})
	public set waitForImageContainer(element: ElementRef) {
		if (element) {
			this.imageContainer = element;
			this.setImageContainerLimit();
			this.resize = new ResizeDetector(this.imageContainer.nativeElement, () => {
				if (!this.expandedImages()) {
					this.setImageContainerLimit();
				}
			});
		}
	}

	/**
	 * Container that displays the images
	 */
	public imageContainer: ElementRef | null = null;

	/**
	 * If true allow to select multiple files.
	 */
	@Input()
	public multiple: boolean = true;

	/**
	 * Allow the input to be disabled.
	 */
	@Input()
	public disabled: boolean = false;

	/**
	 * If set true, the images are compressed before they are sent to the server.
	 *
	 * Uses the configuration stored in the user settings.
	 */
	@Input()
	public compress: boolean = true;

	/**
	 * Local flag control if the file should be stored on resource server or stored in memory.
	 */
	@Input()
	public local: boolean = false;

	/**
	 * Resource representing the images stored.
	 */
	public value: (Resource[] | File[] | Resource | File) = [];

	/**
	 * Resource representing the images that are displayed.
	 */
	public displayedValue: Resource | Resource[] | File | File[] = [];

	/**
	 * URL of the images stored.
	 */
	public urls: string[] = [];

	/**
	 * Image viewer if there is one active. Should be destroyed when the component is destroyed.
	 */
	public viewer: Viewer = null;

	/**
	 * Method called when the data is changed.
	 */
	public onChange: (value: any)=> void = function(value) { };

	/**
	 * Formats of images supported.
	 */
	public static FORMATS: string = '.jpeg, .jpg, .gif, .bpm, .png';

	/**
	 * The number of images that are not displaying and will be displayed once the button is clicked
	 */
	public numberOfExtraImgs: WritableSignal<number> = signal(0);

	/**
	 * Controls if all images are displayed
	 */
	public expandedImages: WritableSignal<boolean> = signal(true);

	/**
	 * Resize observer
	 */
	public resize: ResizeDetector = null;

	/**
	 * Event manager used for drag and drop
	 */
	public events: EventManager = new EventManager();

	/**
	 * Controls if a file is being dragged
	 */
	public isDragging: boolean = false;

	public constructor(public ref: ChangeDetectorRef) { }

	public ngAfterContentInit(): void {
		if (this?.upload?.nativeElement) {
			['dragenter', 'dragover', 'dragleave', 'drop'].forEach((eventName) => {
				this.events.add(this.upload.nativeElement, eventName, (event) => {
					event.preventDefault();
					event.stopPropagation();
				});
			});
			
			this.events.add(this.upload.nativeElement, 'drop', (event) => {
				this.dropImage(event);
				this.isDragging = false;
			});

			this.events.add(this.upload.nativeElement, 'dragenter', () => {
				this.isDragging = true;
			});

			this.events.add(this.upload.nativeElement, 'dragleave', () => {
				this.isDragging = false;
			});
		}

		this.events.create();
	}


	/**
	 * Update the list of URL to display images on the component.
	 */
	public updateUrls(): string[] {
		const urls = [];

		// @ts-ignore
		const values: (Resource | File)[] = this.multiple && this.value instanceof Array ? this.value : this.value ? [this.value] : [];

		for (let i = 0; i < values.length; i++) {
			if (this.local) {
				urls.push(window.URL.createObjectURL(values[i] as File));
			} else {
				const image = values[i] as Resource;
				urls.push(Service.getURL(ServiceList.resources.image.get, {
					uuid: image.uuid,
					format: image.format
				}));
			}
		}

		return urls;
	}

	/**
	 * Open the image viewer to show the image in detail.
	 *
	 * @param resource - Image resource to focus when the viewer is opened.
	 */
	public viewImage(resource: Resource | File): void {
		if (this.viewer !== null) {
			if (!Environment.PRODUCTION) {
				console.warn('EQS: There is already one viewer opened.', this);
			}
			return;
		}

		const values: (Resource | File)[] = this.value instanceof Array ? this.value : [this.value];

		// Show all images in the viewer
		const ul = document.createElement('ul');
		let initialViewIndex = 0;
		for (let i = 0; i < values.length; i++) {
			const li = document.createElement('li');

			const image = document.createElement('img');
			image.src = this.urls[i];

			if (this.value[i] === resource) {
				initialViewIndex = i;
			}

			li.appendChild(image);
			ul.appendChild(li);
		}

		this.viewer = new Viewer(ul, {
			hidden: () => {
				this.viewer.destroy();
				this.viewer = null;
			},
			title: false,
			transition: false,
			initialViewIndex: initialViewIndex,
			zIndex: 1e8
		});

		this.viewer.show();
	}

	public ngOnDestroy(): void {
		if (this.viewer) {
			this.viewer.destroy();
			this.viewer = null;
		}

		if (this.resize) {
			this.resize.destroy();
		}
		
		this.events.destroy();
	}

	/**
	 * Remove an image from the list.
	 *
	 * @param resource - Resource of the image to be removed.
	 */
	public removeImage(resource: Resource | File): void {
		if (this.multiple) {
			if (this.value instanceof Array) {
				// @ts-ignore
				const index = this.value.indexOf(resource);
				if (index !== -1) {
					this.value.splice(index, 1);
					this.value = [].concat(this.value);
					this.writeValue(this.value);
				}
			} else {
				throw new Error('Value must be an array in multiple mode');
			}
		} else {
			this.writeValue(null);
		}
	}

	/**
	 * Select image file(s) selected by the user with a file chooser.
	 */
	public async selectFiles(): Promise<void> {
		const files: File[] = await FileUtils.chooseFile(UnoImageSelectorComponent.FORMATS, this.multiple);

		if (this.multiple && this.value instanceof Array) {
			const values: any[] = this.value.slice();
			for (const file of files) {
				if (this.local) {
					values.push(file as any);
				} else {
					values.push(await this.uploadFile(file));
				}
			}
			this.writeValue(values);
		} else if (files.length > 0) {
			const file = files[0];
			if (this.local) {
				this.writeValue(file as any);
			} else {
				this.writeValue(await this.uploadFile(file));
			}
		}
	}

	/**
	 * Upload an image file to the resource server. Depending on the configuration, the image may be compressed before upload.
	 *
	 * @param file - Image file to be uploaded.
	 */
	public async uploadFile(file: File): Promise<Resource> {
		if (this.local) {
			throw new Error('When local is set true, uploadFile() should not be called');
		}

		if (this.compress && Session.settings.compressPictures) {
			file = await ImageUtils.compressImage(file, Session.settings.pictureMaxSize, Session.settings.pictureFormat, Session.settings.pictureQuality);
		}

		const format = FileUtils.getFileExtension(file);

		const form = new FormData();
		form.append('file', file, 'image');
		form.append('format', format);

		const request = await Service.fetch(ServiceList.resources.image.upload, null, null, form, null);

		return new Resource(request.response.uuid, format);
	}

	public registerOnChange(onChange: any): void {
		this.onChange = onChange;
	}

	public writeValue(value: (Resource[] | File[] | Resource | File)): void {
		if (!value) {
			value = this.multiple ? [] : null;
		}

		this.value = value;
		this.urls = this.updateUrls();
		this.onChange(this.value);
		this.ref.detectChanges();
		if (this.imageContainer) {this.setImageContainerLimit();}
	}

	public registerOnTouched(fn: any): void { }

	public setDisabledState(disabled: boolean): void {
		this.disabled = disabled;
	}

	/**
	 * Saves the image via drag and drop
	 */
	public async dropImage(event: any): Promise<void> {
		const files = event?.dataTransfer?.files;

		if (files) {
			if (this.multiple && this.value instanceof Array) {
				const values: any[] = this.value.slice();
				for (const file of files) {
					if (this.local) {
						values.push(file as any);
					} else {
						values.push(await this.uploadFile(file));
					}
				}
				this.writeValue(values);
			} else if (files.length > 0) {
				const file = files[0];
				if (this.local) {
					this.writeValue(file as any);
				} else {
					this.writeValue(await this.uploadFile(file));
				}
			}
		}


		event.preventDefault();
	}

	/**
	 * Calculates the ammount of images that can be displayed
	 */
	public setImageContainerLimit(): void {
		if (this.multiple && this.value instanceof Array) {
			const margin = 16;
			const imgWidth = 86 + margin;
			const maxwidth = this.imageContainer.nativeElement.offsetWidth;
			const numberOfImgs = Math.floor(maxwidth / imgWidth);
			this.numberOfExtraImgs.set(this.value.length - numberOfImgs);
			if (this.numberOfExtraImgs() < 1) {
				this.expandedImages.set(true);
				this.displayedValue = this.value;
			} else {
				this.expandedImages.set(false);
				this.displayedValue = this.value.slice(0, numberOfImgs);
			}
		} else {
			this.displayedValue = this.value;
		}
	}

	/**
	 * Displays every image
	 */
	public displayAllImages(): void {
		this.displayedValue = this.value;
		this.expandedImages.set(true);
	}
}
