/**
 * Possible results of an Atex inspection.
 */
export const AtexInspectionResult = {
	NONE: 0,
	APPROVED: 1,
	FAILED: 2,
	CONDITIONALLY_APPROVED: 3
};

/**
 * Possible results of an Atex inspection.
 */
export const AtexInspectionResultFilters = {...AtexInspectionResult, ALL: -1};

/**
 * Colors to represent each Atex inspection result.
 */
export const AtexInspectionResultColor: Map<number, string> = new Map([
	[AtexInspectionResult.NONE, 'var(--gray-5)'],
	[AtexInspectionResult.APPROVED, 'var(--success-normal)'],
	[AtexInspectionResult.FAILED, 'var(--error-normal)'],
	[AtexInspectionResult.CONDITIONALLY_APPROVED, 'var(--warning-normal)']
]);

/**
 * Labels presents on the GUI for each Atex inspection result.
 */
export const AtexInspectionResultLabel: Map<number, string> = new Map([
	[AtexInspectionResultFilters.ALL, 'all'],
	[AtexInspectionResultFilters.NONE, 'none'],
	[AtexInspectionResultFilters.APPROVED, 'approved'],
	[AtexInspectionResultFilters.FAILED, 'nonCompliant'],
	[AtexInspectionResultFilters.CONDITIONALLY_APPROVED, 'conditionallyApproved']
]);
