/**
 * State of the EPI inspection.
 */
export const EPIState = {
	ALL: -1,
	TODO: 1,
	BACKOFFICE: 2,
	COMPLETED: 3
};

export const EPIStateLabel: Map<number, string> = new Map([
	[EPIState.ALL, 'all'],
	[EPIState.TODO, 'todo'],
	[EPIState.BACKOFFICE, 'backoffice'],
	[EPIState.COMPLETED, 'completed']
]);
