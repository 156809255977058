import {Node, NodeSocket, Object2D, Text, Vector2} from 'escher.js/build/escher.module.js';
import {Locale} from '../../../../locale/locale';

export class SensorResultNode extends Node {
	/**
	 * Input socket from where the result expression should be obtained.
	 */
	public r: NodeSocket = null;

	public constructor() {
		super();

		// @ts-ignore
		this.type = 'SensorResultNode';

		// @ts-ignore
		this.box.set(new Vector2(-60, -20), new Vector2(60, 20));

		const text = new Text();
		text.serializable = false;
		text.text = Locale.get('result');
		text.font = '12px Arial';
		text.layer = 2;
		// @ts-ignore
		this.add(text);
	}

	public registerSockets(): void {
		if (!this.r) {
			// @ts-ignore
			this.r = this.addInput('string', 'r');
		}
	}

	public serialize(recursive): void {
		// @ts-ignore
		const data = super.serialize(recursive);
		data.r = this.r !== null ? this.r.uuid : null;
		return data;
	}

	public parse(data, root): void {
		// @ts-ignore
		super.parse(data, root);

		if (data.r !== null) {
			this.r = root.getChildByUUID(data.r);
		}
	}
}

Object2D.register(SensorResultNode, 'SensorResultNode');
