import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {AtexInspectionsPage} from './screens/menu/atex-inspections.page';
import {AtexInspectionsListPage} from './screens/list/atex-inspections-list.page';
import {AtexInspectionsEditPage} from './screens/edit/atex-inspections-edit.page';
import {AtexInspectionsChecklistPage} from './screens/master-data/atex-inspections-checklist/atex-inspections-checklist.page';
import {AtexInspectionsFieldsPage} from './screens/master-data/atex-inspections-fields/atex-inspections-fields.page';
import {AtexInspectionsSettingsPage} from './screens/master-data/atex-inspections-settings/atex-inspections-settings.page';

const routes: Routes = [
	{
		path: '',
		component: AtexInspectionsPage
	},
	{
		path: 'list',
		component: AtexInspectionsListPage
	},
	{
		path: 'edit',
		component: AtexInspectionsEditPage
	},
	{
		path: 'master-data',
		children: [
			{
				path: 'checklist',
				component: AtexInspectionsChecklistPage
			},
			{
				path: 'fields',
				component: AtexInspectionsFieldsPage
			},
			{
				path: 'settings',
				component: AtexInspectionsSettingsPage
			}
		]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class AtexInspectionsRouterModule { }
