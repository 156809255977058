/**
 * List of possible action plan criticality.
 */
export const GAActionPlanCriticality = {
	LOW: 0,
	MEDIUM: 1,
	HIGH: 2
};

/**
 * List of possible criticality labels for the gap analysis action plan entries.
 */
export const GAActionPlanCriticalityLabel: Map<number, string> = new Map([
	[GAActionPlanCriticality.LOW, 'low'],
	[GAActionPlanCriticality.MEDIUM, 'medium'],
	[GAActionPlanCriticality.HIGH, 'high']
]);
