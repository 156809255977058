import {
	AfterContentChecked,
	Component,
	Input, OnChanges, SimpleChanges, TemplateRef,
	ViewChild,
	ViewEncapsulation
} from '@angular/core';
import {CdkVirtualScrollViewport, CdkFixedSizeVirtualScroll, CdkVirtualForOf} from '@angular/cdk/scrolling';
import {NgTemplateOutlet} from '@angular/common';
import {UnoNoDataComponent} from '../uno-no-data/uno-no-data.component';
import {UnoListLazyLoadHandler} from './uno-list-lazy-load-handler';

/**
 * UNO list component can be used to display information as lists.
 *
 * Uses virtual scrolling to improve performance and supports lazy loading of data.
 */
@Component({
	selector: 'uno-list',
	templateUrl: './uno-list.component.html',
	styleUrls: ['uno-list-component.scss'],
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [UnoNoDataComponent, CdkVirtualScrollViewport, CdkFixedSizeVirtualScroll, CdkVirtualForOf, NgTemplateOutlet]
})
export class UnoListComponent implements AfterContentChecked, OnChanges {
	@ViewChild('viewport', {static: false})
	private viewport: CdkVirtualScrollViewport;

	/**
	 * Template to be used to display each item of the list.
	 */
	@Input()
	public itemTemplate: TemplateRef<any>;

	/**
	 * Lazy loading data handler.
	 */
	@Input()
	public handler: UnoListLazyLoadHandler<any> = null;

	/**
	 * Item size (height), used to calculate viewport position and scrollbar.
	 *
	 * If not known an average value should be provided.
	 */
	@Input()
	public itemSize: number = 50;

	public ngAfterContentChecked(): void {
		if (this.viewport) {
			this.viewport.checkViewportSize();
		}
	}

	public async ngOnChanges(changes: SimpleChanges): Promise<void> {
		if (this.handler) {
			await this.handler.reset();
		}
	}

	/**
	 * Load next page of the list.
	 *
	 * Checks the current index of the list before loading new content.
	 *
	 * @param e - Event received from output.
	 */
	public nextPage(e): void {
		const end = this.viewport.getRenderedRange().end;
		const total = this.viewport.getDataLength();
		if (end === total) {
			this.handler.nextPage();
		}
	}
}
