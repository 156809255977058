import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {App} from '../../../app';
import {Session} from '../../../session';
import {UserPermissions} from '../../../models/users/user-permissions';
import {UnoOptionsLazyComponent} from '../../uno-input/uno-options-lazy/uno-options-lazy.component';
import {UnoButtonComponent} from '../uno-button/uno-button.component';
import {UnoTextComponent} from '../uno-text/uno-text.component';
import {UnoSearchbarComponent} from '../uno-searchbar/uno-searchbar.component';
import {UnoFilterBarOption, UnoFilterBarOptionType} from './uno-filter-bar-option';

/**
 * UNO filter bar is used to present a list of filter applied alongside with list or tables
 */
@Component({
	selector: 'uno-filter-bar',
	templateUrl: './uno-filter-bar.component.html',
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [UnoSearchbarComponent, UnoTextComponent, IonicModule, FormsModule, UnoButtonComponent, TranslateModule, UnoOptionsLazyComponent]
})
export class UnoFilterBarComponent implements OnChanges {
	public filterType = UnoFilterBarOptionType;

	public app: any = App;

	public session: any = Session;

	public userPermissions: any = UserPermissions;

	/**
	 * List of filter to present.
	 */
	@Input()
	public options: UnoFilterBarOption[] = [];

	/**
	 * Object that contains the filter values presented.
	 */
	@Input()
	public filters: any = {};

	/**
	 * Callback method to be called when the filters change.
	 */
	@Output()
	public onChange = new EventEmitter<any>();

	/**
	 * Name of the search attribute if any.
	 *
	 * There can only be one search attribute in the search bar.
	 */
	public searchFilter: string = null;

	public async ngOnChanges(changes: SimpleChanges): Promise<void> {
		// Validate options
		if (changes.options) {
			// Load values from the options
			for (let i = 0; i < this.options.length; i++) {
				if (this.options[i].type === UnoFilterBarOptionType.OPTIONS && this.options[i].fetchOptions) {
					this.options[i].options = await this.options[i].fetchOptions();
				}
			}

			// Check if there is a search filter
			this.searchFilter = null;
			for (let i = 0; i < this.options.length; i++) {
				if (this.options[i].type === UnoFilterBarOptionType.SEARCH) {
					if (this.searchFilter !== null) {
						throw Error('Only a single search field is allowed');
					}

					this.searchFilter = this.options[i].attribute;
				}
			}
		}
	}

	/**
	 * Reset the filters to their default values.
	 */
	public reset(): void {
		UnoFilterBarComponent.reset(this.filters, this.options);
	}

	/**
	 * Reset the filters to their default values.
	 */
	public static reset(data: any, filters: UnoFilterBarOption[]): any {
		for (const f of filters) {
			data[f.attribute] = f.default;
		}

		return data;
	}

	/**
	 * Update filters and reload data from the API if required.
	 *
	 * @param search - Search value
	 */
	public onSearchChange(search: string): void {
		this.filters[this.searchFilter] = search;
	}

	/**
	 * Emit when the multiple lazy component is changed
	 *
	 * @param selected - Selected value
	 * @param attribute - Attribute to be changed.
	 */
	public multipleLazyChange(selected: any, attribute: string): void {
		this.filters[attribute] = selected;
		this.onChange.emit(this.filters);
	}
}
