<!-- Desktop table -->
@if (app.device.isDesktop()) {
	<div style="width: 100%; height: 100%; overflow: hidden; padding: 0px 10px;">
		<uno-table [loadMore]="this.loadMore" [totalItems]="this.totalItems" [layout]="this.layout" [pageSize]="this.pageSize" [selectable]="this.selectable" [rowClickable]="this.rowClickable" (rowClick)="this.rowClick.emit($event);" (rowChecked)="this.rowChecked.emit($event);" (sortChange)="this.sortBy($event.sortBy)">
		</uno-table>
	</div>
}

<!-- Mobile list -->
@if (app.device.isMobile()) {
	<!-- Template for each row -->
	<ng-template #itemTemplate let-item="item">
		<uno-list-item>
			<!-- The Image on each row -->
			<uno-list-item-icon src="{{this.selectable && this.checkedItemList.indexOf(item) !== -1 ? './assets/icons/assets/avatar-check-icon.svg' : this.getImage(item.image)}}" (click)="this.selectable ? this.rowCheck(item) : null"></uno-list-item-icon>

			<!-- The text on each row -->
			<uno-list-item-label (click)="this.rowClick.emit({index: this.handler.items.indexOf(item) , element: item});">

				<!-- For each item on the layout -->
				@for (row of this.layout; track row;) {
					<!-- If the row is visible -->
					@if (row.visible) {
						<!-- If the row is of type status -->
						@if (row.type === unoTableColumnType.STATUS) {
							<div [ngStyle]="{'background-color': item[row.attribute]}" style="display: inline-block; width: 13px; height: 13px; margin: 6px 3px 0px 0px; border-radius: 50%;"></div>
						}
						@else if (row.type === unoTableColumnType.TEXT || row.type === unoTableColumnType.NUMBER) {
							<!-- Switch case for each tag type -->
							@switch (row.tag) {
								@case (listDisplayStyle.TITLE) {
									<span class="uno-responsive-table-list-title"> {{(this.labelShown ? ((row.header | translate) + ': ') : '') + (row.skipTranslation ? item[row.attribute] ?? '-' : ((item[row.attribute] ?? '-') | translate))}}</span>
								}
								@case (listDisplayStyle.TEXT) {
									<span class="uno-responsive-table-list-text"> {{(this.labelShown ? ((row.header | translate) + ': ') : '') + (row.skipTranslation ? item[row.attribute] ?? '-' : ((item[row.attribute] ?? '-') | translate))}}</span>
								}
								@default {
									<span class="uno-responsive-table-list-label"> {{(this.labelShown ? ((row.header | translate) + ': ') : '') + (row.skipTranslation ? item[row.attribute] ?? '-' : ((item[row.attribute] ?? '-') | translate))}}</span>
								}
							}
							<p></p>
						}
					}
				}
			</uno-list-item-label>

			<!-- The Icons with actions -->
			@if (item.actions?.length > 0) {
				<div style="gap: 4px; display: flex; flex-direction: column;">
					@for (icon of item.actions; track icon;) {
						<uno-icon [color]="'primary'" [src]="this.getImage(icon.src)" [height]="32" [width]="32" (click)="icon.click()"></uno-icon>
					}
				</div>
			}
		</uno-list-item>
	</ng-template>

	<div style="width: 100%; height: 100%; overflow: hidden;">
		<uno-list [itemTemplate]="itemTemplate" [handler]="this.handler"></uno-list>
	</div>
}
