import {Component, OnInit, ViewChildren} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {App} from 'src/app/app';
import {ScreenComponent} from 'src/app/components/screen/screen.component';
import {UnoFormModule} from 'src/app/components/uno-forms/uno-form.module';
import {UnoFormComponent} from 'src/app/components/uno-forms/uno-form/uno-form.component';
import {UnoButtonComponent} from 'src/app/components/uno/uno-button/uno-button.component';
import {UnoContentComponent} from 'src/app/components/uno/uno-content/uno-content.component';
import {Service} from 'src/app/http/service';
import {ServiceList} from 'src/app/http/service-list';
import {Locale} from 'src/app/locale/locale';
import {Modal} from 'src/app/modal';
import {AssetFluidType} from 'src/app/models/asset-portfolio/asset-fluid-type';
import {UserPermissions} from 'src/app/models/users/user-permissions';
import {Session} from 'src/app/session';
import {AssetFluidFamily} from 'src/app/models/asset-portfolio/asset-fluid-family';
import {UnoNoDataComponent} from 'src/app/components/uno/uno-no-data/uno-no-data.component';
import {CommonModule} from '@angular/common';
import {KeyValue} from 'src/app/components/uno-input/uno-key-value/uno-key-value.component';
import {AssetFluidService} from '../../../services/asset-fluid.service';
import {AssetFluidTypeLayout} from './asset-fluid-type-layout';


@Component({
	selector: 'asset-fluid-type-page',
	templateUrl: 'asset-fluid-type.page.html',
	styleUrls: ['./asset-fluid-type.page.css'],
	standalone: true,
	imports: [CommonModule, TranslateModule, UnoContentComponent, UnoButtonComponent, UnoFormModule, UnoNoDataComponent]
})

export class AssetFluidTypesPage extends ScreenComponent implements OnInit {
	@ViewChildren('form')
	public forms: UnoFormComponent[] = null;

	public permissions = [UserPermissions.ASSET_PORTFOLIO_FLUID_EDIT];

	public get layout(): any { return AssetFluidTypeLayout;};

	public fluidFamilies: AssetFluidFamily[] = [];

	public async ngOnInit(): Promise<void> {
		super.ngOnInit();

		App.navigator.setTitle('fluidType');

		this.fluidFamilies = (await AssetFluidService.listFamily()).families;
		const fluidTypes = (await AssetFluidService.listTypes({})).types;

		this.fluidFamilies.forEach((fam: AssetFluidFamily) => { 
			const typs = fluidTypes.filter((typ: AssetFluidType) => { return typ.fluidFamilyUuid === fam.uuid; });
			fam.fluidTypes = typs.map((t) => { return new KeyValue(t.uuid, t.label);});
		});

		this.fluidFamilies = structuredClone(this.fluidFamilies);
	}

	/**
	 * Update the asset fluid type values in the server.
	 */
	public async save(stayOnPage: boolean = false): Promise<void> {

		// Check if all the forms are filled.
		for (const form of this.forms) {
			if (!form.requiredFilled()) {
				await Modal.alert(Locale.get('error'), Locale.get('requiredFieldsError'));
				return;
			}
		}

		const fluidTypes: any[] = [];

		for (const family of this.fluidFamilies) {

			family.fluidTypes?.forEach((fluidType) => {
				fluidTypes.push({
					fluidFamilyUuid: family.uuid,
					uuid: fluidType.key,
					label: fluidType.value
				});
			});
		}

		await Service.fetch(ServiceList.assetPortfolio.fluidType.updateBulk, null, null, fluidTypes, Session.session);

		Modal.toast(Locale.get('updatedSuccessfully'));

		if (!stayOnPage) {
	 		App.navigator.pop();
		}
	}
}
