import {Resource} from '../resource';
import {UUID, UUIDIdentification} from '../uuid';

/**
 * Describe details about a company, users and project can be attached to a company.
 *
 * Allows the system to provide company based statistics or restrict a set of data to the users of a company.
 */
export class Company extends UUIDIdentification {
	/**
	 * Name of the company.
	 */
	public name: string = '';

	/**
	 * Email to contact the company, might be used for automatically send emails.
	 */
	public email: string = '';

	/**
	 * Phone contact of the company.
	 */
	public phoneNumber: string = '';

	/**
	 * Physical address of the company.
	 */
	public address: string = '';

	/**
	 * Company's Fiscal/VAT (Value Added Tax) number.
	 */
	public vat: string = '';

	/**
	 * Picture of the company (e.g. logo, building)
	 */
	public picture: Resource = null;

	/**
	 * Parent company ID.
	 */
	public parent: UUID = null;

	/**
	 * Notes about the company.
	 */
	public notes: string = '';

	/**
	 * Representative of the company.
	 */
	public representative: string = '';

	/**
	 * Parse the data field of an company type
	 *
	 * @param data - Company object to parse
	 */
	public static parse(data: Company): Company {
		const company = new Company();

		company.uuid = data.uuid;
		company.createdAt = new Date(data.createdAt);
		company.updatedAt = new Date(data.updatedAt);

		company.name = data.name;
		company.email = data.email;
		company.phoneNumber = data.phoneNumber;
		company.address = data.address;
		company.vat = data.vat;
		company.picture = Resource.parse(data.picture);
		company.parent = data.parent;
		company.notes = data.notes;
		company.representative = data.representative;

		return company;
	}
}
