
/**
 * Enum for all modules available in the application.
 * 
 * Used to hide routes that are not available in the current environment.
 */
export enum Modules {
    /**
     * ATEX inspection module.
     */
    ATEX_INSPECTIONS = 'atex-inspections',

    /**
     * Digital twin module.
     */
    DIGITAL_TWIN = 'digital-twin',

    /**
     * Asset planning module.
     */
    ASSET_PLANNING = 'asset-planning',

    /**
     * CSI (EPI) inspection module.
     */
    EPI = 'epi',

    /**
     * Gap analysis & action plan.
     */
    GAP_ANALYSIS = 'gap-analysis',

    /**
     * DL-50 inspection module.
     */
    DL50 = 'dl-50',

    /**
     * Dynamic inspections module.
     */
    INSPECTIONS = 'inspections',

    /**
     * Observations module.
     */
    OBSERVATIONS = 'observations',

    /**
     * Pipeline integrity module
     */
    PIPELINE_INTEGRITY = 'pipeline-integrity',

    /**
     * Repair and repair inspection.
     */
    REPAIRS = 'repairs',
}
