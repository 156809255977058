import {Service} from '../http/service';
import {ServiceList} from '../http/service-list';
import {UUID} from '../models/uuid';
import {AssetReport} from '../modules/asset-portfolio/data/asset-report';
import {AssetService} from '../modules/asset-portfolio/services/asset.service';
import {InspectionReport} from '../modules/inspections/data/inspection/inspection-report';
import {InspectionService} from '../modules/inspections/services/inspection.service';
import {Session} from '../session';
import {FileUtils} from './file-utils';

/**
 * Utilities to help with the development and test of report templates.
 */
export class ReportUtils {
	/**
	 * Export a report for an asset.
	 * 
	 * @param reportTemplate - URL to remote file or direct path to the template file available in code scope.
	 * @param assetUuid - UUID of the asset to be exported.
	 * @param toPDF - If true should generate PDF file.
	 */
	public static async assetReport(reportTemplate: string, assetUuid: UUID, toPDF: boolean = false): Promise<void> {
		const arraybuffer = await FileUtils.readFileArrayBuffer(reportTemplate);
		const asset = await AssetService.get(assetUuid);

		const report = await AssetReport.generateDocx(asset, arraybuffer);
		if (toPDF) {
			const form = new FormData();
			form.append('file', new Blob([report]), asset.uuid + '.docx');
			const request = await Service.fetch(ServiceList.fileConverter.docxToPdf, null, null, form, Session.session);
			FileUtils.writeFileArrayBuffer('asset.pdf', request.response);
		} else {
			FileUtils.writeFileArrayBuffer('asset.docx', report);
		}
	}

	/**
	 * Auxiliary method to test the generation of inspection reports.
	 *
	 * @param reportTemplate - URL to remote file or direct path to the template file available in code scope.
	 * @param inspectionUuid - UUID of the inspection to be exported.
	 * @param toPDF - If true should generate PDF file.
	 */
	public static async inspectionReport(reportTemplate: string, inspectionUuid: UUID, toPDF: boolean = false): Promise<void> {
		const arraybuffer = await FileUtils.readFileArrayBuffer(reportTemplate);
		const inspection = await InspectionService.get(inspectionUuid);

		let report = await InspectionReport.generateDocx(inspection, arraybuffer, {});
		if (toPDF) {
			const form = new FormData();
			form.append('file', new Blob([report]), inspection.uuid + '.docx');
			report = (await Service.fetch(ServiceList.fileConverter.docxToPdf, null, null, form, Session.session)).response;
			FileUtils.writeFileArrayBuffer('Inspection_Report.pdf', report);
		} else {
			FileUtils.writeFileArrayBuffer('Inspection_Report.docx', report);
		}
	}
}
