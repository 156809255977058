<!-- Desktop -->
@if (app.device.isDesktop()) {
	<!-- Image section -->
	<div style="position: absolute; top:0px; left: 0px; overflow: hidden; border-radius: 0px 0px 60px 0px; width:45%; height:100%;">
		<!-- Background Image -->
		<img [src]="styleManager.loginBackground" [ngStyle]="this.imageStyle" style="object-fit: cover; position: absolute; min-width:calc(100% + 20px); min-height:calc(100% + 20px);">
		<img src="./assets/login/uno-pattern.svg" style="position: absolute; opacity: 0.6; transform: rotate(180deg); top:0px; left:0px; width:183px; height:183px" />
		<!-- Overlay gradients -->
		<div style="position: absolute; mix-blend-mode: multiply; top:0px; left: 0px; opacity:0.5; width:100%; height:100%; background-image: var(--brand-gradient);"></div>
		<!-- Logo -->
		<div style="position: absolute; left:calc(50% - 157px); top:calc(50% - 23px); width:314px; height: 46px">
			<img style="position: absolute;" [ngStyle]="this.logoStyle" src="./assets/login/logo.svg" />
		</div>
	</div>
	<!-- Pattern decoration -->
	<img src="./assets/login/uno-pattern.svg" style="position: absolute; bottom:0px; right:0px; width:64px; height:64px" />
	<!-- Login section -->
	<div style="position: absolute; top:0px; right: 0px; width: 55%; height:100%; overflow-y: auto;">
		<!-- UNO Logo -->
		<img src="./assets/login/uno-symbol.svg" style="position: absolute; left: 20%; top:15%; width:43px; height:43px" />
		<!-- Content -->
		<div style="position: absolute; top: calc(15% + 80px); left: 20%; width: 60%; height: 60%;">
			<router-outlet></router-outlet>
		</div>
		<!-- Language -->
		@if (this.height > 650) {
			<div style="position: absolute; bottom: 90px; left: 25%; width: 50%">
				<ion-select (ionChange)="this.useLocale($event.target.value)" [value]="session.settings.locale" interface="{{app.device.isMobile() ? 'alert' : 'popover'}}">
					@for (locale of localeList; track locale) {
						<ion-select-option [value]="locale.code">{{locale.name | translate}}</ion-select-option>
					}
				</ion-select>
			</div>
		}
		<!-- Version -->
		@if (this.height > 650) {
			<div style="position: absolute; height: 40px; bottom: 30px; left: 25%; width: 50%">
				<uno-text ngClass="copyright" alignment="center">{{environment.VERSION}} {{environment.TIMESTAMP}}<br>{{environment.COMMIT}}
					@if (!environment.PRODUCTION) {
						{{environment.BRANCH}}
					}
				</uno-text>
			</div>
		}
	</div>
}
<!-- Mobile -->
@if (app.device.isMobile()) {
	<!-- Image section -->
	@if (this.height > 750) {
		<div style="position: absolute; display: block; top:0px; left: 0px; overflow: hidden; border-radius: 0px 0px 50px 0px; width:100%; height:150px;">
			<!-- Background Image -->
			<img [src]="styleManager.loginBackground" style="object-fit: cover; position: absolute; width:100%; height:100%;">
			<!-- Overlay gradients -->
			<div style="position: absolute; mix-blend-mode: multiply; top:0px; left: 0px; opacity:0.5; width:100%; height:100%; background-image: var(--brand-gradient);"></div>
			<img src="./assets/login/logo.svg" style="position: absolute; left:calc(50% - 100px); top:calc(50% - 15px); width:200px; height: 30px" />
		</div>
	}
	<!-- Login section -->
	<div [ngStyle]="this.height > 750 ? {top: '22%', height: '78%'} : {top: '0px', height: '100%'}" style="position: absolute; overflow-y: auto; right: 0px; width:100%;">
		<!-- UNO Logo -->
		@if (this.height > 400) {
			<img src="./assets/login/uno-symbol.svg" style="position: absolute; top:30px; left: 10%; width:43px; height:43px" />
		}
		<!-- Content -->
		<div [ngStyle]="{top: this.height > 400 ? '100px' : '30px'}" style="position: absolute; width: 80%; right: 10%;">
			<router-outlet></router-outlet>
		</div>
		<!-- Language -->
		@if (this.height > 550) {
			<div style="position: absolute; bottom:60px; width: 80%; right: 10%;">
				<ion-select (ionChange)="this.useLocale($event.target.value)" [value]="session.settings.locale" interface="{{app.device.isMobile() ? 'alert' : 'popover'}}">
					@for (locale of localeList; track locale) {
						<ion-select-option [value]="locale.code">{{locale.name | translate}}</ion-select-option>
					}
				</ion-select>
			</div>
		}
		<!-- Version -->
		@if (this.height > 550) {
			<div style="position: absolute; width: 80%; right: 10%; height: 30px; bottom: 20px;">
				<uno-text class="login-page-copyright" alignment="center">{{environment.VERSION}} {{environment.TIMESTAMP}}<br>{{environment.COMMIT}}
					@if (!environment.PRODUCTION) {
						{{environment.BRANCH}}
					}
				</uno-text>
			</div>
		}
	</div>
	<!-- Pattern decoration -->
	@if (this.height > 550) {
		<img src="./assets/login/uno-pattern.svg" style="position: absolute; bottom:0px; right:0px; width:64px; height:64px" />
	}
}
