import {FileReaderAsync} from '../../../../utils/file-reader-async';
import {Service} from '../../../../http/service';
import {ServiceList} from '../../../../http/service-list';
import {Session} from '../../../../session';
import {Channel} from '../../../../models/pipeline-integrity/mot/channel';
import {ExperimentDirection} from '../../../../models/pipeline-integrity/mot/experiment-directions';
import {Experiment} from '../../../../models/pipeline-integrity/mot/experiment';
import {ChannelData} from '../../../../models/pipeline-integrity/mot/channel-data';
import {MOTImportUtils} from './import-utils';

/**
 * Utils to import data related with experiments.
 */
export class ExperimentImport {
	/**
	 * Import content from JSON file to experiment.
	 *
	 * @param experiment - Experiment to import data into.
	 * @param file - File to import data from.
	 */
	public static async importJSON(experiment: Experiment, file: File): Promise<void> {
		const text = await FileReaderAsync.readAsText(file);
		const json = JSON.parse(text);

		const channel = new Channel();
		channel.name = 'channel';
		channel.experimentUuid = experiment.uuid;

		const request = await Service.fetch(ServiceList.pipelineIntegrity.mot.channel.create, null, null, channel, Session.session);
		channel.uuid = request.response.uuid;

		const channelData = new ChannelData();
		channelData.data = json.data;
		channelData.channelUuid = channel.uuid;

		await Service.fetch(ServiceList.pipelineIntegrity.mot.channel.addDataChannels, null, null, channelData, Session.session);
	}

	/**
	 * Import .nano file into the experiment. Nano files contain data about the experiment, acquisition and cmp encoded as JSON followed by channel data.
	 *
	 * Each nano file can contain data for multiple channels. When importing a nano file new channels are created in the experiment.
	 *
	 * @param experiment - Experiment to import data into.
	 * @param file - File to import data from.
	 */
	public static async importNano(experiment: Experiment, file: File): Promise<void> {
		const text = await FileReaderAsync.readAsText(file);

		const result = MOTImportUtils.parseNano(text);

		// Propagation speed of the wave
		const speed = experiment.propagationSpeed;

		// Acquisition period
		const period = 5e-7;
		const direction = experiment.direction === ExperimentDirection.RIGHT ? 1 : -1;

		// Distance between each point of the CMP
		const step = speed * period * direction * 0.5;

		const channelsData = [];
		const columns = result.rows.length > 0 ? result.rows[0].length : 0;
		for (let k = 0; k < columns; k++) {
			const data = [];
			for (let j = 0; j < result.rows.length; j++) {
				data.push([j * step, result.rows[j][k]]);
			}
			channelsData.push(data);
		}

		for (const chData of channelsData) {
			const index = channelsData.indexOf(chData);

			const channel = new Channel();
			channel.name = 'channel_' + index;
			channel.experimentUuid = experiment.uuid;

			const request = await Service.fetch(ServiceList.pipelineIntegrity.mot.channel.create, null, null, channel, Session.session);

			const data = new ChannelData();
			data.data = chData;
			data.channelUuid = request.response.uuid;

			await Service.fetch(ServiceList.pipelineIntegrity.mot.channel.addDataChannels, null, null, data, Session.session);
		}
	}
}
