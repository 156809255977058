<div style="width:100%; height: fit-content; display: flex; flex-direction: column; margin: 10px;">
	<!-- Search bar -->
	<uno-searchbar style="display: block; width: calc(100% - 20px);" [value]="selfStatic.filters.search" (valueChange)="this.onFilterChange($event)"></uno-searchbar>

	<!-- Filter bar -->
	<uno-filter-bar [filters]="selfStatic.filters" [options]="selfStatic.filterOptions" (onChange)="this.onFilterChange($event);"></uno-filter-bar>
</div>

<!-- Uno list item template -->
<ng-template #itemTemplate let-inspection="item">
	<uno-list-item [clickable]="inspection.asset?.uuid ? true : false">
		<uno-list-item-icon src="{{ResourceUtils.getURL(inspection.asset?.pictures[0], './assets/placeholder/asset.png')}}"></uno-list-item-icon>

		<uno-list-item-label (click)="inspection.asset?.uuid ? app.navigator.navigate('/menu/asset-portfolio/asset/edit', {uuid: inspection.asset.uuid}) : null;">
			@if (inspection.asset) {
				<h2>
					<div [ngStyle]="{'background-color': inspection.color}" style="display: inline-block; width: 13px; height: 13px; margin: 6px 3px 0px 0px; border-radius: 50%;"></div>
					{{inspection.asset.name}}
				</h2>
				@if (inspection.asset.tag) {
					<h2>{{inspection.asset.tag}}</h2>
				}
			}
			@else {
				<h2>
					<div [ngStyle]="{'background-color': inspection.color}" style="display: inline-block; width: 13px; height: 13px; margin: 6px 3px 0px 0px; border-radius: 50%;"></div>
					{{'noAsset' | translate}}
				</h2>
			}
			@if (inspection.description) {
				<h3>{{inspection.description}}</h3>
			}
			<p>{{'updatedAt' | translate}}: {{inspection.updatedAt | formatDate}}</p>
		</uno-list-item-label>
		@if (inspection.asset?.uuid) {
			<uno-icon src="./assets/icons/uno/document.svg" style="display:block; width:32px; height:32px" height="32px" width="32px" color="primary" (click)="this.exportReport(inspection)"></uno-icon>
		}
	</uno-list-item>
</ng-template>

<!-- Uno list -->
<uno-content [ngStyle]="{height: 'calc(100% - 134px)', 'padding-bottom': '15px'}">
	<uno-list [handler]="this.handler" [itemSize]="81" [itemTemplate]="itemTemplate"></uno-list>
</uno-content>
