import {
	ComponentRef,
	Directive,
	ElementRef,
	HostListener,
	Input,
	OnInit
} from '@angular/core';
import {Overlay, OverlayPositionBuilder, OverlayRef} from '@angular/cdk/overlay';
import {ComponentPortal} from '@angular/cdk/portal';
import {UnoTooltipComponent, UnoTooltipDirection} from './uno-tooltip.component';

/**
 * UNO icon is an image element wrapper.
 */
@Directive({
	selector: '[uno-tooltip]',
	standalone: true
})
export class UnoTooltipDirective implements OnInit {
	@Input('uno-tooltip-text')
	public text: string = '';

	@Input('uno-tooltip-direction')
	public direction: string = UnoTooltipDirection.RIGHT;

	/**
	 * Overlay element reference.
	 */
	private overlayRef: OverlayRef;

	public constructor(private overlayPositionBuilder: OverlayPositionBuilder, private elementRef: ElementRef, private overlay: Overlay) {}

	public ngOnInit(): void {
		let direction = null;

		if (this.direction === UnoTooltipDirection.RIGHT) {
			direction = {
				originX: 'end',
				originY: 'center',
				overlayX: 'start',
				overlayY: 'center',
				offsetX: 10
			};
		} else if (this.direction === UnoTooltipDirection.LEFT) {
			direction = {
				originX: 'start',
				originY: 'center',
				overlayX: 'end',
				overlayY: 'center',
				offsetX: -10
			};
		} else if (this.direction === UnoTooltipDirection.TOP) {
			direction = {
				originX: 'center',
				originY: 'top',
				overlayX: 'center',
				overlayY: 'bottom',
				offsetY: 10
			};
		} else if (this.direction === UnoTooltipDirection.BOTTOM) {
			direction = {
				originX: 'center',
				originY: 'bottom',
				overlayX: 'center',
				overlayY: 'top',
				offsetY: -10
			};
		}


		const positionStrategy = this.overlayPositionBuilder.flexibleConnectedTo(this.elementRef).withPositions([direction]);

		this.overlayRef = this.overlay.create({positionStrategy: positionStrategy});
	}

	@HostListener('mouseenter')
	public show(): void {
		const portal = new ComponentPortal(UnoTooltipComponent);

		const tooltip: ComponentRef<UnoTooltipComponent> = this.overlayRef.attach(portal);
		tooltip.instance.text = this.text;
		tooltip.instance.direction = this.direction;
	}

	@HostListener('mouseout')
	public hide(): void {
		this.overlayRef.detach();
	}
}
