/**
 * Base64Utils contains methods to convert from and to Base64 binary format.
 */
export class Base64Utils {
	/**
	 * Remove base64 header from data.
	 *
	 * Use full for removing the header from image, audio, video, etc.
	 */
	public static removeHeader(data: string): string {
		return data.slice(data.search(';base64,') + 8);
	}
}


