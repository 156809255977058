import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {RouterOutlet} from '@angular/router';
import {Session} from './session';
import {StyleManager} from './theme/style-manager';
import {ThemeOS} from './theme/theme-os';
import {App} from './app';

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [RouterOutlet]
})
export class AppComponent implements OnInit {
	public constructor(public app: App) {}

	public ngOnInit(): void {
		Session.load();

		StyleManager.setTheme(Session.settings.theme);

		ThemeOS.watchChanges(function(theme: string): void {
			StyleManager.setTheme(theme);
		});
	}
}
