import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';

import {UnoFormComponent} from '../../../../components/uno-forms/uno-form/uno-form.component';
import {ScreenComponent} from '../../../../components/screen/screen.component';
import {App} from '../../../../app';
import {CompanyFormLayout} from '../company-form-layout';
import {Session} from '../../../../session';
import {Service} from '../../../../http/service';
import {ServiceList} from '../../../../http/service-list';
import {Modal} from '../../../../modal';
import {Locale} from '../../../../locale/locale';
import {Company} from '../../../../models/companies/company';
import {UserPermissions} from '../../../../models/users/user-permissions';
import {UUID} from '../../../../models/uuid';
import {UnoFormModule} from '../../../../components/uno-forms/uno-form.module';
import {UnoButtonComponent} from '../../../../components/uno/uno-button/uno-button.component';
import {UnoTitleComponent} from '../../../../components/uno/uno-title/uno-title.component';
import {UnoContentComponent} from '../../../../components/uno/uno-content/uno-content.component';
import {CompanyService} from '../../services/companies.service';
import {PermissionsPipe} from '../../../../pipes/permissions.pipe';

@Component({
	selector: 'companies-edit-page',
	templateUrl: './company-edit.page.html',
	standalone: true,
	imports: [UnoContentComponent, UnoTitleComponent, UnoFormModule, UnoButtonComponent, TranslateModule, PermissionsPipe]
})
export class CompanyEditPage extends ScreenComponent implements OnInit {
	public layout: any = CompanyFormLayout;

	public session: any = Session;

	public userPermissions: any = UserPermissions;

	public permissions = [UserPermissions.COMPANY];

	/**
	 * Flag to indicate if the page is in create mode.
	 */
	public createMode: boolean = false;

	/**
	 * Company being edited in this screen.
	 */
	@Input()
	public company: Company = null;

	/**
	 * Company edit form component for field validation.
	 */
	@ViewChild('companyForm', {static: false})
	public companyForm: UnoFormComponent = null;

	public ngOnInit(): void {
		super.ngOnInit();
		
		this.createMode = false;
		this.company = null;

		const data = App.navigator.getData();
		if (!data) {
			App.navigator.pop();
			return;
		}

		this.createMode = data.createMode === true;
		App.navigator.setTitle(this.createMode ? 'create' : 'edit');

		if (data.createMode) {
			if (!Session.hasPermissions([UserPermissions.COMPANY_CREATE])) {
				Modal.alert(Locale.get('warning'), Locale.get('noPermission'));
				App.navigator.pop();
				return;
			}

			this.createMode = true;
			this.company = new Company();
		} else {
			if (!Session.hasPermissions([UserPermissions.COMPANY_EDIT])) {
				Modal.alert(Locale.get('warning'), Locale.get('noPermission'));
				App.navigator.pop();
				return;
			}

			if (data.uuid !== undefined) {
				this.loadData(data.uuid);
			} else {
				App.navigator.pop();
				return;
			}
		}
	}

	/**
	 * Indicates if the company data can be edited.
	 */
	public canEdit(): boolean {
		return this.createMode && Session.hasPermissions([UserPermissions.COMPANY_CREATE]) || !this.createMode && Session.hasPermissions([UserPermissions.COMPANY_EDIT]);
	}

	/**
	 * Get company data from the API.
	 *
	 * @param uuid - UUID of the company
	 */
	public async loadData(uuid: UUID): Promise<void> {
		this.company = await CompanyService.get(uuid);
	}

	/**
	 * Update the company in the API.
	 */
	public async update(stayOnPage: boolean = false): Promise<void> {
		if (!this.companyForm.requiredFilled()) {
			Modal.alert(Locale.get('error'), Locale.get('requiredFieldsError'));
			return;
		}

		await Service.fetch(this.createMode ? ServiceList.company.create : ServiceList.company.update, null, null, this.company, Session.session);
		Modal.toast(this.createMode ? Locale.get('companyCreated') : Locale.get('companyUpdate'));

		if (!stayOnPage) {
			App.navigator.pop();
		}
	}

	/**
	 * Delete company from API.
	 */
	public async delete(): Promise<void> {
		const confirm = await Modal.confirm(Locale.get('confirm'), Locale.get('confirmDelete'));
		if (confirm) {
			await Service.fetch(ServiceList.company.delete, null, null, {uuid: this.company.uuid}, Session.session);
			Modal.toast(Locale.get('deleteSuccessfully'));
			App.navigator.pop();
		}
	}
}
