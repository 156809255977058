/**
 * Resize detector is used to detect changes in size of DOM elements.
 */
export class ResizeDetector {
	/**
	 * HTML element to be observed.
	 */
	public elements: HTMLElement[] = [];

	/**
	 * Browser resize observer.
	 */
	public resizeObserver: ResizeObserver = null;

	/**
	 * Method to be called when the element is resized.
	 */
	public onResize: ()=> void = null;

	/**
	 * Debounce time in ms applied to the resize event.
	 *
	 * To prevent excessive call of resize method.
	 */
	public debounce: number = 100;

	/**
	 * Debounce timer identifier.
	 */
	public debounceTimer: any = null;

	public constructor(element: HTMLElement, onResize: ()=> void, debounce: number = 100) {
		this.onResize = onResize;
		this.debounce = debounce;

		this.resizeObserver = new ResizeObserver(() => {
			if (this.debounce > 0) {
				if (this.debounceTimer) {
					clearTimeout(this.debounceTimer);
				}

				this.debounceTimer = setTimeout(() => {
					this.debounceTimer = null;
					this.onResize();
				}, this.debounce);
			} else {
				this.onResize();
			}
		});

		this.elements = [element];
		this.resizeObserver.observe(element);
	}

	/**
	 * Attach an additional DOM element to the detector.
	 *
	 * The detector will trigger the resize callback.
	 *
	 * @param element - Element to be attached to the detector.
	 */
	public attach(element: HTMLElement): void {
		this.elements.push(element);
		this.resizeObserver.observe(element);
	}

	/**
	 * Destroy the resize detector.
	 */
	public destroy(): void {
		this.resizeObserver.disconnect();
	}
}
