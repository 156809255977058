import {Component, EventEmitter, forwardRef, Input, Output, ViewEncapsulation} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {DOMUtils} from 'src/app/utils/dom-utils';
import {AsyncUtils} from 'src/app/utils/async-utils';
import {TranslateModule} from '@ngx-translate/core';
import {NgClass} from '@angular/common';
import {CdkConnectedOverlay, CdkOverlayOrigin} from '@angular/cdk/overlay';
import {UnoIconComponent} from '../../uno-icon/uno-icon.component';

/**
 * The Options List Component
 * 
 * @param options - The options to be displayed on the component's dropdown.
 * @param disabled - Allows the input to be disabled.
 * @param width - The width of the component.
 * @param scrollCurrent - Whether or not the dropdown should scroll to the currently selected value.
 * @param value - Value of the string shown to the user.
 */
@Component({
	selector: 'uno-options-list',
	templateUrl: './uno-options-list.component.html',
	styleUrls: ['./uno-options-list.component.css'],
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => { return UnoOptionsList; }),
		multi: true
	}],
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [CdkConnectedOverlay, NgClass, CdkOverlayOrigin, UnoIconComponent, TranslateModule]
})

export class UnoOptionsList implements ControlValueAccessor {

	@Input('options')
	public options: { value: any, label: string }[];

	@Input()
	public disabled: boolean;

	@Input()
	public width: number = 374;

	@Input()
	public scrollCurrent: boolean = false;
	
	/**
	 * Value of the string shown to the user.
	 */
	@Input()
	public value: number = 0;

	@Output()
	public valueChange = new EventEmitter<number>();

	/**
	 * Flag to indicate if the options selection is open.
	 */
	public expanded: boolean = false;

	/**
	 * Flag to indicate if the options selection is closing.
	 */
	public closing: boolean = false;

	/**
	 * Get label from the value selected.
	 * 
	 * @param value - The value to be used to search for the right label
	 */
	public getLabel(value: any): string {
		const opt = this.options.filter((val: any) => {
			return val.value === value;
		});

		return opt.length === 0 ? '' : opt[0].label;
	}

	/**
	 * Method used to show and hide the options when clicking the selector.
	 */
	public async toggle(): Promise<void> {
		if (!this.disabled) {
			if (this.closing) {
				setTimeout(() => {
					this.expanded = false;
					this.closing = false;
				}, 300);
			} else {
				this.expanded = true;
				await AsyncUtils.await(100);
				const element: HTMLElement = document.querySelector('[aria-label="' + this.getLabel(this.value) + '"]') as HTMLElement;
				await DOMUtils.waitUntilRendered(element);
				this.scroll(element);
			}
		}
	}

	/**
	 * Scrolls the dropdown to the currently selected value
	 * 
	 * @param element - The element that has its value currently selected
	 */
	public scroll(element: HTMLElement): void {
		if (element) {
			element.scrollIntoView();
		}
	}

	public writeValue(value: any): void {
		if (!value && typeof value !== 'number') {
			return;
		}
		this.toggle();

		this.value = value;
		this.valueChange.emit(value);
		this.onChange(value);
	}

	public onChange: (value: any)=> void = function(value) { };

	public registerOnChange(onChange: any): void {
		this.onChange = onChange;
	}

	public registerOnTouched(fn: any): void { }

	public setDisabledState(disabled: boolean): void {
		this.disabled = disabled;
	}
}
