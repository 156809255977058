import {UnoFormFieldTypes} from '../../../../components/uno-forms/uno-form/uno-form-field-types';
import {FFPPriority} from '../../../../models/atex-inspections/ffp/ffp-priority';
import {FFPRecommendation} from '../../../../models/atex-inspections/ffp/ffp-recommendation';
import {UnoFormField} from '../../../../components/uno-forms/uno-form/uno-form-field';
import {FFPState, FFPStateLabel} from '../../../../models/atex-inspections/ffp/ffp-state';
import {AtexInspectionForm} from '../../../../models/atex-inspections/inspections/atex-inspection-form';
import {AtexFfpService} from '../services/atex-ffp.service';

/**
 * Stores the form layout to edit FFP data.
 */
export const FFPLayout: UnoFormField[] = [
	{
		label: 'uuid',
		attribute: 'uuid',
		type: UnoFormFieldTypes.UUID
	},
	{
		attribute: 'state',
		label: 'status',
		editable: false,
		sort: false,
		type: UnoFormFieldTypes.OPTIONS,
		options: Object.values(FFPState).map(function(value) {
			return {value: value, label: FFPStateLabel.get(value)};
		})
	},
	{
		label: 'field',
		attribute: 'field',
		type: UnoFormFieldTypes.TEXT,
		editable: false
	},
	{
		label: 'form',
		attribute: 'form',
		type: UnoFormFieldTypes.OPTIONS,
		sort: false,
		editable: false,
		options: [
			{label: AtexInspectionForm.BACKOFFICE, value: AtexInspectionForm.BACKOFFICE},
			{label: AtexInspectionForm.INSPECTOR, value: AtexInspectionForm.INSPECTOR}
		]
	},
	{
		attribute: 'description',
		label: 'description',
		type: UnoFormFieldTypes.TEXT_MULTILINE
	},
	{
		attribute: 'cost',
		label: 'cost',
		inUnit: '€',
		unit: '€',
		type: UnoFormFieldTypes.NUMBER_UNITS,
		options: [
			{value: '€', label: '€'}
		]
	},
	{
		attribute: 'priority',
		label: 'priority',
		type: UnoFormFieldTypes.OPTIONS,
		sort: false,
		options: [
			{label: 'low', value: FFPPriority.LOW},
			{label: 'medium', value: FFPPriority.MEDIUM},
			{label: 'high', value: FFPPriority.HIGH}
		]
	},
	{
		attribute: 'data.photos',
		label: 'photos',
		type: UnoFormFieldTypes.IMAGE_RESOURCE_MULTIPLE
	},
	{
		attribute: 'recommendations',
		label: 'recommendations',
		type: UnoFormFieldTypes.OPTIONS_MULTIPLE,
		options: [],
		fetchOptions: async function(object: any, row: UnoFormField) {
			row.options = [];
			
			const recommendations: FFPRecommendation[] = await AtexFfpService.listRecommendations();
			recommendations.forEach((r: FFPRecommendation) => {
				row.options.push({
					value: r.id,
					label: r.label
				});
			});
		}
	}
];

