<uno-tab style="width: 100%; height: 100%">
	@if (this.user) {
		<uno-tab-section [active]="true" title="{{'user' | translate}}">
			<uno-form [editable]="canEdit()" [layout]="this.layout" [object]="this.user" #userForm></uno-form>
			@if (canEdit()) {
				<div style="display: flex; flex-direction: row-reverse; gap: 5px;" class="ion-padding">
					<!-- Save / Create -->
					<uno-button (click)="this.update();">{{(this.createMode() ? 'create' : 'save') | translate}}</uno-button>
					<!-- Update / Submit -->
					@if (!this.createMode()) {
						<uno-button (click)="this.update(true);">{{'update' | translate}}</uno-button>
						@if (this.user.uuid === session.user.uuid) {
							<uno-button (click)="this.changePassword();">{{'changePassword' | translate}}</uno-button>
						}
					}
				</div>
			}
		</uno-tab-section>
		<uno-tab-section title="{{'permissions' | translate}}">
			<ion-list>
				@for (permission of this.options; track permission) {
					<ion-item (click)="this.user.permissions = roleUtils.togglePermission(this.user.permissions, permission.value);" button="true">
						<ion-icon slot="start" (click)="permission.collapsed = !permission.collapsed; $event.stopPropagation();" name="{{permission.collapsed ? 'chevron-forward-outline' : 'chevron-down-outline'}}"></ion-icon>
						<ion-checkbox justify="start" labelPlacement="end" [checked]="this.user.permissions.indexOf(permission.value) !== -1" button="true">
							{{permission.label}}<br>
							{{permission.value}}
						</ion-checkbox>
					</ion-item>
					@if (!permission.collapsed) {
						@for (subPermission of permission.subPermissions; track subPermission) {
							<ion-item (click)="this.user.permissions = roleUtils.togglePermission(this.user.permissions, subPermission.value);">
								<div style="width: 100px"></div>
								<ion-checkbox justify="start" labelPlacement="end" [checked]="this.user.permissions.indexOf(subPermission.value) !== -1">
									{{subPermission.label}}<br>
									{{subPermission.value}}
								</ion-checkbox>
							</ion-item>
						}
					}

				}
			</ion-list>

			<div style="display: flex; flex-direction: row-reverse; gap: 5px;" class="ion-padding">
				<uno-button (click)="this.update();">{{'save' | translate}}</uno-button>
			</div>
		</uno-tab-section>
	}
</uno-tab>
