<div style="height: 110px; margin: 10px;">
	<!-- Search bar -->
	<uno-searchbar [value]="selfStatic.filters.search" (valueChange)="onSearch($event)"></uno-searchbar>

	<uno-filter-bar [filters]="selfStatic.filters" [options]="selfStatic.filterOptions" (onChange)="this.onFilterChange($event);"></uno-filter-bar>
</div>

<!-- List -->
<uno-content [ngStyle]="{height: 'calc(100% - 130px)'}">
	@if (!this.handler.hasItems) {
		<uno-no-data></uno-no-data>
	}
	@if (this.handler.hasItems) {
		<cdk-virtual-scroll-viewport itemSize="70" style="height: 100%; width: 100%;">
			<ng-container *cdkVirtualFor="let model of this.handler.items; let last = last;">
				<!-- Models -->
				<ion-item>
					<ion-icon (click)="this.expand(model);" name="{{model.expanded ? 'chevron-down-outline' : 'chevron-forward-outline'}}" style="cursor: pointer;"></ion-icon>
					<ion-label style="margin-left: 5px">
						<h2>{{model.manufacturer || ('nd' | translate)}}</h2>
						<h3>{{model.model || ('nd' | translate)}}</h3>
					</ion-label>
					<ion-icon (click)="this.editModel(model, undefined, $event);" name="create-outline" slot="end" style="cursor: pointer;"></ion-icon>
				</ion-item>
				<!-- Assets -->
				@if (model.expanded && model.assets !== null) {
					@for (asset of model.assets; track asset) {
						<ion-item (click)="app.navigator.navigate('/menu/asset-portfolio/asset/edit', {uuid: asset.uuid});" button="true">
							<ion-avatar style="margin-left: 30px">
								<ion-img src="{{this.settings.showListPictures && asset.pictures.length > 0 ? ResourceUtils.getURL(asset.pictures[0], './assets/placeholder/asset.png') : './assets/placeholder/asset.png'}}"></ion-img>
							</ion-avatar>
							<ion-label style="margin-left: 5px">
								<h2>{{asset.name}}</h2>
								<h3>{{asset.tag || ('nd' | translate)}}</h3>
								@if (asset.atex !== null) {
									@for (tag of asset.atex.tags; track tag) {
										<p>{{tag.toString()}}</p>
									}
								}
								<p>
									<ion-icon color="{{asset.documents.length > 0 ? 'success' : 'danger'}}" name="{{asset.documents.length > 0 ? 'checkmark-circle-outline' : 'close-circle-outline'}}" style="display: inline-block; font-size: 16px; vertical-align: middle;"></ion-icon>
									{{asset.documents.length > 0 ? (asset.documents.length + ' ' + ('documents' | translate)) : ('noDocuments' | translate)}}
								</p>
							</ion-label>
							<ion-icon (click)="this.editModel(model, asset, $event);" name="create-outline" slot="end" style="cursor: pointer;"></ion-icon>
							@if (app.device.isDesktop()) {
								<!-- Open new tab -->
								<ion-icon (click)="app.openInTab('/menu/asset-portfolio/asset/edit', {uuid: asset.uuid}, $event);" name="desktop-outline" slot="end"></ion-icon>
							}
						</ion-item>
					}
				}
				@if (last && this.handler.nextPage()) {
				}
			</ng-container>
		</cdk-virtual-scroll-viewport>
	}
</uno-content>
