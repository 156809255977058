/**
 * Possible configurations for two-factor authentication for the user.
 */
export const UserTwoFactorAuth = {
	NONE: 0,
	SMS: 1,
	EMAIL: 2
};

export const UserTwoFactorAuthLabel: Map<number, string> = new Map([
	[UserTwoFactorAuth.NONE, 'none'],
	[UserTwoFactorAuth.SMS, 'sms'],
	[UserTwoFactorAuth.EMAIL, 'email']
]);
