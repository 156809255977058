import {
	AfterViewInit,
	Component,
	ElementRef,
	Input,
	OnDestroy,
	OnInit,
	ViewChild,
	ViewEncapsulation
} from '@angular/core';
import {
	NoToneMapping,
	PCFSoftShadowMap,
	Vector2,
	WebGLRenderer
} from 'three';
import {NgStyle} from '@angular/common';
import {CssNgStyle} from '../../../../../../utils/css-ng-style';
import {CSS3DRenderer} from '../../../../render/css-renderer/css-3d-renderer';
import {Environment} from '../../../../../../../environments/environment';
import {EventManager} from '../../../../../../utils/event-manager';
import {ResizeDetector} from '../../../../../../utils/resize-detector';

/**
 * A canvas element that also contains a webgl renderer object.
 *
 * The renderer is automatically updated to match the canvas size, it also handles the device pixel ratio.
 */
@Component({
	selector: 'renderer-canvas',
	templateUrl: './renderer-canvas.component.html',
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [NgStyle]
})
export class RendererCanvasComponent implements OnInit, OnDestroy, AfterViewInit {
	@ViewChild('element', {static: true})
	public elementRef: ElementRef = null;

	@ViewChild('canvas', {static: true})
	public canvasRef: ElementRef = null;

	/**
	 * Style to apply to the element.
	 */
	@Input()
	public ngStyle: CssNgStyle = {};

	/**
	 * Indicates if a CSS renderer should be created alongside the WebGL renderer.
	 */
	@Input()
	public useCSSRenderer: boolean = false;

	/**
	 * List os parameters to be passed to the WebGL renderer.
	 */
	@Input()
	public options: any = null;

	/**
	 * Set on resize callback, can be useful to update cameras and other screen space dependent objects.
	 *
	 * The callback receives the width and height of the rendering canvas.
	 */
	@Input()
	public onresize: (width: number, height: number)=> void = null;
	
	/**
	 * Set on destroy callback, called before the webgl renderer is destroyed
	 */
	@Input()
	public ondestroy: ()=> void = null;

	public get canvas(): HTMLCanvasElement {
		return this.canvasRef.nativeElement;
	}

	/**
	 * Rendering canvas resolution currently used.
	 *
	 * Considers the device pixel ratio.
	 */
	public resolution: Vector2 = new Vector2();

	/**
	 * WebGl renderer used to draw 3D objects into the canvas.
	 */
	public renderer: WebGLRenderer = null;

	/**
	 * DOM element used to store CSS 3D elements.
	 */
	public cssDivision: any = null;

	/**
	 * Overlay division used to place the css rendered DOM objects.
	 */
	public cssRenderer: CSS3DRenderer = null;

	/**
	 * Indicates if the component is visible or not in the DOM tree.
	 *
	 * Used to keep track of the component state and refresh the screen state.
	 */
	public visible: boolean = false;

	/**
	 * Size of the component in the DOM.
	 *
	 * Used to keep track of the component state and refresh the screen state.
	 */
	public size: Vector2 = new Vector2();

	/**
	 * Event manager used to manage events associated with DOM.
	 */
	public event: EventManager = new EventManager();

	/**
	 * Resize detector.
	 */
	public resizeDetector: ResizeDetector = null;

	/**
	 * Default options used to create the renderer.
	 */
	public static defaultOptions: any = {
		canvas: null,
		precision: 'highp',
		alpha: true,
		premultipliedAlpha: true,
		antialias: true,
		preserveDrawingBuffer: false,
		powerPreference: 'high-performance',
		logarithmicDepthBuffer: false,
		depth: true
	};

	public ngOnInit(): void {
		this.event.add(this.canvas, 'contextmenu', function(event: any) {
			event.preventDefault();
			event.stopPropagation();
			return false;
		});

		this.event.add(window, 'beforeunload', () => {
			if (this.ondestroy) {
				this.ondestroy();
			}
		});

		this.resizeDetector = new ResizeDetector(this.canvasRef.nativeElement, () => {
			this.resize();
		});

		this.event.create();

		this.resetCanvas();
		this.createRenderer();
	}

	public ngOnDestroy(): void {
		if (this.ondestroy) {
			this.ondestroy();
		}

		this.forceContextLoss();
		this.event.destroy();
		this.resizeDetector.destroy();
	}

	public ngAfterViewInit(): void {
		this.resize();
		setTimeout(() => {this.resize();}, 1000);
	}

	/**
	 * Get blob with data present on this rendering canvas.
	 *
	 * If the preserveDrawingBuffer is set to false.
	 */
	public getBlob(encoding: string = 'image/jpeg', quality: number = 0.7): Promise<Blob> {
		return new Promise((resolve, reject): void => {
			this.canvasRef.nativeElement.toBlob(resolve, encoding, quality);
		});
	}

	/**
	 * If the flag useCSSRenderer is set true creates an overlay CSS 3D division.
	 *
	 * This div is manipulated using CSS to match the same camera position and perspective as the 3D scene.
	 */
	public resetCanvas(): void {
		if (this.useCSSRenderer) {
			this.cssDivision = document.createElement('div');
			this.cssDivision.style.position = 'absolute';
			this.cssDivision.style.top = '0px';
			this.cssDivision.style.left = '0px';
			this.cssDivision.style.width = '100%';
			this.cssDivision.style.height = '100%';
			this.elementRef.nativeElement.appendChild(this.cssDivision);
		}
	}

	/**
	 * Creates a new WebGL renderer.
	 *
	 * The renderer is created with the options specified on the object, always uses the canvas attached to the component.
	 *
	 * By default, autoClear, shadowMap, sortObject and toneMapping are disabled.
	 *
	 * The user has to ensure that the old context was disposed before creating a new renderer.
	 */
	public createRenderer(): void {
		if (!this.options) {
			this.options = Object.assign({}, RendererCanvasComponent.defaultOptions);
		}

		// Create WebGL 2 context
		this.options.canvas = this.canvas;
		this.options.context = this.canvas.getContext('webgl2');

		// WebGL renderer
		this.renderer = new WebGLRenderer(this.options);
		this.renderer.setScissorTest(false);
		this.renderer.autoClear = false;
		this.renderer.autoClearColor = false;
		this.renderer.autoClearDepth = false;
		this.renderer.autoClearStencil = false;
		this.renderer.toneMapping = NoToneMapping;
		this.renderer.sortObjects = false;
		this.renderer.shadowMap.enabled = false;
		this.renderer.shadowMap.type = PCFSoftShadowMap;

		// CSS Renderer
		if (this.useCSSRenderer) {
			this.cssRenderer = new CSS3DRenderer(this.cssDivision);
		}

		if (!Environment.PRODUCTION) {
			console.log('EQS: WebGL renderer WebXR manager.', this.renderer.xr);
		}
	}

	/**
	 * Set animation callback that is managed by the WebGL renderer.
	 *
	 * The function will be called every available frame.
	 *
	 * If null is passed it will stop any already ongoing animation.
	 */
	public setAnimationLoop(callback: XRFrameRequestCallback): void {
		this.renderer.setAnimationLoop(callback);
	}

	/**
	 * Create a new context for this renderer, this may be useful to change some configurations in the renderer.
	 */
	public createNewContext(): void {
		this.forceContextLoss();
		this.resetCanvas();
		this.createRenderer();
	}

	/**
	 * Force the current renderer to lose context.
	 *
	 * This is achieved by using the WEBGL_lose_context extension and may not be supported by all browsers.
	 */
	public forceContextLoss(): void {
		try {
			this.renderer.setAnimationLoop(null);
			this.renderer.dispose();
			this.renderer.forceContextLoss();
		} catch (e) {}
	}

	/**
	 * Update the size of the DOM elements.
	 */
	public resize(): void {
		const pixelRatio = window.devicePixelRatio;

		// Get the size of the canvas in screen coordinates
		const width = this.canvas.offsetWidth * pixelRatio;
		const height = this.canvas.offsetHeight * pixelRatio;

		this.resolution.set(width, height);

		this.canvas.width = width;
		this.canvas.height = height;

		this.renderer.setSize(width, height, false);

		if (this.useCSSRenderer) {
			this.cssRenderer.setSize(this.resolution.x, this.resolution.y);
		}

		if (this.onresize) {
			this.onresize(width, height);
		}
	}
}
