export type DL50InspectionStatusType = typeof DL50InspectionStatus[keyof typeof DL50InspectionStatus];

/**
 * List of possible status for the DL50 inspections.
 */
export enum DL50InspectionStatus {
	/**
	 * All status.
	 */
	ALL = -1,
	
	/**
	 * Inspection is in progress.
	 */
	IN_PROGRESS = 0,
	
	/**
	 * Inspection has ended but it is now under validation.
	 */
	VALIDATION = 1,
	
	/**
	 * Client has rejected the inspection on client feedback status.
	 */
	CLIENT_FEEDBACK = 2,
	
	/**
	 * Backoffice has rejected the inspection on validation status.
	 */
	SUPERVISION_REJECTED = 3,
	
	/**
	 * The inspection has ended and is fully validated and approved.
	 */
	FINISHED = 4,
};

/**
 * List of possible status labels for the DL50 inspections entries.
 */
export const DL50InspectionStatusLabel: Map<number, string> = new Map([
	[DL50InspectionStatus.ALL, 'all'],
	[DL50InspectionStatus.IN_PROGRESS, 'inProgress'],
	[DL50InspectionStatus.VALIDATION, 'validation'],
	[DL50InspectionStatus.CLIENT_FEEDBACK, 'clientFeedback'],
	[DL50InspectionStatus.SUPERVISION_REJECTED, 'rejected'],
	[DL50InspectionStatus.FINISHED, 'finished']
]);
