import {Color, MathUtils} from 'three';

/**
 * Utils to generate ionic colors CSS blocks.
 */
export class ThemeUtils {
	/**
	 * Generate CSS color variables for each color, given a color.
	 * 
	 * Contrast color is computed from color luminance
	 *
	 * @param color - Accent color
	 */
	public static generateGroup(color: Color): string {
		const hsl = color.getHSL({h: 0, s: 0, l: 0});

		// Relative luminance value (different from the l component from HSL)
		const luminance = 0.2126 * color.r + 0.7152 * color.g + 0.0722 * color.b;

		// Define the contrast color based off luminance.
		const contrast = luminance < 0.5 ? new Color(0xFFFFFF) : new Color(0x000000);

		const shade = new Color();
		shade.setHSL(hsl.h, hsl.s, MathUtils.clamp(hsl.l * 0.7, 0.0, 0.95));

		const tint = new Color();
		tint.setHSL(hsl.h, MathUtils.clamp(hsl.s * 1.3, 0, 1.0), MathUtils.clamp(hsl.l * 1.3, 0.0, 0.95));

		return `--brand-primary: ${color.getStyle()};
			--brand-contrast: ${contrast.getStyle()};
			--brand-primary-hover: ${shade.getStyle()};
			--brand-primary-tint: ${tint.getStyle()};`;
	}

	/**
	 * Generate CSS color palette variables from base/accent color
	 * 
	 * @param accent - The color, used to generate the other two on color palette.
	 */
	public static generate(accent: Color): string {
		return ThemeUtils.generateGroup(accent);
	}
}
