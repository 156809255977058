import {Resource} from '../resource';

/**
 * Master settings appearance used on application appearance.
 */
export class MasterSettingsAppearance {
	/**
	 * Title content, presented as browser tab title
	 */
	public title: string = null;

	/**
	 * The favicon to be used on application (also presented on browser tab)
	 */
	public icon: Resource = null;

	/**
	 * The image presented behind login form
	 */
	public loginBackgroundImage: Resource = null;

	/**
	 * The logo of the application (presented on login form and navigation menu top when expanded, for example)
	 */
	public logo: Resource = null;

	/**
	 * The small logo version of the application (presented on navigation menu top when collapsed, for example)
	 */
	public smallLogo: Resource = null;

	/**
	 * The primary/accent color to customize application theme
	 */
	public accentColor: string = null;

	/**
	 * Parse the data from API for a MasterSettingsAppearance object.
	 *
	 * @param data - settings object to parse data
	 */
	public static parse(data: any): MasterSettingsAppearance {
		const appearance = new MasterSettingsAppearance();

		appearance.title = data.title;
		appearance.icon = data.icon;
		appearance.loginBackgroundImage = data.loginBackgroundImage;
		appearance.logo = data.logo;
		appearance.smallLogo = data.smallLogo;
		appearance.accentColor = data.accentColor;

		return appearance;
	}
}
