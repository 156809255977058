/**
 * Possible flow modes used in the project.
 */
export enum InspectionProjectFlowMode {
	// When the user clicks an adjacency button, the user returns to the list page
	SINGLE_STEP = 0,

	// When the user clicks an adjacency button, the user moves to the adjacent step selected.
	WIZARD = 1,

	// When the user clicks an adjacency button, the application shows a modal to ask if the user wants to move to the new step.
	MODAL = 2
};

/**
 * Map of the flow modes and their corresponding label as stored in locale.
 */
export const InspectionProjectFlowModeLabel: Map<InspectionProjectFlowMode, string> = new Map([
	[InspectionProjectFlowMode.WIZARD, 'wizard'],
	[InspectionProjectFlowMode.SINGLE_STEP, 'singleStep'],
	[InspectionProjectFlowMode.MODAL, 'modal']
]);
