import {Vector2} from 'three';

/**
 * Math utils contains any type of number math related auxiliary methods.
 */
export class MathUtils {
	/**
	 * Round number to a certain amount of decimal places.
	 *
	 * @param num - Number to be rounded.
	 * @param decimal - Amount of decimal places to round to.
	 * @returns Rounded number.
	 */
	public static roundDecimal(num: number, decimal: number = 2): number {
		const p = Math.pow(10, decimal);
		return Math.round(num * p) / p;
	}

	/**
	 * Convert cartesian to 2D polar coordinates.
	 *
	 * Polar coordinates represent points as a angle and distance relative to the origin point.
	 *
	 * @param x - The coordinate x.
	 * @param y - The coordinate y.
	 * @returns Returns the angle and distance of the point relative to the origin.
	 */
	public static cartesianToPolar(x: number, y: number): {r: number, t: number} {
		const r: number = Math.sqrt(x * x + y * y);
		const t: number = Math.atan2(y, x) * (180 / Math.PI);

		return {r: r, t: t};
	}

	/**
	 * Calculates the distance of the closest point to a line segment.
	 *
	 * @param point - Point to measure the distance.
	 * @param line - Array with starting point and endpoint of the line segment.
	 * @returns The distance between the point and segment.
	 */
	public static distancePointLineSegment(point: Vector2, line: Vector2[]): number {
		const la = line[0];
		const lb = line[1];

		const a = point.x - la.x;
		const b = point.y - la.y;

		const c = lb.x - la.x;
		const d = lb.y - la.y;

		const dot = a * c + b * d;
		const lenSq = c * c + d * d;

		const param = lenSq === 0 ? -1 : dot / lenSq;

		const xx: number = param < 0 ? la.x : param > 1 ? lb.x : la.x + param * c;
		const yy: number = param < 0 ? la.y : param > 1 ? lb.y : la.y + param * d;
		
		const dx = point.x - xx;
		const dy = point.y - yy;

		return Math.sqrt(dx * dx + dy * dy);
	}

	/**
	 * Gets the closest point on the perpendicular between a point and line.
	 *
	 * The point returned forms a 90º angle between the provided point and any other point in the line.
	 *
	 * @param point - x and y of where the point was drawn.
	 * @param line - Array with starting point and endpoint of the line segment.
	 * @returns The x and y of the point inside the line.
	 */
	public static getClosesPointOnLine(point: Vector2, line: Vector2[]): Vector2 {
		if (line.length === 0) {
			throw new Error('Line has no points');
		}

		let i = 0;
		let fTo: number, fFrom: number;

		for (let n = 1 ; n < line.length ; n++) {
			let dist: number;
			if (line[n].x !== line[n - 1].x) {
				const a = (line[n].y - line[n - 1].y) / (line[n].x - line[n - 1].x);
				const b = line[n].y - a * line[n].x;
				dist = Math.abs(a * point.x + b - point.y) / Math.sqrt(a * a + 1);
			} else {
				dist = Math.abs(point.x - line[n].x);
			}

			// length^2 of line segment
			const rl2 = Math.pow(line[n].y - line[n - 1].y, 2) + Math.pow(line[n].x - line[n - 1].x, 2);

			// distance^2 of pt to end line segment
			const ln2 = Math.pow(line[n].y - point.y, 2) + Math.pow(line[n].x - point.x, 2);

			// distance^2 of pt to begin line segment
			const lnm12 = Math.pow(line[n - 1].y - point.y, 2) + Math.pow(line[n - 1].x - point.x, 2);

			// minimum distance^2 of pt to infinite line
			const dist2 = Math.pow(dist, 2);

			// calculated length^2 of line segment
			const calcrl2 = ln2 - dist2 + lnm12 - dist2;

			// redefine minimum distance to line segment (not infinite line) if necessary
			if (calcrl2 > rl2) {dist = Math.sqrt(Math.min(ln2, lnm12));}

			let minDist = null;

			if (calcrl2 > rl2) {
				if (lnm12 < ln2) {
					fTo = 0; // nearer to previous point
					fFrom = 1;
				} else {
					fFrom = 0; // nearer to current point
					fTo = 1;
				}
			} else {
				// perpendicular from point intersects line segment
				fTo = Math.sqrt(lnm12 - dist2) / Math.sqrt(rl2);
				fFrom = Math.sqrt(ln2 - dist2) / Math.sqrt(rl2);
			}

			minDist = dist;
			i = n;
		}

		const dx = line[i - 1].x - line[i].x;
		const dy = line[i - 1].y - line[i].y;

		const x = line[i - 1].x - dx * fTo;
		const y = line[i - 1].y - dy * fTo;

		return new Vector2(x, y);
	}

	/**
	 * Create a string to represent a number with specific formatting.
	 * 
	 * @param value - Value to be formatted.
	 * @param intPlaces - Minimum integer places to be occupied by the number.
	 * @param decPlaces - Maximum decimal places.
	 * @returns The number formatted as string.
	 */
	public static formatNumber(value: number, intPlaces: number, decPlaces: number): string {
		const numberIntPlaces = MathUtils.roundDecimal(value, 0).toString().length;
		if (intPlaces > numberIntPlaces) {
			return '0'.repeat(intPlaces - numberIntPlaces) + MathUtils.roundDecimal(value, decPlaces);
		} else {
			return MathUtils.roundDecimal(value, decPlaces).toString();
		}
	}
}
