@if (this.object) {
	<div (dblclick)="this.showModal();" (click)="this.editor.select($any(this.object));" class="digital-twin-tree-object" (mouseenter)="this.hover = true;" (mouseleave)="this.hover = false;" [ngStyle]="{'background-color': (this.hover || this.editor.isSelected($any(this.object))) ? 'var(--gray-10)' : ''}">

		<!-- Level Spacing -->
		<div [ngStyle]="{'width': (this.level * 25) + 'px'}"></div>

		<!-- Arrow -->
		<div class="digital-twin-tree-arrow">
			@if ($any(this.object).childrenCount > 0) {
				<uno-icon (click)="this.expand()" [src]="'assets/icons/uno/' + (this.expanded ? 'down' : 'right') + '-specialarrow.svg'" [width]="24" [height]="24"></uno-icon>
			}
		</div>

		<!-- Icon -->
		<img [src]="typeIcon.get(this.object.type)" loading="eager" width="18" height="18" />

		<!-- Info -->
		<div class="digital-twin-tree-info">
			{{this.object.name}}
		</div>

		<!-- Asset Link -->
		@if ($any(this.object).assetUuid) {
			<uno-icon (click)="app.navigator.navigate('/menu/asset-portfolio/asset/edit', {uuid: $any(this.object).assetUuid})" color="contrast" src="assets/icons/uno/document.svg" [width]="24" [height]="24"></uno-icon>
		}

		<!-- Visibility -->
		<uno-icon (click)="this.toggleVisibility();" color="contrast" [src]="'/assets/icons/uno/' + (this.object.visible ? 'visible' : 'hidden') + '.svg'" [width]="24" [height]="24"></uno-icon>

		<!-- Lock -->
		@if ($any(this.object).locked) {
			<uno-icon color="contrast" src="assets/icons/uno/lock.svg" [width]="24" [height]="24"></uno-icon>
		}
	</div>

	@if (this.expanded) {
		@for (child of this.object.children; track child) {
			@if ($any(child).isDigitalTwinObject) {
				<digital-twin-tree-object [editor]="this.editor" [object]="child" [level]="this.level + 1"></digital-twin-tree-object>
			}
		}
	}
}
