import {UnoFormField} from '../../../../components/uno-forms/uno-form/uno-form-field';
import {UnoFormFieldTypes} from '../../../../components/uno-forms/uno-form/uno-form-field-types';
import {Service} from '../../../../http/service';
import {ServiceList} from '../../../../http/service-list';
import {Session} from '../../../../session';
import {InspectionWorkflowStepAction, InspectionWorkflowStepActionLabel} from '../../../../models/inspections/workflow/inspection-workflow-step-action';

export const InspectionWorkflowStepAdjacencyLayout: UnoFormField[] = [
	{
		label: 'uuid',
		attribute: 'uuid',
		type: UnoFormFieldTypes.UUID
	},
	{
		isActive: false,
		required: true,
		attribute: 'workflowUuid',
		label: 'workflow',
		type: UnoFormFieldTypes.OPTIONS,
		options: [{value: null, label: 'none'}],
		fetchOptions: function(object: any, row: UnoFormField) {
			Service.call(ServiceList.inspection.workflow.list, null, null, null, Session.session, (response, xhr) => {
				row.options = [{value: null, label: 'none'}];
				for (let i = 0; i < response.workflows.length; i++) {
					row.options.push({
						value: response.workflows[i].uuid,
						label: response.workflows[i].name
					});
				}
			});
		},
		sort: true
	},
	{
		required: false,
		attribute: 'bypassRequiredCheck',
		label: 'bypassRequiredCheck',
		type: UnoFormFieldTypes.CHECKBOX
	},
	{
		label: 'alarms',
		type: UnoFormFieldTypes.SUB_FORM,
		expanded: true,
		fields: [
			{
				required: false,
				attribute: 'action',
				label: 'action',
				type: UnoFormFieldTypes.OPTIONS,
				sort: false,
				options: Object.values(InspectionWorkflowStepAction).map(function(value) {
					return {value: value, label: InspectionWorkflowStepActionLabel.get(value)};
				})
			},
			{
				required: false,
				attribute: 'userUuids',
				label: 'users',
				type: UnoFormFieldTypes.USER_SELECTOR,
				multiple: true,
				isActive: (object: any, row: UnoFormField) => {
					return object.action.length !== 0;
				}
			},
			{
				required: false,
				attribute: 'teamUuids',
				label: 'teams',
				type: UnoFormFieldTypes.TEAM_SELECTOR,
				multiple: true,
				isActive: (object: any, row: UnoFormField) => {
					return object.action.length !== 0;
				}
			}
		]
	},
	{
		required: true,
		attribute: 'originUuid',
		label: 'origin',
		type: UnoFormFieldTypes.OPTIONS,
		options: [{value: null, label: 'none'}]
	},
	{
		required: true,
		attribute: 'destinationUuid',
		label: 'destination',
		type: UnoFormFieldTypes.OPTIONS,
		options: [{value: null, label: 'none'}]
	},
	{
		required: false,
		attribute: 'analysesGaps',
		label: 'analysesGaps',
		type: UnoFormFieldTypes.CHECKBOX
	}
];
