<uno-content [borders]="true">
	<!-- Chart -->
	<div #chart style="width: 100%; height: 420px; padding: 10px;"></div>

	<!-- List of experiments -->
	@if (this.experiments.length === 0) {
		<uno-no-data></uno-no-data>
	}
	@if (this.experiments.length !== 0) {
		@for (experiment of this.experiments; track experiment) {
			<ion-item (click)="displayExperiment(experiment)" button="true" lines="none">
				<ion-avatar slot="start">
					<ion-img src="./assets/placeholder/asset.png"></ion-img>
				</ion-avatar>
				<ion-label>
					@if (experiment.label) {
						<h3>{{experiment.label}}</h3>
					}
					<h2>{{experiment.dateTime | formatDate}}</h2>
					@if (experiment.frequency) {
						<h3>{{experiment.frequency}} KHZ</h3>
					}
				</ion-label>
				@if (app.device.isDesktop()) {
				}
			</ion-item>
		}
	}
</uno-content>
