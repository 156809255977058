import {UUID, UUIDIdentification} from '../uuid';
import {APAssetFormTabCard} from './asset-form-tab-card';

/**
 * Used to represent the content of a form tab.
 * 
 * This tabs can be either from an asset type or a (sub-)sub-type. When it is a (sub-)sub-type card, the attribute 'subTypeUuid' must be set.
 */
export class APAssetFormTab extends UUIDIdentification {
	/**
	 * 	The name shown on tab component
	 */
	public name: string = '';

	/**
	 * 	The description of this tab
	 */
	public description: string = '';

	/**
	 * 	The UUID of the asset type where this tab is used on
	 */
	public typeUuid: UUID = null;

	/**
	 * 	The UUID of the asset sub-type where this tab is used on (if existent)
	 */
	public subTypeUuid: UUID = null;

	/**
	 * The indexes of this tab, used to sort tabs relative to each other.
	 *
	 * Indexes are separated by a | and start at "1" for asset types and "0" for sub-types (to allow sub-type tabs to be placed before the type ones). Every | on the index, represents a level deep from the types (lvl 1) to the sub-types (lvl 0 to infinity)
	 *
	 * E.g.: Tabs of a type can be "1" or "123". Tabs of a sub-type can be "0|1" or "0|1 and 1|1 and 1|2 and 1|3" or "123|1". Tabs of a sub-sub-sub-type can be "0|0|0|1" or "0|0|0|1 and 1|0|0|1 and 1|0|0|2 and 1|0|0|3" or "123|0|0|1"
	 */
	public indexes: string = '';

	/**
	 * The array of form tab cards present on this form tab
	 */
	public cards: APAssetFormTabCard[] = [];

	/**
	 * Parse JSON data received from the API into an object of the correct type.
	 * 
	 * @param data - Object received from API.
	 * @returns formTab object instance, built from the data received.
	 */
	public static parse(data: any): APAssetFormTab {
		const tab = new APAssetFormTab();

		// Identification params
		tab.uuid = data.uuid;
		tab.updatedAt = new Date(data.updatedAt);
		tab.createdAt = new Date(data.createdAt);

		// Editable data
		tab.name = data.name;
		tab.description = data.description;
		tab.typeUuid = data.typeUuid;
		tab.subTypeUuid = data.subTypeUuid;
		
		// Structure data
		tab.indexes = data.indexes;

		// Child objects
		if (data.cards) {
			for (const card of data.cards) {
				tab.cards.push(APAssetFormTabCard.parse(card));
			}
		}

		return tab;
	}
}
