import {UUID} from '../../uuid';
import {ExperimentDirection} from './experiment-directions';

/**
 * An experiment defines the frequency and direction of an acquisition.
 */
export class Experiment {

	/**
	 * UUID of the experiment.
	 */
	public uuid: UUID = null;

	/**
	 * Label of the experiment.
	 */
	public label: string = '';

	/**
	 * Frequency of the experiment in Khz.
	 */
	public frequency: number = null;

	/**
	 * Direction of the experiment.
	 */
	public direction: number = ExperimentDirection.LEFT;
	
	/**
	 * Type of wave emitted in the experiment.
	 */
	public waveChannels: string = '';

	/**
	 * Propagation speed of the wave in m/s.
	 */
	public propagationSpeed: number = 3200;

	/**
	 * Date the experiment took place.
	 */
	public dateTime: Date = null;
	
	/**
	 * UUID of the acquisition that belongs to the experiment.
	 */
	public acquisitionUuid: UUID = null;

	/**
	 * Parse the data of an API retrieved experiment object.
	 *
	 * @param data - Experiment object to parse data.
	 * @returns Experiment object parsed from data.
	 */
	public static parse(data: any): Experiment {
		const experiment = new Experiment();

		experiment.uuid = data.uuid;
		experiment.label = data.label;
		experiment.frequency = data.frequency;
		experiment.direction = data.direction;
		experiment.waveChannels = data.waveChannels;
		experiment.dateTime = data.dateTime;
		experiment.acquisitionUuid = data.acquisitionUuid;
		experiment.propagationSpeed = data.propagationSpeed || 3200;

		return experiment;
	}
}
