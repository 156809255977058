import {Pipe, PipeTransform} from '@angular/core';
import {ValidationUtils} from 'src/app/utils/validation-utils';

/**
 * Validates an email address.
 * 
 * If no email is provided, it will return true.
 */
@Pipe({
	name: 'validateEmail',
	standalone: true,
	pure: true
})
export class UnoFormEmailValidationPipe implements PipeTransform {
	public transform(email: string): boolean {
		if (!email) {
			return true;
		}

		return ValidationUtils.isEmail(email);
	}
}
