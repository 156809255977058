import {SphereGeometry, MeshBasicMaterial, Material, BufferGeometry, Object3D, Vector3} from 'three';
import {ScalableMesh} from '../../render/objects/scalable-mesh';
import {DigitalTwinObject} from '../digital-twin-object';
import {DigitalTwinObjectType} from '../digital-twin-object-type';

/**
 * Marker represents an interest point in the world.
 */
export class Marker extends DigitalTwinObject {
	/**
	 * Marker geometry, used to represent all markers.
	 */
	public static geometry: BufferGeometry = new SphereGeometry(0.2, 16, 16);

	/**
	 * Marker material, used to draw all markers.
	 */
	public static material: Material = new MeshBasicMaterial({
		transparent: true,
		opacity: 0.5,
		color: 0xFF0000,
		depthTest: false,
		depthWrite: false
	});

	public constructor() {
		super (DigitalTwinObjectType.MARKER);

		this.name = 'marker ' + this.uuid.substr(0, 3);
		this.add(new ScalableMesh(Marker.geometry, Marker.material));
	}

	/**
	 * Load markers from list of points into an object.
	 */
	public static loadPointList(points: Vector3[], object: Object3D): void {
		if (points && points.length > 0) {
			// Set the position of the object to the first point
			object.position.copy(points[0]);
			object.add(new Marker());

			// Create markers for the remaining points
			for (let i = 1; i < points.length; i++) {
				const marker = new Marker();
				marker.position.copy(points[i]);
				marker.position.sub(object.position);
				object.add(marker);
			}
		}
	}
}

