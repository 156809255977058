/**
 * List of possible results for the repair inspection entries.
 */
export const RepairInspectionResult = {
	NONE: 0,
	COMPLIANT: 1,
	NON_COMPLIANT: 2
};

/**
 * List of possible results for the repair entries.
 */
export const RepairInspectionResultLabel: Map<number, string> = new Map([
	[RepairInspectionResult.NONE, 'none'],
	[RepairInspectionResult.COMPLIANT, 'compliant'],
	[RepairInspectionResult.NON_COMPLIANT, 'nonCompliant']
]);
