import {StringUtils} from './string-utils';

/**
 * A compare method is to compare two objects.
 *
 * Is used to sort information. (e.g. array.sort())
 *
 * @returns 1 if a is bigger than b; -1 if a is lower than b; 0 if a equals b
 */
export type CompareMethod = (a: any, b: any)=> number;

export class CompareUtils {
	/**
	 * Compare method that compares two text entries using the same rules as SQL Server.
	 * 
	 * This method is not case sensitive and it ignores accentuation.
	 * 
	 * Returns:
	 * - negative number  if 'a' is lower than 'b'  (example: 'a'.localeCompare('c') return a negative value)
	 * - positive number  if 'b' is lower than 'a'  (example: 'c'.localeCompare('a') return a positive value)
	 * - zero             if 'a' equals 'b'         (example: 'a'.localeCompare('a') return zero)
	 * 
	 * @param a - String 'a' to validate.
	 * @param b - String 'b' to validate.
	 * @returns A number.
	 */
	public static compareTextSQL(a: string, b: string): number {
		return a.localeCompare(b, 'en', {sensitivity: 'base'});
	}

	/**
	 * Compare method that compares two date entries using the same rules as SQL Server.
	 * 
	 * This method convert the received parameter into a 'the number of milliseconds since January 1, 1970 00:00:00' using the getTime() and calls
	 * the compareNumber() method. 
	 * 
	 * Returns:
	 * - negative number  if 'a' is lower than 'b'  (example: 'a'.localeCompare('c') return a negative value)
	 * - positive number  if 'b' is lower than 'a'  (example: 'c'.localeCompare('a') return a positive value)
	 * - zero             if 'a' equals 'b'         (example: 'a'.localeCompare('a') return zero)
	 * 
	 * @param a - Date 'a' to validate.
	 * @param b - Date 'b' to validate.
	 * @returns A number.
	 */
	public static compareDate(a: Date, b: Date): number {
		return CompareUtils.compareNumber(new Date(a).getTime(), new Date(b).getTime()); 
	}


	/**
	 * Compare dates, but ignores seconds of the date.
	 * 
	 * @param a - Date 'a' to validate.
	 * @param b - Date 'b' to validate.
	 * @returns A number.
	 */
	public static compareDateNoSeconds(a: Date, b: Date): number {
		a = new Date(a);
		a.setMilliseconds(0);
		a.setSeconds(0);

		b = new Date(b);
		b.setMilliseconds(0);
		b.setSeconds(0);

		return CompareUtils.compareNumber(a.getTime(), b.getTime()); 
	}

	/**
	 * Compare two numeric values.
	 * 
	 * @param a - Number 'a' to validate.
	 * @param b - Number 'b' to validate.
	 * @returns A number.
	 */
	public static compareNumber(a: number, b: number): number {
		return a !== b ? a > b ? 1 : -1 : 0; 
	}

	/**
	 * Compare two strings without taking into account accent marks (Example: õ, ç, á).
	 * @param a - String 'a' to validate.
	 * @param b - String 'b' to validate.
	 * @returns True if both strings are equal. False otherwise.
	 */
	public static normalizedCompare(a: string, b: string): boolean {
		return StringUtils.normalize(a).toLowerCase() === StringUtils.normalize(b).toLowerCase();
	}

	/**
	 * See if a string is included in another without into account accent marks (Example: õ, ç, á).
	 * @param a - String 'a' to validate.
	 * @param b - String 'b' to validate.
	 * @returns True if string 'a' includes the string 'b'. False otherwise.
	 */
	public static normalizedIncludes(a: string, b: string): boolean {
		return StringUtils.normalize(a).toLowerCase().includes(StringUtils.normalize(b).toLowerCase());
	}
}
