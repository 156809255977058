import {Team} from 'src/app/models/teams/team';
import {User} from 'src/app/models/users/user';
import {UUID} from '../../../models/uuid';
import {Service} from '../../../http/service';
import {ServiceList} from '../../../http/service-list';
import {Session} from '../../../session';

// Team users list API request format.
export type TeamListUsersResponse = {
	// If true, there are more items to fetch on the list.
	hasMore: boolean,

	// The fetched users
	users: User[],

	// ID of the request
	id: number
};

// Parameters to apply on the team users count API request.
export type TeamUsersCountParams = {
	// UUID of the team to count users
	uuid?: UUID
};

// Parameters to apply on the team users list API request.
export type TeamListUsersParams = {
	// From where to start fetching items
	from?: number,
	// How many items to fetch
	count?: number,
	// Sort Direction to apply
	sortDirection?: string,
	// Sort field to sort by
	sortField?: string,
	// UUID of the team to fetch users
	uuid?: UUID
};


export class TeamService {
	/**
	 * Gets multiple teams in batch by their UUIDs.
	 *
	 * @param teamUUIDs - The UUIDs of the teams.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 * @returns Array of teams that match the UUIDs.
	 */
	public static async getBatch(teamUUIDs: UUID[], hideLoading: boolean = false, displayError: boolean = true): Promise<Team[]> {
		const request = await Service.fetch(ServiceList.teams.getBatch, null, null, {teamsUuids: teamUUIDs}, Session.session, hideLoading, displayError);
		return request.response.teams.map((t: Team) => { return Team.parse(t); });
	}

	/**
	 * List Team users matching the params.
	 *
	 * @param params - The params to apply on the api request.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 * 
	 * @returns Array of team users that match the params.
	 */
	public static async listUsers(params: TeamListUsersParams, hideLoading: boolean = false, displayError: boolean = true): Promise<TeamListUsersResponse> {
		const request = await Service.fetch(ServiceList.teams.listUsers, null, null, params, Session.session, hideLoading, displayError);

		return {
			hasMore: request.response.hasMore,
			users: request.response.users,
			id: request.id
		};
	}

	/**
	 * Count team users matching the params.
	 *
	 * @param params - The params to apply on the api request.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 * 
	 * @returns The number of team users that match the params.
	 */
	public static async countUsers(params: TeamUsersCountParams, hideLoading: boolean = true, displayError: boolean = true): Promise<number> {
		const request = await Service.fetch(ServiceList.teams.countUsers, null, null, params, Session.session, hideLoading, displayError);
		return request.response.count;
	}

}
