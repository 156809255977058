import {AssetFluidFamily} from 'src/app/models/asset-portfolio/asset-fluid-family';
import {AssetFluidType} from 'src/app/models/asset-portfolio/asset-fluid-type';
import {UUID} from 'src/app/models/uuid';
import {Service} from '../../../http/service';
import {ServiceList} from '../../../http/service-list';
import {Session} from '../../../session';

// Asset fluid families list API body request.
export type AssetFluidFamilyListParams = {
	// Search value to apply
	search?: string,
	// Fields to search by
	searchFields?: string[],
	// From where to start fetching items
	from?: number,
	// How many items to fetch
	count?: number,
	// Sort Direction to apply on the response
	sortDirection?: string,
	// Sort Field to apply on the response
	sortField?: string,
};

// Asset fluid families list API request response format.
export type AssetFluidFamilyListResponse = {
	// If true, there are more items to fetch on the list.
	hasMore: boolean,
	// The fetched Asset Fluid Families.
	families: AssetFluidFamily[],
	// The id of the api call.
	id: number
};

// Asset fluid type list API request format.
export type AssetFluidTypeListResponse = {
	// If true, there are more items to fetch on the list.
	hasMore: boolean,
	// The fetched Asset Fluid Types.
	types: AssetFluidType[],
	// The id of the api call.
	id: number
};

// Asset fluid type list params.
export type AssetFluidTypeListParams = {
	// The UUID of the family to filter.
	assetFluidFamilyUuid?: UUID,
	// Search value to apply
	search?: string,
	// Fields to search by
	searchFields?: string[],
	// From where to start fetching items
	from?: number,
	// How many items to fetch
	count?: number,
	// Sort Direction to apply on the response
	sortDirection?: string,
	// Sort Field to apply on the response
	sortField?: string,
};

export class AssetFluidService {
	/**
	 * Gets a list of asset fluid families.
	 *
	 * @param params - The parameters to filter the fuild families by.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 * @returns All the fluid families.
	 */
	public static async listFamily(params: AssetFluidFamilyListParams = {}, hideLoading: boolean = false, displayError: boolean = true): Promise<AssetFluidFamilyListResponse> {
		const request = await Service.fetch(ServiceList.assetPortfolio.fluidFamily.list, null, null, params, Session.session, hideLoading, displayError);

		return {
			hasMore: request.response.hasMore,
			families: request.response.families.map((f: any) => {return AssetFluidFamily.parse(f);}),
			id: request.id
		};
	}

	/**
	 * Get an of asset fluid family.
	 *
	 * @param uuid - The UUID of the fuild family.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 * @returns The fluid family.
	 */
	public static async getFamily(uuid: UUID, hideLoading: boolean = false, displayError: boolean = true): Promise<AssetFluidFamily> {
		const request = await Service.fetch(ServiceList.assetPortfolio.fluidFamily.get, null, null, {uuid: uuid}, Session.session, hideLoading, displayError);
		return AssetFluidFamily.parse(request.response.family);
	}

	/**
	 * Gets all the fluid types that match the params.
	 *
	 * @param params - The params to apply on the request.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 * @returns All the fluid types.
	 */
	public static async listTypes(params: AssetFluidTypeListParams, hideLoading: boolean = false, displayError: boolean = true): Promise<AssetFluidTypeListResponse> {
		const request = await Service.fetch(ServiceList.assetPortfolio.fluidType.list, null, null, params, Session.session, hideLoading, displayError);

		return {
			hasMore: false,
			types: request.response.types.map((f: any) => {return AssetFluidType.parse(f);}),
			id: request.id
		};
	}

	/**
	 * Get an of asset fluid type.
	 *
	 * @param uuid - The UUID of the fuild type.
	 * @param hideLoading - True to hide the loading spinner, false otherwise.
	 * @param displayError - True to display an error message, false otherwise.
	 * @returns The fluid type.
	 */
	public static async getType(uuid: UUID, hideLoading: boolean = false, displayError: boolean = true): Promise<AssetFluidType> {
		const request = await Service.fetch(ServiceList.assetPortfolio.fluidType.get, null, null, {uuid: uuid}, Session.session, hideLoading, displayError);
		return AssetFluidType.parse(request.response.type);
	}
}
