import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {AssetPlanningCalendarScreen} from './screens/calendar/asset-planning-calendar-screen.component';

const routes: Routes = [
	{
		path: '',
		component: AssetPlanningCalendarScreen
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class AssetPlanningRouterModule { }
