import {UUID, UUIDIdentification} from 'src/app/models/uuid';

/**
 * Object that represents a fluid type.
 */
export class AssetFluidType extends UUIDIdentification {
	/**
	 * UUID of the fluid family that has this fluid type.
	 */
	public fluidFamilyUuid: UUID = null;

	/**
	 * Label shown to the user.
	 */
	public label: string = null;

	/**
	 * Parse JSON data received from the API into an object.
	 *
	 * @param data - Object received from API.
	 * @returns Asset fluid type object instance built from the data received.
	 */
	public static parse(data: any): AssetFluidType {
		const type = new AssetFluidType();

		type.createdAt = new Date(data.createdAt);
		type.updatedAt = new Date(data.updatedAt);

		type.uuid = data.uuid;
		type.fluidFamilyUuid = data.fluidFamilyUuid;
		type.label = data.label;

		return type;
	}
}
