import {Pipe, PipeTransform} from '@angular/core';
import {UnitConverter} from '../utils/unit-converter';

/**
 * Pipe to convert a unit of measurement.
 */
@Pipe({
	name: 'convertUnit',
	standalone: true,
	pure: true
})
export class UnitConverterPipe implements PipeTransform {
	public transform(input: any, inUnit: string, outUnit: string): any {
		return UnitConverter.convert(input, inUnit, outUnit);
	}
}
