import {UnoFilterBarOptionType} from 'src/app/components/uno/uno-filter-bar/uno-filter-bar-option';
import {Environment} from 'src/environments/environment';

/**
 * Asset filter for the repair list in repsol.
 */
export const RepsolRepairListAssetFilter = {
	type: UnoFilterBarOptionType.OPTIONS,
	attribute: 'parentAssetUuid',
	label: 'parentAsset',
	default: null,
	multiple: false,
	options: [
		{
			value: null,
			label: 'all'
		},
		{
			value: 'ca0d1232-6400-1a1f-8b0c-26a284b72527',
			label: 'BUTADIENO (BUT)'
		},
		{
			value: '893f00fa-a3af-14d0-855a-7b5cf2926fa5',
			label: 'CENTR.TERMOELÉCTR. - (CT)'
		},
		{
			value: 'b790fe49-2d85-1087-9eac-7b4494b4738f',
			label: 'ETILENO (ETI)'
		},
		{
			value: '12471835-d19c-17a5-8aab-2e86abb1f298',
			label: 'FÁBRICA PEAD (EAD)'
		},
		{
			value: '30f810ce-1244-169f-9d62-e64404901b7f',
			label: 'FABRICA PEBD (EBD)'
		},
		{
			value: 'c391396a-f748-416a-a11e-5e50f9012fc6',
			label: 'Flare 2'
		},
		{
			value: '83ed2760-ce2a-1601-aa09-1c92b08c4e02',
			label: 'PAE'
		},
		{
			value: 'c07a84ca-396d-1dd2-937f-7ad54fe852b2',
			label: 'PLO'
		},
		{
			value: '21c3e81f-e834-186b-b7ef-774da6c7ac62',
			label: 'POL'
		},
		{
			value: '5cfdc215-f5cb-13de-8f4d-251516784b79',
			label: 'PPO'
		},
		{
			value: 'c777594c-1ab9-1826-b612-bc54c712e742',
			label: 'PUT'
		},

		{
			value: '43783440-d577-4b6c-a368-9a1e446dffd0',
			label: 'MTBE/ETBE'
		},
		{
			value: '38acec3b-e921-19a2-abd3-3fb32204c350',
			label: 'TER.PORTUÁRIO (TP)'
		}
	],
	enabled: (filters: any): boolean => {return Environment.CLIENT_ID === 'repsol' || !Environment.PRODUCTION;}
};
