import {Object3D, OrthographicCamera as TOrthographicCamera, WebGLRenderer} from 'three';
import {Viewport} from './viewport';

/**
 * Orthographic Camera with support for aspect ratio.
 */
export class OrthographicCamera extends TOrthographicCamera {
	/**
	 * Default near plane.
	 */
	public static NEAR: number = 1e-1;

	/**
	 * Default far plane.
	 */
	public static FAR: number = 1e8;

	/**
	 * Camera vertical resize mode.
	 *
	 * The camera resizes only vertically.
	 */
	public static HORIZONTAL: number = 0;

	/**
	 * Camera vertical resize mode.
	 *
	 * The camera resizes only vertically.
	 */
	public static VERTICAL: number = 1;

	/**
	 * Viewport indicates the portion of the screen to where this camera renders.
	 */
	public viewport: Viewport = new Viewport();

	public mode: number;

	public aspect: number;

	public size: number;

	public constructor(size?: number, aspect?: number, mode?: number, near: number = OrthographicCamera.NEAR, far: number = OrthographicCamera.FAR) {
		super(-1.0, 1.0, 1.0, -1.0, near, far);

		this.aspect = aspect ? aspect : 1.0;
		this.mode = mode ? mode : OrthographicCamera.HORIZONTAL;
		this.size = size ? size : 10.0;

		this.updateProjectionMatrix();
	}

	/**
	 * Render the scene from this camera.
	 *
	 * Applies the viewport attached to the camera to the renderer.
	 */
	public render(renderer: WebGLRenderer, scene: Object3D): void {
		this.viewport.enable(renderer);
		renderer.render(scene, this);
	}

	/**
	 * Update camera projection matrix.
	 *
	 * Should be called after changing projection parameters.
	 */
	public updateProjectionMatrix(): void {
		if (this.mode === OrthographicCamera.HORIZONTAL) {
			this.top = this.size / 2;
			this.bottom = -this.top;
			this.right = this.top * this.aspect;
			this.left = -this.right;
		} else if (this.mode === OrthographicCamera.VERTICAL) {
			this.right = this.size / 2;
			this.left = -this.right;
			this.top = this.right / this.aspect;
			this.bottom = -this.top;
		}

		super.updateProjectionMatrix();
	}
}
