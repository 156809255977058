/**
 * Format to present dates.
 */
export enum DateFormat {
	/**
	 * Follow the format defined for locale.
	 */
	DEFAULT = 0,

	/**
	 * Day, month and year.
	 */
	DDMMYYYY = 1,

	/**
	 * Month, day and year.
	 */
	MMDDYYYY = 2,

	/**
	 * Year, month and day.
	 */
	YYYYMMDD = 3,
}

export const DateFormatLabel: Map<DateFormat, string> = new Map<DateFormat, string>([
	[DateFormat.DEFAULT, 'default'],
	[DateFormat.DDMMYYYY, 'DD/MM/YYYY'],
	[DateFormat.MMDDYYYY, 'MM/DD/YYYY'],
	[DateFormat.YYYYMMDD, 'YYYY/MM/DD']
]);
