<div [ngStyle]="this.containerStyle">
	<input #input [autocomplete]="this.autocomplete ? 'on' : 'off'" [disabled]="this.disabled" [ngClass]="this.ngClass" [ngStyle]="this.inputStyle" [placeholder]="this.placeholder" [type]="this.displayPassword ? 'text' : this.type" />

	@if (this.type === 'password') {
		<div (click)="this.toggleVisibility();" style="top: 0px; right: 0px;position: absolute; width: 40px; height: 40px; cursor: pointer;">
			<uno-icon [src]="this.displayPassword ? 'assets/icons/mono/eye-off.svg' : 'assets/icons/mono/eye.svg'" color="gray" [width]="25" [height]="40"></uno-icon>
		</div>
	}

	@if (this.validator) {
		@if (this.validator.successMessage && this.valid()) {
			<uno-text [ngStyle]="{'font-size': '12px'}" color="success">{{this.validator.successMessage | translate}}</uno-text>
		}
		@if (this.validator.errorMessage && !this.valid()) {
			<uno-text [ngStyle]="{'font-size': '12px'}" color="error">{{this.validator.errorMessage | translate}}</uno-text>
		}
	}
</div>
