import {Component, Input, OnInit, signal, ViewEncapsulation, WritableSignal} from '@angular/core';
import {NgStyle, NgClass} from '@angular/common';
import {UnoNoDataComponent} from '../uno-no-data/uno-no-data.component';
import {UnoTreeItemComponent} from './uno-tree-item/uno-tree-item.component';
import {UnoTreeLayout, UnoTreeLayoutType} from './uno-tree-layout';

/**
 * Method used to load more items in the tree list.
 *
 * @param item - The item to load children from.
 * @returns The children of the item.
 */
export type UnoTreeLoadData = (item: any)=> Promise<any[]>;

/**
 * Method called when a item is clicked.
 * 
 * @param item - The item clicked.
 */
export type UnoTreeOnClick = (item: any)=> void;

@Component({
	selector: 'uno-tree',
	templateUrl: './uno-tree.component.html',
	styleUrls: ['./uno-tree.component.css'],
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [NgStyle, NgClass, UnoTreeItemComponent, UnoNoDataComponent]
})
export class UnoTreeComponent implements OnInit {
	/**
	 * Layout used to present a item in the tree.
	 */
	@Input()
	public layout: UnoTreeLayout[] = [];

	/**
	 * Method used to load more items in the tree list.
	 */
	@Input()
	public loadData: UnoTreeLoadData = null;

	/**
	 * Method to call if the item is clicked.
	 */
	@Input()
	public onClick: UnoTreeOnClick = null;

	/**
	 * Root items to be displayed in the tree.
	 */
	public items: WritableSignal<any[]> = signal([]);

	/**
	 * Picture field (if any).
	 */
	public pictureField: WritableSignal<UnoTreeLayout> = signal(null);

	public async ngOnInit(): Promise<void> {
		// Check if the layout has more than one picture
		const pictureFields = this.layout.filter(function(layout) {return layout.type === UnoTreeLayoutType.PICTURE;});	
		if (pictureFields.length > 1) {
			throw new Error('The layout can only have one picture field');
		}

		if (pictureFields.length > 0) {
			this.pictureField.set(pictureFields[0]);
		}

		this.items.set(await this.loadData(null));
	}
}
