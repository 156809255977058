/**
 * Service content type indicates the type of the URL data sent by the service.
 */
// eslint-disable-next-line no-shadow
export enum ServiceURLType {
	/**
	 * No data to be sent to the server.
	 */
	NONE,

	/**
	 * QUERY encoded data type directly in the URL of the request.
	 *
	 * When this type of data is used the depth of the data object has to be 1.
	 */
	QUERY,

	/**
	 * URL encoded data as part of the API route.
	 *
	 * The data is an array with the values to be passed in the correct order.
	 */
	URL_ARRAY,

	/**
	 * URL encoded data as part of the API route.
	 *
	 * The data is represented in the API by writing wildcards into the address declaration as `{param}`.
	 *
	 * e.g `api/abc/{param}/{test}/abc`, the input object to send is a `{param:(...), test:{...}}` the values are replaced in the address.
	 */
	URL
}
