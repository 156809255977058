import * as XLSX from 'xlsx';
import {Service} from '../../../http/service';
import {ServiceList} from '../../../http/service-list';
import {UnoFormField} from '../../../components/uno-forms/uno-form/uno-form-field';
import {UnoFormFieldTypes} from '../../../components/uno-forms/uno-form/uno-form-field-types';
import {Modal} from '../../../modal';
import {ProgressBar} from '../../../progress-bar';
import {Session} from '../../../session';
import {FormSortUtils} from '../../../utils/form-sort-utils';
import {XlsxSheetData, XlsxUtils} from '../../../utils/xlsx-utils';
import {Resource} from '../../../models/resource';
import {FileUtils} from '../../../utils/file-utils';
import {generateUUID, UUID} from '../../../models/uuid';
import {Locale} from '../../../locale/locale';
import {APAssetFormBlockFieldComponentType, APAssetFormBlockFieldComponentTypeLabel} from '../../../models/asset-portfolio/asset-form-block-field-type';
import {APAssetFormBlockField} from '../../../models/asset-portfolio/asset-form-block-field';
import {APAssetFormBlock} from '../../../models/asset-portfolio/asset-form-block';
import {ResourceUtils} from '../../../utils/resource-utils';

export class FormBlockDataTools {
	/**
	 * The name of the form block sheet name, used on XLSX files
	 */
	public static formBlockSheetName: string = 'formBlocks';
	
	/**
	 * The name of the form block fields sheet name, used on XLSX files
	 */
	public static formBlockFieldsSheetName: string = 'formBlockFields';

	/**
	 * Sheet that contains the form block data. A form block per row.
	 */
	public static blocksSheetData: ()=> XlsxSheetData = () => {
		return {
			name: Locale.get(FormBlockDataTools.formBlockSheetName),
			data: [
				[
				// Sheet headers
					Locale.get('uuid'), Locale.get('createdAt'), Locale.get('updatedAt'), 
					Locale.get('name'), Locale.get('description')
				]
			]
		};
	};

	/**
	 * Sheet that contains the form block fields data. A form block field per row.
	 */
	public static fieldsSheetData: ()=> XlsxSheetData = () => {
		return {
			name: Locale.get(FormBlockDataTools.formBlockFieldsSheetName),
			data: [
				[
				// Sheet headers
					Locale.get('uuid'), Locale.get('createdAt'), Locale.get('updatedAt'), 
					Locale.get('name'), Locale.get('description'),
					Locale.get('formBlockUuid'), Locale.get('formBlock'),
					Locale.get('required'),
					Locale.get('componentName'),
					Locale.get('data'),
					Locale.get('indexes')
				]
			]
		};
	};
	
	/**
	 * Export all the non private form blocks and fields in a XLSX file.
	 * 
	 * A form block with base info per row in the first sheet and a form block field with all its info per row.
	 */
	public static async exportAllXLSX(): Promise<void> {		
		// The progress bar use to display export progress on a modal
		const progressBar: ProgressBar = new ProgressBar();
		progressBar.show();

		try {
			const blocksSheetData: XlsxSheetData = FormBlockDataTools.blocksSheetData();
			const blockFieldsSheetData: XlsxSheetData = FormBlockDataTools.fieldsSheetData();
	
			const workBookSheets: XlsxSheetData[] = [blocksSheetData, blockFieldsSheetData];
	
			// Get list of form blocks with their fields and parse them into correct objects
			const reqBlocks = await Service.fetch(ServiceList.assetPortfolio.formBlock.listDetailed, null, null, {listFields: true}, Session.session, true, false);
			const formBlocks: APAssetFormBlock[] = reqBlocks.response.blocks.map((d: any) => { return APAssetFormBlock.parse(d); });
	
			for (let i = 0; i < formBlocks.length; i++) {
				progressBar.update(Locale.get('loadingData'), (i + 1) / formBlocks.length);
	
				const block: APAssetFormBlock = formBlocks[i];
				block.fields = FormSortUtils.sortByIndexes(block.fields);
				
				// Push form block data to the form blocks sheet
				blocksSheetData.data.push([
					block.uuid, block.createdAt ? block.createdAt.toLocaleString(Locale.code) : '', block.updatedAt ? block.updatedAt.toLocaleString(Locale.code) : '',
					block.name, block.description
				]);
				
				for (let j = 0; j < block.fields.length; j++) {
					const field: APAssetFormBlockField = block.fields[j];
	
					// Push form block field data to the form block fields sheet
					blockFieldsSheetData.data.push([
						field.uuid, field.createdAt ? field.createdAt.toLocaleString(Locale.code) : '', field.updatedAt ? field.updatedAt.toLocaleString(Locale.code) : '',
						field.name, field.description,
						block.uuid, block.name,
						field.required,
						Locale.get(APAssetFormBlockFieldComponentTypeLabel.get(field.formFieldComponent)),
						field.data ? JSON.stringify(field.data) : '',
						field.indexes
					]);
				}
			}
	
			XlsxUtils.writeMultiSheetFile(workBookSheets, 'form-blocks.xlsx');
		} catch (e) {
			Modal.alert(Locale.get('error'), e.response ? e.response : e);
		}
				
		progressBar.destroy();
	}

	/**
	 * Import non private form blocks and fields from a XLSX file.
	 * 
	 * A form block with base info per row in the first sheet and a form block field with all its info per row.
	 */
	public static async importXLSX(): Promise<void> {
		const obj: {deleteMissingFormBlocks: boolean, deleteMissingFormFields: boolean, xlsxResource: Resource} = {
			// If set, missing form blocks on document should be deleted on API
			deleteMissingFormBlocks: false,
			// If set, missing form block fields on a form block should be deleted on API
			deleteMissingFormFields: false,
			// The imported file with formb blocks and fields
			xlsxResource: null
		};

		// Form layout to present on Modal
		const layout: UnoFormField[] = [
			{
				required: true,
				attribute: 'deleteMissingFormBlocks',
				label: 'deleteMissingFormBlocks',
				type: UnoFormFieldTypes.CHECKBOX
			},
			{
				required: true,
				attribute: 'deleteMissingFormFields',
				label: 'deleteMissingFormFields',
				type: UnoFormFieldTypes.CHECKBOX
			},
			{
				required: true,
				attribute: 'xlsxResource',
				label: 'selectFile',
				sampleData: 'assets/template/form-blocks-import-template.xlsx',
				type: UnoFormFieldTypes.DOCUMENT_RESOURCE,
				filter: '.xlsx',
				isEmpty: function(object) {
					return !object.xlsxResource;
				}
			}
		];

		try {
			await Modal.form(Locale.get('import'), obj, layout);
		} catch {
			return;
		}

		// Present a confirmation modal to ensure that user really want to delete items not present on document
		if ((obj.deleteMissingFormBlocks || obj.deleteMissingFormFields) && !await Modal.confirm(Locale.get('confirm'), Locale.get('deleteMissingItemsWarning'))) {
			return;
		}


		// Extract data from imported file
		const file = await FileUtils.readFileArrayBuffer(ResourceUtils.getURL(obj.xlsxResource));
	
		// Extract file sheets
		const fileSheets: {[sheetName: string]: XLSX.WorkSheet;} = XLSX.read(file, {type: 'array', cellDates: true}).Sheets;
		
		// Extract document sheets 
		const blocksWorkSheet: XLSX.WorkSheet = fileSheets[Locale.get(FormBlockDataTools.formBlockSheetName)];
		const fieldsWorkSheet: XLSX.WorkSheet = fileSheets[Locale.get(FormBlockDataTools.formBlockFieldsSheetName)];

		if (!blocksWorkSheet || !fieldsWorkSheet) {
			Modal.alert(Locale.get('error'), Locale.get('errorMissingDocumentSheets'));
		}
	
		const progress = new ProgressBar();

		try {
			// Extract rows from sheets
			const blockRows: any[] = XLSX.utils.sheet_to_json(blocksWorkSheet);
			const fieldRows: any[] = XLSX.utils.sheet_to_json(fieldsWorkSheet);
	
			// Set row import counters for the progress bar
			const totalRows: number = blockRows.length + fieldRows.length;
			
			const updateProgress: Function = function() {
				// Update progress
				progress.update(Locale.get('importingData', {
					total: totalRows,
					imported: stats.created + stats.updated,
					failed: stats.failed
				}), (stats.created + stats.updated + stats.failed) / totalRows);
			};
	
			// Sheets imported data and error details.
			const errorBlocksSheetData: Map<UUID, any[]> = new Map();
			const errorFieldsSheetData: Map<UUID, any[]> = new Map();
	
			const stats = {
				// The number of items that failed on imports
				failed: 0,
				// The number of items that were successfully created on import
				created: 0,
				// The number of items that were successfully updated on import
				updated: 0
			};
	
			// Adds a new row with an extra column of error details to the form blocks sheet and all its fields with an extra column to the form block fields sheet on file to export with errors
			const setBlockError: Function = function(b: APAssetFormBlock, errorBlockMsg: string, setFieldsErrors: boolean = false, errorFieldMsg: string = ''): void {
				const blockData: any[] = errorBlocksSheetData.get(b.uuid);
				
				// Do not update stats twice for the same block failure
				if (!blockData) {
					stats.failed++;
					updateProgress();
				}
	
				let blockErrMsg: string = '';
				if (blockData) {
					blockErrMsg = blockData[blockData.length - 1];
	
					if (errorBlockMsg.length > 0) {
						blockErrMsg = blockErrMsg + ' | ' + errorBlockMsg;
					}
				} else {
					blockErrMsg = errorBlockMsg;
				}
	 
				// Set or update block error row with error message
				errorBlocksSheetData.set(b.uuid, [
					b.uuid, b.createdAt ? b.createdAt.toLocaleString(Locale.code) : '', b.updatedAt ? b.updatedAt.toLocaleString(Locale.code) : '',
					b.name, b.description,
					blockErrMsg
				]);
	
				if (setFieldsErrors) {
					setFieldsError(b, b.fields, errorFieldMsg);
				}
			};
	
			// Adds a new row with an extra column of error details to the form block fields sheet on file to export with errors
			const setFieldsError: Function = function(b: APAssetFormBlock, fs: APAssetFormBlockField[], errorMsg: string): void {
				for (let i = 0; i < fs.length; i++) {
					const f: APAssetFormBlockField = fs[i];
	
					// If there is already an error entry for that specific form block field, update its error data appending the new error message to it
					const fieldData: any[] = errorFieldsSheetData.get(f.uuid);
	
					// Do not update stats twice for the same block field failure
					if (!fieldData) {
						stats.failed++;
						updateProgress();
					}
	
					let fieldErrMsg: string = '';
					if (fieldData) {
						fieldErrMsg = fieldData[fieldData.length - 1];
	
						if (errorMsg.length > 0) {
							fieldErrMsg = fieldErrMsg + ' | ' + errorMsg;
						}
					} else {
						fieldErrMsg = errorMsg;
					}
	
					// Set or update block field error row with error message
					errorFieldsSheetData.set(f.uuid, [
						f.uuid, f.createdAt ? f.createdAt.toLocaleString(Locale.code) : '', f.updatedAt ? f.updatedAt.toLocaleString(Locale.code) : '',
						f.name, f.description,
						b ? b.uuid : '', b ? b.name : '',
						f.required,
						Locale.get(APAssetFormBlockFieldComponentTypeLabel.get(f.formFieldComponent)),
						f.data,
						f.indexes,
						fieldErrMsg
					]);
				}
			};

			progress.show();
			updateProgress();
	
			// List of form block attributes and possible names in the XLSX file
			const formBlockAttributes: {attribute: string, names: string[]}[] = [
				{
					attribute: 'uuid',
					names: Locale.getAllTranslations('uuid')
				},
				{
					attribute: 'createdAt',
					names: Locale.getAllTranslations('createdAt')
				},
				{
					attribute: 'updatedAt',
					names: Locale.getAllTranslations('updatedAt')
				},
				{
					attribute: 'name',
					names: Locale.getAllTranslations('name')
				},
				{
					attribute: 'description',
					names: Locale.getAllTranslations('description')
				}
			];
	
			// List of form block fields attributes and possible names in the XLSX file
			const formBlockFieldsAttributes: {attribute: string, names: string[]}[] = [
				{
					attribute: 'uuid',
					names: Locale.getAllTranslations('uuid')
				},
				{
					attribute: 'createdAt',
					names: Locale.getAllTranslations('createdAt')
				},
				{
					attribute: 'updatedAt',
					names: Locale.getAllTranslations('updatedAt')
				},
				{
					attribute: 'name',
					names: Locale.getAllTranslations('name')
				},
				{
					attribute: 'description',
					names: Locale.getAllTranslations('description')
				},
				{
					attribute: 'formBlockUuid',
					names: Locale.getAllTranslations('formBlockUuid')
				},
				{
					attribute: 'formBlock',
					names: Locale.getAllTranslations('formBlock')
				},
				{
					attribute: 'required',
					names: Locale.getAllTranslations('required')
				},
				{
					attribute: 'componentName',
					names: Locale.getAllTranslations('componentName')
				},
				{
					attribute: 'data',
					names: Locale.getAllTranslations('data')
				},
				{
					attribute: 'indexes',
					names: Locale.getAllTranslations('indexes')
				}
			];
			
			// Keep blocks to create in a map by its name
			const blocksCreate: Map<string, APAssetFormBlock> = new Map();
	
			// Keep blocks to update in a map by its UUID
			const blocksUpdate: Map<UUID, APAssetFormBlock> = new Map();
	
			// Import form blocks
			for (let i = 0; i < blockRows.length; i++) {
				// Get row colums in a map with column header as key and row data as its value
				const rowMap: Map<string, any> = XlsxUtils.readRowAsMap(blockRows[i], formBlockAttributes);
				
				// If row has no UUID for the form block it is a new form block
				const toUpdate: boolean = XlsxUtils.hasCell(rowMap, 'uuid');
				
				const block: APAssetFormBlock = new APAssetFormBlock();
				// Form blocks must have a valid UUID
				block.uuid = toUpdate ? rowMap.get('uuid') : generateUUID();
				block.createdAt = XlsxUtils.hasCell(rowMap, 'createdAt') ? new Date(rowMap.get('createdAt')) : null;
				block.updatedAt = XlsxUtils.hasCell(rowMap, 'updatedAt') ? new Date(rowMap.get('updatedAt')) : null;
				block.name = rowMap.get('name');
				block.description = rowMap.get('description');
				block.privateBlock = false;
				block.fields = [];
	
				// Form block must have a valid name
				if (!block.name || block.name.length === 0) {
					setBlockError(block, Locale.get('errorNameRequired'));
					continue;
				}
				
				// Store blocks to create and update separately
				if (toUpdate) {
					blocksUpdate.set(block.uuid, block);
				} else {
					blocksCreate.set(block.name, block);
				}
			}
	
			// Import form block fields
			for (let i = 0; i < fieldRows.length; i++) {
				// Get row colums in a map with column header as key and row data as its value
				const rowMap: Map<string, any> = XlsxUtils.readRowAsMap(fieldRows[i], formBlockFieldsAttributes);
							
				const fieldToUpdate: boolean = XlsxUtils.hasCell(rowMap, 'uuid');
	
				const field: APAssetFormBlockField = new APAssetFormBlockField();
				// If row has no UUID for the form field it is a new form field and a new UUID must be set
				field.uuid = fieldToUpdate ? rowMap.get('uuid') : generateUUID();
				field.createdAt = XlsxUtils.hasCell(rowMap, 'createdAt') ? new Date(rowMap.get('createdAt')) : null;
				field.updatedAt = XlsxUtils.hasCell(rowMap, 'updatedAt') ? new Date(rowMap.get('updatedAt')) : null;
				field.name = rowMap.get('name');
				field.description = rowMap.get('description');
				field.required = rowMap.get('required');
				field.data = rowMap.get('data');
				field.indexes = XlsxUtils.hasCell(rowMap, 'indexes') ? String(rowMap.get('indexes')) : '';
				
				// Get the form field component from the form field component name
				const componentName: string = rowMap.get('componentName');
				for (const [componentCode, label] of APAssetFormBlockFieldComponentTypeLabel) {
					if (Locale.getAllTranslations(label).includes(componentName)) {
						field.formFieldComponent = componentCode;
						break;
					}
				}
				
				// Form blocks must be in the same file even if referenced by UUID. We need to differ new blocks from blocks to update in order to apply validation rules to the fields.
				let block: APAssetFormBlock;
				let blockToUpdate: boolean = false;
				if (XlsxUtils.hasCell(rowMap, 'formBlockUuid')) {
					blockToUpdate = true;
					block = blocksUpdate.get(rowMap.get('formBlockUuid'));
				} else if (XlsxUtils.hasCell(rowMap, 'formBlock')) {
					block = blocksCreate.get(rowMap.get('formBlock'));
				}
				
				// Form block fields must reference a valid form block
				if (!block) {
					setFieldsError(block, [field], Locale.get('errorFormBlockNotPresentOnDocument'));
					continue;
				}
	
				field.formBlockUuid = block.uuid;
	
				// Form fields must have a valid UUID but fields referencing new blocks to create, can't have (field) UUID previously set
				if (!blockToUpdate && fieldToUpdate) {
					setFieldsError(block, [field], Locale.get('errorCannotUpdateFieldOfNewFormBlock'));
					continue;
				}
	
				// Form field must have a valid name
				if (!field.name || field.name.length === 0) {
					setFieldsError(block, [field], Locale.get('errorNameRequired'));
					continue;
				}
	
				// Field must reference a valid component
				if (!field.formFieldComponent || field.formFieldComponent === APAssetFormBlockFieldComponentType.NONE) {
					setFieldsError(block, [field], Locale.get('errorInvalidFieldComponent'));
					continue;
				}
				
				// Add field to the block
				block.fields.push(field);
	
				// Update block
				if (blockToUpdate) {
					blocksUpdate.set(block.uuid, block);
				} else {
					blocksCreate.set(block.name, block);
				}
			}

			// Updates form fields indexes. Fields indexes can either be all empty or all filled (not repeated) but not mixed. Returns true in case of error.
			const updateIndexesAndSendBlock: (b: APAssetFormBlock, toUpdate: boolean)=> Promise<void> = async function(b: APAssetFormBlock, toUpdate: boolean): Promise<void> {
				// Separate indexed fields from the ones without indexes. Sort indexed fields first and then append non-sorted fields and update indexes of them all
				let indexedFields: APAssetFormBlockField[] = b.fields.reduce((fieldsAcc: APAssetFormBlockField[], f: APAssetFormBlockField) => {
					if (f.indexes && f.indexes.length > 0) {
						fieldsAcc.push(f);
					}
	
					return fieldsAcc;
				}, []);
	
				const nonIndexedFields: APAssetFormBlockField[] = b.fields.reduce((fieldsAcc: APAssetFormBlockField[], f: APAssetFormBlockField) => {
					if (!f.indexes || f.indexes.length === 0) {
						fieldsAcc.push(f);
					}
	
					return fieldsAcc;
				}, []);
				
				// 	If the index columns exists and there are missing values, don't process form fields and add to error list
				if (indexedFields.length > 0 && nonIndexedFields.length > 0 ) {
					// Store block data on sheet to export with errors
					setBlockError(b, '', true, Locale.get('errorUpdatingFormBlockFieldsIndexesMixed'));
					return;
				}
	
				// Validate for duplicated indexes if already indexed
				for (let i = 0; i < indexedFields.length - 1; i++) {
					for (let j = i + 1; j < indexedFields.length; j++) {
						if (indexedFields[i].indexes === indexedFields[j].indexes) {
							setBlockError(b, '', true, Locale.get('errorUpdatingFormBlockFieldsIndexesMixed'));
							return;
						}
					}
				}
	
				if (indexedFields.length > 0) {
					// Sort fields before update indexes
					indexedFields = FormSortUtils.sortByIndexes(indexedFields);
					b.fields = FormSortUtils.updateIndexes(indexedFields);
				} else if (nonIndexedFields.length > 0) {
					// Update form block fields indexes
					b.fields = FormSortUtils.updateIndexes(nonIndexedFields);
				}
			
				// Add block UUID to the list of blocks present on document
				blockUuids.push(b.uuid);
	
				// Send form block for creation on API
				try {
					await Service.fetch(toUpdate ? ServiceList.assetPortfolio.formBlock.update : ServiceList.assetPortfolio.formBlock.create, null, null, toUpdate ? {block: b, deleteMissingFields: obj.deleteMissingFormFields} : b, Session.session, true, false);
	
					const counter: number = 1 + b.fields.length;
					if (toUpdate) {
						stats.updated += counter;
					} else {
						stats.created += counter;
					}
	
					updateProgress();
				} catch (e) {
					// Store block data on sheet to export with errors
					setBlockError(b, e.response, true, e.response);
				}
			};

			// Keep a list of UUIDs of the created and updated blocks
			const blockUuids: UUID[] = [];
	
			// Correctly sort form block fields/update their indexes and send errors on blocks and fields to the exported file with errors
			for (const b of blocksCreate.values()) {
				await updateIndexesAndSendBlock(b, false);
			};
	
			for (const b of blocksUpdate.values()) {
				await updateIndexesAndSendBlock(b, true);
			};
			
			// fazer a parte do delete dos blocos aqui caso se tenha decidido remover os blocos
			if (obj.deleteMissingFormBlocks) {
				// Get the list of all the (non-private) form blocks UUIDs from API
				const req = await Service.fetch(ServiceList.assetPortfolio.formBlock.listName, null, null, {}, Session.session, true, false);
				const blockUuidsList: UUID[] = req.response.blocks.map((b: {name: string, uuid: UUID}) => { return b.uuid; });
				
				// Remove the UUIDs of the blocks present on document from the list of form blocks to delete
				const blockUuidsToDelete: UUID[] = blockUuidsList.filter((id: UUID) => { return !blockUuids.includes(id); });
							
				for (const id of blockUuidsToDelete) {
					try {
						await Service.fetch(ServiceList.assetPortfolio.formBlock.delete, null, null, {uuid: id}, Session.session, true, false);
					} catch {}
				}
			}

			const blocksErrorData: any[][] = [];
			for (const v of errorBlocksSheetData.values()) {
				blocksErrorData.push(v);
			}

			const fieldsErrorData: any[][] = [];
			for (const v of errorFieldsSheetData.values()) {
				fieldsErrorData.push(v);
			}
	
			const blocksSheetErrorData: XlsxSheetData = FormBlockDataTools.blocksSheetData();
			
			// Change form blocks sheet to include an extra column header for errors
			blocksSheetErrorData.data[0].push(Locale.get('errors'));
	
			// Add error rows to the form blocks sheet data
			blocksSheetErrorData.data = blocksSheetErrorData.data.concat(blocksErrorData);
			
			const fieldsSheetErrorData: XlsxSheetData = FormBlockDataTools.fieldsSheetData();
	
			// Change form block fields sheet to include an extra column for errors
			fieldsSheetErrorData.data[0].push(Locale.get('errors'));
			
			// Add error rows to the form block fields sheet data
			fieldsSheetErrorData.data = fieldsSheetErrorData.data.concat(fieldsErrorData);
	
			if (blocksSheetErrorData.data.length > 1 || fieldsSheetErrorData.data.length > 1) {
				// Write the work book sheets object with errors and export it as xlsx file
				XlsxUtils.writeMultiSheetFile([blocksSheetErrorData, fieldsSheetErrorData], 'form_blocks_import_errors.xlsx');
			}
		} catch (e) {
			Modal.alert(Locale.get('error'), e);
			return;
		}
		
		progress.destroy();

		try {
			Modal.alert(Locale.get('success'), Locale.get('importSuccessful'));
		} catch {};
	}
}
