<div>
	@if(this.isModal) {
		<div class="qr-reader-header">
			<div class="qr-reader-title">
				{{'qrCode' | translate }}
			</div>
			<div class="qr-reader-close" (click)="dismiss()">
				<uno-icon src="assets/icons/uno/close.svg" color="primary" width="32"></uno-icon>
			</div>
		</div>
	}

	<zxing-scanner previewFitMode="cover" (camerasFound)="this.camerasFound($event);" (camerasNotFound)="this.camerasNotFound();" [autofocusEnabled]="false" [enable]="this.enabled" [formats]="this.formats" [tryHarder]="true" #scanner style="max-width: 100%; max-height: 100%"></zxing-scanner>

	<ion-fab horizontal="end" slot="fixed" vertical="bottom">
		@if (this.cameras.length > 1) {
			<ion-fab-button (click)="this.nextCamera();" type="ios" color="light" style="margin-bottom: 10px">
				<ion-icon name="camera-reverse-outline"></ion-icon>
			</ion-fab-button>
		}
		@if (this.torchCompatible) {
			<ion-fab-button (click)="this.toggleTorch();" type="ios" color="light">
				<ion-icon name="flashlight-outline"></ion-icon>
			</ion-fab-button>
		}
	</ion-fab>
