import {UUIDIdentification} from '../../uuid';

/**
 * Format of a regulatory standard.
 */
export class DL50RegulatoryStandard extends UUIDIdentification {
	/**
	 * Label of the standard.
	 */
	public label: string = '';

	/**
	 * Description of the standard.
	 */
	public description: string = '';
	
	/**
	* Parse JSON data received from the API into an object.
	*
	* @param data - Object received from API.
	* @returns Object of the correct type, built with parsed data received.
	*/
	public static parse(data: any): DL50RegulatoryStandard {
		const standard = new DL50RegulatoryStandard();

		standard.uuid = data.uuid;
		standard.updatedAt = new Date(data.updatedAt);
		standard.createdAt = new Date(data.createdAt);

		standard.label = data.label;
		standard.description = data.description;
		
		return standard;
	}
}
