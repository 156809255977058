import {Environment} from '../../environments/environment';
import {ServiceMeta} from './service-meta';
import {ServiceType} from './type/service-type';
import {ServiceBodyType} from './type/service-body-type';
import {ServiceURLType} from './type/service-url-type';
import {ServiceResponseType} from './type/service-response-type';

/**
 * Contains all the services used by the application, all API declarations should be added to this file.
 *
 * Services are described using their service metaobjects.
 */
export const ServiceList = {
	version: new ServiceMeta(Environment.API_SERVER, '/version', ServiceType.GET, ServiceBodyType.NONE, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),

	privacyPolicy: {
		latest: new ServiceMeta(Environment.API_SERVER, '/v1/privacy-policy/latest', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
		update: new ServiceMeta(Environment.API_SERVER, '/v1/privacy-policy/update', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT)
	},

	assetPortfolio: {
		formBlock: {
			list: new ServiceMeta(Environment.API_SERVER, '/v1/asset-portfolio/form-block/list/items', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			count: new ServiceMeta(Environment.API_SERVER, '/v1/asset-portfolio/form-block/count', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			listName: new ServiceMeta(Environment.API_SERVER, '/v1/asset-portfolio/form-block/list/name', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			listDetailed: new ServiceMeta(Environment.API_SERVER, '/v1/asset-portfolio/form-block/list/detailed', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			get: new ServiceMeta(Environment.API_SERVER, '/v1/asset-portfolio/form-block/get', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			getBatch: new ServiceMeta(Environment.API_SERVER, '/v1/asset-portfolio/form-block/get/batch', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			create: new ServiceMeta(Environment.API_SERVER, '/v1/asset-portfolio/form-block/create', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			update: new ServiceMeta(Environment.API_SERVER, '/v1/asset-portfolio/form-block/update', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			delete: new ServiceMeta(Environment.API_SERVER, '/v1/asset-portfolio/form-block/delete', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			deleteBatch: new ServiceMeta(Environment.API_SERVER, '/v1/asset-portfolio/form-block/delete/batch', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT)
		},
		assetType: {
			count: new ServiceMeta(Environment.API_SERVER, '/v1/asset-portfolio/type/count', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			list: new ServiceMeta(Environment.API_SERVER, '/v1/asset-portfolio/type/list/items', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			listDetailed: new ServiceMeta(Environment.API_SERVER, '/v1/asset-portfolio/type/list/detailed', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			listName: new ServiceMeta(Environment.API_SERVER, '/v1/asset-portfolio/type/list/name', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			get: new ServiceMeta(Environment.API_SERVER, '/v1/asset-portfolio/type/get', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			getByName: new ServiceMeta(Environment.API_SERVER, '/v1/asset-portfolio/type/get/name', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			getBatch: new ServiceMeta(Environment.API_SERVER, '/v1/asset-portfolio/type/get/batch', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			create: new ServiceMeta(Environment.API_SERVER, '/v1/asset-portfolio/type/create', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			update: new ServiceMeta(Environment.API_SERVER, '/v1/asset-portfolio/type/update', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			delete: new ServiceMeta(Environment.API_SERVER, '/v1/asset-portfolio/type/delete', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			history: {
				listUsers: new ServiceMeta(Environment.API_SERVER, '/v1/asset-portfolio/type/history/list/users', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
				get: new ServiceMeta(Environment.API_SERVER, '/v1/asset-portfolio/type/history/get', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT)
			}
		},
		assetSubType: {
			count: new ServiceMeta(Environment.API_SERVER, '/v1/asset-portfolio/sub-type/count', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			list: new ServiceMeta(Environment.API_SERVER, '/v1/asset-portfolio/sub-type/list/items', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			listDetailed: new ServiceMeta(Environment.API_SERVER, '/v1/asset-portfolio/sub-type/list/detailed', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			listName: new ServiceMeta(Environment.API_SERVER, '/v1/asset-portfolio/sub-type/list/name', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			listParentsName: new ServiceMeta(Environment.API_SERVER, '/v1/asset-portfolio/sub-type/parents/list/name', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			get: new ServiceMeta(Environment.API_SERVER, '/v1/asset-portfolio/sub-type/get', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			getByName: new ServiceMeta(Environment.API_SERVER, '/v1/asset-portfolio/sub-type/get/name', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			getBatch: new ServiceMeta(Environment.API_SERVER, '/v1/asset-portfolio/sub-type/get/batch', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			create: new ServiceMeta(Environment.API_SERVER, '/v1/asset-portfolio/sub-type/create', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			update: new ServiceMeta(Environment.API_SERVER, '/v1/asset-portfolio/sub-type/update', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			delete: new ServiceMeta(Environment.API_SERVER, '/v1/asset-portfolio/sub-type/delete', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			history: {
				listUsers: new ServiceMeta(Environment.API_SERVER, '/v1/asset-portfolio/sub-type/history/list/users', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
				get: new ServiceMeta(Environment.API_SERVER, '/v1/asset-portfolio/sub-type/history/get', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT)
			}
		},
		formTab: {
			listAll: new ServiceMeta(Environment.API_SERVER, '/v1/asset-portfolio/form-tab/list', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			assetType: {
				list: new ServiceMeta(Environment.API_SERVER, '/v1/asset-portfolio/form-tab/asset-type/list', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
				set: new ServiceMeta(Environment.API_SERVER, '/v1/asset-portfolio/form-tab/asset-type/set', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT)
			},
			assetSubType: {
				list: new ServiceMeta(Environment.API_SERVER, '/v1/asset-portfolio/form-tab/asset-sub-type/list', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
				set: new ServiceMeta(Environment.API_SERVER, '/v1/asset-portfolio/form-tab/asset-sub-type/set', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT)
			}
		},
		formTabCard: {
			get: new ServiceMeta(Environment.API_SERVER, '/v1/asset-portfolio/form-tab-card/get', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			listAll: new ServiceMeta(Environment.API_SERVER, '/v1/asset-portfolio/form-tab-card/list', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			assetType: {list: new ServiceMeta(Environment.API_SERVER, '/v1/asset-portfolio/form-tab-card/asset-type/list', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT)},
			assetSubType: {list: new ServiceMeta(Environment.API_SERVER, '/v1/asset-portfolio/form-tab-card/asset-sub-type/list', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT)}
		},
		asset: {
			listByModel: new ServiceMeta(Environment.API_SERVER, '/v1/asset-portfolio/asset/list/model', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			listModels: new ServiceMeta(Environment.API_SERVER, '/v1/asset-portfolio/asset/list-models', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			get: new ServiceMeta(Environment.API_SERVER, '/v1/asset-portfolio/asset/get', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			getBatch: new ServiceMeta(Environment.API_SERVER, '/v1/asset-portfolio/asset/get/batch', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			getTag: new ServiceMeta(Environment.API_SERVER, '/v1/asset-portfolio/asset/get/tag', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			getByName: new ServiceMeta(Environment.API_SERVER, '/v1/asset-portfolio/asset/get/name', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			getQR: new ServiceMeta(Environment.API_SERVER, '/v1/asset-portfolio/asset/get/qr', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			getNFC: new ServiceMeta(Environment.API_SERVER, '/v1/asset-portfolio/asset/get/nfc', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			create: new ServiceMeta(Environment.API_SERVER, '/v1/asset-portfolio/asset/create', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			update: new ServiceMeta(Environment.API_SERVER, '/v1/asset-portfolio/asset/update', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			delete: new ServiceMeta(Environment.API_SERVER, '/v1/asset-portfolio/asset/delete', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			list: new ServiceMeta(Environment.API_SERVER, '/v1/asset-portfolio/asset/list/items', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			listByGAGaps: new ServiceMeta(Environment.API_SERVER, '/v1/asset-portfolio/asset/list/by-gaps', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			listDetailed: new ServiceMeta(Environment.API_SERVER, '/v1/asset-portfolio/asset/list/detailed', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			listGeolocation: new ServiceMeta(Environment.API_SERVER, '/v1/asset-portfolio/asset/list/geolocation', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			listNameAllowedParents: new ServiceMeta(Environment.API_SERVER, '/v1/asset-portfolio/asset/allowed-parents/list/name', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			listTree: new ServiceMeta(Environment.API_SERVER, '/v1/asset-portfolio/asset/list/tree', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			listTreeLevel: new ServiceMeta(Environment.API_SERVER, '/v1/asset-portfolio/asset/list/tree/level', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			listAncestors: new ServiceMeta(Environment.API_SERVER, '/v1/asset-portfolio/asset/list/ancestors', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			count: new ServiceMeta(Environment.API_SERVER, '/v1/asset-portfolio/asset/count', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			data: {
				list: new ServiceMeta(Environment.API_SERVER, '/v1/asset-portfolio/asset-field-data/list/by-asset', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
				listBatch: new ServiceMeta(Environment.API_SERVER, '/v1/asset-portfolio/asset-field-data/list/batch', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
				createBatch: new ServiceMeta(Environment.API_SERVER, '/v1/asset-portfolio/asset-field-data/create/batch', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
				updateBatch: new ServiceMeta(Environment.API_SERVER, '/v1/asset-portfolio/asset-field-data/update/batch', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT)
			}
		},
		settings: {
			get: new ServiceMeta(Environment.API_SERVER, '/v1/asset-portfolio/settings/get', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			update: new ServiceMeta(Environment.API_SERVER, '/v1/asset-portfolio/settings/update', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT)
		},
		fluidFamily: {
			get: new ServiceMeta(Environment.API_SERVER, '/v1/asset-portfolio/fluid-family/get', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			list: new ServiceMeta(Environment.API_SERVER, '/v1/asset-portfolio/fluid-family/list', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			delete: new ServiceMeta(Environment.API_SERVER, '/v1/asset-portfolio/fluid-family/delete', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			updateBulk: new ServiceMeta(Environment.API_SERVER, '/v1/asset-portfolio/fluid-family/update/bulk', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT)
		},
		fluidType: {
			get: new ServiceMeta(Environment.API_SERVER, '/v1/asset-portfolio/fluid-type/get', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			list: new ServiceMeta(Environment.API_SERVER, '/v1/asset-portfolio/fluid-type/list', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			delete: new ServiceMeta(Environment.API_SERVER, '/v1/asset-portfolio/fluid-type/delete', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			updateBulk: new ServiceMeta(Environment.API_SERVER, '/v1/asset-portfolio/fluid-type/update/bulk', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT)
		}
	},

	masterSettings: {
		appearance: {
			get: new ServiceMeta(Environment.API_SERVER, '/v1/master-settings/appearance/get', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			update: new ServiceMeta(Environment.API_SERVER, '/v1/master-settings/appearance/update', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT)
		},
		preferences: {
			get: new ServiceMeta(Environment.API_SERVER, '/v1/master-settings/preferences/get', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			update: new ServiceMeta(Environment.API_SERVER, '/v1/master-settings/preferences/update', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT)
		}
	},

	authentication: {
		login: new ServiceMeta(Environment.API_SERVER, '/v1/auth/login', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
		validate: new ServiceMeta(Environment.API_SERVER, '/v1/auth/validate', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
		logout: new ServiceMeta(Environment.API_SERVER, '/v1/auth/logout', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
		forgot: new ServiceMeta(Environment.API_SERVER, '/v1/auth/forgot', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
		reset: new ServiceMeta(Environment.API_SERVER, '/v1/auth/reset', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT)
	},

	logs: {
		errorList: new ServiceMeta(Environment.API_SERVER, '/v1/logs/error/list', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
		errorTasksList: new ServiceMeta(Environment.API_SERVER, '/v1/logs/error/tasks/list', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
		errorClientList: new ServiceMeta(Environment.API_SERVER, '/v1/logs/error/client/list', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
		errorClientReport: new ServiceMeta(Environment.API_SERVER, '/v1/logs/error/client/report', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
		accessList: new ServiceMeta(Environment.API_SERVER, '/v1/logs/access/list', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT)
	},

	company: {
		create: new ServiceMeta(Environment.API_SERVER, '/v1/company/create', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
		update: new ServiceMeta(Environment.API_SERVER, '/v1/company/update', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
		list: new ServiceMeta(Environment.API_SERVER, '/v1/company/list', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
		listName: new ServiceMeta(Environment.API_SERVER, '/v1/company/list/name', ServiceType.POST, ServiceBodyType.NONE, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
		listChild: new ServiceMeta(Environment.API_SERVER, '/v1/company/list/child/name', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
		listAllowedParents: new ServiceMeta(Environment.API_SERVER, '/v1/company/list/allowed-parents', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
		get: new ServiceMeta(Environment.API_SERVER, '/v1/company/get', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
		getBatch: new ServiceMeta(Environment.API_SERVER, '/v1/company/get/batch', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
		delete: new ServiceMeta(Environment.API_SERVER, '/v1/company/delete', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT)
	},

	users: {
		create: new ServiceMeta(Environment.API_SERVER, '/v1/user/create', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
		update: new ServiceMeta(Environment.API_SERVER, '/v1/user/update', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
		list: new ServiceMeta(Environment.API_SERVER, '/v1/user/list', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
		count: new ServiceMeta(Environment.API_SERVER, '/v1/user/count', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
		listName: new ServiceMeta(Environment.API_SERVER, '/v1/user/list/name', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
		get: new ServiceMeta(Environment.API_SERVER, '/v1/user/get', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
		getBatch: new ServiceMeta(Environment.API_SERVER, '/v1/user/get/batch', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
		changePassword: new ServiceMeta(Environment.API_SERVER, '/v1/user/password/change', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT)
	},

	roles: {
		create: new ServiceMeta(Environment.API_SERVER, '/v1/role/create', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
		get: new ServiceMeta(Environment.API_SERVER, '/v1/role/get', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
		update: new ServiceMeta(Environment.API_SERVER, '/v1/role/update', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
		delete: new ServiceMeta(Environment.API_SERVER, '/v1/role/delete', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
		list: new ServiceMeta(Environment.API_SERVER, '/v1/role/list', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
		count: new ServiceMeta(Environment.API_SERVER, '/v1/role/count', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
		listName: new ServiceMeta(Environment.API_SERVER, '/v1/role/list/name', ServiceType.POST, ServiceBodyType.NONE, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
		listRelations: new ServiceMeta(Environment.API_SERVER, '/v1/role/list/relations', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT)
	},

	teams: {
		create: new ServiceMeta(Environment.API_SERVER, '/v1/team/create', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
		update: new ServiceMeta(Environment.API_SERVER, '/v1/team/update', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
		list: new ServiceMeta(Environment.API_SERVER, '/v1/team/list', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
		count: new ServiceMeta(Environment.API_SERVER, '/v1/team/count', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
		countUsers: new ServiceMeta(Environment.API_SERVER, '/v1/team/count/users', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
		listName: new ServiceMeta(Environment.API_SERVER, '/v1/team/list/name', ServiceType.POST, ServiceBodyType.NONE, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
		listChild: new ServiceMeta(Environment.API_SERVER, '/v1/team/list/children', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
		listUsers: new ServiceMeta(Environment.API_SERVER, '/v1/team/list/users', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
		listAllowedParents: new ServiceMeta(Environment.API_SERVER, '/v1/team/list/allowed-parents', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
		delete: new ServiceMeta(Environment.API_SERVER, '/v1/team/delete', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
		get: new ServiceMeta(Environment.API_SERVER, '/v1/team/get', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
		getBatch: new ServiceMeta(Environment.API_SERVER, '/v1/team/get/batch', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT)
	},

	digitalTwin: {
		scene: {
			list: new ServiceMeta(Environment.API_SERVER, '/v1/digital-twin/scene/list', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			get: new ServiceMeta(Environment.API_SERVER, '/v1/digital-twin/scene/get', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			create: new ServiceMeta(Environment.API_SERVER, '/v1/digital-twin/scene/create', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			delete: new ServiceMeta(Environment.API_SERVER, '/v1/digital-twin/scene/delete', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			update: new ServiceMeta(Environment.API_SERVER, '/v1/digital-twin/scene/update', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT)
		},
		object: {
			tree: new ServiceMeta(Environment.API_SERVER, '/v1/digital-twin/object/tree', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			list: new ServiceMeta(Environment.API_SERVER, '/v1/digital-twin/object/list', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			listByAsset: new ServiceMeta(Environment.API_SERVER, '/v1/digital-twin/object/list/asset', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			create: new ServiceMeta(Environment.API_SERVER, '/v1/digital-twin/object/create', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			get: new ServiceMeta(Environment.API_SERVER, '/v1/digital-twin/object/get', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			delete: new ServiceMeta(Environment.API_SERVER, '/v1/digital-twin/object/delete', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			update: new ServiceMeta(Environment.API_SERVER, '/v1/digital-twin/object/update', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT)
		},
		pointCloud: {
			get: new ServiceMeta(Environment.DIGITAL_TWIN_SERVER, '/v1/pointcloud/get', ServiceType.GET, ServiceBodyType.NONE, ServiceURLType.NONE, ServiceResponseType.ARRAYBUFFER),
			delete: new ServiceMeta(Environment.DIGITAL_TWIN_SERVER, '/v1/pointcloud/delete', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			uploadZip: new ServiceMeta(Environment.DIGITAL_TWIN_SERVER, '/v1/pointcloud/upload/upcl', ServiceType.POST, ServiceBodyType.FILE_FORM, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			uploadLaz: new ServiceMeta(Environment.DIGITAL_TWIN_SERVER, '/v1/pointcloud/upload/laz', ServiceType.POST, ServiceBodyType.FILE_FORM, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT)
		}
	},

	epi: {
		create: new ServiceMeta(Environment.API_SERVER, '/v1/epi/create', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
		listDetailed: new ServiceMeta(Environment.API_SERVER, '/v1/epi/list/detailed', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
		list: new ServiceMeta(Environment.API_SERVER, '/v1/epi/list', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
		get: new ServiceMeta(Environment.API_SERVER, '/v1/epi/get', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
		delete: new ServiceMeta(Environment.API_SERVER, '/v1/epi/delete', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
		update: new ServiceMeta(Environment.API_SERVER, '/v1/epi/update', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
		count: new ServiceMeta(Environment.API_SERVER, '/v1/epi/count', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT)
	},

	pipelineIntegrity: {
		pipeline: {
			list: new ServiceMeta(Environment.PIPELINE_INTEGRITY_SERVER, '/v1/pipeline/list', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			get: new ServiceMeta(Environment.PIPELINE_INTEGRITY_SERVER, '/v1/pipeline/get', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			create: new ServiceMeta(Environment.PIPELINE_INTEGRITY_SERVER, '/v1/pipeline/create', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			update: new ServiceMeta(Environment.PIPELINE_INTEGRITY_SERVER, '/v1/pipeline/update', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			delete: new ServiceMeta(Environment.PIPELINE_INTEGRITY_SERVER, '/v1/pipeline/delete', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			count: new ServiceMeta(Environment.PIPELINE_INTEGRITY_SERVER, '/v1/pipeline/count', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			set: new ServiceMeta(Environment.PIPELINE_INTEGRITY_SERVER, '/v1/pipeline/set', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),

			plant: {
				list: new ServiceMeta(Environment.PIPELINE_INTEGRITY_SERVER, '/v1/plant/list', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
				get: new ServiceMeta(Environment.PIPELINE_INTEGRITY_SERVER, '/v1/plant/get', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
				create: new ServiceMeta(Environment.PIPELINE_INTEGRITY_SERVER, '/v1/plant/create', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
				update: new ServiceMeta(Environment.PIPELINE_INTEGRITY_SERVER, '/v1/plant/update', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
				delete: new ServiceMeta(Environment.PIPELINE_INTEGRITY_SERVER, '/v1/plant/delete', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
				count: new ServiceMeta(Environment.PIPELINE_INTEGRITY_SERVER, '/v1/plant/count', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT)
			},

			segment: {
				list: new ServiceMeta(Environment.PIPELINE_INTEGRITY_SERVER, '/v1/mot/segment/list', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
				get: new ServiceMeta(Environment.PIPELINE_INTEGRITY_SERVER, '/v1/mot/segment/get', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
				create: new ServiceMeta(Environment.PIPELINE_INTEGRITY_SERVER, '/v1/mot/segment/create', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
				createBatch: new ServiceMeta(Environment.PIPELINE_INTEGRITY_SERVER, '/v1/mot/segment/create/batch', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
				update: new ServiceMeta(Environment.PIPELINE_INTEGRITY_SERVER, '/v1/mot/segment/update', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
				deleteBatch: new ServiceMeta(Environment.PIPELINE_INTEGRITY_SERVER, '/v1/mot/segment/delete/batch', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
				count: new ServiceMeta(Environment.PIPELINE_INTEGRITY_SERVER, '/v1/mot/segment/count', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
				connectionList: new ServiceMeta(Environment.PIPELINE_INTEGRITY_SERVER, '/v1/segment/mot/connection/list', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT)
			},

			feature: {
				list: new ServiceMeta(Environment.PIPELINE_INTEGRITY_SERVER, '/v1/mot/feature/list', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
				get: new ServiceMeta(Environment.PIPELINE_INTEGRITY_SERVER, '/v1/mot/feature/get', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
				create: new ServiceMeta(Environment.PIPELINE_INTEGRITY_SERVER, '/v1/mot/feature/create', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
				update: new ServiceMeta(Environment.PIPELINE_INTEGRITY_SERVER, '/v1/mot/feature/update', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
				createBatch: new ServiceMeta(Environment.PIPELINE_INTEGRITY_SERVER, '/v1/mot/feature/create/batch', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
				delete: new ServiceMeta(Environment.PIPELINE_INTEGRITY_SERVER, '/v1/mot/feature/delete', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
				count: new ServiceMeta(Environment.PIPELINE_INTEGRITY_SERVER, '/v1/mot/feature/count', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT)
			}
		},
		mot: {
			cmp: {
				list: new ServiceMeta(Environment.PIPELINE_INTEGRITY_SERVER, '/v1/mot/cmp/list', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
				get: new ServiceMeta(Environment.PIPELINE_INTEGRITY_SERVER, '/v1/mot/cmp/get', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
				create: new ServiceMeta(Environment.PIPELINE_INTEGRITY_SERVER, '/v1/mot/cmp/create', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
				update: new ServiceMeta(Environment.PIPELINE_INTEGRITY_SERVER, '/v1/mot/cmp/update', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
				delete: new ServiceMeta(Environment.PIPELINE_INTEGRITY_SERVER, '/v1/mot/cmp/delete', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
				count: new ServiceMeta(Environment.PIPELINE_INTEGRITY_SERVER, '/v1/mot/cmp/count', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT)
			},
			acquisition: {
				list: new ServiceMeta(Environment.PIPELINE_INTEGRITY_SERVER, '/v1/mot/acquisition/list', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
				get: new ServiceMeta(Environment.PIPELINE_INTEGRITY_SERVER, '/v1/mot/acquisition/get', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
				create: new ServiceMeta(Environment.PIPELINE_INTEGRITY_SERVER, '/v1/mot/acquisition/create', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
				update: new ServiceMeta(Environment.PIPELINE_INTEGRITY_SERVER, '/v1/mot/acquisition/update', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
				delete: new ServiceMeta(Environment.PIPELINE_INTEGRITY_SERVER, '/v1/mot/acquisition/delete', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
				count: new ServiceMeta(Environment.PIPELINE_INTEGRITY_SERVER, '/v1/mot/acquisition/count', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT)
			},
			experiment: {
				list: new ServiceMeta(Environment.PIPELINE_INTEGRITY_SERVER, '/v1/mot/experiment/list', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
				get: new ServiceMeta(Environment.PIPELINE_INTEGRITY_SERVER, '/v1/mot/experiment/get', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
				create: new ServiceMeta(Environment.PIPELINE_INTEGRITY_SERVER, '/v1/mot/experiment/create', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
				update: new ServiceMeta(Environment.PIPELINE_INTEGRITY_SERVER, '/v1/mot/experiment/update', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
				delete: new ServiceMeta(Environment.PIPELINE_INTEGRITY_SERVER, '/v1/mot/experiment/delete', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
				count: new ServiceMeta(Environment.PIPELINE_INTEGRITY_SERVER, '/v1/mot/experiment/count', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT)
			},
			channel: {
				list: new ServiceMeta(Environment.PIPELINE_INTEGRITY_SERVER, '/v1/mot/channel/list', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
				get: new ServiceMeta(Environment.PIPELINE_INTEGRITY_SERVER, '/v1/mot/channel/get', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
				create: new ServiceMeta(Environment.PIPELINE_INTEGRITY_SERVER, '/v1/mot/channel/create', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
				update: new ServiceMeta(Environment.PIPELINE_INTEGRITY_SERVER, '/v1/mot/channel/update', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
				delete: new ServiceMeta(Environment.PIPELINE_INTEGRITY_SERVER, '/v1/mot/channel/delete', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
				count: new ServiceMeta(Environment.PIPELINE_INTEGRITY_SERVER, '/v1/mot/channel/count', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
				dataCount: new ServiceMeta(Environment.PIPELINE_INTEGRITY_SERVER, '/v1/mot/channel/data/count', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
				addDataChannels: new ServiceMeta(Environment.PIPELINE_INTEGRITY_SERVER, '/v1/mot/channel/data/add', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
				getDataChannels: new ServiceMeta(Environment.PIPELINE_INTEGRITY_SERVER, '/v1/mot/channel/data/get', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
				getDataChannelsByExperiment: new ServiceMeta(Environment.PIPELINE_INTEGRITY_SERVER, '/v1/mot/channel/data/get/experiment', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT)
			}
		},
		leakDetection: {
			lds: {
				list: new ServiceMeta(Environment.PIPELINE_INTEGRITY_SERVER, '/v1/leak-detection/lds/list', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
				count: new ServiceMeta(Environment.PIPELINE_INTEGRITY_SERVER, '/v1/leak-detection/lds/count', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
				create: new ServiceMeta(Environment.PIPELINE_INTEGRITY_SERVER, '/v1/leak-detection/lds/create', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
				update: new ServiceMeta(Environment.PIPELINE_INTEGRITY_SERVER, '/v1/leak-detection/lds/update', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
				get: new ServiceMeta(Environment.PIPELINE_INTEGRITY_SERVER, '/v1/leak-detection/lds/get', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
				delete: new ServiceMeta(Environment.PIPELINE_INTEGRITY_SERVER, '/v1/leak-detection/lds/delete', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
				data: {
					add: new ServiceMeta(Environment.PIPELINE_INTEGRITY_SERVER, '/v1/leak-detection/lds/data/add', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
					get: new ServiceMeta(Environment.PIPELINE_INTEGRITY_SERVER, '/v1/leak-detection/lds/data/get', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
					last: new ServiceMeta(Environment.PIPELINE_INTEGRITY_SERVER, '/v1/leak-detection/lds/data/last', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
					list: {timestamp: new ServiceMeta(Environment.PIPELINE_INTEGRITY_SERVER, '/v1/leak-detection/lds/data/list/timestamp', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT)}
				}
			},
			alarm: {
				create: new ServiceMeta(Environment.PIPELINE_INTEGRITY_SERVER, '/v1/leak-detection/alarm/create', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
				update: new ServiceMeta(Environment.PIPELINE_INTEGRITY_SERVER, '/v1/leak-detection/alarm/update', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
				get: new ServiceMeta(Environment.PIPELINE_INTEGRITY_SERVER, '/v1/leak-detection/alarm/get', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
				delete: new ServiceMeta(Environment.PIPELINE_INTEGRITY_SERVER, '/v1/leak-detection/alarm/delete', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
				list: new ServiceMeta(Environment.PIPELINE_INTEGRITY_SERVER, '/v1/leak-detection/alarm/list', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
				history: {list: new ServiceMeta(Environment.PIPELINE_INTEGRITY_SERVER, '/v1/leak-detection/alarm-history/list', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT)}
			}
		}

	},

	observation: {
		create: new ServiceMeta(Environment.API_SERVER, '/v1/observation/create', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
		update: new ServiceMeta(Environment.API_SERVER, '/v1/observation/update', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
		list: new ServiceMeta(Environment.API_SERVER, '/v1/observation/list', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
		get: new ServiceMeta(Environment.API_SERVER, '/v1/observation/get', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
		count: new ServiceMeta(Environment.API_SERVER, '/v1/observation/count', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
		delete: new ServiceMeta(Environment.API_SERVER, '/v1/observation/delete', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT)
	},

	inspection: {
		form: {
			create: new ServiceMeta(Environment.API_SERVER, '/v1/inspection/form/create', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			update: new ServiceMeta(Environment.API_SERVER, '/v1/inspection/form/update', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			delete: new ServiceMeta(Environment.API_SERVER, '/v1/inspection/form/delete', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			get: new ServiceMeta(Environment.API_SERVER, '/v1/inspection/form/get', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			list: new ServiceMeta(Environment.API_SERVER, '/v1/inspection/form/list', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			count: new ServiceMeta(Environment.API_SERVER, '/v1/inspection/form/count', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			listBatch: new ServiceMeta(Environment.API_SERVER, '/v1/inspection/form/list/batch', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			clone: new ServiceMeta(Environment.API_SERVER, '/v1/inspection/form/clone', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT)
		},
		field: {
			delete: new ServiceMeta(Environment.API_SERVER, '/v1/inspection/form/field/delete', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			get: new ServiceMeta(Environment.API_SERVER, '/v1/inspection/form/field/get', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			list: new ServiceMeta(Environment.API_SERVER, '/v1/inspection/form/field/list', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT)
		},
		workflow: {
			create: new ServiceMeta(Environment.API_SERVER, '/v1/inspection/workflow/create', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			update: new ServiceMeta(Environment.API_SERVER, '/v1/inspection/workflow/update', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			delete: new ServiceMeta(Environment.API_SERVER, '/v1/inspection/workflow/delete', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			get: new ServiceMeta(Environment.API_SERVER, '/v1/inspection/workflow/get', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			getFromProject: new ServiceMeta(Environment.API_SERVER, '/v1/inspection/workflow/get/project', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			list: new ServiceMeta(Environment.API_SERVER, '/v1/inspection/workflow/list', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			count: new ServiceMeta(Environment.API_SERVER, '/v1/inspection/workflow/count', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			listBatch: new ServiceMeta(Environment.API_SERVER, '/v1/inspection/workflow/list/batch', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			clone: new ServiceMeta(Environment.API_SERVER, '/v1/inspection/workflow/clone', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT)
		},
		workflowStep: {
			list: new ServiceMeta(Environment.API_SERVER, '/v1/inspection/workflow/step/list', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			get: new ServiceMeta(Environment.API_SERVER, '/v1/inspection/workflow/step/get', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			getBatch: new ServiceMeta(Environment.API_SERVER, '/v1/inspection/workflow/step/get/batch', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT)
		},
		workflowStepAdjacency: {list: new ServiceMeta(Environment.API_SERVER, '/v1/inspection/workflow/step/adjacency/list', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT)},
		project: {
			create: new ServiceMeta(Environment.API_SERVER, '/v1/inspection/project/create', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			update: new ServiceMeta(Environment.API_SERVER, '/v1/inspection/project/update', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			delete: new ServiceMeta(Environment.API_SERVER, '/v1/inspection/project/delete', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			get: new ServiceMeta(Environment.API_SERVER, '/v1/inspection/project/get', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			list: new ServiceMeta(Environment.API_SERVER, '/v1/inspection/project/list', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			count: new ServiceMeta(Environment.API_SERVER, '/v1/inspection/project/count', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			listByAsset: new ServiceMeta(Environment.API_SERVER, '/v1/inspection/project/list/asset', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			defaultStep: new ServiceMeta(Environment.API_SERVER, '/v1/inspection/project/get/default-step', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT)
		},
		create: new ServiceMeta(Environment.API_SERVER, '/v1/inspection/create', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
		update: new ServiceMeta(Environment.API_SERVER, '/v1/inspection/update', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
		delete: new ServiceMeta(Environment.API_SERVER, '/v1/inspection/delete', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
		get: new ServiceMeta(Environment.API_SERVER, '/v1/inspection/get', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
		getQR: new ServiceMeta(Environment.API_SERVER, '/v1/inspection/get/qr', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
		count: new ServiceMeta(Environment.API_SERVER, '/v1/inspection/count', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
		countProject: new ServiceMeta(Environment.API_SERVER, '/v1/inspection/count/project', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
		list: new ServiceMeta(Environment.API_SERVER, '/v1/inspection/list', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
		listData: new ServiceMeta(Environment.API_SERVER, '/v1/inspection/list/data', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
		listByAsset: new ServiceMeta(Environment.API_SERVER, '/v1/inspection/list/asset', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
		listByAssetAndProject: new ServiceMeta(Environment.API_SERVER, '/v1/inspection/list/asset-project', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
		listDetailed: new ServiceMeta(Environment.API_SERVER, '/v1/inspection/list/detailed', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
		inspectionListData: new ServiceMeta(Environment.API_SERVER, '/v1/inspection/list/data', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
		inspectionData: {get: new ServiceMeta(Environment.API_SERVER, '/v1/inspection/inspection-data/get', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT)}
	},

	atex: {
		inspection: {
			historyList: new ServiceMeta(Environment.API_SERVER, '/v1/atex/inspection/history/list', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			historyGet: new ServiceMeta(Environment.API_SERVER, '/v1/atex/inspection/history/get', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			historyListUsers: new ServiceMeta(Environment.API_SERVER, '/v1/atex/inspection/history/list/users', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			listDetailed: new ServiceMeta(Environment.API_SERVER, '/v1/atex/inspection/list/detailed', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			listDetailedWithAsset: new ServiceMeta(Environment.API_SERVER, '/v1/atex/inspection/list/detailed/asset', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			list: new ServiceMeta(Environment.API_SERVER, '/v1/atex/inspection/list', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			listByAsset: new ServiceMeta(Environment.API_SERVER, '/v1/atex/inspection/list/asset', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			listByAssetTag: new ServiceMeta(Environment.API_SERVER, '/v1/atex/inspection/list/asset/tag', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			count: new ServiceMeta(Environment.API_SERVER, '/v1/atex/inspection/count', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			get: new ServiceMeta(Environment.API_SERVER, '/v1/atex/inspection/get', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			delete: new ServiceMeta(Environment.API_SERVER, '/v1/atex/inspection/delete', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			update: new ServiceMeta(Environment.API_SERVER, '/v1/atex/inspection/update', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			create: new ServiceMeta(Environment.API_SERVER, '/v1/atex/inspection/create', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			createForAsset: new ServiceMeta(Environment.API_SERVER, '/v1/atex/inspection/create/asset', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			checklistsGet: new ServiceMeta(Environment.API_SERVER, '/v1/atex/inspection/checklists/get', ServiceType.POST, ServiceBodyType.NONE, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			checklistsUpdate: new ServiceMeta(Environment.API_SERVER, '/v1/atex/inspection/checklists/update', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			fieldsGet: new ServiceMeta(Environment.API_SERVER, '/v1/atex/inspection/fields/get', ServiceType.POST, ServiceBodyType.NONE, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			fieldsUpdate: new ServiceMeta(Environment.API_SERVER, '/v1/atex/inspection/fields/update', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			countStatusResult: new ServiceMeta(Environment.API_SERVER, '/v1/atex/inspection/count/status-result', ServiceType.POST, ServiceBodyType.NONE, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			countUpdated: new ServiceMeta(Environment.API_SERVER, '/v1/atex/inspection/count/updated', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			countStatus: new ServiceMeta(Environment.API_SERVER, '/v1/atex/inspection/count/status', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			settingsGet: new ServiceMeta(Environment.API_SERVER, '/v1/atex/inspection/settings/get', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			settingsUpdate: new ServiceMeta(Environment.API_SERVER, '/v1/atex/inspection/settings/update', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT)
		},
		ffp: {
			list: new ServiceMeta(Environment.API_SERVER, '/v1/atex/ffp/list', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			listDetailed: new ServiceMeta(Environment.API_SERVER, '/v1/atex/ffp/list/detailed', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			listData: new ServiceMeta(Environment.API_SERVER, '/v1/atex/ffp/list/data', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			listBatch: new ServiceMeta(Environment.API_SERVER, '/v1/atex/ffp/list/batch', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			historyList: new ServiceMeta(Environment.API_SERVER, '/v1/atex/ffp/history/list', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			historyListUsers: new ServiceMeta(Environment.API_SERVER, '/v1/atex/ffp/history/list/users', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			get: new ServiceMeta(Environment.API_SERVER, '/v1/atex/ffp/get', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			getBatch: new ServiceMeta(Environment.API_SERVER, '/v1/atex/ffp/get/batch', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			update: new ServiceMeta(Environment.API_SERVER, '/v1/atex/ffp/update', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			updateBatch: new ServiceMeta(Environment.API_SERVER, '/v1/atex/ffp/update/batch', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			delete: new ServiceMeta(Environment.API_SERVER, '/v1/atex/ffp/delete', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			deleteBatch: new ServiceMeta(Environment.API_SERVER, '/v1/atex/ffp/delete/batch', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			count: new ServiceMeta(Environment.API_SERVER, '/v1/atex/ffp/count', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			recommendationsGet: new ServiceMeta(Environment.API_SERVER, '/v1/atex/ffp/recommendations/get', ServiceType.POST, ServiceBodyType.NONE, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			recommendationsUpdate: new ServiceMeta(Environment.API_SERVER, '/v1/atex/ffp/recommendations/update', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT)
		},
		actionPlan: {
			create: new ServiceMeta(Environment.API_SERVER, '/v1/atex/action-plan/create', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			listDetailed: new ServiceMeta(Environment.API_SERVER, '/v1/atex/action-plan/list/detailed', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			list: new ServiceMeta(Environment.API_SERVER, '/v1/atex/action-plan/list', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			listAssets: new ServiceMeta(Environment.API_SERVER, '/v1/atex/action-plan/list/assets', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			listData: new ServiceMeta(Environment.API_SERVER, '/v1/atex/action-plan/list/data', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			listDetailedForFFP: new ServiceMeta(Environment.API_SERVER, '/v1/atex/action-plan/list/detailed/ffp', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			historyList: new ServiceMeta(Environment.API_SERVER, '/v1/atex/action-plan/history/list', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			historyListUsers: new ServiceMeta(Environment.API_SERVER, '/v1/atex/action-plan/history/list/users', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			get: new ServiceMeta(Environment.API_SERVER, '/v1/atex/action-plan/get', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			count: new ServiceMeta(Environment.API_SERVER, '/v1/atex/action-plan/count', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			update: new ServiceMeta(Environment.API_SERVER, '/v1/atex/action-plan/update', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			delete: new ServiceMeta(Environment.API_SERVER, '/v1/atex/action-plan/delete', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			actionsGet: new ServiceMeta(Environment.API_SERVER, '/v1/atex/action-plan/actions/get', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			actionsUpdate: new ServiceMeta(Environment.API_SERVER, '/v1/atex/action-plan/actions/update', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			countState: new ServiceMeta(Environment.API_SERVER, '/v1/atex/action-plan/count/state', ServiceType.POST, ServiceBodyType.NONE, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT)
		}
	},

	import: {assetsCsv: new ServiceMeta(Environment.API_SERVER, '/v1/import/assets/csv', ServiceType.POST, ServiceBodyType.FILE_FORM, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT)},

	resources: {
		image: {
			upload: new ServiceMeta(Environment.RESOURCE_SERVER, '/v1/image/upload', ServiceType.POST, ServiceBodyType.FILE_FORM, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			get: new ServiceMeta(Environment.RESOURCE_SERVER, '/v1/image/get/{uuid}.{format}', ServiceType.GET, ServiceBodyType.NONE, ServiceURLType.URL, ServiceResponseType.JSON_TEXT)
		},
		file: {
			upload: new ServiceMeta(Environment.RESOURCE_SERVER, '/v1/file/upload', ServiceType.POST, ServiceBodyType.FILE_FORM, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			get: new ServiceMeta(Environment.RESOURCE_SERVER, '/v1/file/get/{uuid}.{format}', ServiceType.GET, ServiceBodyType.NONE, ServiceURLType.URL, ServiceResponseType.JSON_TEXT)
		}
	},

	fileConverter: {docxToPdf: new ServiceMeta(Environment.FILE_CONVERTER_SERVER, '/v1/convert/doc/pdf', ServiceType.POST, ServiceBodyType.FILE_FORM, ServiceURLType.NONE, ServiceResponseType.ARRAYBUFFER)},

	repairs: {
		get: new ServiceMeta(Environment.API_SERVER, '/v1/repairs/get', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
		getQR: new ServiceMeta(Environment.API_SERVER, '/v1/repairs/get/qr', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
		list: new ServiceMeta(Environment.API_SERVER, '/v1/repairs/list', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
		count: new ServiceMeta(Environment.API_SERVER, '/v1/repairs/count', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
		listByAsset: new ServiceMeta(Environment.API_SERVER, '/v1/repairs/list/asset', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
		listDetailed: new ServiceMeta(Environment.API_SERVER, '/v1/repairs/list/detailed', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
		create: new ServiceMeta(Environment.API_SERVER, '/v1/repairs/create', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
		update: new ServiceMeta(Environment.API_SERVER, '/v1/repairs/update', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
		delete: new ServiceMeta(Environment.API_SERVER, '/v1/repairs/delete', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
		countStatus: new ServiceMeta(Environment.API_SERVER, '/v1/repairs/count/status', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
		countStatusAll: new ServiceMeta(Environment.API_SERVER, '/v1/repairs/count/status/all', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
		countCriticality: new ServiceMeta(Environment.API_SERVER, '/v1/repairs/count/criticality', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
		historyListUsers: new ServiceMeta(Environment.API_SERVER, '/v1/repairs/history/list/users', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
		historyGet: new ServiceMeta(Environment.API_SERVER, '/v1/repairs/history/get', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
		importXlsx: new ServiceMeta(Environment.API_SERVER, '/v1/repairs/import/xlsx', ServiceType.POST, ServiceBodyType.FILE_FORM, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
		ndt: {
			list: new ServiceMeta(Environment.API_SERVER, '/v1/repairs/ndt/list', ServiceType.POST, ServiceBodyType.NONE, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			delete: new ServiceMeta(Environment.API_SERVER, '/v1/repairs/ndt/delete', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			updateBulk: new ServiceMeta(Environment.API_SERVER, '/v1/repairs/ndt/update/bulk', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT)
		},
		weldingProcedure: {
			list: new ServiceMeta(Environment.API_SERVER, '/v1/repairs/welding-procedure/list', ServiceType.POST, ServiceBodyType.NONE, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			delete: new ServiceMeta(Environment.API_SERVER, '/v1/repairs/welding-procedure/delete', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			updateBulk: new ServiceMeta(Environment.API_SERVER, '/v1/repairs/welding-procedure/update/bulk', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT)
		},
		weldingType: {
			list: new ServiceMeta(Environment.API_SERVER, '/v1/repairs/welding-type/list', ServiceType.POST, ServiceBodyType.NONE, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			delete: new ServiceMeta(Environment.API_SERVER, '/v1/repairs/welding-type/delete', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			updateBulk: new ServiceMeta(Environment.API_SERVER, '/v1/repairs/welding-type/update/bulk', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT)
		},
		temporaryType: {
			list: new ServiceMeta(Environment.API_SERVER, '/v1/repairs/temporary-type/list', ServiceType.POST, ServiceBodyType.NONE, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			delete: new ServiceMeta(Environment.API_SERVER, '/v1/repairs/temporary-type/delete', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			updateBulk: new ServiceMeta(Environment.API_SERVER, '/v1/repairs/temporary-type/update/bulk', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT)
		},
		consumable: {
			list: new ServiceMeta(Environment.API_SERVER, '/v1/repairs/consumable/list', ServiceType.POST, ServiceBodyType.NONE, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			delete: new ServiceMeta(Environment.API_SERVER, '/v1/repairs/consumable/delete', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			updateBulk: new ServiceMeta(Environment.API_SERVER, '/v1/repairs/consumable/update/bulk', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT)
		},
		weldingStandard: {
			list: new ServiceMeta(Environment.API_SERVER, '/v1/repairs/welding-standard/list', ServiceType.POST, ServiceBodyType.NONE, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			delete: new ServiceMeta(Environment.API_SERVER, '/v1/repairs/welding-standard/delete', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			updateBulk: new ServiceMeta(Environment.API_SERVER, '/v1/repairs/welding-standard/update/bulk', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT)
		},
		settingsGet: new ServiceMeta(Environment.API_SERVER, '/v1/repairs/settings/get', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
		settingsUpdate: new ServiceMeta(Environment.API_SERVER, '/v1/repairs/settings/update', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT)
	},

	repairInspections: {
		get: new ServiceMeta(Environment.API_SERVER, '/v1/repair-inspection/get', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
		list: new ServiceMeta(Environment.API_SERVER, '/v1/repair-inspection/list', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
		listDetailed: new ServiceMeta(Environment.API_SERVER, '/v1/repair-inspection/list/detailed', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
		listForRepair: new ServiceMeta(Environment.API_SERVER, '/v1/repair-inspection/list/repair', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
		create: new ServiceMeta(Environment.API_SERVER, '/v1/repair-inspection/create', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
		update: new ServiceMeta(Environment.API_SERVER, '/v1/repair-inspection/update', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
		count: new ServiceMeta(Environment.API_SERVER, '/v1/repair-inspection/count', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
		countStatus: new ServiceMeta(Environment.API_SERVER, '/v1/repair-inspection/status/count', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
		delete: new ServiceMeta(Environment.API_SERVER, '/v1/repair-inspection/delete', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT)
	},

	assetPlanning: {
		calendarEvent: {
			create: new ServiceMeta(Environment.API_SERVER, '/v1/calendar-event/create', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			update: new ServiceMeta(Environment.API_SERVER, '/v1/calendar-event/update', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			delete: new ServiceMeta(Environment.API_SERVER, '/v1/calendar-event/delete', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			get: new ServiceMeta(Environment.API_SERVER, '/v1/calendar-event/get', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			count: new ServiceMeta(Environment.API_SERVER, '/v1/calendar-event/count', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			list: new ServiceMeta(Environment.API_SERVER, '/v1/calendar-event/list', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			listByAsset: new ServiceMeta(Environment.API_SERVER, '/v1/calendar-event/list/asset', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			countByAsset: new ServiceMeta(Environment.API_SERVER, '/v1/calendar-event/list/asset/count', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			listByRepair: new ServiceMeta(Environment.API_SERVER, '/v1/calendar-event/list/repair', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			updateOccurrence: new ServiceMeta(Environment.API_SERVER, '/v1/calendar-event/occurrence/update', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			listOccurrences: new ServiceMeta(Environment.API_SERVER, '/v1/calendar-event/occurrence/list/event', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			deleteOccurrence: new ServiceMeta(Environment.API_SERVER, '/v1/calendar-event/occurrence/delete', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			countOccurrences: new ServiceMeta(Environment.API_SERVER, '/v1/calendar-event/occurrence/count', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT)
		}
	},

	mapbox: {places: new ServiceMeta('https://api.mapbox.com', '/geocoding/v5/mapbox.places', ServiceType.GET, ServiceBodyType.NONE, ServiceURLType.QUERY, ServiceResponseType.JSON_TEXT)},

	gapAnalysis: {
		gap: {
			get: new ServiceMeta(Environment.API_SERVER, '/v1/gap-analysis/gap/get', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			getBatch: new ServiceMeta(Environment.API_SERVER, '/v1/gap-analysis/gap/get/batch', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			update: new ServiceMeta(Environment.API_SERVER, '/v1/gap-analysis/gap/update', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			updateBatch: new ServiceMeta(Environment.API_SERVER, '/v1/gap-analysis/gap/update/batch', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			list: new ServiceMeta(Environment.API_SERVER, '/v1/gap-analysis/gap/list', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			listByAsset: new ServiceMeta(Environment.API_SERVER, '/v1/gap-analysis/gap/list/asset', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			delete: new ServiceMeta(Environment.API_SERVER, '/v1/gap-analysis/gap/delete', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			deleteBatch: new ServiceMeta(Environment.API_SERVER, '/v1/gap-analysis/gap/delete/batch', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			countByStatus: new ServiceMeta(Environment.API_SERVER, '/v1/gap-analysis/gap/count-by-status', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			countByAsset: new ServiceMeta(Environment.API_SERVER, '/v1/gap-analysis/gap/count-by-asset', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			count: new ServiceMeta(Environment.API_SERVER, '/v1/gap-analysis/gap/count', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			history: {list: new ServiceMeta(Environment.API_SERVER, '/v1/gap-analysis/gap/history/list', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT)},
			recommendations: {
				list: new ServiceMeta(Environment.API_SERVER, '/v1/gap-analysis/gap/recommendations/list', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
				updateBatch: new ServiceMeta(Environment.API_SERVER, '/v1/gap-analysis/gap/recommendations/update/batch', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT)
			}
		},
		actionPlan: {
			get: new ServiceMeta(Environment.API_SERVER, '/v1/gap-analysis/action-plan/get', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			create: new ServiceMeta(Environment.API_SERVER, '/v1/gap-analysis/action-plan/create', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			update: new ServiceMeta(Environment.API_SERVER, '/v1/gap-analysis/action-plan/update', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			listAssets: new ServiceMeta(Environment.API_SERVER, '/v1/gap-analysis/action-plan/list-assets', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			list: new ServiceMeta(Environment.API_SERVER, '/v1/gap-analysis/action-plan/list', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			delete: new ServiceMeta(Environment.API_SERVER, '/v1/gap-analysis/action-plan/delete', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			countByStatus: new ServiceMeta(Environment.API_SERVER, '/v1/gap-analysis/action-plan/count-by-status', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			count: new ServiceMeta(Environment.API_SERVER, '/v1/gap-analysis/action-plan/count', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			history: {list: new ServiceMeta(Environment.API_SERVER, '/v1/gap-analysis/action-plan/history/list', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT)}
		}
	},

	dl50: {
		masterdata: {
			questions: {
				create: new ServiceMeta(Environment.API_SERVER, '/v1/dl50/question/create', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
				get: new ServiceMeta(Environment.API_SERVER, '/v1/dl50/question/get', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
				getByResponse: new ServiceMeta(Environment.API_SERVER, '/v1/dl50/question/get/by-response', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
				getBatch: new ServiceMeta(Environment.API_SERVER, '/v1/dl50/question/get/batch', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
				delete: new ServiceMeta(Environment.API_SERVER, '/v1/dl50/question/delete', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
				update: new ServiceMeta(Environment.API_SERVER, '/v1/dl50/question/update', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
				updateBatch: new ServiceMeta(Environment.API_SERVER, '/v1/dl50/question/update/batch', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
				list: new ServiceMeta(Environment.API_SERVER, '/v1/dl50/question/list', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
				count: new ServiceMeta(Environment.API_SERVER, '/v1/dl50/question/count', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT)
			},
			regulatoryStandards: {
				getBatch: new ServiceMeta(Environment.API_SERVER, '/v1/dl50/regulatory-standard/get/batch', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
				create: new ServiceMeta(Environment.API_SERVER, '/v1/dl50/regulatory-standard/create', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
				delete: new ServiceMeta(Environment.API_SERVER, '/v1/dl50/regulatory-standard/delete', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
				update: new ServiceMeta(Environment.API_SERVER, '/v1/dl50/regulatory-standard/update', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
				list: new ServiceMeta(Environment.API_SERVER, '/v1/dl50/regulatory-standard/list', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
				count: new ServiceMeta(Environment.API_SERVER, '/v1/dl50/regulatory-standard/count', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT)
			},
			masterSettings: {
				get: new ServiceMeta(Environment.API_SERVER, '/v1/dl50/master-settings/get', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
				update: new ServiceMeta(Environment.API_SERVER, '/v1/dl50/master-settings/update', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT)
			}
		},
		inspections: {
			create: new ServiceMeta(Environment.API_SERVER, '/v1/dl50/inspection/create', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			update: new ServiceMeta(Environment.API_SERVER, '/v1/dl50/inspection/update', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			get: new ServiceMeta(Environment.API_SERVER, '/v1/dl50/inspection/get', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			getByResponse: new ServiceMeta(Environment.API_SERVER, '/v1/dl50/inspection/get/response', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			listDetailed: new ServiceMeta(Environment.API_SERVER, '/v1/dl50/inspection/list/detailed', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			list: new ServiceMeta(Environment.API_SERVER, '/v1/dl50/inspection/list', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			listByGaps: new ServiceMeta(Environment.API_SERVER, '/v1/dl50/inspection/list/by-gaps', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			count: new ServiceMeta(Environment.API_SERVER, '/v1/dl50/inspection/count', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			countByStatus: new ServiceMeta(Environment.API_SERVER, '/v1/dl50/inspection/count/status', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			countByConclusion: new ServiceMeta(Environment.API_SERVER, '/v1/dl50/inspection/count/conclusion', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			delete: new ServiceMeta(Environment.API_SERVER, '/v1/dl50/inspection/delete', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			history: {
				get: new ServiceMeta(Environment.API_SERVER, '/v1/dl50/history/get', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
				list: new ServiceMeta(Environment.API_SERVER, '/v1/dl50/history/list', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT)
			}
		}
	},
	dashboards: {
		dashboard: {
			create: new ServiceMeta(Environment.API_SERVER, '/v1/dashboard/create', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			update: new ServiceMeta(Environment.API_SERVER, '/v1/dashboard/update', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			delete: new ServiceMeta(Environment.API_SERVER, '/v1/dashboard/delete', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			get: new ServiceMeta(Environment.API_SERVER, '/v1/dashboard/get', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			list: new ServiceMeta(Environment.API_SERVER, '/v1/dashboard/list', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT),
			count: new ServiceMeta(Environment.API_SERVER, '/v1/dashboard/count', ServiceType.POST, ServiceBodyType.JSON, ServiceURLType.NONE, ServiceResponseType.JSON_TEXT)
		}
	}
};
