<ion-card>
	@if (this.meta) {
		<ion-card-header>
			@if (this.meta.name) {
				<ion-card-title>{{this.meta.name}}</ion-card-title>
			}
			@if (this.meta.description) {
				<ion-card-subtitle>{{this.meta.description}}</ion-card-subtitle>
			}
		</ion-card-header>
	}

	<doughnut-chart [data]="this.data" [ngStyle]="{height: '300px'}" class="ion-padding"></doughnut-chart>
</ion-card>
