<uno-content [borders]="true">
	@if (this.cmp) {
		<!-- Base Information -->
		<uno-form [layout]="this.cmpLayout" [object]="this.cmp" #form></uno-form>
		<!-- Save/Update -->
		<div style="display: flex; flex-direction: row-reverse; gap: 5px;" class="ion-padding">
			@if (this.createMode) {
				@if ([userPermissions.PIPELINE_INTEGRITY_MOT_CMP_CREATE] | hasPermissions) {
					<uno-button (click)="this.update();">{{'create' | translate}}</uno-button>
				}
			}
			@else {
				@if ([userPermissions.PIPELINE_INTEGRITY_MOT_CMP_EDIT] | hasPermissions) {
					<uno-button (click)="this.update();">{{'save' | translate}}</uno-button>
				}
				@if ([userPermissions.PIPELINE_INTEGRITY_MOT_CMP_EDIT] | hasPermissions) {
					<uno-button (click)="this.update(true);">{{'update' | translate}}</uno-button>
				}
				<uno-button (click)="this.importFea();">{{'import' | translate}} .fea</uno-button>
				@if ([userPermissions.PIPELINE_INTEGRITY_MOT_CMP_DELETE] | hasPermissions) {
					<uno-button (click)="this.delete();" color="error">{{'delete' | translate}}</uno-button>
				}
			}
		</div>
	}
</uno-content>
