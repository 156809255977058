export enum CalendarEventStatus {
	// No action associated with the occurrence
	NO_ACTION = 1,
	// An action is associated but is still not working
	TODO = 2,
	// In progress indicates that the action associated is being worked on
	IN_PROGRESS = 3,
	// Done status indicates that the action associated was completed
	DONE = 4
}

export const CalendarEventStatusLabel = new Map<CalendarEventStatus, string> ([
	[CalendarEventStatus.NO_ACTION, 'noAction'],
	[CalendarEventStatus.TODO, 'todo'],
	[CalendarEventStatus.IN_PROGRESS, 'inProgress'],
	[CalendarEventStatus.DONE, 'done']
]);

export const CalendarEventStatusBackground = new Map<CalendarEventStatus, string> ([
	[CalendarEventStatus.TODO, 'var(--light-information-off)'],
	[CalendarEventStatus.IN_PROGRESS, 'var(--light-warning-off)'],
	[CalendarEventStatus.DONE, 'var(--light-success-off)']
]);
