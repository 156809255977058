import {Component, OnInit} from '@angular/core';
import {NgStyle} from '@angular/common';
import {App} from '../../../../app';
import {ServiceList} from '../../../../http/service-list';
import {Session} from '../../../../session';
import {ScreenComponent} from '../../../../components/screen/screen.component';
import {UserPermissions} from '../../../../models/users/user-permissions';
import {ServiceSync} from '../../../../http/service-sync';
import {UnoListLazyLoadHandler} from '../../../../components/uno/uno-list/uno-list-lazy-load-handler';
import {FormatDatePipe} from '../../../../pipes/format-date.pipe';
import {UnoListComponent} from '../../../../components/uno/uno-list/uno-list-component';
import {UnoContentComponent} from '../../../../components/uno/uno-content/uno-content.component';
import {UnoListItemLabelComponent} from '../../../../components/uno/uno-list-item/uno-list-item-label.component';
import {UnoListItemComponent} from '../../../../components/uno/uno-list-item/uno-list-item.component';
import {UnoSearchbarComponent} from '../../../../components/uno/uno-searchbar/uno-searchbar.component';

@Component({
	selector: 'logs-error-client-page',
	templateUrl: 'logs-error-client.page.html',
	standalone: true,
	imports: [UnoSearchbarComponent, UnoListItemComponent, UnoListItemLabelComponent, UnoContentComponent, NgStyle, UnoListComponent, FormatDatePipe]
})
export class LogsErrorClientPage extends ScreenComponent implements OnInit {
	public selfStatic: any = LogsErrorClientPage;

	public permissions = [UserPermissions.LOGS_ERROR_CLIENT];

	/**
	 * Lazy list loading handler.
	 */
	public handler: UnoListLazyLoadHandler<any> = new UnoListLazyLoadHandler<any>();

	/**
	 * Object to synchronize service requests.
	 */
	public serviceSync: ServiceSync = new ServiceSync();

	public ngOnInit(): void {
		super.ngOnInit();
		
		App.navigator.setTitle('errorLogs');

		this.handler.loadMore = async(count: number, pageSize: number) => {
			const data = {
				from: count,
				count: pageSize,
				search: LogsErrorClientPage.filters.search
			};

			const request = await this.serviceSync.fetch(ServiceList.logs.errorClientList, null, null, data, Session.session);
			const response = request.response;
			return {
				elements: response.logs,
				hasMore: response.hasMore
			};
		};
		this.handler.reset();
	}

	public static filters = {
		/**
		 * Text used to filter list entries by their content.
		 */
		search: ''
	};

	public static defaultFilters = structuredClone(LogsErrorClientPage.filters);

	public resetFilters(): void {
		this.serviceSync.reset();
		Object.assign(LogsErrorClientPage.filters, LogsErrorClientPage.defaultFilters);
	}

	/**
	 * Update the search term used.
	 *
	 * @param event - DOM event.
	 */
	public onFilterChange(event: any): void {
		if (event.target === undefined) {
			LogsErrorClientPage.filters.search = event;
		}

		this.serviceSync.reset();
		this.handler.reset();
	}
}
