/**
 * Describes the periodicity modes available for calendar events.
 */

export enum CalendarEventPeriodicityModes {
	REPETITIONS = 1,
	END_DATE = 2,
	
};

export const CalendarEventPeriodicityModesLabel = new Map<number, string> ([
	[CalendarEventPeriodicityModes.REPETITIONS, 'repetitions'],
	[CalendarEventPeriodicityModes.END_DATE, 'endDate']
]);
