import {Resource} from '../resource';
import {UUID, UUIDIdentification} from '../uuid';
import {DL50InspectionQuestionResponseGap} from './dl50-inspection-question-response-gap';
import {DL50InspectionQuestionResult} from './dl50-inspection-question-result';


/**
 * DL50 inspection question response data format to send to the API.
 */
export class DL50InspectionQuestionResponse extends UUIDIdentification {
	/**
	 * Inspection that this response belongs to.
	 */
	public inspectionUuid: UUID = null;
		
	/**
	 * Question that this response belongs to.
	 */
	public questionUuid: UUID = null;
	
	/**
	 * If true, the question on this inspection is not applicable and no other data may exist on response.
	 */
	public notApplicable: boolean = false;

	/**
	 * The evaluation done to this question response.
	 */
	public evaluation: DL50InspectionQuestionResult = DL50InspectionQuestionResult.NONE;
	
	/**
	 * The photos related to the question response.
	 */
	public photos: Resource[] = [];
	
	/**
	 * The comments related to the question response.
	 */
	public comments: string = '';

	/**
	 * The assessment gaps selected to the question response as well as the photos and comments that justify the gaps.
	 */
	public gaps: DL50InspectionQuestionResponseGap[] = [];

	/**
	 * Parse data into the correct object format.
	 *
	 * @param data - Data to be parsed.
	 * @returns Object of the correct type.
	 */
	public static parse(data: any): DL50InspectionQuestionResponse {
		const response = new DL50InspectionQuestionResponse();

		response.uuid = data.uuid;
		response.createdAt = new Date(data.createdAt);
		response.updatedAt = new Date(data.updatedAt);

		response.inspectionUuid = data.inspectionUuid;
		response.questionUuid = data.questionUuid;

		response.notApplicable = data.notApplicable === true;
		response.evaluation = data.evaluation;
		response.photos = data.photos ? data.photos : [];
		response.comments = data.comments;

		response.gaps = data.gaps ? data.gaps.map((d: any) => {
			return DL50InspectionQuestionResponseGap.parse(d);
		}) : [];

		return response;
	}
}
