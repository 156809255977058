/**
 * Fetch response is the object returned in the async calls of the Service.fetch() method to read the data sent from the API.
 */
export class ServiceResponse {
	/**
	 * The response provided by the API server.
	 */
	public response: any = null;

	/**
	 * Status code returned by the server.
	 */
	public status: number = 0;

	/**
	 * Sequential identifier of the request, these are attributed by order of arrival of the request.
	 *
	 * Can be used to sort the request responses.
	 */
	public id: number = 0;

	public constructor(response: any, status: number, id: number) {
		this.response = response;
		this.status = status;
		this.id = id;
	}
}
