import {Component, OnInit} from '@angular/core';
import {Color} from 'three';
import {TranslateModule} from '@ngx-translate/core';
import {NgStyle} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {Session} from '../../../../../session';
import {Locale} from '../../../../../locale/locale';
import {Service} from '../../../../../http/service';
import {ServiceList} from '../../../../../http/service-list';
import {DoughnutChartData, DoughnutChartComponent} from '../../../../../components/charts/doughnut-chart/doughnut-chart.component';

@Component({
	selector: 'graph-repair-criticality',
	templateUrl: './repair-criticality-graph.component.html',
	standalone: true,
	imports: [IonicModule, DoughnutChartComponent, NgStyle, TranslateModule]
})
export class RepairsCriticalityGraphComponent implements OnInit {
	public data: DoughnutChartData[] = [];

	public ngOnInit(): void {
		Service.call(ServiceList.repairs.countCriticality, null, null, null, Session.session, (response, xhr) => {
			this.data = [
				new DoughnutChartData(Locale.get('veryLow'), response.veryLow, new Color(0x5AE76A)),
				new DoughnutChartData(Locale.get('low'), response.low, new Color(0x81E454)),
				new DoughnutChartData(Locale.get('normal'), response.normal, new Color(0xBBE14E)),
				new DoughnutChartData(Locale.get('high'), response.high, new Color(0xDEC549)),
				new DoughnutChartData(Locale.get('veryHigh'), response.veryHigh, new Color(0xDB8144)),
				new DoughnutChartData(Locale.get('emergency'), response.emergency, new Color(0xD93E42))
			];
		}, undefined, true);
	}
}

