import {Component, forwardRef, Input, ViewEncapsulation} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {AssetTreeLevelItem} from 'src/app/modules/asset-portfolio/screens/asset/tree/asset-tree-list.page';
import {AssetService, AssetTreeLevel} from 'src/app/modules/asset-portfolio/services/asset.service';
import {ObjectUtils} from 'src/app/utils/object-utils';
import {UnoFilterBarComponent} from 'src/app/components/uno/uno-filter-bar/uno-filter-bar.component';
import {PopoverController, IonicModule} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';
import {NgClass} from '@angular/common';
import {OverlayModule} from '@angular/cdk/overlay';
import {SortDirection} from 'src/app/utils/sort-direction';
import {UnoFilterBarOption, UnoFilterBarOptionType} from 'src/app/components/uno/uno-filter-bar/uno-filter-bar-option';
import {UnoSearchbarComponent} from '../../../uno/uno-searchbar/uno-searchbar.component';
import {UnoIconComponent} from '../../../uno/uno-icon/uno-icon.component';
import {UnoButtonComponent} from '../../../uno/uno-button/uno-button.component';
import {RangePipe} from '../../../../pipes/range.pipe';

import {AssetTreeListItemComponent} from '../../../../modules/asset-portfolio/screens/asset/tree/asset-tree-list-item/asset-tree-list-item.component';


@Component({
	selector: 'uno-asset-selector',
	templateUrl: './uno-asset-selector-tree-navigation.component.html',
	styleUrls: ['./uno-asset-selector-tree-navigation.component.css'],
	encapsulation: ViewEncapsulation.None,
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => { return UnoAssetSelectorTreeNavigationComponent; }),
		multi: true
	}],
	standalone: true,
	imports: [AssetTreeListItemComponent, TranslateModule, IonicModule, OverlayModule, ScrollingModule, UnoSearchbarComponent, UnoFilterBarComponent, UnoIconComponent, UnoButtonComponent, NgClass, RangePipe]
})
export class UnoAssetSelectorTreeNavigationComponent {
	public selfStatic: any = UnoAssetSelectorTreeNavigationComponent;

	/**
	 * Callback for the external method to run when adding a asset.
	 */
	@Input()
	public onAdd: (asset: AssetTreeLevelItem)=> void = null;

	/**
	 * List of assets to render in the tree.
	 * 
	 * Includes assets from all levels in a flat list.
	 */
	public items: AssetTreeLevelItem[] = [];

	/**
	 * Flat list of assets to render in the tree.
	 */
	public flatItems: AssetTreeLevelItem[] = [];

	/**
	 * Last filters used to load data from the API.
	 */
	public lastFilters: any = null;

	/**
	 * Selected Assets
	 */
	public selectedAsset: AssetTreeLevelItem = null;

	/*
	* Controls if sort input is open
	*/
	public isSortOpen: boolean = false;

	/**
	 * Controls if filters input is open
	 */
	public isFiltersOpen: boolean = false;

	/**
	 * Controls if button is active
	 */
	public canAdd: boolean = false;

	/**
	 * Currently selected filters
	 */
	public selectedFilters: string[] = [];

	/**
	 * Possible database filter to be used for ordering the Asset Tree list.
	 */
	public static filterOptions: UnoFilterBarOption[] = [
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'sortDirection',
			label: 'direction',
			default: SortDirection.ASC,
			options: [
				{label: 'asc', value: SortDirection.ASC},
				{label: 'desc', value: SortDirection.DESC}
			]
		},
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'sortField',
			label: 'sortField',
			default: 'a.name',
			options: [
				{label: 'name', value: 'a.name'},
				{label: 'tag', value: 'a.tag'},
				{label: 'createdAt', value: 'a.created_at'},
				{label: 'updatedAt', value: 'a.updated_at'}
			]
		},
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'searchFields',
			label: 'searchFields',
			default: ['ap_asset.name', 'ap_asset.tag'],
			multiple: true,
			options: [
				{label: 'uuid', value: 'ap_asset.id'},
				{label: 'name', value: 'ap_asset.name'},
				{label: 'tag', value: 'ap_asset.tag'},
				{label: 'description', value: 'ap_asset.description'},
				{label: 'nfc', value: 'ap_asset.nfc'},
				{label: 'qr', value: 'ap_asset.qr'}
			]
		}
	];

	public static filters = UnoFilterBarComponent.reset({
		/**
		 * Text used to filter list entries by their content.
		 */
		search: '',

		/**
		 * Search fields to be considered to filter list entries by their content.
		 */
		searchFields: [],

		/**
		 * Database attribute name used to sort the values.
		 */
		sortField: '',

		/**
		 * Sort direction applied to the loaded list from database.
		 */
		sortDirection: ''
	}, UnoAssetSelectorTreeNavigationComponent.filterOptions);

	public static defaultFilters = structuredClone(UnoAssetSelectorTreeNavigationComponent.filters);

	public constructor(public controller: PopoverController) {}

	public async ngOnInit(): Promise<void> {
		this.selectedFilters = UnoAssetSelectorTreeNavigationComponent.filterOptions[2].default;
		await this.loadData();
	}
 
	/**
	 * Toggle the expansion of an asset in the tree.
	 */
	public async toggleExpansion(item: AssetTreeLevelItem): Promise<void> {
		item.expanded = !item.expanded;

		if (item.expanded && !item.loaded) {
			await this.loadData(item);
		}

		this.flatTree();
	}

	/**
	 * Load a level of the asset tree.
	 * 
	 * @param parentItem - Parent item to load children from.
	 * @returns Items loaded from the API.
	 */
	public async loadData(parentItem: AssetTreeLevelItem = null): Promise<void> {
		if (!parentItem && this.lastFilters && ObjectUtils.equal(this.lastFilters, UnoAssetSelectorTreeNavigationComponent.filters)) {
			return;
		}

		this.lastFilters = structuredClone(UnoAssetSelectorTreeNavigationComponent.filters);

		const items = await AssetService.getTreeLevel(parentItem ? parentItem.uuid : null, UnoAssetSelectorTreeNavigationComponent.filters);

		const treeItems = items.map((item: AssetTreeLevel) => {
			return {
				...item,
				level: parentItem ? parentItem.level + 1 : null,
				expanded: false,
				loaded: false,
				items: []
			};
		});

		if (parentItem) {
			parentItem.loaded = true;
			parentItem.items = treeItems;
		} else {
			this.items = treeItems;
			this.flatTree();
		}
	}

	/**
	 * Prepare the list of items to be rendered in the tree.
	 */
	public flatTree(): void {
		const flat = [];

		function addItem(item: AssetTreeLevelItem): void {
			flat.push(item);
			if (item.expanded) {
				for (const child of item.items) {
					addItem(child);
				}
			}
		}

		for (const item of this.items) {
			addItem(item);
		}

		this.flatItems = flat;
	}

	/**
	 * Selects assets
	 * 
	 * @param item - Selected asset
	 */
	public select(item: AssetTreeLevelItem): void {	
		this.selectedAsset = item;	
		this.canAdd = true;
	}

	/**
	 * Adds selected asset to the selector
	 */
	public add(): void {
		this.onAdd(this.selectedAsset);
	}

	/**
	 * Verifies if the given asset is selected
	 * 
	 * @param item - Given asset
	 * @returns - true if its selected false if it isn't
	 */
	public isAssetSelected(item: AssetTreeLevelItem): boolean {
		if (this.selectedAsset?.uuid === item.uuid) {
			return true;
		} else {
			return false;
		}
	}

	/**
	 * Changes the sorting property
	 * 
	 * @param sort - Sort option 
	 */
	public async changeSort(sort: string): Promise<void> {
		this.selfStatic.filters.sortField = sort;
		this.isSortOpen = false;
		await this.loadData();
	}

	/**
	 * Changes filters
	 * 
	 * @param filter - Selected filter
	 */
	public changeFilter(filter: string): void {
		if (this.selectedFilters.includes(filter)) {
			this.selectedFilters = this.selectedFilters.filter((value: string) => {
				return value !== filter;
			});
		} else {
			this.selectedFilters.push(filter);
		}

		this.selfStatic.filters.searchFields = this.selectedFilters;
	}


	/**
	 * Update the search term used.
	 *
	 * @param event - DOM event.
	 */
	public async onSearch(event: any): Promise<void> {
		UnoAssetSelectorTreeNavigationComponent.filters.search = event;
		await this.loadData();
	}

	/**
	 * Dismiss the modal controller. Should close the modal screen instantly (if used as a modal).
	 */
	public async dismiss(): Promise<void> {
		await this.controller.dismiss();
	}
}
