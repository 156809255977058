<div style="position:absolute; width: 100%; height: 100%; top: 0px; left: 0px">
	<div #map class="plant-map-map-expanded">
		<!-- Map -->
		<div #mapContainer style="position:absolute; top:0px; bottom:0px; width: 100%; height: 100%;"></div>

		<!-- Legend -->
		<div class="plant-map-legend plant-map-map-overlay">
			@for (l of this.legend(); track l) {
				<div style="cursor: pointer;" (click)="this.toggleLegendItem(l.type, l.featureType);">
					<span class="plant-map-legend-key" [ngStyle]="{backgroundColor: l.color, pointerEvents: 'none'}"></span>
					<span [ngClass]="!l.enabled ? 'plant-map-strikethrough' : ''" [ngStyle]="{pointerEvents: 'none'}">
						{{l.label}}
					</span>
				</div>
			}
		</div>
	</div>

</div>
