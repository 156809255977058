import {Service} from '../http/service';
import {ServiceList} from '../http/service-list';

/**
 * File converter service containing methods to convert files.
 */
export class FileConverterService {
	public static async convertDocxToPdf(doc: ArrayBuffer): Promise<ArrayBuffer> {
		const form = new FormData();
		form.append('file', new Blob([doc]), 'file.docx');

		const request = await Service.fetch(ServiceList.fileConverter.docxToPdf, null, null, form, null);
		return request.response;
	}
}
