import {Component, OnInit, ViewChild} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';

import {PlantsService} from 'src/app/modules/pipeline-integrity/services/plant.service';
import {UnoFormComponent} from '../../../../../../components/uno-forms/uno-form/uno-form.component';
import {ScreenComponent} from '../../../../../../components/screen/screen.component';
import {App} from '../../../../../../app';
import {UserPermissions} from '../../../../../../models/users/user-permissions';
import {Session} from '../../../../../../session';
import {Service} from '../../../../../../http/service';
import {ServiceList} from '../../../../../../http/service-list';
import {Modal} from '../../../../../../modal';
import {Locale} from '../../../../../../locale/locale';
import {Plant} from '../../../../../../models/pipeline-integrity/pipeline/plant';
import {UnoFormModule} from '../../../../../../components/uno-forms/uno-form.module';
import {PlantLayout} from '../plant-layout';
import {UnoButtonComponent} from '../../../../../../components/uno/uno-button/uno-button.component';
import {UnoContentComponent} from '../../../../../../components/uno/uno-content/uno-content.component';
import {PermissionsPipe} from '../../../../../../pipes/permissions.pipe';

@Component({
	selector: 'plant-edit-page',
	templateUrl: 'plant-edit.page.html',
	standalone: true,
	imports: [UnoContentComponent, UnoFormModule, UnoButtonComponent, TranslateModule, PermissionsPipe]
})
export class PlantEditPage extends ScreenComponent implements OnInit {
	public app: any = App;

	public layout: any = PlantLayout;

	public userPermissions: any = UserPermissions;

	public session: any = Session;

	@ViewChild('form', {static: false})
	public form: UnoFormComponent = null;

	public permissions = [UserPermissions.PIPELINE_INTEGRITY_PLANT_CREATE, UserPermissions.PIPELINE_INTEGRITY_PLANT_EDIT, UserPermissions.PIPELINE_INTEGRITY_PLANT_DELETE];

	/**
	 * Plant being edited/created on this screen, data is fetched from the API.
	 */
	public plant: Plant = null;

	/**
	 * Flag to indicate if the screen is being used to create a new entry.
	 */
	public createMode: boolean = false;

	public async ngOnInit(): Promise<void> {
		super.ngOnInit();

		this.plant = null;
		this.createMode = false;

		const data = App.navigator.getData();
		if (!data || !data.uuid && !data.createMode) {
			App.navigator.pop();
			return;
		}

		if (data.uuid && data.createMode) {
			throw Error('UUID and createMode cannot be used simultaneously.');
		}
		

		// Create plant
		if (data.createMode) {
			App.navigator.setTitle('create');
			this.createMode = true;
			this.plant = new Plant();
			// Edit plant , load specific plant
		} else {
			App.navigator.setTitle('edit');
			this.plant = await PlantsService.get(data.uuid);
			if (this.plant.name) {
				App.navigator.setTitle(this.plant.name);
			}
		}
	}

	/**
	 * Create/update plant.
	 *
	 * @param stayOnPage - Flag to indicate if the system should stay on the page after changes are saved.
	 */
	public async update(stayOnPage: boolean = false): Promise<void> {
		if (!this.form.requiredFilled()) {
			Modal.alert(Locale.get('error'), Locale.get('requiredFieldsError'));
			return;
		}
		// Set assetUuid to null , serialization just accept a valid UUID or null
		if (this.plant.assetUuid === '') {
			this.plant.assetUuid = null;
		}

		await Service.fetch(this.createMode ? ServiceList.pipelineIntegrity.pipeline.plant.create : ServiceList.pipelineIntegrity.pipeline.plant.update, null, null, this.plant, Session.session);
		if (!stayOnPage) {
			App.navigator.pop();
		}

		Modal.toast(Locale.get('updatedSuccessfully'));
	}

	/**
	 * Delete plant.
	 */
	public async delete(): Promise<void> {
		const confirm = await Modal.confirm(Locale.get('confirm'), Locale.get('confirmDelete'));
		if (confirm) {
			await Service.fetch(ServiceList.pipelineIntegrity.pipeline.plant.delete, null, null, {uuid: this.plant.uuid}, Session.session);
			Modal.toast(Locale.get('deleteSuccessfully'));
			App.navigator.pop();
		}
	}

}
