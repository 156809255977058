import {DOM, NodeSocket, Object2D, Text, Vector2} from 'escher.js/build/escher.module.js';
import {Modal} from '../../../../modal';
import {Locale} from '../../../../locale/locale';
import {DestroyableNode} from './destroyable-node';

export class NumberInputNode extends DestroyableNode {
	public div: DOM = null;

	public text: Text = null;

	public value: number = 0;

	public r: NodeSocket = null;

	public constructor() {
		super();

		// @ts-ignore
		this.type = 'NumberInputNode';

		// @ts-ignore
		this.box.set(new Vector2(-50, -30), new Vector2(50, 30));

		this.value = 0;

		this.text = new Text();
		this.text.serializable = false;
		this.text.font = '16px Arial';
		this.text.layer = 2;
		// @ts-ignore
		this.add(this.text);
	}

	public async selectValue(): Promise<void> {
		const result = await Modal.prompt(Locale.get('value'), [{name: 'value', placeholder: Locale.get('value'), type: 'number'}]);
		if (result.confirm) {
			this.value = Number.parseInt(result.data.value, 10);
		}
	}

	public registerSockets(): void {
		if (!this.r) {
			// @ts-ignore
			this.r = this.addOutput('string', 'v');
			this.r.getValue = () => {
				return String(this.value);
			};
		}
	}

	public onUpdate(): void {
		super.onUpdate();
		this.text.text = this.value;
	}

	public onPointerOver(pointer, viewport): void {
		if (pointer.buttonDoubleClicked(pointer.LEFT)) {
			// @ts-ignore
			this.selectValue();
		}
	}

	public serialize(recursive): void {
		// @ts-ignore
		const data = super.serialize(recursive);
		data.value = this.value;
		data.out = this.r !== null ? this.r.uuid : null;
		return data;
	}

	public parse(data, root): void {
		// @ts-ignore
		super.parse(data, root);
		this.value = data.value;
		if (data.out !== null) {
			this.r = root.getChildByUUID(data.out);
			this.r.getValue = () => {
				return String(this.value);
			};
		}
	}
}

Object2D.register(NumberInputNode, 'NumberInputNode');
