<div (click)="this.openSelector($event)" [ngStyle]="{cursor: this.disabled ? 'unset': 'pointer'}" [ngClass]="{'date-time-input-box': this.border, 'date-time-disabled': this.disabled}">
	<ion-text [ngClass]="{'date-time-input-text': this.border}">{{this.displayText()}}</ion-text>
</div>

<ion-popover #popover [dismissOnSelect]="false" reference="event">
	<ng-template>
		<ion-datetime style="z-index: 5;" #dateTime (ionChange)="this.writeValue($event.target.value);" [disabled]="this.disabled" [locale]="locale.code" [max]="this.maxDate" [min]="this.minDate" [presentation]="this.mode" [value]="this.localeDate" class="ion-no-padding" doneText="{{'done' | translate}}" style="padding: 0px; display: inline-block;" [showDefaultButtons]="true">
			<ion-buttons slot="buttons">
				<ion-button color="primary" (click)="dateTime.cancel(); this.dateTimeClick()">{{'cancel' | translate}}</ion-button>
				<ion-button color="primary" (click)="dateTime.confirm(); this.dateTimeClick()">{{'confirm' | translate}}</ion-button>
			</ion-buttons>
		</ion-datetime>
	</ng-template>
</ion-popover>
