
/**
 * Defines the size of a form item.
 */
export enum UnoFormItemSize {
	/**
	 * Large form item, always takes up the full width.
	 */
	LARGE = 3,

	/**
	 * Medium form item, takes up half the width.
	 */
	MEDIUM = 2,

	/**
	 * Small form item, takes up a third of the width.
	 */
	SMALL = 1
}
