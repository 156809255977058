<uno-title style="padding: 15px;">{{this.title}}</uno-title>

<div style="padding: 15px; width: 100%; max-height: 60vh; overflow: auto;">
	<uno-form [editable]="this.editable" [layout]="this.layout" [object]="this.object" #form></uno-form>
</div>

<div style="display: flex; flex-direction: row-reverse; gap: 5px; padding: 15px;">
	@for (button of this.buttons; track button) {
		<uno-button (click)="buttonPressed(button);" [color]="button.color">{{button.label | translate}}</uno-button>
	}
</div>
