import {
	Component,
	OnChanges, OnInit,
	SimpleChanges,
	ViewEncapsulation
} from '@angular/core';
import {SortDirection} from 'src/app/utils/sort-direction';
import {IonicModule} from '@ionic/angular';
import {AsyncPipe} from '@angular/common';
import {App} from '../../../../../app';
import {Session} from '../../../../../session';
import {UnoListLazyLoadHandler} from '../../../../../components/uno/uno-list/uno-list-lazy-load-handler';
import {APAssetType} from '../../../../../models/asset-portfolio/asset-type';
import {AssetPortfolioMenuPage} from '../asset-portfolio-menu.page';
import {UnoCardComponent} from '../../../../../components/uno/uno-card/uno-card.component';
import {AssetTypeListNameResponse, AssetTypeListParams, AssetTypeService} from '../../../services/asset-type.service';
import {AssetService} from '../../../services/asset.service';

/**
 * Menu cards layout should be used for navigation menus is compatible with desktop and mobile.
 *
 * Displays the menu as a grid of cards in the desktop platform and as a list in mobile devices.
 */
@Component({
	selector: 'asset-type-cards',
	templateUrl: './asset-type-cards.component.html',
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [IonicModule, UnoCardComponent, AsyncPipe]
})
export class AssetTypeCardsComponent implements OnChanges, OnInit {
	public app: any = App;

	public session: any = Session;

	/**
	 * Lazy list loading handler.
	 */
	public handler: UnoListLazyLoadHandler<APAssetType> = new UnoListLazyLoadHandler<APAssetType>();

	public ngOnInit(): void {
		this.handler.loadMore = async(count: number, pageSize: number) => {
			const data: AssetTypeListParams = {
				search: AssetPortfolioMenuPage.filters.search,
				searchFields: AssetPortfolioMenuPage.filters.searchFields,
				sortField: '[ap_asset_type].[name]',
				sortDirection: SortDirection.ASC,
				from: count,
				count: pageSize
			};
			
			const request: AssetTypeListNameResponse = await AssetTypeService.listName(data);

			const types: APAssetType[] = [];

			for (let i = 0; i < request.types.length; i++) {
				const type: any = APAssetType.parse(request.types[i]);
				type.badge = (async() => {
					return AssetService.count({typeUuid: type.uuid}, true);
				})();
				types.push(type);
			}

			return {
				elements: types,
				hasMore: request.hasMore
			};
		};
	}

	public async ngOnChanges(changes: SimpleChanges): Promise<void> {
		await this.reset();
	}

	/**
	 * Reset the card list.
	 *
	 * Automatically performs the API call for the first page.
	 */
	public async reset(): Promise<void> {
		await this.handler.reset();
	}
}
