import {Component, ViewEncapsulation} from '@angular/core';
import {Dl50ClientModule} from 'src/client-custom/dl50/dl50-client.module';
import {InspectionProjectService} from 'src/app/modules/inspections/services/inspection-project.service';
import {Environment} from 'src/environments/environment';
import {Session} from '../../../../session';
import {App} from '../../../../app';
import {UserPermissions} from '../../../../models/users/user-permissions';
import {MenuOption} from '../../data/menu-option';
import {MenuDesktopComponent} from '../menu-desktop/menu-desktop.component';
import {MenuMobileComponent} from '../menu-mobile/menu-mobile.component';
import {Modules} from '../../../../modules';
import {MenuOptions} from './menu-options';

/**
 * The menu component contains the basic menu layout with all the navigation buttons.
 *
 * The layout is adjusted for mobile and desktop version.
 */
@Component({
	selector: 'menu-component',
	templateUrl: './menu.component.html',
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [MenuMobileComponent, MenuDesktopComponent]
})
export class MenuComponent {
	public app: any = App;

	public session: any = Session;

	/**
	 * Get the full list of options available in the menu.
	 */
	public options: MenuOption[] = [];

	public async ngOnInit(): Promise<void> {
		if (!Session.isValid()) {
			App.navigator.navigate('login');
			return;
		}

		await this.buildOptions();
	}

	/**
	 * Build the list of options to be displayed in the side menu.
	 */
	public async buildOptions(): Promise<void> {
		this.options = MenuOptions();

		if (Session.hasPermissions([UserPermissions.INSPECTION])) {
			const request = await InspectionProjectService.list({shownInMenuOnly: true});

			for (let i = 0; i < request.projects.length; i++) {
				this.options.push({
					route: '/menu/inspection/list/steps',
					icon: './assets/components/menu/digital-operation.svg',
					label: request.projects[i].name,
					available: !Environment.PRODUCTION || !Dl50ClientModule.isClient(),
					highlight: (option: MenuOption) => {
						const data = App.navigator.getData();
						if (!data) {
							return false;
						}

						return App.navigator.has(option.route) && data.project === option.data.project;
					},
					module: Modules.INSPECTIONS,
					data: {project: request.projects[i].uuid},
					permissions: [UserPermissions.INSPECTION]
				});
			}
		}
	}

}
