import {UnoFormField} from '../../../../components/uno-forms/uno-form/uno-form-field';
import {UnoFormFieldTypes} from '../../../../components/uno-forms/uno-form/uno-form-field-types';

export const LdsChannelLayout: UnoFormField[] = [
	{
		required: true,
		attribute: 'tag',
		label: 'tag',
		type: UnoFormFieldTypes.TEXT
	},
	{
		required: false,
		attribute: 'offset',
		label: 'angularOffset',
		inUnit: 'd',
		unit: 'd',
		type: UnoFormFieldTypes.NUMBER_UNITS,
		options: [
			{value: 'd', label: 'º'},
			{value: 'r', label: 'Rad'}
		]
	}
];
