import {Component, ContentChildren, EventEmitter, QueryList, ViewEncapsulation, Output, OnInit, Input} from '@angular/core';
import {CdkDragDrop, moveItemInArray, CdkDropList, CdkDrag} from '@angular/cdk/drag-drop';
import {NgClass} from '@angular/common';
import {UUID} from '../../../models/uuid';
import {UnoIconComponent} from '../uno-icon/uno-icon.component';
import {UnoTabSectionComponent} from './uno-tab-section/uno-tab-section.component';

/**
 * The tab component, should use in conjuction with the UnoTabSectionComponent.
 *
 * The user can change the tabs order by clicking and holding a tab and dragging it to the left and right.
 */
@Component({
	selector: 'uno-tab',
	templateUrl: './uno-tab.component.html',
	styleUrls: ['./uno-tab.component.css'],
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [CdkDropList, CdkDrag, NgClass, UnoIconComponent]
})
export class UnoTabComponent implements OnInit {
	/**
	 * Emit when a tab is moved
 	 */
	@Output()
	public tabChange = new EventEmitter<any>();

	/**
	 * Can be used to enabled or disable the drag and drop feature.
	 */
	@Input()
	public dragDisabled: boolean = true;

	@ContentChildren(UnoTabSectionComponent)
	public tabSections: QueryList<UnoTabSectionComponent>;

	public ngOnInit(): void {
		// If no tabs are active, make the first one active
		if (this.tabSections?.length > 0 && !this.tabSections.some((item) => {return item.active;})) {
			this.tabSections[0].active = true;
		}
	}

	/**
	 * When a tab is clicked change the one that is active.
	 *
	 * @param uuid - The uuid of the child tab section to open.
	 */
	public toggleTabs(uuid: UUID): void {
		// Deactivates all tabs and activates the one that matches uuid.
		this.tabSections.forEach((item) => {
			const active = item.uuid === uuid;

			if (item.active && !active && item.onHide) {
				item.onHide.emit();
			}

			if (!item.active && active && item.onPresent) {
				item.onPresent.emit();
			}

			item.active = active;
		});
	}

	/**
	 * When the tab is dropped.
	 *
	 * @param event - The event of dropping the tab.
	 */
	public drop(event: CdkDragDrop<UnoTabSectionComponent[]>): void {
		this.tabChange.emit({title: this.tabSections.toArray()[event.previousIndex].title, uuid: this.tabSections.toArray()[event.previousIndex].uuid, previous: event.previousIndex, current: event.currentIndex});

		// @ts-ignore
		// eslint-disable-next-line no-underscore-dangle
		moveItemInArray(this.tabSections._results, event.previousIndex, event.currentIndex);
	}

}
