import {FileReaderAsync} from '../../../../utils/file-reader-async';
import {Service} from '../../../../http/service';
import {ServiceList} from '../../../../http/service-list';
import {Session} from '../../../../session';
import {generateUUID, UUID} from '../../../../models/uuid';
import {Feature} from '../../../../models/pipeline-integrity/pipeline/feature';
import {Segment} from '../../../../models/pipeline-integrity/pipeline/segment';
import {Geolocation} from '../../../../models/geolocation';
import {GeolocationUtils} from '../../../../utils/geolocation-utils';
import {CMP} from '../../../../models/pipeline-integrity/mot/cmp';
import {MOTFeaData, MOTImportUtils} from './import-utils';

/**
 * CMP data.
 */
export type CMPData = { uuid: UUID, name: string, distance: number, distanceToSegmentStart: number, distanceToSegmentEnd: number };

/**
 * Feature data.
 */
export type FeatureData = { description: string, distance: number, type: number };

/**
 * Data of a segment in the pipeline.
 */
export type SegmentData = {
	name: string,
	uuid: UUID,
	startPoint: Geolocation,
	endPoint: Geolocation,
	distance: number,
	features: FeatureData[],
	cmps: CMPData[]
};

/**
 * Utils to import data related with the pipeline.
 */
export class PipelineImport {
	/**
	 * Import features from a .fea file.
	 *
	 * Parses the fea, extracts the features and their distance, projects 1D features into the 2D segments that compose the pipeline.
	 *
	 * @param file - File to import data from.
	 * @param pipelineUuid - UUID of target pipeline
	 * @param cmpUuid - UUID of the target CMP
	 */
	public static async importFea(file: File, pipelineUuid: UUID, cmpUuid: UUID): Promise<void> {
		const result = await FileReaderAsync.readAsText(file, 'ISO-8859-1');

		const data: MOTFeaData = MOTImportUtils.parseFea(result);

		const pipeline: SegmentData[] = await this.loadPipelineData(pipelineUuid, cmpUuid);

		const features: Feature[] = this.create2dFeatures(data, pipeline, cmpUuid);

		await Service.fetch(ServiceList.pipelineIntegrity.pipeline.feature.createBatch, null, null, features, Session.session);
	}

	/**
	 * Creates features based on data retrieve from fea file.
	 *
	 * @param featureData - Feature data from the parsed fea file.
	 * @param segment - Segment data of the segment being analyzed.
	 * @param featureCoordinates - Coordinates of the feature.
	 * @returns Feature object created.
	 */
	public static createFeature(featureData: any, segment: any, featureCoordinates: Geolocation): Feature {
		const feature = new Feature();
		feature.uuid = generateUUID();
		feature.type = featureData.type;
		feature.subtype = featureData.subtype ? featureData.subtype : '';
		feature.position = featureCoordinates;
		feature.name = featureData.name;
		feature.size = featureData.size ? featureData.size : 0;
		feature.observations = featureData.observations ? featureData.observations : '';
		feature.segmentUuid = segment.uuid;
		return feature;
	}

	/**
	 * Transform 1D distance into 2D geolocation coordinates and create feature attributes from fea file.
	 *
	 * Traverse along the pipeline segments and place points based on their distance from the reference in the pipeline.
	 *
	 * Only considers 2D distance of the points, and assumes that the pipeline doesn't have bifurcations.
	 *
	 * @param featuresData - Pipeline UUID that belongs to this acquisition.
	 * @param pipelineData - Pipeline UUID that belongs to this acquisition.
	 * @param cmpUuid - Pipeline UUID that belongs to this acquisition.
	 * @returns An array of feature objects
	 */
	public static create2dFeatures(featuresData: MOTFeaData, pipelineData: SegmentData[], cmpUuid: UUID): Feature[] {
		const features: Feature[] = [];

		for (let i = 0; i < pipelineData.length; i++) {
			const segment = pipelineData[i];

			for (const cmp of segment.cmps) {

				// Found cmp being analyzed on acquisition(segment can have more than 1 cmp)
				if (cmp.uuid === cmpUuid) {
					for ( const feats of featuresData.features) {
						// Features on same segment of CMP
						if (feats.distance < 0 && Math.abs(feats.distance) <= cmp.distanceToSegmentStart || feats.distance >= 0 && feats.distance < cmp.distanceToSegmentEnd) {
							// Left of CMP
							if (feats.distance < 0) {
								const featDistInSeg = Math.abs(feats.distance) - cmp.distanceToSegmentStart;
								const featGeoCoords = this.convertDistanceToCoords(featDistInSeg, segment, false);
								features.push(this.createFeature(feats, segment, featGeoCoords));
								// Right of CMP
							} else {
								const featDistInSeg = Math.abs(cmp.distanceToSegmentStart) + Math.abs(feats.distance);
								const featGeoCoords = this.convertDistanceToCoords(featDistInSeg * -1, segment, false);
								features.push(this.createFeature(feats, segment, featGeoCoords));
							}
						}

						// Features on segments to the left of CMP segment
						let switchOrder = false;
						if (Math.abs(feats.distance) > cmp.distanceToSegmentStart && feats.distance < 0) {
							let pointer = -1;
							let found = false;
							let sumDist = 0;
							switchOrder = true;

							while (i + pointer >= 0 && !found) {
								const [, treeSegAfter] = Object.entries(pipelineData[i + pointer]) as any[][0];
								sumDist += treeSegAfter.distance;

								if (Math.abs(feats.distance) - cmp.distanceToSegmentStart <= sumDist) {
									const featDistInSeg = Math.abs(feats.distance) - cmp.distanceToSegmentStart - (sumDist - treeSegAfter.distance);
									const featGeoCoords = this.convertDistanceToCoords(featDistInSeg * -1, treeSegAfter, switchOrder);
									features.push(this.createFeature(feats, treeSegAfter, featGeoCoords));
									found = true;
								}
								pointer--;
							}
						}

						// Features on segments to the right of CMP segment
						if (feats.distance > cmp.distanceToSegmentEnd) {
							let pointer = 1;
							let found = false;
							let sumDist = 0;

							while (i + pointer < pipelineData.length && !found) {
								const [, treeSegNext] = Object.entries(pipelineData[i + pointer]) as any[][0];
								sumDist += treeSegNext.distance;

								if (feats.distance - cmp.distanceToSegmentEnd <= sumDist) {
									const featDistInSeg = feats.distance - cmp.distanceToSegmentEnd - (sumDist - treeSegNext.distance);
									const featGeoCoords = this.convertDistanceToCoords(featDistInSeg * -1, treeSegNext, switchOrder);
									features.push(this.createFeature(feats, treeSegNext, featGeoCoords));
									found = true;
								}
								pointer++;
							}
						}
					}
				}
			}
		}

		return features;
	}


	/**
	 * Load segments, features and cmp for a pipeline.
	 *
	 * @param pipelineUuid - Pipeline UUID that belongs to this acquisition.
	 * @param cmpUuid - CMP being analyzed.
	 * @returns an array with all the segment objects of that pipeline, segment object include distance of the segment and features/CMPS in that segment.
	 */
	public static async loadPipelineData(pipelineUuid: UUID, cmpUuid: UUID): Promise<SegmentData[]> {
		// Segments that compose the pipeline
		const segments: Segment[] = [];

		// Load segments from API
		const requestSegments = await Service.fetch(ServiceList.pipelineIntegrity.pipeline.segment.list, null, null, {pipelineUuid: pipelineUuid}, Session.session);
		for (let j = 0; j < requestSegments.response.segments.length; j++) {
			segments.push(Segment.parse(requestSegments.response.segments[j]));
		}

		// Pipeline data organized by segment name
		const pipeline: SegmentData[] = [];

		for (let i = 0; i < segments.length; i++) {
			const segStartCoords = new Geolocation(segments[i].startPoint.latitude, segments[i].startPoint.longitude);
			const segEndCoords = new Geolocation(segments[i].endPoint.latitude, segments[i].endPoint.longitude);

			const objSeg: SegmentData = {
				name: segments[i].name,
				uuid: segments[i].uuid,
				distance: GeolocationUtils.distance(segStartCoords, segEndCoords),
				startPoint: segments[i].startPoint,
				endPoint: segments[i].endPoint,
				features: [],
				cmps: []
			};

			// Features
			const requestFeatures = await Service.fetch(ServiceList.pipelineIntegrity.pipeline.feature.list, null, null, {segmentUuid: segments[i].uuid}, Session.session);
			for (let j = 0; j < requestFeatures.response.features.length; j++) {
				const feature = Feature.parse(requestFeatures.response.features[j]);
				const featureCoords = new Geolocation(feature.position.latitude, feature.position.longitude);
				objSeg.features.push({
					description: feature.name,
					distance: GeolocationUtils.distance(segStartCoords, featureCoords),
					type: feature.type
				});
			}

			// CMPS
			const requestCmps = await Service.fetch(ServiceList.pipelineIntegrity.mot.cmp.list, null, null, {segmentUuid: segments[i].uuid}, Session.session);
			for (let k = 0; k < requestCmps.response.cmps.length; k++) {
				const cmp = CMP.parse(requestCmps.response.cmps[k]);
				const coords = new Geolocation(cmp.position.latitude, cmp.position.longitude);
				objSeg.cmps.push({
					uuid: cmp.uuid,
					name: cmp.name,
					distance: GeolocationUtils.distance(segStartCoords, coords),
					distanceToSegmentEnd: 0,
					distanceToSegmentStart: 0
				});
			}

			pipeline.push(objSeg);
		}

		let sumDistance: number = 0;
		let cmpDistance: number = 0;

		// Calculate distance from pipeline start to CMP being analyzed in the acquisition
		for (let i = 0; i < pipeline.length; i++) {
			for (const cmpD of pipeline[i].cmps) {
				// Found cmp being analyzed on acquisition
				if (cmpD.uuid === cmpUuid) {
					cmpDistance = sumDistance + cmpD.distance;
				}
			}
			sumDistance += pipeline[i].distance;
		}

		let distanceToSum: number = 0;

		// Add segment distances to feature and CMP's, set CMP being analyzed to 0
		for (let i = 0; i < pipeline.length; i++) {
			for (const cmpD of pipeline[i].cmps) {
				if (cmpD.uuid !== cmpUuid) {
					cmpD.distance = cmpD.distance + distanceToSum;
				} else {
					// Set distance of CMP being analyzed from start and end of the segment.
					cmpD.distanceToSegmentStart = cmpD.distance;
					cmpD.distanceToSegmentEnd = pipeline[i].distance - cmpD.distance;
					cmpD.distance = 0;
				}
			}

			for (const featD of pipeline[i].features) {
				featD.distance = featD.distance + distanceToSum;
			}

			distanceToSum = distanceToSum + pipeline[i].distance;
		}

		// Remove distance of the CMP being analyzed to all features and cmps
		for (let i = 0; i < pipeline.length; i++) {
			for (const cmpD of pipeline[i].cmps) {
				if (cmpD.uuid !== cmpUuid) {
					cmpD.distance = cmpD.distance - cmpDistance;
				}
			}

			for (const featD of pipeline[i].features) {
				featD.distance = featD.distance - cmpDistance;
			}
		}

		return pipeline;
	}

	/**
	 * Adjust the referential of the features relative to the CMP.
	 *
	 * Subtracts the distance of the CMP being analyzed to all the features.
	 *
	 * @param data - Object with features/cmps data.
	 * @returns An object with features/cmps data with updated distance values.
	 */
	public static subtractDistanceFromCMP(data: MOTFeaData): MOTFeaData {
		// Distance of the CMP is used as reference
		const reference = data.cmps[0].distance;
		const elems: {distance: number}[] = data.cmps.concat(data.features);

		// Subtract all distances relative to the reference.
		for (let i = 0; i < elems.length; i++) {
			elems[i].distance = elems[i].distance - reference;
		}

		return data;
	}

	/**
	 * Converts feature distance to Geolocation.
	 *
	 * @param featurePosition - Position of the feature in the segment.
	 * @param segment - Data of the segment where the feature going to be placed.
	 * @param switchOrder - If true the start and end points must be switched.
	 * @returns a geolocation object.
	 */
	public static convertDistanceToCoords(featurePosition: number, segment: SegmentData, switchOrder: boolean): Geolocation {
		const ppb = featurePosition / segment.distance;

		const xa = switchOrder ? segment.endPoint.longitude : segment.startPoint.longitude;
		const xb = switchOrder ? segment.startPoint.longitude : segment.endPoint.longitude;

		const ya = switchOrder ? segment.endPoint.latitude : segment.startPoint.latitude;
		const yb = switchOrder ? segment.startPoint.latitude : segment.endPoint.latitude;

		return new Geolocation((ya - yb) * ppb + ya, (xa - xb) * ppb + xa);
	}
}
