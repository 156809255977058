<uno-content>

	@for (section of this.options; track section) {
		@if (section.permissions | hasPermissions) {
			<ion-card-content>
				<ion-title color="primary">{{section.title | translate}}</ion-title>
				<ion-grid>
					<ion-row>

						@for (option of section.options; track option) {
							@if ((option.permissions | hasPermissions) && option.available) {
								<ion-col size="12" size-lg="4">
									<ion-card>
										<ion-card-header>
											<ion-card-title>{{option.label | translate}}</ion-card-title>
											<ion-card-subtitle>{{option.description | translate}}</ion-card-subtitle>
										</ion-card-header>
										<ion-card-content style="display: flex; flex-direction: row; gap: 5px;">
											@for (button of option.buttons; track button) {
												<uno-button (click)="button.onClick();" color="{{button.color || 'primary'}}" size="medium">{{(button.label || 'selectFile') | translate}}</uno-button>
											}
										</ion-card-content>
									</ion-card>
								</ion-col>
							}
						}
					</ion-row>
				</ion-grid>
			</ion-card-content>
		}
	}
</uno-content>
