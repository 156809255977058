import {FormatDatePipe} from 'src/app/pipes/format-date.pipe';
import {UnoFormField} from '../../../components/uno-forms/uno-form/uno-form-field';
import {UnoFormFieldTypes} from '../../../components/uno-forms/uno-form/uno-form-field-types';
import {CalendarEventSubtypes} from '../../../models/asset-planning/calendar-event-actions';
import {InputOptionsMultipleBatchRequest, InputOptionsMultipleLazyPageRequest} from '../../../components/uno-input/uno-options-lazy/uno-options-lazy.component';
import {RepairStatus} from '../../../models/repairs/repairs/repair-status';
import {SortDirection} from '../../../utils/sort-direction';
import {Service} from '../../../http/service';
import {ServiceList} from '../../../http/service-list';
import {Session} from '../../../session';
import {AtexInspectionStatus} from '../../../models/atex-inspections/inspections/atex-inspection-status';
import {RepairInspectionStatus} from '../../../models/repairs/inspections/repair-inspection-status';
import {
	CalendarEventStatus,
	CalendarEventStatusLabel
} from '../../../models/asset-planning/calendar-event-occurrence-status';
import {
	CalendarEventOccurrencePriority,
	CalendarEventOccurrencePriorityLabel
} from '../../../models/asset-planning/calendar-event-occurrence-priority';
import {EnumUtils} from '../../../utils/enum-utils';
import {InspectionService} from '../../inspections/services/inspection.service';
import {AtexInspectionService} from '../../atex/inspections/services/atex-inspection.service';
import {DL50InspectionService} from '../../dl50/services/dl50-inspection.service';


export const CalendarOccurrenceLayout: UnoFormField[] = [
	{
		required: false,
		label: 'uuid',
		attribute: 'uuid',
		type: UnoFormFieldTypes.UUID
	},
	{
		required: false,
		label: 'name',
		attribute: 'event.name',
		type: UnoFormFieldTypes.TEXT,
		editable: false
	},
	{
		required: false,
		attribute: 'event.description',
		label: 'description',
		type: UnoFormFieldTypes.TEXT_MULTILINE,
		editable: false
	},
	{
		required: false,
		attribute: 'repairUuid',
		label: 'repair',
		multiple: false,
		type: UnoFormFieldTypes.OPTIONS_MULTIPLE_LAZY,
		identifierAttribute: 'uuid',
		isActive: (object: any, row: UnoFormField) => {
			return object.event.actionSubtype === CalendarEventSubtypes.REPAIR_DEFINITIVE_REPAIR;
		},
		fetchOptionsLazy: async function(request: InputOptionsMultipleLazyPageRequest): Promise<{options: any[], hasMore: boolean, id: number}> {
			const data = {
				status: RepairStatus.ALL,
				from: request.from,
				count: request.count,
				search: request.search,
				searchFields: ['[repair].[id]', '[repair].[description]', '[ap_asset].[id]', '[ap_asset].[name]', '[ap_asset].[tag]'],
				sortField: '[ap_asset].[name]',
				sortDirection: SortDirection.ASC
			};


			const req = await Service.fetch(ServiceList.repairs.list, null, null, data, Session.session);
			return {options: req.response.repairs, hasMore: req.response.hasMore, id: req.id};
			
		},
		fetchOptionsBatch: async function(request: InputOptionsMultipleBatchRequest): Promise<{options: any[]}> {
			if (request.options.length > 0) {
				const data = {search: request.options[0], searchFields: ['[repair].[id]']};
				const req = await Service.fetch(ServiceList.repairs.list, null, null, data, Session.session);
				return {options: req.response.repairs};
			}

			return {options: []};
		},
		getOptionText: function(option: any): string {
			return [option.asset?.name, option.asset?.tag, option.description].filter(Boolean).join(' - ');
		}
	},
	{
		required: false,
		attribute: 'atexInspectionUuid',
		label: 'atexInspection',
		multiple: false,
		type: UnoFormFieldTypes.OPTIONS_MULTIPLE_LAZY,
		identifierAttribute: 'uuid',
		isActive: (object: any, row: UnoFormField) => {
			return object.event.actionSubtype === CalendarEventSubtypes.ASSET_ATEX_INSPECTION;
		},
		fetchOptionsLazy: async function(request: InputOptionsMultipleLazyPageRequest): Promise<{options: any[], hasMore: boolean, id: number}> {
			const data = {
				status: AtexInspectionStatus.ALL,
				from: request.from,
				count: request.count,
				search: request.search,
				searchFields: ['[atex_inspection].[id]', '[ap_asset].[id]', '[ap_asset].[name]', '[ap_asset].[tag]'],
				sortField: '[ap_asset].[name]',
				sortDirection: SortDirection.ASC
			};

			const req = await AtexInspectionService.list(data);
			return {options: req.inspections, hasMore: req.hasMore, id: req.id};
		},
		fetchOptionsBatch: async function(request: InputOptionsMultipleBatchRequest): Promise<{options: any[]}> {
			if (request.options.length > 0) {
				const data = {search: request.options[0], searchFields: ['[atex_inspection].[id]']};
				const req = await AtexInspectionService.list(data);
				return {options: req.inspections};
			}

			return {options: []};
		},
		getOptionText: function(option: any): string {
			return [option.asset?.name, option.asset?.tag].filter(Boolean).join(' - ');
		}
	},
	{
		required: false,
		attribute: 'dl50InspectionUuid',
		label: 'dl50Inspection',
		multiple: false,
		type: UnoFormFieldTypes.OPTIONS_MULTIPLE_LAZY,
		identifierAttribute: 'uuid',
		isActive: (object: any, row: UnoFormField) => {
			return object.event.actionSubtype === CalendarEventSubtypes.ASSET_DL50_INSPECTION;
		},
		fetchOptionsLazy: async function(request: InputOptionsMultipleLazyPageRequest): Promise<{options: any[], hasMore: boolean, id: number}> {
			const data = {
				status: AtexInspectionStatus.ALL,
				from: request.from,
				count: request.count,
				search: request.search,
				searchFields: ['[dl50_inspection].[id]', '[ap_asset].[id]', '[ap_asset].[name]', '[ap_asset].[tag]'],
				sortField: '[ap_asset].[name]',
				sortDirection: SortDirection.ASC
			};

			const req = await DL50InspectionService.list(data);
			return {options: req.inspections, hasMore: req.hasMore, id: req.id};
		},
		fetchOptionsBatch: async function(request: InputOptionsMultipleBatchRequest): Promise<{options: any[]}> {
			if (request.options.length > 0) {
				const data = {search: request.options[0], searchFields: ['[dl50_inspection].[id]']};
				const req = await DL50InspectionService.list(data);
				return {options: req.inspections};
			}

			return {options: []};
		},
		getOptionText: function(option: any): string {
			return [option.asset?.name, option.asset?.tag].filter(Boolean).join(' - ');
		}
	},
	{
		required: false,
		attribute: 'inspectionUuid',
		label: 'inspection',
		multiple: false,
		type: UnoFormFieldTypes.OPTIONS_MULTIPLE_LAZY,
		identifierAttribute: 'uuid',
		isActive: (object: any, row: UnoFormField) => {
			return object.event.actionSubtype === CalendarEventSubtypes.ASSET_DYNAMIC_INSPECTION;
		},
		fetchOptionsLazy: async function(request: InputOptionsMultipleLazyPageRequest, object: any): Promise<{options: any[], hasMore: boolean, id: number}> {
			const data: any = {
				status: AtexInspectionStatus.ALL,
				from: request.from,
				count: request.count,
				search: request.search,
				searchFields: ['[inspection].[name]', '[inspection].[description]', '[ap_asset].[id]', '[ap_asset].[name]', '[ap_asset].[tag]'],
				sortField: '[ap_asset].[name]',
				sortDirection: SortDirection.ASC
			};
			
			if (object.event?.inspectionProjectUuid) {
				data.project = object.event.inspectionProjectUuid ;
			}

			const req = await InspectionService.list(data);
			return {options: req.inspections, hasMore: req.hasMore, id: req.id};
		},
		fetchOptionsBatch: async function(request: InputOptionsMultipleBatchRequest, object: any): Promise<{options: any[]}> {
			if (request.options.length > 0) {
				const data: any = {
					search: request.options[0], 
					searchFields: ['[inspection].[id]']
				};
				
				if (object.event?.inspectionProjectUuid) {
					data.project = object.event.inspectionProjectUuid ;
				}

				const req = await InspectionService.list(data);

				return {options: req.inspections};
			}

			return {options: []};
		},
		getOptionText: function(option: any): string {
			return [option.name, option.description].filter(Boolean).join(' - ');
		}
	},
	{
		required: false,
		attribute: 'repairInspectionUuid',
		label: 'repairInspection',
		multiple: false,
		type: UnoFormFieldTypes.OPTIONS_MULTIPLE_LAZY,
		identifierAttribute: 'uuid',
		isActive: (object: any, row: UnoFormField) => {
			return object.event.actionSubtype === CalendarEventSubtypes.REPAIR_TEMPORARY_INSPECTION;
		},
		fetchOptionsLazy: async function(request: InputOptionsMultipleLazyPageRequest): Promise<{options: any[], hasMore: boolean, id: number}> {
			const data = {
				status: RepairInspectionStatus.ALL,
				from: request.from,
				count: request.count,
				search: request.search,
				searchFields: ['[repair].[id]', '[repair].[description]', '[ap_asset].[id]', '[ap_asset].[name]', '[ap_asset].[tag]'],
				sortField: '[ap_asset].[name]',
				sortDirection: SortDirection.ASC
			};

			const req = await Service.fetch(ServiceList.repairInspections.list, null, null, data, Session.session);
			return {options: req.response.inspections, hasMore: req.response.hasMore, id: req.id};
		},
		fetchOptionsBatch: async function(request: InputOptionsMultipleBatchRequest): Promise<{options: any[]}> {
			if (request.options.length > 0) {
				const data = {search: request.options[0], searchFields: ['[repair_inspection].[id]']};
				const req = await Service.fetch(ServiceList.repairInspections.list, null, null, data, Session.session);
				return {options: req.response.inspections};
			}
			return {options: []};
		},
		getOptionText: function(option: any): string {
			return [option.asset?.name, option.asset?.tag, option.date ? FormatDatePipe.formatDate(option.date) : null].filter(Boolean).join(' - ');
		}
	},
	{
		required: false,
		attribute: 'priority',
		label: 'priority',
		type: UnoFormFieldTypes.OPTIONS,
		options: EnumUtils.values(CalendarEventOccurrencePriority).map((value) => {
			return {label: CalendarEventOccurrencePriorityLabel.get(value as CalendarEventOccurrencePriority), value: value};
		})
	},
	{
		required: false,
		attribute: 'status',
		label: 'status',
		type: UnoFormFieldTypes.OPTIONS,
		editable: false,
		options: EnumUtils.values(CalendarEventStatus).map((value) => {
			return {label: CalendarEventStatusLabel.get(value as CalendarEventStatus), value: value};
		})
	},
	{
		required: true,
		attribute: 'date',
		label: 'date',
		isActive: (object: any, row: UnoFormField) => {
			return !object.triggerEvent;
		},
		type: UnoFormFieldTypes.DATE
	}
];
