import {Component, OnInit} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';

import {App} from '../../../../../../app';
import {Session} from '../../../../../../session';
import {ScreenComponent} from '../../../../../../components/screen/screen.component';
import {UserPermissions} from '../../../../../../models/users/user-permissions';
import {Modal} from '../../../../../../modal';
import {Locale} from '../../../../../../locale/locale';
import {Service} from '../../../../../../http/service';
import {ServiceList} from '../../../../../../http/service-list';
import {RepairConsumable} from '../../../master-data/repair-consumable';
import {UnoButtonComponent} from '../../../../../../components/uno/uno-button/uno-button.component';
import {UnoFormModule} from '../../../../../../components/uno-forms/uno-form.module';
import {UnoContentComponent} from '../../../../../../components/uno/uno-content/uno-content.component';
import {RepairConsumableLayout} from './repair-consumable-layout';

@Component({
	selector: 'masterdata-repair-consumables-page',
	templateUrl: 'repair-consumables.page.html',
	standalone: true,
	imports: [UnoContentComponent, UnoFormModule, UnoButtonComponent, TranslateModule]
})
export class RepairConsumablesPage extends ScreenComponent implements OnInit {
	public layout: any = RepairConsumableLayout;


	public permissions = [UserPermissions.REPAIR_CONSUMABLES_EDIT];

	/**
	 * List of repair consumables available in database.
	 */
	public consumables: RepairConsumable[] = [];

	public ngOnInit(): void {
		super.ngOnInit();
		
		App.navigator.setTitle('consumables');

		RepairConsumable.load().then((consumables: RepairConsumable[]) => {
			this.consumables = consumables;
		});
	}

	/**
	 * Add a consumable test to the list.
	 */
	public add(): void {
		this.consumables.push(new RepairConsumable());
	}

	/**
	 * Delete a consumable from the list.
	 *
	 * @param consumable - Element to be deleted from list.
	 */
	public delete(consumable: RepairConsumable): void {
		Modal.confirm(Locale.get('confirm'), Locale.get('confirmDelete')).then((confirm) => {
			if (confirm) {
				Service.call(ServiceList.repairs.consumable.delete, null, null, {id: consumable.id}, Session.session, (response, xhr) => {
					const index = this.consumables.indexOf(consumable);
					if (index !== -1) {
						this.consumables.splice(index, 1);
					}
				});
			}
		});
	}

	/**
	 * Update the checklist value in the server.
	 */
	public save(): void {
		const data = structuredClone(this.consumables);

		Modal.confirm(Locale.get('confirm'), Locale.get('masterdataConfirm')).then((confirm) => {
			if (confirm) {
				Service.call(ServiceList.repairs.consumable.updateBulk, null, null, data, Session.session, (response, xhr) => {
					Modal.toast(Locale.get('updatedSuccessfully'));
					App.navigator.pop();
				});
			}
		});
	}

}
