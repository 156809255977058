import {ValidationUtils} from './validation-utils';

/**
 * URL utils class is used to manage URL data and URL processing.
 */
export class UrlUtils {
	/**
	 * Get the query parameter from the browser URL.
	 *
	 * Try to parse each value read into a valid JS format.
	 *
	 * @param query - Query parameters after the URL usually in the format ?a=x&b=y.
	 * @returns Object with parameters read from the URL.
	 */
	public static getQueryParameters(query: string): any {
		const values = query.substring(1).split('&');
		const parameters = {};

		for (let i = 0; i < values.length; i++) {
			const pair = values[i].split('=');
			if (pair.length > 1) {
				const name = decodeURIComponent(pair[0]).replace(new RegExp('"', 'g'), '');
				let value: any = decodeURIComponent(pair[1]).replace(new RegExp('"', 'g'), '');

				if (value === 'true' || value === 'false') {
					value = value === 'true';
				} else if (value === 'null') {
					value = null;
				} else if (ValidationUtils.validNumber(value)) {
					value = Number.parseFloat(value);
				}

				parameters[name] = value;
			}
		}

		return parameters;
	}

	/**
	 * Generate form URL encoded data from JSON object.
	 *
	 * @param obj - Object to be encoded.
	 * @returns Form like data to be sent to the server.
	 */
	public static encodeFormURL(obj: any): string {
		const data = [];

		for (const i in obj) {
			data.push(encodeURIComponent(i) + '=' + encodeURIComponent(obj[i]));
		}

		return data.join('&').replace(/%20/g, '+');
	}

}
