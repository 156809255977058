import {Color} from 'three';

/**
 * Utils to access and manipulate CSS attributes on the application.
 */
export class CSSUtils {
	/**
	 * Get a CSS variable value from the page.
	 *
	 * @param name - name of the CSS variable.
	 * @returns Value of the CSS variable if found, null otherwise.
	 */
	public static getVariable(name: string): any {
		return getComputedStyle(document.body).getPropertyValue(name);
	}

	/**
	 * Get a CSS variable that contains a color value and add alpha value to it.
	 *
	 * @param name - Name of the variable.
	 * @param alpha - Alpha values from 0 to 1.
	 */
	public static getVariableWithAlpha(name: string, alpha: number): string {
		const cssVar = CSSUtils.getVariable(name);
		const color: Color = CSSUtils.parseColor(cssVar);

		const alphaHex = ('00' + Math.floor(alpha * 255).toString(16)).slice(-2).toUpperCase();
		const colorHex = ('000000' + color.getHex().toString(16)).slice(-6).toUpperCase();

		return '#' + colorHex + alphaHex;
	}

	/**
	 * Parse any CSS color into a color object.
	 *
	 * @param css - Input CSS color
	 */
	public static parseColor(css: string): Color {
		// @ts-ignore
		css = css.replaceAll(' ', '');

		const rgbRegEx = /rgb\(([0-9]+)\s*,\s*([0-9]+)\s*,\s*([0-9]+)\)/g;
		const rgb = rgbRegEx.exec(css);
		if (rgb !== null) {
			return new Color(Number.parseInt(rgb[1]) / 255, Number.parseInt(rgb[2]) / 255, Number.parseInt(rgb[3]) / 255);
		}

		const rgbaRegEx = /rgb\(([0-9]+)\s*,\s*([0-9]+)\s*,\s*([0-9]+)\s*,\s*[0-9]+\)/g;
		const rgba = rgbaRegEx.exec(css);
		if (rgba !== null) {
			return new Color(Number.parseInt(rgba[1]) / 255, Number.parseInt(rgba[2]) / 255, Number.parseInt(rgba[3]) / 255);
		}

		const rgbPercRegEx = /rgb\(([0-9]+)%\s*,\s*([0-9]+)%\s*,\s*([0-9]+)%\)/g;
		const rgbPerc = rgbPercRegEx.exec(css);
		if (rgbPerc !== null) {
			return new Color(Number.parseInt(rgbPerc[1]) / 100, Number.parseInt(rgbPerc[2]) / 100, Number.parseInt(rgbPerc[3]) / 100);
		}

		return new Color(css);
	}

}
