import {Resource} from '../../resource';
import {UUID, UUIDIdentification} from '../../uuid';

/**
 * Format of the master settings associated to the DL50 project.
 */
export class DL50MasterSettings extends UUIDIdentification {
	/**
	 * UUID of the default company for DL50 inspections.
	 */
	public companyUuid: UUID = null;

	/**
	 * The project code.
	 */
	public projectCode: number = null;

	/**
	 * The inspections counter.
	 */
	public inspectionsCounter: UUID = null;

	/**
	 * The template to be used on inspections export report. If none, use the one hardcoded.
	 */
	public reportTemplate: Resource = null;

	/**
	* Parse JSON data received from the API into an object.
	*
	* @param data - Object received from API.
	* @returns Object of the correct type, built with parsed data received.
	*/
	public static parse(data: any): DL50MasterSettings {
		const settings = new DL50MasterSettings();

		settings.companyUuid = data.companyUuid;
		settings.projectCode = data.projectCode;
		settings.inspectionsCounter = data.inspectionsCounter;
		settings.reportTemplate = data.reportTemplate;
		
		return settings;
	}
}
