<div class="uno-list-item" [ngStyle]="{'cursor': this.clickable ? 'pointer': 'auto', 'background-color': this.clickable ? 'none !important' : 'unset'}">
	<div>
		<ng-content select="uno-list-item-icon"></ng-content>
	</div>

	<div style="flex-basis: 100%; min-width: 0px;">
		<ng-content select="uno-list-item-label"></ng-content>
	</div>

	<div style="margin: 10px; font-size: 23px;">
		<ng-content></ng-content>
	</div>
</div>
