import {Component, Input, ViewEncapsulation} from '@angular/core';
import {NgClass, NgStyle} from '@angular/common';
import {CssNgStyle} from '../../../utils/css-ng-style';

/**
 * UNO badges can be used to display counters.
 */
@Component({
	selector: 'uno-badge',
	templateUrl: './uno-badge.component.html',
	styleUrls: ['./uno-badge.component.scss'],
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [NgClass, NgStyle]
})
export class UnoBadgeComponent {
	/**
	 * Indicates if the button is disabled.
	 */
	@Input()
	public disabled: boolean = false;

	/**
	 * Value stored in the badge.
	 */
	@Input()
	public value: number = null;

	/**
	 * Maximum value that can be displayed before simplifying to "max+".
	 */
	@Input()
	public max: number = Infinity;

	/**
	 * Style of the element.
	 */
	@Input()
	public ngStyle: CssNgStyle = {};

	/**
	 * Get value to display on the badge.
	 */
	public displayValue(): string {
		if (!this.value) {
			return '';
		}

		return this.max < this.value ? this.max + '+' : this.value.toString();
	}
}
