<div class="ion-padding-vertical">
	@if (this.value !== null) {
		<audio controls src="{{this.audioUrl}}"></audio>
		@if (!this.disabled) {
			<ion-button mode="ios" (click)="this.writeValue(null)" expand="block" fill="outline">
				<ion-label slot="end">{{'delete' | translate }}</ion-label>
			</ion-button>
			<ion-button mode="ios" (click)="this.selectFile()" expand="block" fill="outline">
				<ion-label slot="end">{{'selectFile' | translate }}</ion-label>
			</ion-button>
		}
	}
	@if (!this.value) {
		@if (this.disabled) {
			<ion-icon name="musical-notes" style="font-size: 70px; opacity: 0.4;"></ion-icon>
		}
		@if (!this.disabled) {
			<ion-icon (click)="this.selectFile();" name="musical-notes" style="cursor: pointer; font-size: 70px;"></ion-icon>
		}
	}
</div>
