<uno-text [ngStyle]="{position: 'absolute', display: 'block', top: '0px', left: '0px', width: '100%'}" ngClass="welcome">{{'welcome' | translate}}</uno-text>
<uno-text [multiline]="true" [ngStyle]="{position: 'absolute', top: '20px', left: '0px', width: '100%', height: '100px'}" ngClass="credentials">{{'enterCredentials' | translate}}</uno-text>

<div [ngStyle]="{position: 'absolute', display: 'block', top: '100px', width: '100%', height: '300px', left: '0px'}">
	<form autocomplete="on">
		<!-- Email -->
		<uno-text [ngStyle]="{position: 'absolute', display: 'block', top: '0px', width: '100%'}" ngClass="input">{{'email' | translate}}</uno-text>
		<uno-input [ngStyle]="{position: 'absolute', display: 'block', top: '15px', width: '100%'}" [(ngModel)]="email" [ngModelOptions]="{standalone: true}" placeholder="{{'email' | translate}}" type="email"></uno-input>

		<!-- Password -->
		<uno-text [ngStyle]="{position: 'absolute', display: 'block', top: '50px', width: '100%'}" ngClass="input">{{'password' | translate}}</uno-text>
		<uno-input ngClass="password-padding" [ngStyle]="{position: 'absolute', display: 'block', top: '65px', width: '100%'}" [(ngModel)]="password" [ngModelOptions]="{standalone: true}" placeholder="{{'password' | translate}}" type="password"></uno-input>
	</form>
</div>

<!-- Submit -->
<uno-button [ngStyle]="{position: 'absolute', display: 'flex', top: '170px', width: '100%'}" (click)="this.login();" [expand]="true">{{'login' | translate}}</uno-button>

<!-- Back -->
<div [ngStyle]="{position: 'absolute', display: 'block', top: '400px', width: '100%', height: '40px', cursor: 'pointer'}">
	<uno-text alignment="center" ngClass="recover" (click)="this.recoverPassword();">{{'forgotPassword' | translate}}</uno-text>
</div>
