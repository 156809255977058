import {NavigationEnd, NavigationStart, Router} from '@angular/router';
import {inject, Injectable, signal, WritableSignal} from '@angular/core';
import {Environment} from '../../environments/environment';
import {UrlUtils} from '../utils/url-utils';
import {LocalStorage} from '../utils/local-storage';
import {ObjectUtils} from '../utils/object-utils';

/**
 * Navigation node stores a step in the navigation path alongside its data.
 */
export class NavigationNode {
	/**
	 * Full route of the navigation step.
	 */
	public route: string = '';

	/**
	 * Data attached to the navigation node (optional).
	 */
	public data: any = null;

	/**
	 * Title of the screen set on navigation (optional).
	 */
	public title: string = '';

	public constructor(route: string, data?: any, title?: string) {
		this.route = route;
		this.data = data ? data : null;
		this.title = title || '';
	}

	/**
	 * Compare two navigation nodes for route and data.
	 *
	 * Title content is not analysed.
	 *
	 * @param node - Other node to compare against.
	 */
	public equals(node: NavigationNode): boolean {
		return this.route === node.route && ObjectUtils.equal(this.data, node.data);
	}
}

/**
 * The navigator object is used to navigate the AppRoutes of the application.
 *
 * It encapsulates a single navigator object created from the main root of the app.
 *
 * Also allows navigation to be done in a stack fashion and send data between pages.
 */
@Injectable({providedIn: 'root'})
export class Navigation {
	/**
	 * Angular router service.
	 */
	public router: Router;

	/**
	 * The stack of navigation routes ordered.
	 *
	 * Last route is on top of the stack (last object in the array).
	 */
	public route: NavigationNode[] = [];

	/**
	 * Navigation title to be displayed in the navigation bar.
	 *
	 * This value can be changed by routes or manually by code.
	 */
	public title: WritableSignal<string> = signal('');

	/**
	 * Navigation history to display under the title.
	 */
	public breadcrumbs: WritableSignal<NavigationNode[]> = signal([]);

	/**
	 * History limit of the navigation path.
	 */
	public static breadcrumbLength: number = 5;

	/**
	 * Size limit for navigation history stored in local storage.
	 */
	public static historyLength: number = 10;

	public constructor() {
		this.router = inject(Router);

		this.route = [];

		this.router.events.subscribe((val: any) => {
			if (val instanceof NavigationStart) {
				if (val.navigationTrigger === 'popstate') {
					const url = val.url;

					// Back one step
					if (this.route.length > 1 && this.route[this.route.length - 2].route === url) {
						this.route.pop();
					// Reset stack
					} else {
						this.route = [new NavigationNode(url)];
					}
				}
			} else if (val instanceof NavigationEnd) {
				if (this.route.length === 0) {
					this.restoreFromURL();
				}
			}
		});
	}

	/**
	 * Get the current navigation node.
	 */
	public current(): NavigationNode {
		return this.route.length > 0 ? this.route[this.route.length - 1] : null;
	}

	/**
	 * Store the navigator state into local storage.
	 */
	public store(): void {
		LocalStorage.set('navigation', this.route.slice(this.route.length - Navigation.historyLength));
	}

	/**
	 * Restore the navigator state from data in local storage.
	 */
	public load(): void {
		if (LocalStorage.exists('navigation')) {
			this.route = LocalStorage.get('navigation');
		}

		if (!Environment.PRODUCTION) {
			console.log('EQS: Navigation route restored.', this.route);
		}

		this.updateBreadcrumbs();
	}

	/**
	 * Update the router to match the currently selected route.
	 *
	 * Takes the route from the stack.
	 */
	public async update(): Promise<void> {
		if (this.route.length > 0) {
			const route = this.current();

			const success = await this.router.navigate([route.route], route.data ? {queryParams: route.data} : undefined);
			if (!success) {
				throw new Error('Failed to navigate to ' + route.route + '.');
			}

			if (route.title) {
				this.title.set(route.title);
			}
		}

		this.updateBreadcrumbs();
	}

	/**
	 * Navigate to another screen.
	 *
	 * @param route - Name of the route path.
	 * @param data - Data to be sent to that path.
	 * @param title - Title of the screen (can also be set on the screen code via the setTitle() method).
	 */
	public async navigate(route: string, data?: any, title?: string): Promise<void> {
		if (this.route.length === 0) {
			this.restoreFromURL();
		}

		const node = new NavigationNode(route, data, title);

		if (this.route.length > 1) {
			const current = this.current();
			if (node.equals(current)) {
				if (!Environment.PRODUCTION) {
					console.log('EQS: Route is the same' + route + '.', data, title);
				}
				return;
			}
		}

		if (!Environment.PRODUCTION) {
			console.log('EQS: Navigated to ' + route + '.', data, title);
		}

		this.route.push(node);
		await this.update();
	}

	/**
	 * Update the breadcrumbs (navigation history) relative to the nearest path to the menu.
	 *
	 * The information update in this method is used for the menu GUI.
	 */
	public updateBreadcrumbs(): void {
		const breadcrumbs: NavigationNode[] = [];

		for (let i = this.route.length - 1; i >= 0 && i > this.route.length - Navigation.breadcrumbLength; i--) {
			const route = this.route[i];
			breadcrumbs.unshift(route);

			// Assume a two-step as the root and stop there (e.g. /menu/asset or /menu/monitor)
			const url = route.route.split('/');
			if (url.length === 2) {
				break;
			}
		}

		this.breadcrumbs.set(breadcrumbs);

		const title = this.current()?.title || null;
		if (title) {
			this.title.set(title);
		}
	}

	/**
	 * Rollback to a spefic route.
	 * 
	 * @param route - Route in the navigation stack.
	 */
	public rollback(route: NavigationNode): void {
		if (this.route.indexOf(route) === -1) {
			throw new Error('Route does not exist in the navigation stack.');
		}

		while (this.current() !== route) {
			this.pop();
		}
	}

	/**
	 * Return back one screen, try to get the last route from the stack.
	 *
	 * If no route available on the stack, remove the last part of the URL (if possible).
	 */
	public pop(): void {
		if (this.route.length > 1) {
			this.route.pop();
			this.update();
		} else {
			if (!Environment.PRODUCTION) {
				console.warn('EQS: Cannot navigate back.');
			}

			this.navigate('/');
		}
	}

	/**
	 * Read the route from the URl of the browser and return it as array of subdirectories.
	 */
	public static readRoute(): {url: string, path: string, data: string} {
		const href = window.location.href;
		const url = new URL(href);


		return {path: url.pathname, data: url.search, url: url.href};
	}

	/**
	 * Compare it against the current URL on the stack and override if necessary.
	 */
	public restoreFromURL(): void {
		const route = Navigation.readRoute();
		const url = route.path + route.data;

		if (!Environment.PRODUCTION) {
			console.log('EQS: Route Restored from URL', url, route);
		}

		// Check if route is correct
		if (this.route.length > 0) {
			const node = this.route[this.route.length - 1];
			if (url !== node.route) {
				this.route = [new NavigationNode(url)];
			}
		} else {
			this.route = [new NavigationNode(url)];
		}
	}

	/**
	 * Check if the navigator has a name in the middle of its route.
	 *
	 * @param name - Name to look for.
	 * @returns True if the name is in the route.
	 */
	public has(name: string): boolean {
		if (this.route.length > 0) {
			const route = this.current().route;
			return route.indexOf(name) !== -1;
		}

		return false;
	}

	/**
	 * Get the data for the current screen, data is passed alongside the route.
	 *
	 * If no route data is available it checks for URL query data.
	 */
	public getData(): any {
		let data = null;

		if (this.route.length > 0) {
			data = this.current().data;
		}

		if (!data) {
			data = UrlUtils.getQueryParameters(location.search);
			if (Object.keys(data).length === 0) {
				data = null;
			}
		}

		return data;
	}

	/**
	 * Set the data for the current screen.
	 */
	public setData(data: any): void {
		if (this.route.length === 0) {
			return;
		}

		this.route[this.route.length - 1].data = data;
	}

	/**
	 * Set the title of the current screen.
	 *
	 * Update the title associated with the current route.
	 */
	public setTitle(title: string): void {
		if (this.route.length === 0 || title === null) {
			return;
		}

		this.route[this.route.length - 1].title = title;
		this.title.set(title);
	}
}
