import {UUIDIdentification} from '../../uuid';
import {InspectionFormField} from './inspection-form-field';

/**
 * Inspection form contains the structure of a inspection form that can be attached fot a inspection workflow step.
 *
 * These forms are composed of fields that may be of multiple data types.
 */
export class InspectionForm extends UUIDIdentification {
	/**
	 * Name of the inspection form.
	 */
	public name: string = '';

	/**
	 * Description of the form.
	 */
	public description: string = '';

	/**
	 * Fields that compose this form.
	 *
	 * These fields should be sorted by the order to be shown.
	 */
	public fields: InspectionFormField[] = [];

	/**
	 * Parse the data of an API retrieved and create a Inspection form object.
	 *
	 * @param data - Inspection object to parse data.
	 * @returns Object of the correct type.
	 */
	public static parse(data: any): InspectionForm {
		const form = new InspectionForm();

		form.uuid = data.uuid;
		form.createdAt = new Date(data.createdAt);
		form.updatedAt = new Date(data.updatedAt);
		form.name = data.name;
		form.description = data.description;

		if (data.fields instanceof Array) {
			form.fields = InspectionFormField.parseArray(data.fields);
		}

		return form;
	}

	/**
	 * Update the index of the fields that compose this form.
	 */
	public updateFieldIndex(): void {
		for (let i = 0; i < this.fields.length; i++) {
			this.fields[i].index = i;
		}
	}
}
