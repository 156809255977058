import {Resource} from '../../resource';
import {AtexInspectionField} from './atex-inspection-field';

export type AtexInspectionChecklists = {[field: string]: boolean};

export type AtexInspectionResponses = {
	inspector: {[field: string]: AtexInspectionField},
	backoffice: {[field: string]: AtexInspectionField}
};

/**
 * Data of the inspection stored as JSON in the server.
 */
export class AtexInspectionData {
	/**
	 * How many times this inspection has been rejected by the backoffice.
	 */
	public rejectedCount: number = 0;

	/**
	 * Message sent to the inspector by the backoffice when the inspection gets rejected.
	 */
	public rejectionMessage: string = '';

	/**
	 * Notify the inspector that the inspection has been rejected by the backoffice.
	 */
	public rejectionNotification: boolean = false;
	
	/**
	 * Message sent to the supervisor.
	 */
	public supervisorMessage: string = '';

	/**
	 * Notes of the inspection.
	 */
	public notes: string = '';

	/**
	 * Photos for the notes of the inspection.
	 */
	public photo: Resource[] = [];

	/**
	 * Video for the notes of the inspection.
	 */
	public video: Resource = null;

	/**
	 * Audio recording attached to the inspection.
	 */
	public audio: Resource = null;

	/**
	 * Object contains the state of the inspections (selected or not selected).
	 */
	public inspections: AtexInspectionChecklists = {};

	/**
	 * Response to the combined form fields created from the selected inspections.
	 */
	public responses: AtexInspectionResponses = {
		inspector: {},
		backoffice: {}
	};

	/**
	 * Parse data field of the Atex inspection.
	 *
	 * @param data - Data stored in the database.
	 * @returns Parsed Atex inspection data object.
	 */
	public static parse(data: any): AtexInspectionData {
		const inspectionData = new AtexInspectionData();

		inspectionData.rejectedCount = data.rejectedCount;
		inspectionData.rejectionMessage = data.rejectionMessage;
		inspectionData.rejectionNotification = data.rejectionNotification === true;
		inspectionData.supervisorMessage = data.supervisorMessage;
		inspectionData.notes = data.notes;
		inspectionData.photo = Resource.parseArray(data.photo);
		inspectionData.video = Resource.parse(data.video);
		inspectionData.audio = Resource.parse(data.audio);
		inspectionData.inspections = data.inspections || {};

		// Parse responses
		for (const r in data.responses.inspector) {
			if (data.responses.inspector[r]) {
				inspectionData.responses.inspector[r] = AtexInspectionField.parse(data.responses.inspector[r]);
			}
		}
		for (const r in data.responses.backoffice) {
			if (data.responses.backoffice[r]) {
				inspectionData.responses.backoffice[r] = AtexInspectionField.parse(data.responses.backoffice[r]);
			}
		}

		return inspectionData;
	}


}
