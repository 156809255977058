<div class="uno-tab-container">

	<div class="uno-tab-tabs-container">
		<!-- The title container -->
		<div class="uno-tab-title-container" cdkDropList cdkDropListOrientation="horizontal" (cdkDropListDropped)="drop($event)">
			@for (tab of this.tabSections.toArray(); track tab) {
				<span class="uno-tab-section-title" [ngClass]="{'uno-tab-section-active': tab.active}" (click)="this.toggleTabs(tab.uuid)" cdkDrag [cdkDragDisabled]="this.dragDisabled" cdkDragBoundary=".uno-tab-title-container" title="{{tab.title}}">
					@if (tab.icon !== '') {
						<uno-icon style="width:28px;height:28px" [src]="tab.icon" [color]="tab.iconColor" [width]="28" [height]="28"></uno-icon>
					}
					{{tab.title}}
				</span>
			}
		</div>
	</div>

	<!-- The tabs container -->
	<div class="uno-tab-section-content-container">
		<ng-content></ng-content>
	</div>

</div>
