/**
 * Random utils contains tools for randomized data generation.
 *
 * Useful to create mock data for tests.
 */
export class RandomUtils {
	/**
	 * List of characters used to generate random text.
	 */
	private static chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';

	/**
	 * Get a random value from an object or enum type.
	 * 
	 * @param obj - Enumerate or object to get random value from.
	 * @param skipStart - Number of positions to be skipped at the beginning of the object or enumerate.
	 * @param skipEnd - Number of positions to be skipped at the end of the object or enumerate.
	 * @returns Random value present in the object provided.
	 */
	public static randomValue(obj: any, skipStart: number = 0, skipEnd: number = 0): any {
		const types = Object.values(obj);
		return types[RandomUtils.randomNumber(skipStart, types.length - skipEnd)];
	}

	/**
	 * Generate a random boolean.
	 *
	 * @returns Random boolean value.
	 */
	public static randomBool(): boolean {
		return Math.random() > 0.5;
	}

	/**
	 * Generate a random number between a range with "decimalDigits" decimal digits
	 *
	 * @param min - Minimum value (inclusive).
	 * @param max - Maximum value (exclusive).
	 * @param decimalDigits - The number of decimal digits.
	 * @returns Random number value between min and max with "decimalDigits" decimal digits.
	 */
	public static randomNumber(min: number, max: number, decimalDigits: number = 0): number {
		if (decimalDigits !== 0 ) {
			return parseFloat((Math.random() * (max - min) + min).toFixed(decimalDigits));
		}
		return Math.floor(Math.random() * (max - min) + min);
	}
	
	/**
	 * Generate a random string with a certain length.
	 *
	 * @param length - Length of the string to be generated.
	 */
	public static randomString(length: number): string {
		let str = '';

		while (str.length < length) {
			str += RandomUtils.chars[Math.floor(Math.random() * RandomUtils.chars.length)];
		}

		return str;
	}


	/**
	 * Generate random text with a specific number of words.
	 *
	 * @param words - How many words to be considered in the text.
	 */
	public static randomText(words: number): string {
		let str = '';

		for (let i = 0; i < words; i++) {
			str += RandomUtils.randomString(RandomUtils.randomNumber(3, 8, 0));
			if (i + 1 < words) {
				str += ' ';
			}
		}

		return str + '.';
	}

	/**
	 * Generate a random email used to create test data with the format "XXXXX\@XXXX.XXX"
	 * 
	 * @returns random email
	 */
	public static randomEmail(): string {
		return (this.randomString(7) + '@' + this.randomString(7) + '.' + this.randomString(3)).toLowerCase( );
	}
}
