import {Component, OnInit, ViewChildren} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';

import {UnoFormModule} from 'src/app/components/uno-forms/uno-form.module';
import {generateUUID} from 'src/app/models/uuid';
import {UnoFormComponent} from 'src/app/components/uno-forms/uno-form/uno-form.component';
import {App} from '../../../../../../app';
import {ScreenComponent} from '../../../../../../components/screen/screen.component';
import {UserPermissions} from '../../../../../../models/users/user-permissions';
import {Modal} from '../../../../../../modal';
import {Locale} from '../../../../../../locale/locale';
import {UnoButtonComponent} from '../../../../../../components/uno/uno-button/uno-button.component';
import {UnoContentComponent} from '../../../../../../components/uno/uno-content/uno-content.component';
import {RepairTemporaryType} from '../../../master-data/repair-temporary-type';
import {RepairTemporaryTypeService} from '../../../services/repair-temporary-types.service';
import {RepairTemporaryTypeLayout} from './repair-temporary-type-layout';

@Component({
	selector: 'repair-temporary-types-page',
	templateUrl: 'repair-temporary-types.page.html',
	styleUrls: ['./repair-temporary-types.page.css'],
	standalone: true,
	imports: [UnoContentComponent, UnoFormModule, UnoButtonComponent, TranslateModule]
})

export class RepairTemporaryTypesPage extends ScreenComponent implements OnInit {
	@ViewChildren('form')
	public forms: UnoFormComponent[] = null;

	public layout: any = RepairTemporaryTypeLayout;

	public permissions = [UserPermissions.REPAIR_TEMPORARY_TYPES_EDIT];

	/**
	 * List of repair temporary types available in database.
	 */
	public types: RepairTemporaryType[] = [];

	public async ngOnInit(): Promise<void> {
		super.ngOnInit();
		
		App.navigator.setTitle('repairType');

		this.types = (await RepairTemporaryTypeService.list()).types;
	}

	/**
	 * Delete the temporary type from the list and database.
	 *
	 * @param type - Element to be deleted from list.
	 */
	public async delete(type: RepairTemporaryType): Promise<void> {
		const confirm: boolean = await Modal.confirm(Locale.get('confirm'), Locale.get('confirmDelete'));
		if (confirm) {
			if (type.uuid) {
				await RepairTemporaryTypeService.delete(type.uuid);
			}

			const index = this.types.indexOf(type);
			if (index !== -1) {
				this.types.splice(index, 1);
			}
		}
	}

	/**
	 * Add a new repair temporary type to the list.
	 */
	public add(): void {
		this.types.push(new RepairTemporaryType());
	}

	/**
	 * Update the temporary repair values in the server.
	 */
	public async save(stayOnPage: boolean = false): Promise<void> {
		// Check if all the forms are filled.
		for (const form of this.forms) {
			if (!form.requiredFilled()) {
				await Modal.alert(Locale.get('error'), Locale.get('requiredFieldsError'));
				return;
			}
		}

		// Set the UUIDs of the new temporary repair types to be created on API
		for (const type of this.types) {
			if (type.uuid === null) {
				type.uuid = generateUUID();
			}
		}

		await RepairTemporaryTypeService.updateBulk(this.types);

		Modal.toast(Locale.get('updatedSuccessfully'));

		if (!stayOnPage) {
			App.navigator.pop();
		}
	}
}
