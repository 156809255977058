import {Circle, Node} from 'escher.js/build/escher.module.js';

/**
 * Destructible nodes have a button which allows the user to destruct them.
 *
 * When the node gets destroyed it automatically gets removed from the graph.
 */
export class DestroyableNode extends Node {
	private closeButton: Circle;

	public constructor() {
		super();

		this.closeButton = new Circle();
		this.closeButton.serializable = false;
		this.closeButton.layer = 2;
		this.closeButton.radius = 8;
		this.closeButton.onButtonDown = () => {
			// @ts-ignore
			this.destroy();
		};
		this.closeButton.draw = function(context, viewport, canvas): void {
			Circle.prototype.draw.call(this, context, viewport, canvas);

			const size = this.radius * 0.5;
			context.beginPath();
			context.moveTo(-size, -size);
			context.lineTo(size, size);
			context.moveTo(-size, size);
			context.lineTo(size, -size);
			context.stroke();
		};
		// @ts-ignore
		this.add(this.closeButton);
	}

	public onUpdate(): void {
		// @ts-ignore
		this.closeButton.position.set(this.box.max.x, this.box.min.y);

		super.onUpdate();
	}
}
