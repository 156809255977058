import {Session} from 'src/app/session';
import {ProgressBar} from 'src/app/progress-bar';
import {Locale} from 'src/app/locale/locale';
import {Modal} from 'src/app/modal';
import {XlsxUtils} from 'src/app/utils/xlsx-utils';
import {Service} from '../../../../http/service';
import {ServiceList} from '../../../../http/service-list';
import {UUID} from '../../../../models/uuid';
import {InspectionService} from '../../services/inspection.service';
import {InspectionProjectService} from '../../services/inspection-project.service';

/**
 * Tools to manipulate inspections in bulk.
 */
export class InspectionTools {
	/**
	 * Update all inspection available in the system.
	 * 
	 * Force gap analysis process to run to all inspections.
	 */
	public static async runGapAnalysis(): Promise<void> {
		const count = 100;

		const progress = new ProgressBar();
		progress.show();

		const total = await InspectionProjectService.count();
		progress.update(Locale.get('processingData'), 0);

		const errors: {uuid: UUID, name: string, description: string, error: Error}[] = [];

		let projectFrom = 0;
		while (true) {
			try {
				const projects = await InspectionProjectService.list({from: projectFrom, count: count}, true);
				progress.update(Locale.get('processingData'), projectFrom / total);
				for (const project of projects.projects) {
					if (!project.generatesGaps) {
						continue;
					}

					let inspectionFrom = 0;
					while (true) {
						const list = await InspectionService.listDetailed({project: project.uuid, from: inspectionFrom, count: count, fetchData: true}, true);

						for (const i in list.inspections) {
							try {
								await Service.fetch(ServiceList.inspection.update, null, null, list.inspections[i], Session.session, true, false);
							} catch (e) {
								errors.push({
									uuid: list.inspections[i].uuid,
									name: list.inspections[i].name,
									description: list.inspections[i].description,
									error: e
								});
							}	
						}
						
						if (!list.hasMore) {
							break;
						}
						inspectionFrom += count;
					}
				}
				
				if (!projects.hasMore) {
					break;
				}
				projectFrom += count;
			} catch (e) {
				Modal.alert(Locale.get('error'), Locale.get('errorProcessingData'));
				progress.destroy();
			}
		}

		if (errors.length > 0) {
			Modal.alert(Locale.get('error'), Locale.get('errorProcessingSomeFailed'));
			XlsxUtils.writeFileObject(errors, 'errors.xlsx');
		}

		Modal.toast(Locale.get('updatedSuccessfully'));
		progress.destroy();
	}
}
