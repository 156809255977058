import {Component, OnInit} from '@angular/core';
import {App} from '../../../../app';
import {ScreenComponent} from '../../../../components/screen/screen.component';
import {MenuCardsOptions, MenuCardsComponent} from '../../../../components/layout/menu-cards/menu-cards.component';
import {UnoContentComponent} from '../../../../components/uno/uno-content/uno-content.component';

@Component({
	selector: 'inspection-page',
	templateUrl: './inspection.page.html',
	standalone: true,
	imports: [UnoContentComponent, MenuCardsComponent]
})
export class InspectionPage extends ScreenComponent implements OnInit {
	public permissions = [];

	/**
	 * List of options in this menu.
	 */
	public options: MenuCardsOptions[] = [
		{
			label: 'forms',
			data: {},
			permissions: [],
			icon: 'document-text-outline',
			route: '/menu/inspection/form/list',
			badge: null
		},
		{
			label: 'workflows',
			data: {},
			permissions: [],
			icon: 'git-network-outline',
			route: '/menu/inspection/workflow/list',
			badge: null
		},
		{
			label: 'projects',
			data: {},
			permissions: [],
			icon: 'assets/icons/material/source/outline.svg',
			route: '/menu/inspection/project/list',
			badge: null
		},
		{
			label: 'inspections',
			data: {},
			permissions: [],
			icon: 'assets/components/menu/inspections.svg',
			route: '/menu/inspection/list',
			badge: null
		}
	];

	public ngOnInit(): void {
		super.ngOnInit();
		
		App.navigator.setTitle('inspections');
	}
}
