<uno-content [borders]="true">
	@if (this.data) {
		@for (i of this.data | objectKeys; track i) {
			<uno-title>{{i}}</uno-title>
			<uno-form [layout]="this.layout" [object]="this.data[i]"></uno-form>
		}
	}

	<div style="display: flex; flex-direction: row-reverse; gap: 5px;" class="ion-padding">
		<uno-button (click)="save()" color="error">{{'save' | translate}}</uno-button>
		<uno-button (click)="save(true)">{{'update' | translate}}</uno-button>
		<uno-button (click)="export()">{{'export' | translate}}</uno-button>
		<uno-button (click)="import()">{{'import' | translate}}</uno-button>
	</div>
</uno-content>
