import {UUID} from '../../uuid';

/**
 * A channel defines the differents measurements of an experiment.
 */
export class Channel {

	/**
	 * UUID of the channel.
	 */
	public uuid: UUID = null;

	/**
	 * Name of the channel.
	 */
	public name: string = '';

	/**
	 * Description of the channel.
	 */
	public offset: number = 0;	

	/**
	 * UUID of the experiment associated.
	 */
	public experimentUuid: UUID = null;

	/**
	 * Parse the data of an API retrieved channel object.
	 *
	 * @param data - Channel object to parse data
	 */
	public static parse(data: any): Channel {
		const channel = new Channel();

		channel.uuid = data.uuid;
		channel.name = data.name;
		channel.offset = data.offset;
		channel.experimentUuid = data.experimentUuid;

		return channel;
	}
}
