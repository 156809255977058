import {DateFrequency} from '../date-frequency';
import {UUID} from '../uuid';
import {CalendarEventSubtypes, CalendarEventTypes} from './calendar-event-actions';
import {CalendarEventPeriodicityModes} from './calendar-event-periodicity-modes';
import {CalendarEventTriggerDirection} from './calendar-event-trigger-direction';

export class CalendarEvent {
	/**
	 * Uuid of the calendar event.
	 */
	public uuid: UUID = null;

	/**
	 * Name of the calendar event.
	 */
	public name: string = '';

	/**
	 * Description of the calendar event.
	 */
	public description: string = '';

	/**
	 * Type of the calendar event.
	 */
	public actionType: number = CalendarEventTypes.ASSET;

	/**
	 * Subtype of the calendar event.
	 */
	public actionSubtype: number = CalendarEventSubtypes.ASSET_ATEX_INSPECTION;

	/**
	 * Date of the calendar event.
	 */
	public date: Date = null;

	/**
	 * Creation date of the calendar event.
	 */
	public createdAt: Date = null;

	/**
	 * Duration of the calendar event.
	 */
	public duration: DateFrequency = new DateFrequency();

	/**
	 * Calendar events can have a date defined or point to other calendar event date.
	 */
	public triggerEventUuid: UUID = null;

	/**
	 * The offset of the date regarding start date
	 */
	public triggerEventOffset: DateFrequency = new DateFrequency();

	/**
	 * Depending on this value, the event will happen before, after or at the same time of another event.
	 */
	public triggerEventDirection: number = CalendarEventTriggerDirection.AFTER;

	/**
	 * Indicates if the calendar event should repeat or not.
	 */
	public periodic: boolean = false;

	/**
	 * Indicates the intervals when periodic is defined as true
	 */
	public periodicity: DateFrequency = new DateFrequency();

	/**
	 * Indicates the mode periodicity will calculate occurrences(by repetitions or end date).
	 */
	public periodicityMode: number = CalendarEventPeriodicityModes.REPETITIONS;

	/**
	 * Indicates how many times the periodicity will repeat
	 */
	public periodicityRepetition: number = 0;

	/**
	 * End date to consider to calculate occurrences.
	 */
	public periodicityEndDate: Date = null;

	/**
	 * Type of alarms to be sent to the users.
	 */
	public alarmType: number[] = [];

	/**
	 * Alarm offset defines the time before the alarms should be sent to users.
	 */
	public alarmOffset: DateFrequency = new DateFrequency();

	/**
	 * The UUID of the asset.
	 */
	public assetUuid: UUID = null;

	/**
	 * The UUID of the repair
	 */
	public repairUuid: UUID = null;

	/**
	 * The UUID of the inspection project.
	 */
	public inspectionProjectUuid: UUID = null;

	/**
	 * The UUID of the user.
	 */
	public userUuids: UUID[] = [];

	/**
	 * The team UUIDs of the calendar event.
	 */
	public teamUuids: UUID[] = [];

	/**
	 * Parse object data into a CalendarEvent object.
	 *
	 * @param data - Object data to parse.
	 * @returns CalendarEvent object instance built from the received data.
	 */
	public static parse(data: any): CalendarEvent {
		const event = new CalendarEvent();

		event.uuid = data.uuid;
		event.name = data.name;
		event.description = data.description;
		event.actionType = data.actionType;
		event.actionSubtype = data.actionSubtype;
		event.date = data.date;
		event.createdAt = data.createdAt;
		event.duration = DateFrequency.parse(data.duration);
		event.triggerEventUuid = data.triggerEventUuid;
		event.triggerEventOffset = DateFrequency.parse(data.triggerEventOffset);
		event.triggerEventDirection = data.triggerEventDirection;
		event.periodic = data.periodic;
		event.periodicity = DateFrequency.parse(data.periodicity);
		event.periodicityMode = data.periodicityMode;
		event.periodicityRepetition = data.periodicityRepetition;
		event.periodicityEndDate = data.periodicityEndDate;
		event.alarmType = data.alarmType;
		event.alarmOffset = DateFrequency.parse(data.alarmOffset);
		event.assetUuid = data.assetUuid;
		event.repairUuid = data.repairUuid;
		event.inspectionProjectUuid = data.inspectionProjectUuid;
		event.userUuids = data.userUuids || [];
		event.teamUuids = data.teamUuids || [];

		return event;
	}
}
