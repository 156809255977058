/**
 * Service content type indicates the type of the body data sent by the service.
 */
// eslint-disable-next-line no-shadow
export enum ServiceBodyType {
	/**
	 * No data to be sent to the server.
	 */
	NONE,

	/**
	 * Plain text data type.
	 */
	TEXT,

	/**
	 * Form url encoded data type.
	 */
	FORM,

	/**
	 * Multipart form data (used for file upload).
	 *
	 * When this type of data is used the data has to be using a FormData object.
	 */
	FILE_FORM,

	/**
	 * JSON data.
	 */
	JSON
}
