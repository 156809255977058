/**
 * Possible options for form entries.
 *
 * Form layouts are declared in an array where each element contains at least `{type: number, attribute: string, label: string}` values.
 */
export enum UnoFormFieldTypes {
	/**
	 * Simple single line text option.
	 */
	TEXT,

	/**
	 * Number input as text.
	 *
	 * Can include additional `{unit: string}` to specify a unit shown after the value.
	 */
	NUMBER,

	/**
	 * Multi line text box, expands as the user inserts new lines.
	 */
	TEXT_MULTILINE,

	/**
	 * Password text box, the text is hidden.
	 */
	PASSWORD,

	/**
	 * Checkbox style boolean value.
	 */
	CHECKBOX,

	/**
	 * Switch style boolean value.
	 */
	SWITCH,

	/**
	 * Image resource, insert an image file stored in the resource server.
	 */
	IMAGE_RESOURCE,

	/**
	 * Datetime (day, month, year, hour, minutes) values.
	 */
	DATE,

	/**
	 * Email text input value.
	 */
	EMAIL,

	/**
	 * Phone number text input value.
	 */
	PHONE_NUMBER,

	/**
	 * Select one of many options of any data type.
	 *
	 * Has to include an additional `options:[{label: string, value: any}, ...]` in the object.
	 */
	OPTIONS,

	/**
	 * Video resource, video file stored in the resource server.
	 */
	VIDEO_RESOURCE,

	/**
	 * Audio resource, audio file stored in the resource server.
	 */
	AUDIO_RESOURCE,

	/**
	 * Geolocation input value, shows a map to select a position.
	 */
	GEO_POSITION,

	/**
	 * Inspection results are triple state selectors, for OK, NOK and PARTIAL, that can also store notes and files.
	 *
	 * Stored as a number 0:OK, 1:PARTIAL, 2:NOK
	 */
	ATEX_INSPECTION_FIELD,

	/**
	 * Atex level, contains all the Atex label fields required.
	 */
	ATEX_FIELD,

	/**
	 * Select multiple options from a options list of any data type.
	 *
	 * Has to include an additional `options:[{label: string, value: any}, ...]` in the object.
	 */
	OPTIONS_MULTIPLE,

	/**
	 * Array of image resource elements, images can be added, removed and viewed.
	 *
	 * Can receive as input a single image, but always sets to array on write.
	 */
	IMAGE_RESOURCE_MULTIPLE,

	/**
	 * Select multiple assets from a list, with search bar to search and get data from the API.
	 *
	 * Has to include additional `{multiple:true/false, assetType:...}` to indicate if its possible to select multiple assets and their type.
	 */
	ASSET_SELECTOR,

	/**
	 * Date frequency refers to a value in years / months of a frequency at witch something (an action, etc) must be repeated.
	 *
	 * Should be used with DateFrequency objects.
	 */
	DATE_FREQUENCY,

	/**
	 * Select and store multiple files into an object.
	 */
	DOCUMENT_RESOURCE_MULTIPLE,

	/**
	 * Multiple options with search options lazy loaded.
	 *
	 * Required a method to load page and a method to fetch the already selected elements.
	 *
	 * Has to include additional `{multiple:true/false}` attributes.
	 */
	OPTIONS_MULTIPLE_LAZY,

	/**
	 * Color value stored as a CSS string.
	 */
	COLOR,

	/**
	 * QR code value, can be used to scan QR codes or get the code.
	 *
	 * The QR code content is stored as a string.
	 */
	QR,

	/**
	 * Numeric value with input and output units associated the input units can be selected from dropdown.
	 *
	 * They are automatically converted to the output unit value using the UnitConverter utils.
	 *
	 * Has to include a options list of input units (e.g. `[{value: 'kg', label: 'Kg}, ...]`) and a output unit value.
	 */
	NUMBER_UNITS,

	/**
	 * Single document file.
	 */
	DOCUMENT_RESOURCE,

	/**
	 * Editable text array input
	 * 
	 * New items may be added/deleted to/from the list.
	 */
	TEXT_LIST,

	/**
	 * Team selector field.
	 */
	TEAM_SELECTOR,

	/**
	 * Company selector field.
	 */
	COMPANY_SELECTOR,

	/**
	 * NFC tag value. Can also be used to scan NFC tags.
	 */
	NFC,

	/**
	 * User selector field.
	 */
	USER_SELECTOR,

	/**
	 * Options selector with a search input field.
	 *
	 * An additional options object must be set, like `options:[{label: string, value: any}, ...]`.
	 */
	OPTIONS_SEARCH,

	/**
	 * Lateral selector switch that shows the currently selected option.
	 *
	 * An additional options object must be set, like `options:[{label: string, value: number}, ...]`.
	 */
	LATERAL_SELECTOR,

	/**
	 * An array of key value entries to store objects by a key.
	 */
	KEY_VALUE_ARRAY,

	/**
	 * Options list that lists the options given
	 *
	 * An additional options object must be set, like `options:[{label: string, value: any}, ...]`.
	 */
	OPTIONS_LIST,

	/**
	 * Periodicity selector 
	 */
	PERIODICITY_SELECTOR,

	/**
	 * UUID field with a copy function.
	 */
	UUID,

	/**
	 * Form title used to separate principal sections of the form.
	 */
	TITLE,

	/**
	 * Editable objects array input, that uses another form as template.
	 *
	 * Items can be added/deleted to/from the list.
	 */
	REPETITIVE_FORM,

	/**
	 * Composed field made from field provided by another form.
	 * 
	 * Used to edit a object with the fields described.
	 */
	COMPOSED_FIELD,

	/**
	 * Represents a sub section to be edited in the form can be used to edit a sub-object or present attributes in a section.
	 * 
	 * These forms may have a title and/or a subtitle and have a sub-form with a list of fields.
	 *
	 * If the "attribute" is not specified it directly edits the main object if a attribute is specified the sub object is passed to the nested form.
	 */
	SUB_FORM
};
