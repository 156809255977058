import {Injectable} from '@angular/core';
import {Modal} from '../modal';
import {EventManager} from '../utils/event-manager';
import {Locale} from '../locale/locale';

/**
 * Network indicator is used to display toast messages when the network access is modified.
 */
@Injectable({providedIn: 'root'})
export class NetworkIndicatorService {
	/**
	 * Event manager to handle connection status events.
	 */
	public manager: EventManager = new EventManager();

	public constructor() {
		this.manager.add(window, 'online', function(): void {
			Modal.toast(Locale.get('deviceOnline'), 10000, 'success');
		});

		this.manager.add(window, 'offline', function(): void {
			Modal.toast(Locale.get('deviceOffline'), 10000, 'danger');
		});

		this.start();
	}

	/**
	 * Start the network indicator.
	 */
	public start(): void {
		this.manager.create();
	}

	/**
	 * Stop the network indicator events.
	 */
	public destroy(): void {
		this.manager.destroy();
	}
}
