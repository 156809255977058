import {Group, SphereGeometry, Vector3, MeshBasicMaterial, TorusGeometry, Matrix4, Euler, Mesh} from 'three';

/**
 * Orbit marker represents the center of the orbit controls object in the world.
 *
 * Useful for the user to localize itself in the world.
 */
export class OrbitMarker extends Group {
	/**
	 * Ratio between the distance and the scale.
	 */
	public ratio: number = 2e2;

	/**
	 * Minimum distance to apply rescaling to the marker.
	 */
	public minDistance: number = 10.0;

	public constructor() {
		super();

		const torus = new TorusGeometry(10.0, 0.5, 32, 32);
		const mat = new Matrix4();
		mat.makeRotationFromEuler(new Euler(Math.PI / 2, 0.0, 0.0));
		torus.applyMatrix4(mat);

		const torusMaterial = new MeshBasicMaterial({
			color: 0xFF0000,
			transparent: true,
			opacity: 0.4
		});

		this.add(new Mesh(torus, torusMaterial));

		const sphere = new SphereGeometry(3.0, 32, 32);

		const sphereMaterial = new MeshBasicMaterial({
			color: 0xFFFFFF,
			transparent: true,
			opacity: 0.6
		});

		this.add(new Mesh(sphere, sphereMaterial));
	}

	/**
	 * Update the orbit marker position and scale.
	 */
	public update(position: Vector3, distance: number): void {
		const scale = (distance > this.minDistance ? distance : this.minDistance) / this.ratio;

		this.position.copy(position);
		this.scale.set(scale, scale, scale);

		this.updateMatrix();
	}
}
