import {Component, Input, ViewEncapsulation} from '@angular/core';
import {IonicModule} from '@ionic/angular';
import {NgStyle} from '@angular/common';
import {CssNgStyle} from '../../../utils/css-ng-style';
import {UnoBadgeComponent} from '../uno-badge/uno-badge.component';

/**
 * UNO card is used to present menu cards has space for title and icon.
 */
@Component({
	selector: 'uno-card',
	templateUrl: './uno-card.component.html',
	styleUrls: ['./uno-card.component.css'],
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [NgStyle, UnoBadgeComponent, IonicModule]
})
export class UnoCardComponent {
	/**
	 * Value to present in badge.
	 */
	@Input()
	public badge: any;

	/**
	 * Icon to display (optional)
	 *
	 * Can be either an ionic icon or path to file.
	 */
	@Input()
	public icon: string;

	/**
	 * Title of the card (optional)
	 */
	@Input()
	public title: string;

	/**
	 * Style to be applied to the component.
	 */
	@Input()
	public ngStyle: CssNgStyle = {};
}
