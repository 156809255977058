import {DigitalTwinObjectTypeConstructors} from './digital-twin-object-type';
import {DigitalTwinObject} from './digital-twin-object';

/**
 * Utils to load digital twin objects and parse them into the correct data types.
 */
export class DigitalTwinObjectLoader {
	/**
	 * Method to serialize digital twin objects to JSON.
	 *
	 * Parse JSON data received from the API into an object of the correct type.
	 *
	 * Based on the type of the object different object types might be created.
	 *
	 * @param data - Object received from API.
	 * @returns Object instance built from the data received.
	 */
	public static parse(data: any): DigitalTwinObject {
		if (!data.type) {
			throw new Error('Object type missing from data.');
		}

		if (!DigitalTwinObjectTypeConstructors.has(data.type)) {
			throw new Error('Object type ' + data.type + ' is unknown.');
		}

		// @ts-ignore
		const obj: any = new (DigitalTwinObjectTypeConstructors.get(data.type))();
		obj.parse(data);

		if (data.children) {
			// Parse children data
			for (let i = 0; i < data.children.length; i++) {
				obj.add(DigitalTwinObjectLoader.parse(data.children[i]));
			}

			// Sort children by their index
			obj.children.sort(function(a: DigitalTwinObject, b: DigitalTwinObject) {
				return a.index - b.index;
			});
		}

		return obj;
	}

	/**
	 * Parse array of objects into usable object structures.
	 *
	 * @param data - Data received from the API to be parsed.
	 * @returns Objects parsed from the data provided.
	 */
	public static parseArray(data: any[]): DigitalTwinObject[] {
		const objs: DigitalTwinObject[] = [];

		for (let i = 0; i < data.length; i++) {
			objs.push(DigitalTwinObjectLoader.parse(data[i]));
		}

		return objs;
	}

}
