@if (this.value) {
	<!-- Desktop -->
	@if (app.device.isDesktop()) {
		<div style="display: flex; flex-direction: row;  align-items: center; width: 100%;">
			<!-- Label -->
			<div style="width: calc(100% - 450px);">
				<ion-label class="ion-no-margin ion-text-wrap" style="min-width: 100%; white-space: pre-wrap">{{(this.row | label:this.value) | translate}}</ion-label>
			</div>

			<!-- Content -->
			<div style="width: 450px">
				<uno-form [showRequiredUneditable]="this.showRequiredUneditable" [editable]="!this.disabled" [layout]="this.row.fields" [object]="this.value" [onChange]="this.onChange"></uno-form>
			</div>
		</div>
	}
	<!-- Mobile -->
	@if (app.device.isMobile()) {
		<!-- Title -->
		<div style="display: flex; flex-direction: column; width: 100%;">
			<ion-label class="ion-text-wrap" style="white-space: pre-wrap">{{(this.row | label:this.value) | translate}}</ion-label>
			<uno-form [showRequiredUneditable]="this.showRequiredUneditable" [editable]="!this.disabled" [layout]="this.row.fields" [object]="this.value" [onChange]="this.onChange"></uno-form>
		</div>
	}
}
