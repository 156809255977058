<!-- Mobile -->
@if (app.device.isMobile()) {
	<ion-list>
		@for (type of this.handler.items; track type) {
			<ion-item (click)="app.navigator.navigate('/menu/asset-portfolio/asset/list', {typeUuid: type.uuid, name: type.name})" button="true">
				<ion-icon src="./assets/icons/assets/component.svg" style="padding: 10px;"></ion-icon>
				<ion-label style="text-overflow: ellipsis;">{{type.name}}</ion-label>
				<div slot="end">
					@if ($any(type).badge !== null) {
						<ion-badge color="primary" mode="ios">{{$any(type).badge | async}}</ion-badge>
					}
					<ion-icon name="caret-forward-outline"></ion-icon>
				</div>
			</ion-item>
		}
	</ion-list>
}
<!-- Desktop -->
@if (app.device.isDesktop()) {
	<ion-card-content>
		<ion-grid>
			<ion-row>
				@for (type of this.handler.items; track type) {
					<ion-col size="4">
						<uno-card icon="./assets/icons/assets/component.svg" [title]="type.name" (click)="app.navigator.navigate('/menu/asset-portfolio/asset/list', {typeUuid: type.uuid, name: type.name})" badge="{{$any(type).badge | async}}">
						</uno-card>
					</ion-col>
				}
			</ion-row>
		</ion-grid>
	</ion-card-content>
}
