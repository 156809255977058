import {SortDirection} from 'src/app/utils/sort-direction';
import {UnoFormFieldTypes} from '../../../../components/uno-forms/uno-form/uno-form-field-types';
import {Session} from '../../../../session';
import {Service} from '../../../../http/service';
import {ServiceList} from '../../../../http/service-list';
import {ActionPlanAction} from '../../../../models/atex-inspections/action-plan/action-plan-action';
import {UnoFormField} from '../../../../components/uno-forms/uno-form/uno-form-field';
import {ActionPlanState, ActionPlanStateLabel} from '../../../../models/atex-inspections/action-plan/action-plan-state';
import {UserPermissions} from '../../../../models/users/user-permissions';
import {ActionPlanPriority, ActionPlanPriorityLabel} from '../../../../models/atex-inspections/action-plan/action-plan-priority';
import {ActionPlanActionLoadParams, ActionPlanActionLoadResponse, ActionPlanActionService} from '../services/action-plan-action.service';

/**
 * Layout of the form to edit action plan entries.
 */
export const ActionPlanFormLayout: UnoFormField[] = [
	{
		label: 'uuid',
		attribute: 'uuid',
		type: UnoFormFieldTypes.UUID
	},
	{
		attribute: 'state',
		label: 'status',
		sort: false,
		type: UnoFormFieldTypes.OPTIONS,
		isActive: function(object: any, row: UnoFormField) { return Session.hasPermissions([UserPermissions.ACTION_PLAN_OVERRIDE_STATE]); },
		editable: true,
		options: Object.values(ActionPlanState).map(function(value) {
			return {value: value, label: ActionPlanStateLabel.get(value)};
		})
	},
	{
		required: false,
		attribute: 'workOrder',
		label: 'workOrder',
		type: UnoFormFieldTypes.TEXT
	},
	{
		required: false,
		attribute: 'teamUuid',
		label: 'team',
		type: UnoFormFieldTypes.TEAM_SELECTOR,
		showClear: true
	},
	{
		required: false,
		attribute: 'priority',
		label: 'priority',
		sort: false,
		type: UnoFormFieldTypes.OPTIONS,
		options: Object.values(ActionPlanPriority).map(function(value) {
			return {value: value, label: ActionPlanPriorityLabel.get(value)};
		})
	},
	{
		attribute: 'description',
		label: 'description',
		required: false,
		type: UnoFormFieldTypes.TEXT_MULTILINE
	},
	{
		required: false,
		attribute: 'companyUuid',
		label: 'company',
		type: UnoFormFieldTypes.COMPANY_SELECTOR,
		showClear: true
	},
	{
		required: false,
		attribute: 'cost',
		label: 'cost',
		inUnit: '€',
		unit: '€',
		type: UnoFormFieldTypes.NUMBER_UNITS,
		options: [
			{value: '€', label: '€'}
		]
	},
	{
		required: true,
		attribute: 'limitDate',
		label: 'limitDate',
		type: UnoFormFieldTypes.DATE
	},
	{
		required: false,
		attribute: 'alertEmail',
		label: 'alertEmail',
		type: UnoFormFieldTypes.CHECKBOX
	},
	{
		required: true,
		attribute: 'actions',
		label: 'actions',
		sort: false,
		identifierAttribute: 'id',
		type: UnoFormFieldTypes.OPTIONS_MULTIPLE_LAZY,
		fetchOptionsLazy: async function(request): Promise<{options: any[], hasMore: boolean, id: number}> {
			const data: ActionPlanActionLoadParams = {search: request.search};
			const actionReq: ActionPlanActionLoadResponse = await ActionPlanActionService.load(data);

			return {options: actionReq.actions, hasMore: actionReq.hasMore, id: actionReq.id};
		},
		fetchOptionsBatch: async function(request) {
			const actionReq: ActionPlanActionLoadResponse = await ActionPlanActionService.load();

			const actions: ActionPlanAction[] = actionReq.actions.filter((act) => {
				return request.options.indexOf(act.id) !== -1;
			});

			return {options: actions};
	
		},
		getOptionText: function(option) {
			return option.label;
		}
	},
	{
		required: true,
		attribute: 'ffpUuids',
		label: 'ffp',
		type: UnoFormFieldTypes.OPTIONS_MULTIPLE_LAZY,
		identifierAttribute: 'uuid',
		fetchOptionsLazy: async function(request): Promise<{options: any[], hasMore: boolean, id: number}> {
			const data = {
				from: request.from,
				count: request.count,
				search: request.search,
				sortField: '[ap_asset].[name]',
				sortDirection: SortDirection.ASC
			};

			const req = await Service.fetch(ServiceList.atex.ffp.list, null, null, data, Session.session);
			return {options: req.response.ffp, hasMore: req.response.hasMore, id: req.id};
			
		},
		fetchOptionsBatch: async function(request): Promise<{options: any[]}> {
			const data = {ffpUuids: request.options};
			const req = await Service.fetch(ServiceList.atex.ffp.listBatch, null, null, data, Session.session);
			return {options: req.response.ffp};
		},
		getOptionText: function(option) {
			return [option.field, option.asset?.tag, option.asset?.name].filter(Boolean).join(' - ');
		}
	},
	{
		attribute: 'data.notes',
		label: 'notes',
		type: UnoFormFieldTypes.TEXT_MULTILINE
	},
	{
		attribute: 'data.photos',
		label: 'photos',
		type: UnoFormFieldTypes.IMAGE_RESOURCE_MULTIPLE
	},
	{
		attribute: 'data.documents',
		label: 'documents',
		type: UnoFormFieldTypes.DOCUMENT_RESOURCE_MULTIPLE
	}
];
