import {Component, OnInit} from '@angular/core';
import {App} from '../../../app';
import {MenuCardsOptions, MenuCardsComponent} from '../../../components/layout/menu-cards/menu-cards.component';
import {UserPermissions} from '../../../models/users/user-permissions';
import {ScreenComponent} from '../../../components/screen/screen.component';
import {UnoContentComponent} from '../../../components/uno/uno-content/uno-content.component';

@Component({
	selector: 'master-settings-page',
	templateUrl: './master-settings.page.html',
	standalone: true,
	imports: [UnoContentComponent, MenuCardsComponent]
})
export class MasterSettingsPage extends ScreenComponent implements OnInit {


	public permissions = [UserPermissions.MASTER_SETTINGS];

	/**
	 * List of options in this menu.
	 */
	public options: MenuCardsOptions[] = [
		{
			label: 'appearance',
			data: {},
			permissions: [UserPermissions.MASTER_SETTINGS_EDIT],
			icon: 'color-palette-outline',
			route: '/menu/master-settings/appearance',
			badge: null
		},
		{
			label: 'preferences',
			data: {},
			permissions: [UserPermissions.MASTER_SETTINGS_EDIT],
			icon: 'assets/icons/material/psychology/outline.svg',
			route: '/menu/master-settings/preferences',
			badge: null
		}
	];

	public ngOnInit(): void {
		super.ngOnInit();
		
		App.navigator.setTitle('settings');
	}
}
