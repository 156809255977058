import {
	Group,
	Intersection,
	MathUtils,
	Object3D,
	Raycaster,
	Scene,
	Vector2,
	Vector3
} from 'three';
import {Sky} from 'three/examples/jsm/objects/Sky';
import {LODFrustum, MapBoxProvider, MapView} from 'geo-three';
import {update} from '@tweenjs/tween.js';
import {TransformControls} from 'three/examples/jsm/controls/TransformControls.js';
import Hammer from 'hammerjs';
import {PerspectiveCamera} from '../../../render/camera/perspective-camera';
import {Keyboard} from '../../../render/input/keyboard';
import {Mouse} from '../../../render/input/mouse';
import {OrbitControls} from '../../../render/controls/orbit-controls';
import {Environment} from '../../../../../../environments/environment';
import {DigitalTwinScene} from '../../../data/digital-twin-scene';
import {PointCloud} from '../../../data/objects/pointcloud';
import {Session} from '../../../../../session';
import {ServiceList} from '../../../../../http/service-list';
import {Service} from '../../../../../http/service';
import {DigitalTwinObject} from '../../../data/digital-twin-object';
import {Resource} from '../../../../../models/resource';
import {ImageUtils} from '../../../../../utils/image-utils';
import {FileUtils} from '../../../../../utils/file-utils';
import {ProgressBar} from '../../../../../progress-bar';
import {Locale} from '../../../../../locale/locale';
import {Modal} from '../../../../../modal';
import {DigitalTwinObjectType} from '../../../data/digital-twin-object-type';
import {Geolocation} from '../../../../../models/geolocation';
import {GeolocationUtils} from '../../../../../utils/geolocation-utils';
import {OrbitMarker} from '../../../render/controls/orbit-marker';
import {RendererCanvasComponent} from '../components/renderer-canvas/renderer-canvas.component';
import {Keys} from '../../../render/input/keys';
import {App} from '../../../../../app';
import {DigitalTwinEditorState} from './digital-twin-editor-state';
import {DigitalTwinEditorMode} from './digital-twin-editor-mode';

Object3D.DefaultMatrixAutoUpdate = false;


/**
 * Digital twin editor contains the rendering and interaction logic of the digital twin module for a specific scene.
 *
 * It renders content into a canvas and handles all functions of the editor.
 */
export class DigitalTwinEditor {
	/**
	 * Canvas where the editor content will be rendered.
	 */
	public canvas: RendererCanvasComponent = null;

	/**
	 * Digital twin scene data.
	 */
	public scene: DigitalTwinScene = null;

	/**
	 * Camera in use to render the scene
	 */
	public camera: PerspectiveCamera = null;

	/**
	 * Ray caster object is used to pick objects with the mouse.
	 */
	public raycaster: Raycaster = null;

	/**
	 * Scene containing 3D objects to be rendered into the screen.
	 *
	 * Includes both objects from the scene and auxiliary GUI elements.
	 */
	public renderScene: Scene = null;

	/**
	 * Container with the objects from the scene only.
	 */
	public content: Group = null;

	/**
	 * Controller is used to control the camera and navigate around the scene.
	 */
	public controls: OrbitControls = null;

	/**
	 * Transform control marker to help the user locate itself visually in the scene.
	 */
	public marker: OrbitMarker = null;

	/**
	 * Keyboard input.
	 */
	public keyboard: Keyboard = null;

	/**
	 * Mouse input.
	 */
	public mouse: Mouse = null;

	/**
	 * Map layer using data from the mapbox tile API.
	 */
	public map: MapView;

	/**
	 * Time of the last frame used to calculate frame delta.
	 */
	public time: number = 0.0;

	/**
	 * Mode of the digital twin editor.
	 */
	public mode: DigitalTwinEditorMode = DigitalTwinEditorMode.NAVIGATION;

	/**
	 * Object currently selected from the scene.
	 */
	public selected: DigitalTwinObject = null;

	/**
	 * State of the editor object.
	 */
	public state: DigitalTwinEditorState = DigitalTwinEditorState.IDLE;

	/**
	 * Transform controls are used to transform 3D objects.
	 *
	 * Change their position, scale and rotation.
	 */
	public transformControls: TransformControls = null;

	/**
	 * Constructor of the object type to be created on double click.
	 */
	public objectConstructor: any = null;

	/**
	 * Multitouch gesture handler using hammer.js. (e.g. pinch, drag)
	 */
	public multitouch: any = null;

	/**
	 * Initialize the editor structure. Creates the rendering context and prepares the scene.
	 *
	 * Handlers for input are also created here.
	 *
	 * @param canvas - Canvas where the editor will be rendered.
	 */
	public initialize(canvas: RendererCanvasComponent): void {
		this.canvas = canvas;

		this.raycaster = new Raycaster();

		this.keyboard = new Keyboard();
		this.mouse = new Mouse(this.canvas.canvas);

		PointCloud.reset();

		this.setupScene();
		this.state = DigitalTwinEditorState.RUNNING;

		this.getGeolocation();

		this.canvas.onresize = (width: number, height: number) => {
			this.resize(width, height);
		};

		this.canvas.setAnimationLoop((time: number) => {
			// Potree visibility
			PointCloud.potree.updatePointClouds(PointCloud.pointClouds, this.camera, this.canvas.renderer);

			// Tween animation update
			update(time);

			// Editor logic update
			this.update(time);
		});

		this.canvas.ondestroy = () => {
			try {
				this.updateThumbnail();
			} catch (e) {
				console.warn('EQS: Failed to update thumbnail', e);
			}
		};


		this.multitouch = this.initMultitouch();
	}

	/**
	 * Initialize the multitouch event handler using hammer.
	 */
	public initMultitouch(): any {

		const manager = new Hammer.Manager(this.canvas.canvas, {enable: true});

		if (App.device.isMobile()) {
			manager.add(new Hammer.Pinch({event: 'pinch'}));

			let distance = null;
			let range = null;

			manager.on('pinch', (evt: HammerInput) => {
				if (evt.eventType === 1) {
					// Pinch start event
					distance = this.controls.distance;
					range = this.controls.distance * 0.2;
				} else if (evt.eventType === 2) {
					// Pinch to zoom speed
					const diff = evt.scale > 1 ? -range * evt.scale : range * (1.0 / evt.scale);
					this.controls.distance = distance + diff;
				}
			});
		}


		return manager;
	}

	/**
	 * Move the camera to the geolocation of the user.
	 *
	 * Will ask for user permission before getting its location.
	 */
	public async getGeolocation(): Promise<void> {
		const location: Geolocation = await GeolocationUtils.getLocation();
		const point = GeolocationUtils.datumsToSpherical(location.latitude, location.longitude);
		this.controls.moveTo(new Vector3(point.x, 0, -point.y));
	}

	/**
	 * Update the thumbnail of the scene in the backend.
	 *
	 * Takes a screenshot of the canvas and uploads it as a resource.
	 */
	public updateThumbnail(format: string = 'jpeg', quality: number = 0.6): void {
		this.canvas.renderer.clear(true, true, true);
		this.canvas.renderer.render(this.renderScene, this.camera);

		this.canvas.canvas.toBlob(async(blob: Blob): Promise<void> => {

			const compressed = await ImageUtils.compressImage(blob);

			const form = new FormData();
			form.append('file', compressed, 'image');
			form.append('format', format);

			const request = await Service.fetch(ServiceList.resources.image.upload, null, null, form, null);

			this.scene.picture = new Resource(request.response.uuid, format);

			await Service.fetch(ServiceList.digitalTwin.scene.update, null, null, this.scene, Session.session);
		}, format, quality);
	}


	/**
	 * Take a screen shoot of the scene currently on display and download to the client.
	 */
	public takeScreenshot(): void {
		const format = 'png';

		this.canvas.renderer.clear(true, true, true);
		this.canvas.renderer.render(this.renderScene, this.camera);

		this.canvas.canvas.toBlob((blob: Blob) => {
			const reader = new FileReader();
			reader.readAsArrayBuffer(blob);
			reader.onload = (event) => {
				FileUtils.writeFileArrayBuffer('screenshot.' + format, reader.result as ArrayBuffer);
			};
		}, format);
	}

	/**
	 * Change the mode of the editor.
	 *
	 * @param mode - Mode to be set in the editor.
	 */
	public setMode(mode: DigitalTwinEditorMode): void {
		this.mode = mode;

		if (this.mode === DigitalTwinEditorMode.PLACE_OBJECT && this.objectConstructor === null) {
			throw new Error('The objectConstructor must be set before object placement is activated.');
		}
	}

	/**
	 * Set the content of the editor loaded from the API.
	 *
	 * Set the children of the content group objects and performs initial matrix update of all objects.
	 *
	 * @param objects - Objects to be added to the 3D scene.
	 */
	public setContent(objects: DigitalTwinObject[]): void {
		// Reset content group
		if (this.content) {
			this.renderScene.remove(this.content);
			this.content = new Group();
			this.renderScene.add(this.content);
		}

		// Add objects to the content
		for (let i = 0; i < objects.length; i++) {
			this.content.add(objects[i]);
		}

		// Initialize digital twin objects
		this.content.traverse((obj: Object3D) => {
			if (obj instanceof DigitalTwinObject) {
				obj.initialize();
				obj.updateMatrix();
			}
		});

		console.log('EQS: Set the content of the 3D editor', this.content, objects, this.renderScene);
	}

	/**
	 * Method to load data from file.
	 *
	 * Data is uploaded to the digital-twin server and objects created on the API.
	 */
	public async loadFile(file: File): Promise<void> {
		const form = new FormData();
		form.append('file', file, 'upcl');

		const progressBar = new ProgressBar();
		progressBar.show();
		progressBar.update(Locale.get('uploadingData'), 0.0);

		try {
			const request = await Service.fetch(ServiceList.digitalTwin.pointCloud.uploadZip, null, null, form, null, true, undefined, (event: ProgressEvent<EventTarget>) => {
				console.log('EQS: Upload point cloud file event', event);
				const progress = event.loaded / event.total;
				progressBar.update(progress >= 1.0 ? Locale.get('processingData') : Locale.get('uploadingData'), progress);
			});

			const pointcloud = new PointCloud();
			pointcloud.pointCloudUuid = request.response.uuid;
			pointcloud.position.copy(this.controls.position);
			await this.addObject(pointcloud);

			console.log('EQS: Uploaded point cloud file', request);
		} catch (e) {}

		progressBar.destroy();
	}

	/**
	 * Create and configure the renderer.
	 *
	 * Creates the webgl renderer, configure rendering settings and initialize the base elements of the scene.
	 */
	public setupScene(): void {
		this.renderScene = new Scene();

		this.content = new Group();
		this.renderScene.add(this.content);

		const sky = this.createSkybox();
		this.renderScene.add(sky);
		sky.updateMatrixWorld();

		const satellite = new MapBoxProvider(Environment.MAPBOX_TOKEN, 'mapbox/satellite-streets-v10', MapBoxProvider.STYLE, 'jpg70');
		const height = new MapBoxProvider(Environment.MAPBOX_TOKEN, 'mapbox.terrain-rgb', MapBoxProvider.MAP_ID, 'pngraw');

		this.map = new MapView(MapView.PLANAR, satellite, height);
		this.map.lod = new LODFrustum();
		this.map.updateMatrix();
		this.renderScene.add(this.map);

		this.camera = new PerspectiveCamera(90, 1.0);

		// Controls
		this.controls = new OrbitControls();
		this.controls.camera = this.camera;
		this.controls.maxDistance = 1e6;
		this.controls.add(this.camera);
		// @ts-ignore
		this.renderScene.add(this.controls);

		this.transformControls = new TransformControls(this.camera, this.canvas.canvas);
		this.transformControls.addEventListener('objectChange', (event) => {
			if (this.selected) {
				this.selected.updateMatrix();
			}
		});

		this.marker = new OrbitMarker();
		this.renderScene.add(this.marker);

		this.transformControls.addEventListener('dragging-changed', (event) => {
			this.controls.enabled = !this.controls.enabled;

			if (this.controls.enabled && this.selected) {
				this.updateObject(this.selected);
			}

		});
		this.renderScene.add(this.transformControls);
	}

	/**
	 * Update method called every frame from the render loop.
	 *
	 * Used to update the state of 3D elements and render content.
	 *
	 * @param time - Time in milliseconds since last frame.
	 */
	public update(time: number): void {
		const delta = time - this.time;
		this.time = time;

		// Input update
		this.keyboard.update();
		this.mouse.update();

		// Delete object.
		if (this.keyboard.keyJustPressed(Keys.DEL) && this.selected) {
			(async() => {
				const confirm = await Modal.confirm(Locale.get('confirm'), Locale.get('confirmDelete'));
				if (confirm) {
					await this.deleteObject(this.selected);
					this.select(null);
				}
			})();
		}

		// Snap transform movement and rotation to fixed steps
		if (this.keyboard.keyJustPressed(Keys.SHIFT)) {
			this.transformControls.setTranslationSnap(100);
			this.transformControls.setRotationSnap(MathUtils.degToRad(15));
			this.transformControls.setScaleSnap(0.01);
		} else if (this.keyboard.keyJustReleased(Keys.SHIFT)) {
			this.transformControls.setTranslationSnap(null);
			this.transformControls.setRotationSnap(null);
			this.transformControls.setScaleSnap(null);
		}

		// Move camera on double click
		if (this.mouse.buttonDoubleClicked()) {
			const intersections = this.raycast(this.content);
			this.raycast(this.map, intersections);

			if (intersections.length > 0) {
				// Move the controls to the selected point
				if (this.mode === DigitalTwinEditorMode.NAVIGATION) {
					const point = intersections[0].point;
					this.controls.moveTo(point);
				}

				// Add a new object to the scene.
				if (this.mode === DigitalTwinEditorMode.PLACE_OBJECT) {
					(async() => {
						const point = intersections[0].point;
						const box = new this.objectConstructor();
						box.position.copy(point);
						await this.addObject(box);
					})();
				}

				// Select an object.
				if (this.mode === DigitalTwinEditorMode.SELECT_OBJECT) {
					this.select(DigitalTwinEditor.getSelection(intersections));
				}

				// Remove an object from the scene.
				if (this.mode === DigitalTwinEditorMode.DELETE_OBJECT) {
					const obj: any = DigitalTwinEditor.getSelection(intersections);
					this.select(null);
					if (obj) {
						(async() => {
							const confirm = await Modal.confirm(Locale.get('confirm'), Locale.get('confirmDelete'));
							if (confirm) {
								await this.deleteObject(obj);
							}
						})();
					}
				}
			}
		}

		this.content.traverse((child: any) => {
			if (child.isDigitalTwinObject) {
				child.update(time, delta, this.mouse, this.keyboard);
			}
		});

		// Update transform controls if they are visible
		if (this.transformControls && this.transformControls.visible) {
			this.transformControls.updateMatrix();
		}

		// Update controls
		this.controls.update(this.mouse, this.keyboard, delta);

		// Controls marker follow the controls
		this.marker.update(this.controls.center, this.controls.distance);
		this.marker.visible = this.controls.isMoving();
		this.marker.updateMatrix();

		// Adjust ray casting tolerance based on distance.
		this.raycaster.params.Points.threshold = this.controls.distance > 10 ? 1.0 : 0.1;

		// Calculate distance to orbit center
		let height = Math.abs(this.controls.position.y);
		if (this.controls.distance > height) {
			height = this.controls.distance;
		}

		// Calculate view frustum based on camera inclination and height
		const inclination = Math.cos(this.controls.orientation.y);

		// Near plane minimum value
		const nearMin = 0.01;

		// Near plane max value
		const nearMax = 1.0;

		// Threshold distance to swap near plane
		const nearThreshold = 5.0;

		this.camera.near = this.controls.distance > nearThreshold ? nearMax : nearMin;

		// Power factor applied to calculate max plane based on height and inclination of the camera
		const farPow = 1.3;

		// Minimum far plane value possible
		const farMin = 5e3;

		this.camera.far = Math.max(Math.pow(height + height * inclination, farPow), farMin);


		this.camera.updateProjectionMatrix();

		// Render content
		this.canvas.renderer.clear(true, true, true);
		this.canvas.renderer.render(this.renderScene, this.camera);
	}

	/**
	 * Check is an object is selected.
	 *
	 * @returns True if the object is currently selected in the scene.
	 */
	public isSelected(obj: DigitalTwinObject): boolean {
		return this.selected === obj;
	}

	/**
	 * Select an object in the scene.
	 *
	 * The object has to belong to the 3D and scene.
	 *
	 * @param obj - Object to be selected.
	 */
	public select(obj: DigitalTwinObject): void {
		if (obj) {
			if (obj.sceneUuid !== this.scene.uuid) {
				throw new Error('The object selected does not belong to the scene.');
			}

			if (!obj.isDigitalTwinObject) {
				throw new Error('The object selected is not a digital twin object.');
			}
		}

		this.selected = obj;

		if (this.selected && !this.selected.locked) {
			this.transformControls.attach(this.selected);
			this.transformControls.updateMatrix();
		} else {
			this.transformControls.detach();
		}

		console.log('EQS: Selected object.', this.selected, this.transformControls);
	}

	/**
	 * Get the first selected object from a list of ray cast intersections objects.
	 *
	 * @param intersections - List of intersections.
	 * @returns The first digital twin object found from the intersection list.
	 */
	public static getSelection(intersections: Intersection[]): DigitalTwinObject {
		let obj: any = intersections[0].object;
		while (obj && obj.parent) {
			if (obj.isDigitalTwinObject) {
				break;
			}
			obj = obj.parent;
		}

		if (obj.isDigitalTwinObject) {
			return obj;
		}

		return null;
	}

	/**
	 * Add a new object to the scene.
	 *
	 * Stamps the object with the required information.
	 *
	 * @param obj - Object to add to the scene.
	 * @param parent - Parent object where to place the object added.
	 */
	public async addObject(obj: DigitalTwinObject, parent?: Object3D): Promise<void> {
		// Set scene and parent UUID
		obj.parentUuid = parent ? parent.uuid : null;
		obj.sceneUuid = this.scene.uuid;

		// Add to parent
		(parent ?? this.content).add(obj);

		// Update matrices
		obj.updateMatrix();

		await obj.initialize();

		// Store in the server
		await Service.fetch(ServiceList.digitalTwin.object.create, null, null, obj, Session.session, true);
	}

	/**
	 * Update an existing object in the scene.
	 */
	public async updateObject(obj: DigitalTwinObject): Promise<void> {
		// Reselect object to update editor
		if (this.isSelected(obj)) {
			this.select(obj);
		}

		// Update matrices
		obj.updateMatrix();

		// Update data in server
		await Service.fetch(ServiceList.digitalTwin.object.update, null, null, obj, Session.session, true);
	}

	/**
	 * Method to remove an object from the scene.
	 */
	public async deleteObject(obj: DigitalTwinObject): Promise<void> {
		// Remove object from its parent
		obj.parent.remove(obj);

		if (this.selected === obj) {
			this.select(null);
		}

		// Remove object from the API
		await Service.fetch(ServiceList.digitalTwin.object.delete, null, null, {uuid: obj.uuid}, Session.session, false);

		// Execute on delete actions
		if (obj.type === DigitalTwinObjectType.POINTCLOUD) {
			const pcl = obj as PointCloud;
			await Service.fetch(ServiceList.digitalTwin.pointCloud.delete, null, null, {uuid: pcl.pointCloudUuid}, Session.session, false);
		}
	}

	/**
	 * Ray cast group of objects, calculates the ray origin and direction based on the coordinates of the mouse object and canvas size.
	 *
	 * Objects have to support intersection method to check if the ray has crossed the object.
	 *
	 * @param object - Group of objects to be tested.
	 * @param intersections - Intersections array, an external array can be received to append objects.
	 * @returns List of intersections.
	 */
	public raycast(object: Object3D, intersections: Intersection<Object3D>[] = []): Intersection[] {
		const resolution = this.canvas.resolution.clone();

		const mouse = this.mouse.position.clone();
		mouse.multiplyScalar(window.devicePixelRatio);

		// Calculate normalized screen coordinates
		const normalized = new Vector2(mouse.x / resolution.x * 2 - 1, -mouse.y / resolution.y * 2 + 1);

		// Set the raycaster frustum
		this.raycaster.setFromCamera(normalized, this.camera);

		return this.raycaster.intersectObjects(object.children, true, intersections);
	}

	/**
	 * Create skybox object generated tough shader code, so that no texture artifacts are visible.
	 *
	 * @returns Skybox object.
	 */
	public createSkybox(): Sky {
		const sky = new Sky();
		sky.scale.setScalar(1e8);

		const effectController = {
			turbidity: 0,
			rayleigh: 0.5,
			mieCoefficient: 0.005,
			mieDirectionalG: 0.7,
			inclination: 0.48,
			azimuth: 0.25,
			exposure: 0.5
		};

		const uniforms = sky.material.uniforms;
		uniforms['turbidity'].value = effectController.turbidity;
		uniforms['rayleigh'].value = effectController.rayleigh;
		uniforms['mieCoefficient'].value = effectController.mieCoefficient;
		uniforms['mieDirectionalG'].value = effectController.mieDirectionalG;

		const theta = Math.PI * (effectController.inclination - 0.5);
		const phi = 2 * Math.PI * (effectController.azimuth - 0.5);
		const sun = new Vector3();
		sun.x = Math.cos(phi);
		sun.y = Math.sin(phi) * Math.sin(theta);
		sun.z = Math.sin(phi) * Math.cos(theta);
		uniforms['sunPosition'].value.copy(sun);

		return sky;
	}

	/**
	 * Resize method should be called everytime that the screen size changes to update projection.
	 */
	public resize(width: number, height: number): void {
		this.camera.aspect = width / height;
		this.camera.updateProjectionMatrix();
	}

	/**
	 * Dispose the editor.
	 *
	 * Stops the rendering loop and destroys all DOM events created.
	 *
	 * Should be called when the editor is no longer needed to prevent code leaks.
	 */
	public dispose(): void {
		this.state = DigitalTwinEditorState.DISPOSED;

		if (this.keyboard) {
			this.keyboard.dispose();
		}

		if (this.mouse) {
			this.mouse.dispose();
		}

		if (this.multitouch) {
			this.multitouch.destroy();
		}	
	}
}
