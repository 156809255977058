import {FileConverterService} from 'src/app/services/file-converter.service';
import {AssetService} from '../../../asset-portfolio/services/asset.service';
import {APAsset} from '../../../../models/asset-portfolio/asset';
import {Service} from '../../../../http/service';
import {ServiceList} from '../../../../http/service-list';
import {Session} from '../../../../session';
import {Locale} from '../../../../locale/locale';
import {Modal} from '../../../../modal';
import {FileUtils} from '../../../../utils/file-utils';
import {UnoFormField} from '../../../../components/uno-forms/uno-form/uno-form-field';
import {UnoFormFieldTypes} from '../../../../components/uno-forms/uno-form/uno-form-field-types';
import {UUID} from '../../../../models/uuid';
import {XlsxUtils} from '../../../../utils/xlsx-utils';
import {User} from '../../../../models/users/user';
import {ProgressBar} from '../../../../progress-bar';
import {ReportTemplateFormat, ReportTemplateFormatLabel} from '../../../../utils/report-template-format';
import {AtexInspectionChecklistService} from '../services/atex-inspection-checklist.service';
import {AtexInspectionFieldsService} from '../services/atex-inspection-fields.service';
import {AtexInspectionStatus, AtexInspectionStatusLabel} from '../../../../models/atex-inspections/inspections/atex-inspection-status';
import {AtexInspectionResultLabel} from '../../../../models/atex-inspections/inspections/atex-inspection-result';
import {AtexInspectionFieldResult} from '../../../../models/atex-inspections/inspections/atex-inspection-field-result';
import {AtexInspection} from '../../../../models/atex-inspections/inspections/atex-inspection';
import {ResourceUtils} from '../../../../utils/resource-utils';
import {AtexInspectionService} from '../services/atex-inspection.service';
import {AtexInspectionReport} from './atex-inspection-report';

export class AtexInspectionExport {
	/**
	 * Export Atex inspections as JSON.
	 *
	 * Only exports the inspection's data, without any related information (asset, ffp, action plan, etc).
	 */
	public static async exportJSON(): Promise<void> {
		let from: number = 0;
		const count: number = 300;
		const inspections: any[] = [];

		const progress = new ProgressBar();
		progress.show();

		try {
			const total = (await Service.fetch(ServiceList.atex.inspection.count, null, null, null, Session.session, true)).response.count;
			if (total > 0) {
				while (true) {
					progress.update(Locale.get('loadingData'), from / total);

					const request = await Service.fetch(ServiceList.atex.inspection.listDetailed, null, null, {
						from: from,
						count: count,
						status: AtexInspectionStatus.ALL
					}, Session.session, true);
					for (let i = 0; i < request.response.inspections.length; i++) {
						inspections.push(AtexInspection.parse(request.response.inspections[i]));
					}

					from += request.response.inspections.length;
					if (!request.response.hasMore) {
						break;
					}
				}

				FileUtils.writeFile('inspections.json', JSON.stringify(inspections, null, '\t'));
			}
		} catch (e) {
			Modal.alert(Locale.get('error'), Locale.get('errorExport'));
			console.error('EQS: Error exporting file.', e);
		}

		progress.destroy();
	}

	/**
	 * Export all Atex inspections as a XLSX file, including details from the inspection equipment.
	 */
	public static async exportXLSX(): Promise<void> {
		const checklists = await AtexInspectionChecklistService.get(true);

		const nd = '';

		// XLSX data exported into the file
		const data: any[][] = [[
			Locale.get('inspectionUuid'), Locale.get('assetUuid'), Locale.get('assetName'), Locale.get('assetTag'),
			Locale.get('manufacturer'), Locale.get('model'), Locale.get('serialNumber'),
			Locale.get('result'), Locale.get('notes'), Locale.get('resultFinal'),
			Locale.get('status'), Locale.get('updatedAt'), Locale.get('inspectionChecklists'),
			Locale.get('parentUuid'), Locale.get('parentName'), Locale.get('parentTag')
		]];

		const progress = new ProgressBar();
		progress.show();

		try {
			const total = (await Service.fetch(ServiceList.atex.inspection.count, null, null, null, Session.session, true)).response.count;
			progress.update(Locale.get('loadingData'), 0 / total);
			
			if (total > 0) {
				const assetsCache: Map<UUID, APAsset> = new Map<UUID, APAsset>();

				let from: number = 0;
				const count: number = 500;

				const inspections: AtexInspection[] = [];

				// Fetch data to export
				while (true) {
					// List all inspections
					const inspectionsRequest = await Service.fetch(ServiceList.atex.inspection.listDetailed, null, null, {
						from: from, 
						count: count,
						status: AtexInspectionStatus.ALL,
						sortField: '[atex_inspection].[id]',
						sortDirection: 'DESC'
					}, Session.session, true);
					const inspectionsBatch: AtexInspection[] = inspectionsRequest.response.inspections.map((d: any) => { return AtexInspection.parse(d); });
				
					// Get all inspections assets UUIDs
					const assetUuids: UUID[] = [];
					inspectionsBatch.forEach((i: AtexInspection) => {
						inspections.push(i);

						if (i.assetUuid) {
							assetUuids.push(i.assetUuid);
						}
					});
	
					// Keep asset parent UUIDs to fetch in batch form API
					const parentUuids: UUID[] = [];
	
					if (assetUuids.length > 0) {
						const request = await Service.fetch(ServiceList.assetPortfolio.asset.getBatch, null, null, {assets: assetUuids}, Session.session, true);
						request.response.assets.forEach((d: any) => {
							const a: APAsset = APAsset.parse(d);
	
							if (a.parentUuid) {
								parentUuids.push(a.parentUuid);
							}
	
							assetsCache.set(a.uuid, a);
						});
					}
	
					if (parentUuids.length > 0) {
						const request = await Service.fetch(ServiceList.assetPortfolio.asset.getBatch, null, null, {assets: parentUuids}, Session.session, true);
						request.response.assets.forEach((d: any) => {
							const a: APAsset = APAsset.parse(d);
							assetsCache.set(a.uuid, a);
						});
					}

					if (!inspectionsRequest.response.hasMore) {
						break;
					} else {
						from += count;
					}

					progress.update(Locale.get('loadingData'), from / total);
				}

				for (let i = 0; i < inspections.length; i++) {
					const inspection = inspections[i];
					const asset: APAsset = assetsCache.get(inspections[i].assetUuid);
					let parentAsset: APAsset;
					
					// Row data
					let row = [inspection.uuid];

					if (asset) {
						row = row.concat([
							asset.uuid, asset.name, asset.tag, 
							asset.manufacturer, asset.model, asset.serialNumber
						]);

						if (asset.parentUuid) {
							parentAsset = assetsCache.get(asset.parentUuid);
						}
					} else {
						row = row.concat([nd, nd, nd, nd, nd, nd]);
					}

					row = row.concat([	
						Locale.get(AtexInspectionResultLabel.get(inspection.result)), inspection?.data?.notes || nd, Locale.get(AtexInspectionResultLabel.get(inspection.resultFinal)),
						Locale.get(AtexInspectionStatusLabel.get(inspection.status)), inspection.updatedAt.toLocaleString(Locale.code)
					]);

					// Checklists selected
					let checklist = '';
					for (const j in inspection.data.inspections) {
						if (inspection.data.inspections[j] === true) {
							if (checklist.length > 0) {
								checklist += ', ';
							}
							checklist += checklists[j].name;
						}
					}
					row.push(checklist.length > 0 ? checklist : nd);

					row = row.concat(parentAsset ? [parentAsset.uuid, parentAsset.name, parentAsset.tag] : [nd, nd, nd]);
					
					data.push(row);
				}

				progress.update(Locale.get('loadingData'), 1);

				// Export XLSX file created from API data.
				XlsxUtils.writeFile(data, 'atex-inspections.xlsx');
			}
		} catch (e) {
			Modal.alert(Locale.get('error'), Locale.get('errorExport'));
			console.error('EQS: Error exporting file.', e);
		}

		progress.destroy();
	}

	/**
	 * Export all completed Atex inspections results, including details about equipment, inspections performed and non-compliant Atex information.
	 */
	public static async exportXLSXResults(): Promise<void> {
		// Control data page access
		let from: number = 0;
		const count: number = 500;

		const nd = '';

		// XLSX data exported into the file
		const data: any[][] = [[
			Locale.get('inspectionUuid'), Locale.get('assetUuid'), Locale.get('name'), Locale.get('tag'), 
			Locale.get('result'), Locale.get('updatedAt'),
			Locale.get('inspectionChecklists'),
			Locale.get('parentUuid'), Locale.get('parentName'), Locale.get('parentTag'),
			Locale.get('nonConformities'), Locale.get('justifications'), Locale.get('notes'), Locale.get('pictures')
		]];

		// Assets data
		const assetsCache: Map<UUID, APAsset> = new Map();

		// Auxiliary method to get assets from the API server.
		//
		// Asset data is stored in the map to prevent hitting the server unnecessarily.
		async function getAsset(uuid: UUID): Promise<APAsset> {
			if (!uuid) {
				return null;
			}

			if (assetsCache.has(uuid)) {
				return assetsCache.get(uuid);
			}

			const asset = await AssetService.get(uuid, true);
			assetsCache.set(uuid, asset);

			return asset;
		}

		const progress = new ProgressBar();
		progress.show();

		try {
			const inspectionFields = await AtexInspectionFieldsService.get(true);
			const checklists = await AtexInspectionChecklistService.get(true);

			const total = await AtexInspectionService.count(null, true);
			if (total > 0) {
				while (true) {
					progress.update(Locale.get('loadingData'), from / total);
					const request = await Service.fetch(ServiceList.atex.inspection.listDetailedWithAsset, null, null, {
						status: AtexInspectionStatus.COMPLETED,
						from: from,
						count: count
					}, Session.session, true);

					const response = request.response;

					for (let i = 0; i < response.inspections.length; i++) {
						progress.update(Locale.get('loadingData'), (from + i) / total);

						const inspection = AtexInspection.parse(response.inspections[i].inspection);
						const asset = APAsset.parse(response.inspections[i].asset);

						let row = [
							inspection.uuid, asset.uuid, asset.name, asset.tag,
							Locale.get(AtexInspectionResultLabel.get(inspection.resultFinal)), inspection.updatedAt.toLocaleString(Locale.code)
						];

						// Checklists selected
						const checklist = [];
						for (const j in inspection.data.inspections) {
							if (inspection.data.inspections[j] === true) {
								checklist.push(checklists[j].name);
							}
						}
						row.push(checklist.length > 0 ? checklist.join(', ') : nd);

						// Parent Asset
						const parentAsset = await getAsset(asset.parentUuid);
						row = row.concat(parentAsset ? [parentAsset.uuid, parentAsset.name, parentAsset.tag] : [nd, nd, nd]);

						// Variable to indicate if at least one row was pushed to the file
						let rowPushed: boolean = false;

						// Inspector Inspection fields
						for (const r in inspection.data.responses.inspector) {
							if (inspection.data.responses.inspector[r] && inspection.data.responses.inspector[r].result === AtexInspectionFieldResult.NOK && !inspection.data.responses.inspector[r].notApplicable) {
								const subRow = structuredClone(row);

								let fieldLabel: string = '';
								const justifications: string[] = [];
								for (const f in inspectionFields) {
									if (f === r) {
										fieldLabel = inspectionFields[f].label ? inspectionFields[f].label : '';

										for (let j = 0; j < inspection.data.responses.inspector[r].justifications.length; j++) {
											justifications.push(inspectionFields[f].justifications[inspection.data.responses.inspector[r].justifications[j]]);
										}
										break;
									}
								}

								subRow.push(r + ' - ' + fieldLabel);
								subRow.push(justifications.join(', '));
								subRow.push(inspection.data.responses.inspector[r].notes);
								subRow.push(inspection.data.responses.inspector[r].photo.map(function(a) {return ResourceUtils.getURL(a);}).join(', '));
								data.push(subRow);
								rowPushed = true;
							}
						}

						// Backoffice Inspection fields
						for (const r in inspection.data.responses.backoffice) {
							if (inspection.data.responses.backoffice[r] && inspection.data.responses.backoffice[r].result === AtexInspectionFieldResult.NOK && !inspection.data.responses.backoffice[r].notApplicable) {
								const subRow = structuredClone(row);

								let fieldLabel: string = '';
								const justifications: string[] = [];
								for (const f in inspectionFields) {
									if (f === r) {
										fieldLabel = inspectionFields[f].label ? inspectionFields[f].label : '';
										for (let j = 0; j < inspection.data.responses.backoffice[r].justifications.length; j++) {
											justifications.push(inspectionFields[f].justifications[inspection.data.responses.backoffice[r].justifications[j]]);
										}
										break;
									}
								}

								subRow.push(r + ' - ' + fieldLabel);
								subRow.push(justifications.join(', '));
								subRow.push(inspection.data.responses.backoffice[r].notes);
								subRow.push(inspection.data.responses.backoffice[r].photo.map(function(a) {return ResourceUtils.getURL(a);}).join(', '));
								data.push(subRow);

								rowPushed = true;
							}
						}

						if (!rowPushed) {
							data.push(row);
						}
					}

					from += response.inspections.length;

					if (!response.hasMore) {
						break;
					}
				}

				// Export XLSX file created from API data.
				XlsxUtils.writeFile(data, 'inspections_results.xlsx');
			}
		} catch (e) {
			Modal.alert(Locale.get('error'), Locale.get('errorExport'));
			console.error('EQS: Error exporting file.', e);
		}

		progress.destroy();
	}

	/**
	 * Export all Atex inspections reports as docx files.
	 */
	public static async exportReportsDocx(): Promise<void> {
		const values = {
			status: AtexInspectionStatus.NONE,
			format: ReportTemplateFormat.PDF
		};

		const layout: UnoFormField[] = [
			{
				label: 'status',
				attribute: 'status',
				sort: false,
				type: UnoFormFieldTypes.OPTIONS,
				options: Object.values(AtexInspectionStatus).map(function(value) {
					return {value: value, label: AtexInspectionStatusLabel.get(value)};
				})
			},
			{
				label: 'format',
				attribute: 'format',
				sort: false,
				type: UnoFormFieldTypes.OPTIONS,
				options: Object.values(ReportTemplateFormat).map((value: number) => {
					return {
						label: ReportTemplateFormatLabel.get(value),
						value: value
					};
				})
			}
		];

		await Modal.form(Locale.get('atexInspection'), values, layout);

		const progress = new ProgressBar();
		progress.show();

		let from: number = 0;
		const count: number = 10;
		let hasMore = true;

		let success = 0;
		let failed = 0;

		try {
			
			const total = await AtexInspectionService.count({status: values.status}, true);
			if (total > 0) {
				while (hasMore) {
					progress.update(Locale.get('loadingData'), from / total);

					const request = await Service.fetch(ServiceList.atex.inspection.listDetailed, null, null, {
						status: values.status,
						from: from,
						count: count
					}, Session.session, true);

					const inspections = request.response.inspections;

					for (let i = 0; i < inspections.length; i++) {
						progress.update(Locale.get('loadingData'), (from + i) / total);

						try {
							const inspection = AtexInspection.parse(inspections[i]);

							const asset = await AssetService.get(inspection.assetUuid, true);
							const doc: ArrayBuffer = await AtexInspectionReport.generateDocx(inspection, asset);

							if (values.format === ReportTemplateFormat.DOCX) {
								FileUtils.writeFileArrayBuffer(asset.name + '_' + asset.tag + '_' + inspection.uuid + '.docx', doc);
							} else {
								const pdf = await FileConverterService.convertDocxToPdf(doc);
								FileUtils.writeFileArrayBuffer(asset.name + '_' + asset.tag + '_' + inspection.uuid + '.pdf', pdf);
							}

							success++;
						} catch (e) {
							failed++;
						}
					}

					hasMore = request.response.hasMore;
					from += count;
				}

				Modal.alert(Locale.get('success'), Locale.get('exportedReportsBulk', {
					success: success,
					failed: failed
				}));
			}
		} catch (e) {}

		progress.destroy();
	}

	/**
	 * Export Atex inspection history with user details.
	 */
	public static async exportXLSXAuditUserHistory(): Promise<void> {
		const checklists = await AtexInspectionChecklistService.get(true);

		// Control data page access
		let from: number = 0;
		const count: number = 500;

		const nd = '';

		// XLSX data exported into the file
		const data: any[][] = [[
			Locale.get('updatedAt'), Locale.get('inspectionUuid'), Locale.get('assetUuid'), Locale.get('name'), 
			Locale.get('tag'), Locale.get('result'), Locale.get('resultFinal'), Locale.get('status'),
			Locale.get('inspectionChecklists'),
			Locale.get('parentUuid'), Locale.get('parentName'), Locale.get('parentTag'),
			Locale.get('userUuid'), Locale.get('email'), Locale.get('userName'), Locale.get('companyUuid'), Locale.get('action'),
			Locale.get('nonConformities'), Locale.get('justifications'), Locale.get('notes')
		]];

		const inspectionFields = await AtexInspectionFieldsService.get(true);

		const assetCache: Map<UUID, APAsset> = new Map();

		const usersCache: Map<UUID, User> = new Map();

		// History action codes (create, update)
		const actionCodes: Map<number, string> = new Map([
			[1, 'created'],
			[2, 'updated']
		]);

		// Auxiliary method to get assets from the API server. Asset data is stored in the map to prevent hitting the server unnecessarily.
		async function getAsset(uuid: UUID): Promise<APAsset> {
			if (!uuid) {
				return null;
			}

			if (assetCache.has(uuid)) {
				return assetCache.get(uuid);
			}

			const asset = await AssetService.get(uuid, true);
			assetCache.set(uuid, asset);

			return asset;
		}

		// Auxiliary method to get users from the API server. User data is stored in the map to prevent hitting the server unnecessarily.
		async function getUser(uuid: UUID): Promise<User> {
			if (!uuid) {
				return null;
			}

			if (usersCache.has(uuid)) {
				return usersCache.get(uuid);
			}

			const request = await Service.fetch(ServiceList.users.get, null, null, {uuid: uuid}, Session.session, true);
			const user = User.parse(request.response.user);
			usersCache.set(uuid, user);
			return user;
		}

		const progress = new ProgressBar();
		progress.show();

		try {
			const total = (await Service.fetch(ServiceList.atex.inspection.count, null, null, null, Session.session, true)).response.count;
			if (total > 0) {
				while (true) {
					const request = await Service.fetch(ServiceList.atex.inspection.listDetailedWithAsset, null, null, {
						status: AtexInspectionStatus.ALL,
						from: from,
						count: count
					}, Session.session, true);

					const response = request.response;

					for (let i = 0; i < response.inspections.length; i++) {
						progress.update(Locale.get('loadingData'), (from + i) / total);

						const inspection = AtexInspection.parse(response.inspections[i].inspection);
						const asset = APAsset.parse(response.inspections[i].asset);
						const responseHistory = await Service.fetch(ServiceList.atex.inspection.historyList, null, null, {uuid: inspection.uuid}, Session.session, true);

						for (const j in responseHistory.response.actions) {
							const action = responseHistory.response.actions[j];

							const user = await getUser(action.user);

							let row = [
								new Date(action.date).toLocaleString(Locale.code), inspection.uuid, asset.uuid, asset.name,
								asset.tag, Locale.get(AtexInspectionResultLabel.get(action.result)), Locale.get(AtexInspectionResultLabel.get(action.resultFinal)), Locale.get(AtexInspectionStatusLabel.get(action.status))
							];

							// Checklists selected
							const checklist = [];
							for (const k in inspection.data.inspections) {
								if (inspection.data.inspections[k] === true) {
									checklist.push(checklists[k].name);
								}
							}
							row.push(checklist.length > 0 ? checklist.join(', ') : nd);

							// Parent Asset
							const parentAsset = await getAsset(asset.parentUuid);
							row = row.concat(parentAsset ? [parentAsset.uuid, parentAsset.name, parentAsset.tag] : [nd, nd, nd]);
							
							row = row.concat([user.uuid, user.email, user.name, user.companyUuid, Locale.get(actionCodes.get(action.action))]);

							const nonConformitiesCell = [];
							const justificationsCell = [];
							const notesCell = [];

							// Inspector Inspection fields
							for (const r in inspection.data.responses.inspector) {
								if (inspection.data.responses.inspector[r] && inspection.data.responses.inspector[r].result === AtexInspectionFieldResult.NOK && !inspection.data.responses.inspector[r].notApplicable) {
									let fieldLabel: string = '';
									const justifications: string[] = [];
									for (const f in inspectionFields) {
										if (f === r) {
											fieldLabel = inspectionFields[f].label ? inspectionFields[f].label : '';

											for (let k = 0; k < inspection.data.responses.inspector[r].justifications.length; k++) {
												justifications.push(inspectionFields[f].justifications[inspection.data.responses.inspector[r].justifications[k]]);
											}
											break;
										}
									}
									const id = r + ' - ';
									if (fieldLabel) {
										nonConformitiesCell.push(id + fieldLabel);
									}
									if (justifications.length > 0) {
										justificationsCell.push(id + justifications.join(' / '));
									}
									if (inspection.data.responses.inspector[r].notes) {
										notesCell.push(id + inspection.data.responses.inspector[r].notes);
									}
								}
							}

							// Backoffice Inspection fields
							for (const r in inspection.data.responses.backoffice) {
								if (inspection.data.responses.backoffice[r] && inspection.data.responses.backoffice[r].result === AtexInspectionFieldResult.NOK && !inspection.data.responses.backoffice[r].notApplicable) {
									let fieldLabel: string = '';
									const justifications: string[] = [];
									for (const f in inspectionFields) {
										if (f === r) {
											fieldLabel = inspectionFields[f].label ? inspectionFields[f].label : '';
											for (let k = 0; k < inspection.data.responses.backoffice[r].justifications.length; k++) {
												justifications.push(inspectionFields[f].justifications[inspection.data.responses.backoffice[r].justifications[k]]);
											}
											break;
										}
									}
									const id = r + ' - ';
									if (fieldLabel) {
										nonConformitiesCell.push(id + fieldLabel);
									}
									if (justifications.length > 0) {
										justificationsCell.push(id + justifications.join(' / '));
									}
									if (inspection.data.responses.backoffice[r].notes) {
										notesCell.push(id + inspection.data.responses.backoffice[r].notes);
									}

								}
							}

							row.push(nonConformitiesCell.join(';'));
							row.push(justificationsCell.join(';'));
							row.push(notesCell.join(';'));

							data.push(row);
						}
					}

					from += response.inspections.length;

					if (!response.hasMore) {
						break;
					}
				}

				// Export XLSX file created from API data.
				XlsxUtils.writeFile(data, 'atex_inspection_history.xlsx');
			}
		} catch (e) {
			Modal.alert(Locale.get('error'), Locale.get('errorExport'));
			console.error('EQS: Error exporting file.', e);
		}

		progress.destroy();
	}	
}
