import {Component, OnInit, ViewChild} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';

import {UnoFormComponent} from '../../../../components/uno-forms/uno-form/uno-form.component';
import {Service} from '../../../../http/service';
import {ServiceList} from '../../../../http/service-list';
import {App} from '../../../../app';
import {UnoFormField} from '../../../../components/uno-forms/uno-form/uno-form-field';
import {UnoFormModule} from '../../../../components/uno-forms/uno-form.module';
import {MasterSettingsAppearance} from '../../../../models/master-settings/master-settings-appearance';
import {UserPermissions} from '../../../../models/users/user-permissions';
import {Locale} from '../../../../locale/locale';
import {Modal} from '../../../../modal';
import {ScreenComponent} from '../../../../components/screen/screen.component';
import {Session} from '../../../../session';
import {StyleManager} from '../../../../theme/style-manager';
import {UnoButtonComponent} from '../../../../components/uno/uno-button/uno-button.component';
import {UnoContentComponent} from '../../../../components/uno/uno-content/uno-content.component';
import {AppearanceMasterSettingsLayout} from './appearance-master-settings-layout';

@Component({
	selector: 'master-settings-appearance-page',
	templateUrl: './appearance-master-settings-edit.page.html',
	standalone: true,
	imports: [UnoContentComponent, UnoFormModule, UnoButtonComponent, TranslateModule]
})
export class MasterSettingsAppearancePage extends ScreenComponent implements OnInit {

	public get appearanceLayout(): UnoFormField[] { return AppearanceMasterSettingsLayout.layout; }
	
	@ViewChild('appearanceForm', {static: false})
	public appearanceForm: UnoFormComponent = null;

	/**
	 * Application appearance settings
	 */
	public appearance: MasterSettingsAppearance = null;
	
	public permissions = [
		UserPermissions.MASTER_SETTINGS_EDIT
	];

	public ngOnInit(): void {
		super.ngOnInit();

		this.appearance = null;

		this.loadSettings();

		App.navigator.setTitle('appearance');
	}

	public loadSettings(): void {
		Service.call(ServiceList.masterSettings.appearance.get, null, null, {}, null, (response, xhr) => {
			this.appearance = MasterSettingsAppearance.parse(response.appearance);
		});
	}

	/**
	 * Reset the appearance settings to default values.
	 */
	public reset(): void {
		this.appearance = new MasterSettingsAppearance();
	}

	/**
	 * Update the information on server
	 * 
	 * @param stayOnPage - If true it stays on the page after update.
	 */
	public update(stayOnPage: boolean = false): void {
		Service.call(ServiceList.masterSettings.appearance.update, null, null, this.appearance, Session.session, (response, xhr) => {
			Modal.toast(Locale.get('settingsUpdated'));

			StyleManager.setAppearance(this.appearance);

			if (!stayOnPage) {
				App.navigator.pop();
			}
		});
	}
}
