import {UUID, UUIDIdentification} from '../uuid';

/**
 * Keeps data inputs for an asset and a field.
 */
export class APAssetFieldData extends UUIDIdentification {
	/**
	 * UUID of the asset which this data will be stored for.
	 */
	public assetUuid: UUID = null;

	/**
	 * UUID of the card this fields is in.
	 */
	public cardUuid: UUID = null;

	/**
	 * UUID of the field this data belongs to.
	 */
	public fieldUuid: UUID = null;

	/**
	 * Data to be stored for the field of a given asset UUID.
	 */
	public data: any = null;

	/**
	 * Parse data into InspectionData object.
	 *
	 * @param data - Data to be parsed.
	 * @returns Object of the correct type.
	 */
	public static parse(data: any): APAssetFieldData {
		const assetData = new APAssetFieldData();

		assetData.uuid = data.uuid;
		assetData.createdAt = new Date(data.createdAt);
		assetData.updatedAt = new Date(data.updatedAt);

		assetData.assetUuid = data.assetUuid;
		assetData.cardUuid = data.cardUuid;
		assetData.fieldUuid = data.fieldUuid;
		assetData.data = data.data;

		return assetData;
	}
}
