/**
 * Object that represents an action the action plan.
 */
export class ActionPlanAction {
	/**
	 * Numeric ID of the action entry.
	 */
	public id: number = 0;

	/**
	 * Label show to the user.
	 */
	public label: string = '';

	/**
	 * Cost of this action in euros.
	 */
	public cost: number = 0;

	/**
	 * Get action by its id from a list of actions.
	 *
	 * @param actions - List of action has to be retrieved using the load() method.
	 * @param id - Action ID searching for.
	 */
	public static getAction(actions: ActionPlanAction[], id: number): ActionPlanAction {
		for (let i = 0; i < actions.length; i++) {
			if (actions[i].id === id) {
				return actions[i];
			}
		}

		return null;
	}

	/**
	 * Parse object data into a ActionPlanAction object.
	 *
	 * @param data - Object data to parse.
	 * @returns ActionPlanAction object instance built from the received data.
	 */
	public static parse(data: any): ActionPlanAction {
		const actionPlanActionData = new ActionPlanAction();

		actionPlanActionData.id = data.id;
		actionPlanActionData.label = data.label;
		actionPlanActionData.cost = data.cost;

		return actionPlanActionData;
	}
}
