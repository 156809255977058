<!-- Buttons to display on tabs-->
<ng-template #saveButtons>
	<!-- Submit buttons -->
	<div style="display: flex; flex-direction: row-reverse; gap: 5px;" class="ion-padding">
		@if (this.createMode && ([userPermissions.GA_ACTION_PLAN_CREATE] | hasPermissions)) {
			<uno-button (click)="this.save();">{{'create' | translate}}</uno-button>
		}

		@if (!this.createMode) {
			<uno-button (click)="this.save();">{{'save' | translate}}</uno-button>
			<uno-button (click)="this.save(undefined, true);">{{'update' | translate}}</uno-button>

			@if (this.actionPlan?.status === actionPlanStatus.PROPOSAL) {
				<uno-button (click)="this.save(actionPlanStatus.WAITING_APPROVAL);" color="success">{{'submit' | translate}}</uno-button>
			}
			@else if (this.actionPlan?.status === actionPlanStatus.WAITING_APPROVAL) {
				<uno-button (click)="this.save(actionPlanStatus.IMPLEMENTATION);" color="success">{{'approve' | translate}}</uno-button>
				<uno-button (click)="this.save(actionPlanStatus.REJECTED);" color="warning">{{'reject' | translate}}</uno-button>
			}
			@else if (this.actionPlan?.status === actionPlanStatus.IMPLEMENTATION) {
				<uno-button (click)="this.save(actionPlanStatus.REINSPECTION);" color="success">{{'finish' | translate}}</uno-button>
				<uno-button (click)="this.save(actionPlanStatus.BLOCKED);" color="warning">{{'blocked' | translate}}</uno-button>
			}
			@else if (this.actionPlan?.status === actionPlanStatus.BLOCKED) {
				<uno-button (click)="this.save(actionPlanStatus.IMPLEMENTATION);" color="success">{{'unlock' | translate}}</uno-button>
			}
			@else if (this.actionPlan?.status === actionPlanStatus.REINSPECTION) {
				<uno-button (click)="this.save(actionPlanStatus.DONE);">{{'finish' | translate}}</uno-button>
			}
			@else if (this.actionPlan?.status !== actionPlanStatus.ARCHIVED) {
				<uno-button (click)="this.save(actionPlanStatus.ARCHIVED);" color="warning">{{'archive' | translate}}</uno-button>
			}

			@if ([userPermissions.GA_ACTION_PLAN_DELETE] | hasPermissions) {
				<uno-button (click)="this.delete();" color="error">{{'delete' | translate}}</uno-button>
			}
		}
	</div>
</ng-template>

<div style="width:100%; height:100%;">
	<uno-tab>
		<uno-tab-section [active]="true" title="{{'actionPlan' | translate}}">
			<!-- Base Form -->
			<uno-form [layout]="this.actionPlanLayout" [object]="this.actionPlan" #actionPlanForm></uno-form>

			@if (this.dl50InspectionQuestionsLayoutObjs?.length > 0) {
				<!-- DL50 inspection fields that generated the selected gaps -->
				<uno-title>{{'dl50' | translate}}</uno-title>
				@for (inspectionGapFields of this.dl50InspectionQuestionsLayoutObjs; track inspectionGapFields) {
					<uno-form [layout]="inspectionGapFields.layout" [object]="inspectionGapFields.object"></uno-form>
				}
			}

			<ng-container [ngTemplateOutlet]="saveButtons"></ng-container>
		</uno-tab-section>

		@if (this.dl50InspectionQuestionsLayoutObjs?.length > 0) {
			<uno-tab-section [active]="false" title="{{'dl50' | translate}}">
				<!-- Limited DL50 Inspections -->
				@for (obj of this.dl50InspectionsLayoutObjs; track obj) {
					<div style="padding-top: 5px; padding-bottom: 5px;">
						<uno-form-collapsable-field [editable]="this.actionPlan.status === this.actionPlanStatus.REINSPECTION" [object]="obj.object" [row]="obj.layout"></uno-form-collapsable-field>

						<div style="display: flex; flex-direction: row-reverse; gap: 5px;" class="ion-padding">
							@if ([userPermissions.DL50_INSPECTIONS_EDIT] | hasPermissions) {
								<uno-button (click)="app.navigator.navigate('/menu/dl50/inspections/edit', {uuid: obj.object.uuid});">{{'viewInspection' | translate}}</uno-button>
							}
						</div>
					</div>
				}

				<ng-container [ngTemplateOutlet]="saveButtons"></ng-container>
			</uno-tab-section>
		}

		<!-- Assets -->
		@if (this.assets?.length > 0 && (userPermissions.ASSET_PORTFOLIO | hasPermissions)) {
			<uno-tab-section title="{{'assets' | translate}}">
				@for (asset of this.assets; track asset) {
					<uno-title>{{'asset' | translate}}</uno-title>

					<!-- Asset information -->
					<uno-form [editable]="false" [layout]="this.assetsLayout" [object]="asset"></uno-form>
					<!-- Open page -->
					<div style="display: flex; flex-direction: row-reverse; gap: 5px;" class="ion-padding">
						@if ([userPermissions.ASSET_PORTFOLIO] | hasPermissions) {
							<uno-button (click)="app.navigator.navigate('/menu/asset-portfolio/asset/edit', {uuid: asset.uuid});">{{'viewAsset' | translate}}</uno-button>
						}
					</div>
				}

				<ng-container [ngTemplateOutlet]="saveButtons"></ng-container>
			</uno-tab-section>
		}

		<!-- History -->
		@if (!this.createMode && this.history?.length > 0) {
			<uno-tab-section title="{{'history' | translate}}">
				<ion-list class="ion-no-padding ion-no-margin">
					@for (option of this.history; track option) {
						<ion-item class="ion-margin-horizontal ion-no-padding" lines="full">
							<ion-label>{{option.updatedAt | formatDate}}</ion-label>
							<div class="ion-text-end">
								<ion-label>{{"userName" | translate}}: {{option.userName}}</ion-label>
								<ion-label color="medium" style="font-size: 13px;">{{option.userEmail}}</ion-label>
							</div>
						</ion-item>
					}
				</ion-list>

				<ng-container [ngTemplateOutlet]="saveButtons"></ng-container>

			</uno-tab-section>
		}
	</uno-tab>
</div>
