import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {NFCPage} from './screens/nfc.page';

const routes: Routes = [
	{
		path: '',
		component: NFCPage
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class NFCRouterModule { }
