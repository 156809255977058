import {UnoFormField} from '../../../../../../components/uno-forms/uno-form/uno-form-field';
import {UnoFormFieldTypes} from '../../../../../../components/uno-forms/uno-form/uno-form-field-types';
import {RepairInspectionReport} from '../../../../repair-inspections/data/repair-inspection-report';
import {RepairReport} from '../../../data/repair-report';

export class RepairSettingsLayout {
	public static layout: UnoFormField[] = [
		{
			type: UnoFormFieldTypes.TITLE,
			label: 'settings'
		},
		{
			attribute: 'reportTemplate',
			label: 'reportTemplate',
			type: UnoFormFieldTypes.DOCUMENT_RESOURCE,
			filter: '.docx',
			isActive: true,
			sampleData: RepairReport.defaultReportURL
		},
		{
			attribute: 'proposalTemplate',
			label: 'proposalTemplate',
			type: UnoFormFieldTypes.DOCUMENT_RESOURCE,
			filter: '.docx',
			isActive: true,
			sampleData: RepairReport.defaultProposalURL
		},
		{
			label: 'repairInspection',
			type: UnoFormFieldTypes.TITLE
		},
		{
			attribute: 'inspectionReportTemplate',
			label: 'reportTemplate',
			type: UnoFormFieldTypes.DOCUMENT_RESOURCE,
			filter: '.docx',
			isActive: true,
			sampleData: RepairInspectionReport.defaultReportURL
		}
	];
}
