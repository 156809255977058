import {Component, ElementRef, Input, ViewChild, ViewEncapsulation} from '@angular/core';
import {NgClass, NgStyle} from '@angular/common';
import {CssNgStyle} from '../../../utils/css-ng-style';

/**
 * UNO content is a scrollable division for content that occupies the entire parent element.
 */
@Component({
	selector: 'uno-content',
	templateUrl: './uno-content.component.html',
	styleUrls: ['./uno-content.component.scss'],
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [NgStyle, NgClass]
})
export class UnoContentComponent {
	@ViewChild('div', {static: true})
	public div: ElementRef = null;

	/**
	 * Style to apply to the element.
	 */
	@Input()
	public ngStyle: CssNgStyle = {};

	/**
	 * Include borders for the content.
	 * 
	 * Border are adjusted based on screen size.
	 */
	@Input()
	public borders: boolean = false;
}
