import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {GAActionPlanEditPage} from './screens/edit/action-plan-edit.page';
import {GAActionPlanListPage} from './screens/list/action-plan-list.page';
import {GAActionPlanMenuPage} from './screens/menu/action-plan-menu.page';

const routes: Routes = [
	{
		path: '',
		component: GAActionPlanMenuPage
	},
	{
		path: 'list',
		component: GAActionPlanListPage
	},
	{
		path: 'edit',
		component: GAActionPlanEditPage
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class GapAnalysisActionPlansRouterModule { }
