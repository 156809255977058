import {UUID, UUIDIdentification} from '../uuid';
import {APAssetFormBlockField} from './asset-form-block-field';

/**
 * Possible form block private filters.
 */
export const APAssetFormBlockPrivateFilter = {
	ALL: -1,
	PUBLIC: 0,
	PRIVATE: 1
};

/**
 * Form Blocks are used to store customized forms fields.
 */
export class APAssetFormBlock extends UUIDIdentification {
	/**
	 * Name of the form block. This name must be unique.
	 */
	public name: string = '';

	/**
	 * Description of the form block.
	 */
	public description: string = '';

	/**
	 * Indicates if the block is private.
	 *
	 * Private blocks are created on a specific type/sub-type form and are not meant to be reused in any other place.
	 */
	public privateBlock: boolean = false;

	/**
	 * The UUID of the type which created this block.
	 *
	 * This can be null when not created by a type or when it is private block.
	 */
	public typeUuid: UUID = null;

	/**
	 * The UUID of the sub-type which created this block.
	 *
	 * This can be null when not created by a sub-type or when it is private block.
	 */
	public subTypeUuid: UUID = null;

	/**
	 * Form block fields
	 */
	public fields: APAssetFormBlockField[] = [];

	/**
	 * Parse JSON data received from the API into an object.
	 *
	 * @param data - Object received from API.
	 * @returns Form block object instance built from the data received.
	 */
	public static parse(data: any): APAssetFormBlock {
		const block = new APAssetFormBlock();

		block.uuid = data.uuid;
		block.updatedAt = new Date(data.updatedAt);
		block.createdAt = new Date(data.createdAt);

		block.name = data.name;
		block.description = data.description;
		
		block.privateBlock = data.privateBlock === true;
		block.typeUuid = data.typeUuid;
		block.subTypeUuid = data.subTypeUuid;

		if (data.fields) {
			for (const field of data.fields) {
				block.fields.push(APAssetFormBlockField.parse(field));
			}
		}
			
		return block;
	}
}
