<div>
	<div class="nfc-reader-header">
		<div class="nfc-reader-title">
			NFC
		</div>
		<div class="nfc-reader-close" (click)="dismiss()">
			<uno-icon src="assets/icons/uno/close.svg" color="primary" width="32"></uno-icon>
		</div>
	</div>

	<div class="nfc-reader-container">
		<div class="nfc-reader-icon">
			<img src="assets/icons/uno/nfc/nfc-light.svg">
		</div>
		<span class="nfc-reader-label">{{'readNFC' | translate}}</span>
	</div>
</div>
