<uno-content [borders]="true">
	<!-- LDS header-->
	<div>
		<h2 style="text-align: center;margin-top:10px;">{{this.lds?.name}}</h2>
	</div>

	<!-- Dropdown-->
	<div style="display: flex; flex-direction: row; align-items: center; width: fit-content; gap: 10px; margin: 0 10px;">
		<uno-filter-bar style="height: 70px;" [filters]="selfStatic.filters" [options]="selfStatic.filterOptions" (onChange)="this.loadSelectedLDSData($event, this.data.ldsUuid);"></uno-filter-bar>
	</div>

	<!-- Graph -->
	<div #chart style="width: 100%; height: calc(100% - 120px); padding: 0 10px;"></div>
</uno-content>
