/**
 * List of possible status for the repair entries.
 *
 * Status is used to control the flow of the repairs.
 */
export const RepairStatus = {
	/**
	 * All valid status
	 */
	ALL: -1,
	
	/**
	 * Status upon creation of a new repair.
	 */
	NONE: 0,

	/**
	 * Proposal phase is where the required basic info about the repair is introduced. The proposal will then be reviewed and accepted/rejected.
	 */
	PROPOSAL: 1,

	/**
	 * Waiting for client approval of repair proposal / budget estimate.
	 */
	WAITING_PROPOSAL_APPROVAL: 2,

	/**
	 * The repair is being implemented.
	 */
	ON_GOING: 3,

	/**
	 * Waiting for approval over the implemented repair work.
	 */
	QUALITY_ASSURANCE: 4,

	/**
	 * Repair entry was completed and it now implemented.
	 */
	IMPLEMENTED: 5,

	/**
	 * Repair is blocked by any need (documents, waiting for something, etc)
	 */
	BLOCKED: 6,

	/**
	 * Repair process was archived.
	 */
	ARCHIVED: 8
};

/**
 * List of possible status for the repair entries.
 */
export const RepairStatusLabel: Map<number, string> = new Map([
	[RepairStatus.NONE, 'none'],
	[RepairStatus.PROPOSAL, 'waitingProposal'],
	[RepairStatus.WAITING_PROPOSAL_APPROVAL, 'waitingProposalApproval'],
	[RepairStatus.ON_GOING, 'onGoing'],
	[RepairStatus.QUALITY_ASSURANCE, 'qualityAssurance'],
	[RepairStatus.IMPLEMENTED, 'implemented'],
	[RepairStatus.BLOCKED, 'blocked'],
	[RepairStatus.ARCHIVED, 'archived']
]);
