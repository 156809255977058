export enum CalendarEventOccurrencePriority {
	// Event occurrence medium priority
	MEDIUM = 1,
	// Event occurrence low priority
	LOW = 2,
	// Event occurrence high priority
	HIGH = 3
}

export const CalendarEventOccurrencePriorityLabel = new Map<CalendarEventOccurrencePriority, string> ([
	[CalendarEventOccurrencePriority.MEDIUM, 'medium'],
	[CalendarEventOccurrencePriority.LOW, 'low'],
	[CalendarEventOccurrencePriority.HIGH, 'high']
]);

export const CalendarEventOccurrencePriorityIcon = new Map<CalendarEventOccurrencePriority, string> ([
	[CalendarEventOccurrencePriority.MEDIUM, '/assets/icons/uno/calendar/priority-medium.svg'],
	[CalendarEventOccurrencePriority.LOW, '/assets/icons/uno/calendar/priority-low.svg'],
	[CalendarEventOccurrencePriority.HIGH, '/assets/icons/uno/calendar/priority-high.svg']
]);
