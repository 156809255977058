<div class="uno-tree-item" (click)="this.itemClick($event, this.item);">

	<div class="uno-tree-item-arrow" [ngStyle]="{'min-width': this.level * 30 + 'px'}" (click)="this.expand($event);">
		@if (!this.childrenAttribute || this.item | objectAttribute:this.childrenAttribute) {
			<ion-icon name="{{this.expanded() ? 'chevron-down-outline' : 'chevron-forward-outline'}}"></ion-icon>
		}
	</div>

	@if (this.pictureField) {
		<img loading="lazy" style="min-width: 30px; min-height: 30px; max-width: 30px; max-height: 30px; overflow: hidden; border-radius: 50%;" src="{{(this.item | objectAttribute:pictureField.attribute) | resourceUrl}}" />
	}

	<div class="uno-tree-item-content">
		@for (row of this.layout; track row) {
			@if (row.type === layoutType.TITLE) {
				<div class="uno-tree-item-title">
					{{this.item | objectAttribute:row.attribute}}
				</div>
			} @else if (row.type === layoutType.LABEL) {
				<div class="uno-tree-item-label">
					{{this.item | objectAttribute:row.attribute}}
				</div>
			}
		}
	</div>
</div>

@if (this.expanded() && this.children?.length > 0) {
	@for (child of this.children; track child) {
		<uno-tree-item [level]="this.level + 1" [item]="child" [loadData]="this.loadData" [layout]="this.layout" [pictureField]="this.pictureField" [onClick]="this.onClick"></uno-tree-item>
	}
}
