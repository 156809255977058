import {Box3, Vector3, Matrix4, Object3D} from 'three';
import {DigitalTwinObjectType} from '../../data/digital-twin-object-type';

/**
 * Object utils contains auxiliary methods to manipulate objects.
 */
export class Object3DUtils {
	/**
	 * Calculates a bounding box for an object considering all its children.
	 *
	 * Used to calculate the bounding box on object focus.
	 *
	 * @param object - Object to calculate bounding box for.
	 * @returns Box calculated from the object and children geometries.
	 */
	public static calculateBoundingBox(object: Object3D): Box3 {
		let box: Box3 = null;
		let onlyHasMarkers: boolean = true;
		let onlyEmptyAssets: boolean = true;

		object.traverse(function(children: Object3D) {
			onlyEmptyAssets = false;

			let position: Vector3 = null;
			let boundingBox: Box3 = null;

			// Markers
			if (onlyHasMarkers === true && children.type === DigitalTwinObjectType.MARKER) {
				position = new Vector3();
				children.getWorldPosition(position);
				boundingBox = new Box3(position.clone().subScalar(1.0), position.clone().addScalar(1.0));
			// Not markers
			} else {
				// Sprites
				// @ts-ignore
				if (children.isSprite === true) {
					onlyHasMarkers = false;
					position = new Vector3();
					children.getWorldPosition(position);
					boundingBox = new Box3(position.clone().subScalar(0.5), position.clone().addScalar(0.5));
				// Mesh, Points, Lines
				// @ts-ignore
				} else if (children.geometry) {
					onlyHasMarkers = false;
					// @ts-ignore
					children.geometry.computeBoundingBox();
					// @ts-ignore
					boundingBox = children.geometry.boundingBox.clone();
					boundingBox.applyMatrix4(children.matrixWorld);
				// Bouding box
				// @ts-ignore
				} else if (children.boundingBox) {
					// @ts-ignore
					boundingBox = children.boundingBox.clone();
					boundingBox.applyMatrix4(children.matrixWorld);
				// Something else
				} else {
					return;
				}
			}

			// Update bounding box size
			if (boundingBox !== null) {
				// First box
				if (box === null) {
					box = boundingBox;
				// Adjust box size to contain new box
				} else {
					if (boundingBox.min.x < box.min.x) {box.min.x = boundingBox.min.x;}
					if (boundingBox.max.x > box.max.x) {box.max.x = boundingBox.max.x;}
					if (boundingBox.min.y < box.min.y) {box.min.y = boundingBox.min.y;}
					if (boundingBox.max.y > box.max.y) {box.max.y = boundingBox.max.y;}
					if (boundingBox.min.z < box.min.z) {box.min.z = boundingBox.min.z;}
					if (boundingBox.max.z > box.max.z) {box.max.z = boundingBox.max.z;}
				}
			}
		});

		if (onlyEmptyAssets) {
			return null;
		}

		return box;
	}

	/**
	 * Recalculate object and children origins, to be centered with geometry.
	 */
	public static centerGeometries(object: Object3D): void {
		object.traverse(function(children: any) {
			if (children.geometry !== undefined) {
				children.geometry.computeBoundingBox();

				const box = children.geometry.boundingBox.clone();

				const center = box.getCenter(new Vector3());
				children.position.add(center);

				const matrix = new Matrix4();
				matrix.makeTranslation(-center.x, -center.y, -center.z);
				children.geometry.applyMatrix(matrix);
			}
		});
	}
}
