<div style="height: 40px; margin: 10px;">
	<uno-searchbar style="width: 100%;" [value]="selfStatic.filters.search" (valueChange)="onFilterChange($event)"></uno-searchbar>
</div>

<ng-template #itemTemplate let-log="item">
	<uno-list-item>
		<uno-list-item-label class="ion-text-wrap">
			<h2>{{'ID' + log.id + ' | ' + log.method + ' - ' + log.route}}</h2>
			<h3>{{log.date | formatDate}}</h3>
			<p>{{log.received.length < 1e4 ? log.received : ('file' | translate)}}</p>
		</uno-list-item-label>
	</uno-list-item>
</ng-template>

<uno-content [ngStyle]="{height: 'calc(100% - 60px)'}">
	<uno-list [handler]="this.handler" [itemTemplate]="itemTemplate"></uno-list>
</uno-content>
