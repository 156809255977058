import {UnoFormFieldTypes} from '../../../components/uno-forms/uno-form/uno-form-field-types';
import {UnoFormField} from '../../../components/uno-forms/uno-form/uno-form-field';
import {ObservationStatus, ObservationStatusLabel} from '../../../models/observations/observation-status';

export const ObservationsLayout: UnoFormField[] = [
	{
		label: 'status',
		attribute: 'status',
		type: UnoFormFieldTypes.OPTIONS,
		sort: false,
		options: Object.values(ObservationStatus).map(function(value) {
			return {value: value, label: ObservationStatusLabel.get(value)};
		}),
		isActive: false
	},
	{
		required: true,
		attribute: 'name',
		label: 'name',
		type: UnoFormFieldTypes.TEXT
	},
	{
		attribute: 'description',
		label: 'description',
		type: UnoFormFieldTypes.TEXT_MULTILINE
	},
	{
		attribute: 'assetUuid',
		label: 'asset',
		multiple: false,
		type: UnoFormFieldTypes.ASSET_SELECTOR,
		showClear: true
	},
	{
		attribute: 'position',
		label: 'position',
		type: UnoFormFieldTypes.GEO_POSITION
	},
	{
		attribute: 'photos',
		label: 'photos',
		type: UnoFormFieldTypes.IMAGE_RESOURCE_MULTIPLE
	},
	{
		attribute: 'documents',
		label: 'documents',
		type: UnoFormFieldTypes.DOCUMENT_RESOURCE_MULTIPLE
	}
];
