import {Component, forwardRef, Input, OnChanges, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR, FormsModule} from '@angular/forms';
import {Locale} from 'src/app/locale/locale';
import {TranslateModule} from '@ngx-translate/core';
import {NgClass} from '@angular/common';
import {CdkConnectedOverlay, CdkOverlayOrigin} from '@angular/cdk/overlay';
import {UnoIconComponent} from '../uno-icon/uno-icon.component';

export type UnoPeriodicityValue = {repeat: number, periodicity: string};

/**
 * The Periodicity Input Selector, first select a periodicity type (yearly, monthly, daily, hourly) and then the ammount of times for it to happen.
 */
@Component({
	selector: 'uno-periodicity-selector',
	templateUrl: './uno-periodicity-selector.component.html',
	styleUrls: ['./uno-periodicity-selector.component.css'],
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => { return UnoPeriodicitySelector; }),
		multi: true
	}],
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [CdkConnectedOverlay, CdkOverlayOrigin, NgClass, FormsModule, UnoIconComponent, TranslateModule]
})

export class UnoPeriodicitySelector implements OnChanges, ControlValueAccessor {

	/**
	 * If the element is disabled cannot change the value.
	 */
	@Input()
	public disabled: boolean = false;

	/**
	 * Minimum value of the selector.
	 */
	@Input()
	public min: number = 1;
	
	/**
	 * Max value of the selector.
	 */
	@Input()
	public max: number = 500;

	/**
	 * The currently selected periodicity's (year/month/day/hour) value.
	 */ 
	public periodicity: string = 'years';

	/**
	 * The number currently shown on the repeat input.
	 */
	public repeatNumber: number = 1;

	/**
	 * Whether the periodicity dropdown is expanded or not.
	 */
	public periodicityExpanded: boolean = false;

	/**
	 * Whether the repeat dropdown is expanded or not.
	 */
	public repeatExpanded: boolean = false;

	/**
	 * The list of numbers to show on the repeat dropdown.
	 */
	public numbers: number[] = [];

	/**
	 * value to update on the form
	 */
	public value: UnoPeriodicityValue = {repeat: this.repeatNumber, periodicity: this.periodicity};
	
	/**
	 * The options to be shown on the periodicity dropdown.
	 */
	public options: {value: string, label: string}[] = [{value: 'years', label: Locale.get('years')}, {value: 'months', label: Locale.get('months')}, {value: 'days', label: Locale.get('days')}, {value: 'hours', label: Locale.get('hours')}];
	
	public onChange: (value: any)=> void = function(value) {};


	public ngOnChanges(changes: SimpleChanges): void {

		if (this.numbers.length || changes.min || changes.max) {
			this.numbers = [];

			if (this.min > this.max) {
				[this.min, this.max] = [this.max, this.min];
			}

			// Fill the array with numbers to be used on the number to repeat input
			for (let i = this.min; i <= this.max; i++) {
				this.numbers.push(i);
			}
		}
	}
	

	/**
	 * When the user selects a year/month/day option replace the current active value with its value and closes the dropdown
	 * 
	 * @param value - The value of the periodicity selected
	 */
	public setPeriodicity(value: string): void {
		this.periodicityExpanded = false;

		if (this.disabled) {
			return;
		}

		this.writeValue({repeat: this.repeatNumber, periodicity: value});
	}

	/**
	 * When the user selects or inserts a number replace the current active number and closes the dropdown (if it is open).
	 * 
	 * @param value - The current value on the input
	 */
	public setRepeat(value: any): void {
		this.repeatExpanded = false;

		if (this.disabled) {
			return;
		}

		this.repeatNumber = Number.parseInt(value);
		this.writeValue({repeat: this.repeatNumber, periodicity: this.periodicity});
	}


	public registerOnChange(onChange: any): void {
		this.onChange = onChange;
	}

	public registerOnTouched(fn: any): void {}

	public writeValue(value: UnoPeriodicityValue): void {
		this.value = value;
		this.onChange(value);
	}
}
