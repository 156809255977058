<uno-text [ngStyle]="{position: 'absolute', display: 'block', top: '0px', left: '0px', width: '100%'}" ngClass="welcome">{{'recover' | translate}}</uno-text>
<uno-text [multiline]="true" [ngStyle]="{position: 'absolute', display: 'block', top: '20px', left: '0px', width: '100%', height: '100px'}" ngClass="credentials">{{'enterEmailToRecover' | translate}}</uno-text>

<!-- Email -->
<div [ngStyle]="{position: 'absolute', display: 'block', top: '100px', width: '100%', height: '300px', left: '0px'}">
	<form autocomplete="on">
		<uno-text [ngStyle]="{position: 'absolute', display: 'block', top: '0px', width: '100%'}" ngClass="input">{{'email' | translate}}</uno-text>
		<uno-input [ngStyle]="{position: 'absolute', display: 'block', top: '15px', width: '100%'}" [(ngModel)]="email" [ngModelOptions]="{standalone: true}" placeholder="{{'email' | translate}}" type="email"></uno-input>
	</form>
</div>

<!-- Submit -->
<uno-button [ngStyle]="{position: 'absolute', display: 'flex', top: '120px', width: '100%'}" (click)="this.forgotPassword();" [expand]="true">{{'recover' | translate}}</uno-button>

<!-- Back -->
<div [ngStyle]="{position: 'absolute', display: 'block', top: '300px', width: '100%', height: '40px', cursor: 'pointer'}">
	<uno-text alignment="center" ngClass="recover" (click)="this.returnLogin();">{{'returnLogin' | translate}}</uno-text>
</div>
