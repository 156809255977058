<div style="height: 100%; width: 100%;">
	<canvas #canvas style="top: 0px; height: calc(100% - 60px); width: 100%;"></canvas>

	<div style="height: 60px; width: 100%; display: flex; flex-direction: row;">
		<uno-button (click)="addOperationNode('+', '+');">+</uno-button>
		<uno-button (click)="addOperationNode('-', '+');">-</uno-button>
		<uno-button (click)="addOperationNode('*', 'x');">*</uno-button>
		<uno-button (click)="addOperationNode('/', '/');">/</uno-button>
		<uno-button (click)="addOperationNode('^', '^');">^</uno-button>
		<uno-button (click)="addOperationNode('%', '%');">%</uno-button>
		<uno-button (click)="addFunctionNode('cos', 'Cos');">Cos</uno-button>
		<uno-button (click)="addFunctionNode('sin', 'Sin');">Sin</uno-button>
		<uno-button (click)="addFunctionNode('abs', 'Abs');">Abs</uno-button>
		<uno-button (click)="addFunctionNode('floor', 'Floor');">Floor</uno-button>
		<uno-button (click)="addFunctionNode('ceil', 'Ceil');">Ceil</uno-button>
		<uno-button (click)="addNumberInput();">{{'number' | translate}}</uno-button>
		<uno-button (click)="createGraph();" color="error">{{'clear' | translate}}</uno-button>
	</div>
</div>
