import {Resource} from '../resource';
import {Geolocation} from '../geolocation';
import {UUID, UUIDIdentification} from '../uuid';
import {ObservationStatus} from './observation-status';

/**
 * Observations can be created by any type of user at the end of an inspection.
 */
export class Observation extends UUIDIdentification {
	/**
	 * Status of the task, can be to do or done.
	 */
	public status: number = ObservationStatus.TODO;

	/**
	 * Name of the observation to identify.
	 */
	public name: string = '';

	/**
	 * Description of the observation.
	 */
	public description: string = '';

	/**
	 * UUID of the Asset attached to this observation (if there is any).
	 */
	public assetUuid: UUID = null;

	/**
	 * GPS location of the observation.
	 */
	public position: Geolocation = null;

	/**
	 * Photos attached to this observation.
	 */
	public photos: Resource[] = [];

	/**
	 * Documents attached to this observation.
	 */
	public documents: Resource[] = [];

	/**
	 * Get API data and parse into observation object.
	 *
	 * @param data - Data received from API.
	 */
	public static parse(data: any): Observation {
		const observation = new Observation();

		observation.uuid = data.uuid;
		observation.updatedAt = new Date(data.updatedAt);
		observation.createdAt = new Date(data.createdAt);

		observation.status = data.status;
		observation.name = data.name;
		observation.description = data.description;
		observation.assetUuid = data.assetUuid;
		observation.position = Geolocation.parse(data.position);
		observation.photos = Resource.parseArray(data.photos);
		observation.documents = Resource.parseArray(data.documents);

		return observation;
	}
}
