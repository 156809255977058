import {UnoFormFieldTypes} from '../../../../../../components/uno-forms/uno-form/uno-form-field-types';
import {UnoFormField} from '../../../../../../components/uno-forms/uno-form/uno-form-field';

export const RepairWeldingProcedureLayout: UnoFormField[] = [
	{
		required: true,
		label: 'label',
		attribute: 'label',
		type: UnoFormFieldTypes.TEXT
	}
];
