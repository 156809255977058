import {Component, Input, ViewEncapsulation, forwardRef} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {IonicModule} from '@ionic/angular';
import {NgIf} from '@angular/common';
import {Modal} from '../../../modal';
import {NFCReaderComponent} from '../../../modules/nfc/components/nfc-reader/nfc-reader.component';
import {App} from '../../../app';
import {Locale} from '../../../locale/locale';
import {UnoIconComponent} from '../../uno/uno-icon/uno-icon.component';
import {UnoImgComponent} from '../../uno/uno-img/uno-img.component';
import {UnoButtonComponent} from '../../uno/uno-button/uno-button.component';

@Component({
	selector: 'uno-nfc-tag',
	templateUrl: './uno-nfc-tag.component.html',
	styleUrls: ['./uno-nfc-tag.component.css'],
	encapsulation: ViewEncapsulation.None,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => { return UnoNfcTagComponent; }),
			multi: true
		}
	],
	standalone: true,
	imports: [NgIf, IonicModule, TranslateModule, UnoIconComponent, UnoImgComponent, UnoButtonComponent]
})
export class UnoNfcTagComponent implements ControlValueAccessor {
	/**
	 * Enable/disable the possibility of scanning NFC tags using this component.
	 */
	@Input()
	public disabled = false;

	/**
	 * Resource representing the NFC tag value stored.
	 */
	public value: any = null;

	/**
	 * Method called when the data is changed.
	 */
	public onChange: (value: any)=> void = function(value) {};

	/**
	 * Show the NFC scanner modal to the user.
	 */
	public async select(): Promise<void> {
		if (this.disabled) {
			throw new Error('Cannot read NFC tag because the input is disabled');
		}

		if (!App.device.hasNFC()) {
			Modal.alert(Locale.get('error'), Locale.get('noNFCReader'));
			return;
		}

		let popover: HTMLIonPopoverElement = null;

		const onRead = (id: any): void => {
			this.writeValue(id);
			popover.dismiss();
		};

		popover = await Modal.component(NFCReaderComponent, {onRead: onRead}, true, false, false);
	}

	public registerOnChange(onChange: any): void {
		this.onChange = onChange;
	}

	public writeValue(value: any): void {
		this.value = value;
		this.onChange(this.value);
	}

	/**
	 * Ask for confirmation before deleteing value
	 */
	public async deleteValue(): Promise<void> {
		const confirm = await Modal.confirm(Locale.get('delete'), Locale.get('confirmDelete'), ['yes', 'no']);

		if (confirm) {
			this.writeValue(null);
		}
	}

	public registerOnTouched(fn: any): void {}

	public setDisabledState(disabled: boolean): void {
		this.disabled = disabled;
	}
}
