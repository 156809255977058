/**
 * Possible control modes in the editor.
 */
export enum DigitalTwinEditorMode {
	/**
	 * Visualization only mode. Objects cannot be selected.
	 */
	NAVIGATION = 0,

	/**
	 * Select objects from the scene.
	 *
	 * Activates transform tools to move, rotate, scale objects in the scene.
	 */
	SELECT_OBJECT = 1,

	/**
	 * Add new objects to the scene.
	 */
	PLACE_OBJECT = 2,

	/**
	 * Delete objects from the scene.
	 */
	DELETE_OBJECT = 3
}
