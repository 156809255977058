import {Component, forwardRef, Input, ViewEncapsulation} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

@Component({
	selector: 'office-viewer',
	templateUrl: './office-viewer.component.html',
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => { return OfficeViewerComponent; }),
		multi: true
	}],
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: []

})
export class OfficeViewerComponent {
	/**
	 * URL of the file
	 */
	@Input()
	public url: string = null;
    
	/**
	 * Safe URL for office documents
	 */
	public urlSafe: SafeResourceUrl = '';

	public constructor(public sanitizer: DomSanitizer) {}

	public ngOnChanges(): void {
		const url = `https://view.officeapps.live.com/op/embed.aspx?src=${this.url}`;
		this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(url);
	}
}
