import {Component, OnInit, ViewChild} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {IonicModule} from '@ionic/angular';

import {UnoFormComponent} from '../../../../components/uno-forms/uno-form/uno-form.component';
import {App} from '../../../../app';
import {Modal} from '../../../../modal';
import {Locale} from '../../../../locale/locale';
import {UnoFormModule} from '../../../../components/uno-forms/uno-form.module';
import {EPILayouts} from '../epi-layouts';
import {EPI, EPICableData} from '../../../../models/epi/epi';
import {EPIValidations} from '../../../../models/epi/epi-validations';
import {Service} from '../../../../http/service';
import {ServiceList} from '../../../../http/service-list';
import {Session} from '../../../../session';
import {ScreenComponent} from '../../../../components/screen/screen.component';
import {UserPermissions} from '../../../../models/users/user-permissions';
import {EPIBarrierType} from '../../../../models/epi/epi-barrier';
import {EPIState} from '../../../../models/epi/epi-state';
import {UnoFormField} from '../../../../components/uno-forms/uno-form/uno-form-field';
import {UnoButtonComponent} from '../../../../components/uno/uno-button/uno-button.component';
import {UnoTitleComponent} from '../../../../components/uno/uno-title/uno-title.component';
import {UnoContentComponent} from '../../../../components/uno/uno-content/uno-content.component';
import {EPIService} from '../../services/epi.service';
import {PermissionsPipe} from '../../../../pipes/permissions.pipe';

/**
 * EPI edit page used to created, do and review EPI inspections.
 */
@Component({
	selector: 'epi-edit-page',
	templateUrl: 'epi-edit.page.html',
	styleUrls: ['./epi-edit.page.scss'],
	standalone: true,
	imports: [UnoContentComponent, UnoFormModule, UnoTitleComponent, UnoButtonComponent, IonicModule, TranslateModule, PermissionsPipe]
})
export class EPIEditPage extends ScreenComponent implements OnInit {
	public app: any = App;


	public layouts: any = EPILayouts;

	public barrierType: any = EPIBarrierType;

	public epiState: any = EPIState;

	public validations: any = EPIValidations;

	public session: any = Session;

	public userPermissions: any = UserPermissions;

	/**
	 * Get the calculated resulting cable from all epi cables.
	 */
	public get resultingCable(): EPICableData {
		if (!this.epi) {
			return null;
		}
		return EPIValidations.calculateResultingCable(this.epi.data.cables);
	}

	@ViewChild('detailsForm', {static: false})
	public detailsForm: UnoFormComponent = null;

	@ViewChild('barrierForm', {static: false})
	public barrierForm: UnoFormComponent = null;

	@ViewChild('equipmentForm', {static: false})
	public equipmentForm: UnoFormComponent = null;

	public permissions = [UserPermissions.EPI];

	/**
	 * EPI data that will be sent to the API.
	 */
	public epi: EPI = null;

	/**
	 * Flag to indicate if the page is in create mode.
	 *
	 * If true the page is used to create a new EPI.
	 */
	public createMode: boolean = false;

	/**
	 * Method called when the user updates the cable data to automatically calculate other cable values.
	 *
	 * @param object - Cable object.
	 * @param row - Dynamic form row changed.
	 */
	public onCableChange = (object: any, row: UnoFormField): void => {
		if (object instanceof EPICableData) {
			object.calculateAttributes(row.attribute);
		}
	};

	public async ngOnInit(): Promise<void> {
		super.ngOnInit();
		
		this.createMode = false;
		this.epi = null;

		// Read state from the route data
		const data = App.navigator.getData();
		if (!data) {
			App.navigator.pop();
			return;
		}

		this.createMode = data.createMode === true;
		App.navigator.setTitle(this.createMode ? 'create' : 'edit');

		if (this.createMode) {
			this.epi = new EPI();
		} else {
			this.epi = await EPIService.get(data.uuid);
		}
	}

	/**
	 * Add a new cable to the EPI list of cables.
	 */
	public addCable(): void {
		this.epi.data.cables.push(new EPICableData());
	}


	/**
	 * Save the EPI in the API, without submitting it.
	 *
	 * The EPI is created in the API if it still does not exist.
	 *
	 * @param stayOnPage - If true the application stays on the page after updating data.
	 * @param overrideState - Override the EPI state to change its state.
	 */
	public async save(stayOnPage: boolean = false, overrideState?: number): Promise<void> {
		const epi = structuredClone(this.epi);
		const state = overrideState !== undefined ? overrideState : epi.state;
		const stateChanged = overrideState !== undefined ? overrideState !== epi.state : false;

		if (!this.detailsForm.requiredFilled() || state >= EPIState.BACKOFFICE && (!this.barrierForm.requiredFilled() || !this.equipmentForm.requiredFilled())) {
			Modal.alert(Locale.get('error'), Locale.get('requiredFieldsError'));
			return;
		}

		epi.state = state;

		if (state >= EPIState.BACKOFFICE) {
			epi.result = EPIValidations.getResult(epi);
		}

		await Service.fetch(this.createMode ? ServiceList.epi.create : ServiceList.epi.update, null, null, epi, Session.session);
		
		if (!stayOnPage) {
			App.navigator.pop();
		}

		Modal.toast(stateChanged ? Locale.get('epiSubmitted') : Locale.get('epiSaved'));
	}

	/**
	 * Delete EPI from the database.
	 */
	public async delete(): Promise<void> {
		const confirm = await Modal.confirm(Locale.get('confirm'), Locale.get('confirmDelete'));
		if (confirm) {
			await EPIService.delete(this.epi.uuid);
		
			Modal.toast(Locale.get('deleteSuccessfully'));
			App.navigator.pop();
		}
	}
}
