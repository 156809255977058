import {Session} from 'src/app/session';
import {Service} from '../../../../http/service';
import {ServiceList} from '../../../../http/service-list';
import {UnoFormField} from '../../../../components/uno-forms/uno-form/uno-form-field';
import {UnoFormFieldTypes} from '../../../../components/uno-forms/uno-form/uno-form-field-types';
import {FeatureType, FeatureTypeLabel} from '../../../../models/pipeline-integrity/pipeline/feature-type';


export const FeatureLayoutSimplified: UnoFormField[] = [
	{
		label: 'uuid',
		attribute: 'uuid',
		type: UnoFormFieldTypes.UUID
	},
	{
		required: true,
		attribute: 'type',
		label: 'type',
		type: UnoFormFieldTypes.OPTIONS,
		sort: false,
		editable: true,
		isEmpty: function(object, row): boolean {
			return object.type === FeatureType.NONE;
		},
		options: Object.values(FeatureType).map(function(value) {
			return {value: value, label: FeatureTypeLabel.get(value)};
		})
	},
	{
		required: false,
		attribute: 'subtype',
		label: 'subtype',
		type: UnoFormFieldTypes.TEXT
	},
	{
		required: true,
		attribute: 'name',
		label: 'name',
		type: UnoFormFieldTypes.TEXT
	},
	{
		required: false,
		attribute: 'size',
		label: 'size',
		type: UnoFormFieldTypes.NUMBER
	},
	{
		required: false,
		attribute: 'observations',
		label: 'observations',
		type: UnoFormFieldTypes.TEXT_MULTILINE
	},
	{
		required: false,
		attribute: 'tag',
		label: 'tag',
		type: UnoFormFieldTypes.TEXT
	}
	
];


export const FeatureLayout = FeatureLayoutSimplified.concat([
	{
		required: false,
		label: 'position',
		attribute: 'position',
		type: UnoFormFieldTypes.GEO_POSITION
	},
	{
		required: true,
		attribute: 'segmentUuid',
		label: 'segment',
		type: UnoFormFieldTypes.OPTIONS,
		options: [],
		editable: true,
		fetchOptions: async(object: any, row: UnoFormField) => {
			const request = await Service.fetch(ServiceList.pipelineIntegrity.pipeline.segment.list, null, null, {pipelineUuid: object.pipelineUuid}, Session.session);
			const segments = request.response.segments;

			const options = [];
			for (let i = 0; i < segments.length; i++) {
				options.push({
					label: segments[i].name,
					value: segments[i].uuid
				});
			}
			row.options = options;
		}
	}
]);
