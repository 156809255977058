import {Service} from '../../../../http/service';
import {ServiceList} from '../../../../http/service-list';
import {Session} from '../../../../session';
import {Locale} from '../../../../locale/locale';
import {Modal} from '../../../../modal';
import {UnoFormFieldTypes} from '../../../../components/uno-forms/uno-form/uno-form-field-types';
import {UnoFormField} from '../../../../components/uno-forms/uno-form/uno-form-field';
import {XlsxUtils} from '../../../../utils/xlsx-utils';
import {AtexInspectionFieldsService} from '../services/atex-inspection-fields.service';
import {AtexInspection} from '../../../../models/atex-inspections/inspections/atex-inspection';
import {AtexInspectionStatus} from '../../../../models/atex-inspections/inspections/atex-inspection-status';
import {AtexInspectionResult, AtexInspectionResultLabel} from '../../../../models/atex-inspections/inspections/atex-inspection-result';
import {AtexInspectionFieldResult, AtexInspectionFieldResultLabel} from '../../../../models/atex-inspections/inspections/atex-inspection-field-result';
import {AtexInspectionFormFieldType} from './master-data/atex-inspection-fields';

export class AtexInspectionWizard {
	/**
	 * Change responses for a field of an Atex inspection in bulk.
	 *
	 * The list of inspections to be changed is read from a XLSX file.
	 */
	public static async changeFieldResponseBulk(): Promise<void> {
		const values = {
			// Inspections fields to be changed
			fields: [],
			// If true the already completed inspections are included
			includeCompleted: true,
			// Include backoffice responses
			includeBackoffice: true,
			// Include inspector responses
			includeInspector: true,
			// Response to be applied for the inspection.
			response: AtexInspectionFieldResult.NONE,
			// Override inspection result
			overrideResult: AtexInspectionResult.NONE,
			// Override inspection final result
			overrideFinalResult: AtexInspectionResult.NONE
		};

		const layout: UnoFormField[] = [
			{
				required: false,
				label: 'fields',
				attribute: 'fields',
				type: UnoFormFieldTypes.OPTIONS_MULTIPLE,
				options: [],
				fetchOptions: async function(object: any, row: UnoFormField) {
					row.options = [];
					const fields = await AtexInspectionFieldsService.get();
					for (const i in fields) {
						if (fields[i].type === AtexInspectionFormFieldType.FIELD) {
							row.options.push({
								value: fields[i].attribute,
								label: fields[i].attribute + ' (' + fields[i].label + ')'
							});
						}
					}
				}
			},
			{
				required: true,
				label: 'response',
				attribute: 'response',
				type: UnoFormFieldTypes.OPTIONS,
				isEmpty: function(object, row) {return object.response === AtexInspectionFieldResult.NONE;},
				options: Object.values(AtexInspectionFieldResult).map(function(value) {
					return {value: value, label: AtexInspectionFieldResultLabel.get(value)};
				})
			},
			{
				label: 'includeCompleted',
				attribute: 'includeCompleted',
				type: UnoFormFieldTypes.CHECKBOX
			},
			{
				label: 'includeBackoffice',
				attribute: 'includeBackoffice',
				type: UnoFormFieldTypes.CHECKBOX
			},
			{
				label: 'includeInspector',
				attribute: 'includeInspector',
				type: UnoFormFieldTypes.CHECKBOX
			},
			{
				label: 'overrideResult',
				attribute: 'overrideResult',
				type: UnoFormFieldTypes.OPTIONS,
				required: false,
				options: Object.values(AtexInspectionResult).map(function(value) {
					return {value: value, label: AtexInspectionResultLabel.get(value)};
				})
			},
			{
				label: 'overrideFinalResult',
				attribute: 'overrideFinalResult',
				type: UnoFormFieldTypes.OPTIONS,
				required: false,
				options: Object.values(AtexInspectionResult).map(function(value) {
					return {value: value, label: AtexInspectionResultLabel.get(value)};
				})
			}
		];

		await Modal.form(Locale.get('atexInspection'), values, layout);

		// Get input XLSX file to extract assets tags
		const xlsx = await XlsxUtils.chooseFileXLSX();

		// Read list of tags from the XLSXX file
		const tags = [];
		for (let i = 0; i < xlsx.length; i++) {
			const tag = XlsxUtils.readRow(xlsx[i], ['Tag', 'Tags', 'Tag Number']);
			if (tag !== null) {
				tags.push(tag);
			}
		}

		// Get Atex inspection from its tag number
		for (let i = 0; i < tags.length; i++) {
			const response = await Service.fetch(ServiceList.atex.inspection.listByAssetTag, null, null, {tag: tags[i]}, Session.session);
			const inspections: any[] = response.response.inspections;

			for (let j = 0; j < inspections.length; j++) {
				const inspection: AtexInspection = AtexInspection.parse(inspections[j]);
				let needsUpdate: boolean = false;

				// Skip inspection in status "completed"
				if (!values.includeCompleted && inspection.status === AtexInspectionStatus.COMPLETED) {
					continue;
				}

				// Backoffice responses
				if (values.includeBackoffice) {
					const backoffice = inspection.data.responses.backoffice;

					for (const k in backoffice) {
						if (values.fields.indexOf(k) !== -1) {
							if (values.response === AtexInspectionFieldResult.NOT_APPLICABLE) {
								backoffice[k].notApplicable = true;
								backoffice[k].result = AtexInspectionFieldResult.NOT_APPLICABLE;
							} else {
								backoffice[k].notApplicable = false;
								backoffice[k].result = values.response;
							}

							needsUpdate = true;
						}
					}
				}

				// Inspector responses
				if (values.includeInspector) {
					const inspector = inspection.data.responses.inspector;
					for (const k in inspector) {
						if (values.fields.indexOf(k) !== -1) {
							if (values.response === AtexInspectionFieldResult.NOT_APPLICABLE) {
								inspector[k].notApplicable = true;
								inspector[k].result = AtexInspectionFieldResult.NOT_APPLICABLE;
							} else {
								inspector[k].notApplicable = false;
								inspector[k].result = values.response;
							}

							needsUpdate = true;
						}
					}
				}

				// Override inspection result
				if (values.overrideResult !== AtexInspectionResult.NONE) {
					inspection.result = values.overrideResult;
					needsUpdate = true;
				}

				if (values.overrideFinalResult !== AtexInspectionResult.NONE) {
					inspection.resultFinal = values.overrideFinalResult;
					needsUpdate = true;
				}

				if (needsUpdate) {
					await Service.fetch(ServiceList.atex.inspection.update, null, null, inspection, Session.session);
				}
			}
		}
	}
}
