import {Component, Input, ViewEncapsulation} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {RouterOutlet} from '@angular/router';
import {NgStyle} from '@angular/common';
import {App} from '../../../../app';
import {Session} from '../../../../session';
import {StyleManager} from '../../../../theme/style-manager';
import {MenuOption} from '../../data/menu-option';
import {UnoIconComponent} from '../../../../components/uno/uno-icon/uno-icon.component';
import {UserAvatarComponent} from '../user-avatar/user-avatar.component';
import {ModuleCheckPipe} from '../../../../pipes/module.pipe';
import {PermissionsPipe} from '../../../../pipes/permissions.pipe';

@Component({
	selector: 'menu-mobile-page',
	templateUrl: './menu-mobile.component.html',
	styleUrls: ['./menu-mobile.component.scss'],
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [NgStyle, UserAvatarComponent, RouterOutlet, UnoIconComponent, TranslateModule, ModuleCheckPipe, PermissionsPipe]
})
export class MenuMobileComponent {
	public menuOption: any = MenuOption;

	public app: any = App;

	public session: any = Session;

	public themes: any = StyleManager;

	@Input()
	public pages: MenuOption[] = [];
}
