import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Object3D} from 'three';

import {ScreenComponent} from '../../../../components/screen/screen.component';
import {App} from '../../../../app';
import {UserPermissions} from '../../../../models/users/user-permissions';
import {UUID} from '../../../../models/uuid';
import {Service} from '../../../../http/service';
import {ServiceList} from '../../../../http/service-list';
import {Session} from '../../../../session';
import {DigitalTwinScene} from '../../data/digital-twin-scene';
import {DigitalTwinObjectLoader} from '../../data/digital-twin-object-loader';
import {DigitalTwinObject} from '../../data/digital-twin-object';
import {DigitalTwinEditor} from './editor/digital-twin-editor';
import {RendererCanvasComponent} from './components/renderer-canvas/renderer-canvas.component';
import {DigitalTwinTreeComponent} from './components/digital-twin-tree/digital-twin-tree.component';
import {DigitalTwinToolBarComponent} from './components/digital-twin-tool-bar/digital-twin-tool-bar.component';

/**
 * Digital twin editor page contains all the GUI elements of the editor.
 *
 * Editor logic is stored in the editor object.
 */
@Component({
	selector: 'digital-twin-editor',
	templateUrl: './digital-twin-editor.page.html',
	standalone: true,
	imports: [RendererCanvasComponent, DigitalTwinToolBarComponent, DigitalTwinTreeComponent]
})
export class DigitalTwinEditorPage extends ScreenComponent implements OnInit, OnDestroy {
	public app: any = App;

	@ViewChild('container', {static: true})
	public container: ElementRef = null;

	@ViewChild('canvas', {static: true})
	public canvas: RendererCanvasComponent = null;

	public permissions = [UserPermissions.DIGITAL_TWIN];

	/**
	 * UUID of the scene to be loaded.
	 */
	@Input()
	public uuid: UUID = null;

	/**
	 * UUID of an object in the scene to focus after the scene is loaded.
	 *
	 * The object must belong to the scene.
	 */
	@Input()
	public objectUuid: UUID = null;

	/**
	 * Editor object responsible for all logic and rendering of the editor.
	 *
	 * Shared across the GUI components to manipulate the editor.
	 */
	public editor: DigitalTwinEditor = new DigitalTwinEditor();

	/**
	 * Digital twin scene.
	 */
	public scene: DigitalTwinScene = null;

	public async ngOnInit(): Promise<void> {
		super.ngOnInit();

		const data = App.navigator.getData();
		if (!data || !data.uuid) {
			App.navigator.pop();
			return;
		}

		this.uuid = data.uuid;
		this.objectUuid = data.objectUuid || null;

		this.scene = await this.loadScene(data.uuid);
		const content = await this.loadObjectTree(data.uuid);
		
		App.navigator.setTitle(this.scene.name);

		this.editor.initialize(this.canvas);
		this.editor.scene = this.scene;
		this.editor.setContent(content);

		// Focus the object
		if (this.objectUuid) {
			// The first frame has to be rendered for bounding box to be available
			setTimeout(() => {
				this.editor.content.traverse((obj: Object3D) => {
					if (obj instanceof DigitalTwinObject && obj.uuid === this.objectUuid) {
						this.editor.select(obj);
						this.editor.controls.focusObject(obj);
					}
				});
			}, 1000);
		}
	}

	/**
	 * Load scene data and object tree from database.
	 *
	 * Objects are organized into a tree.
	 */
	public async loadScene(sceneUuid: UUID): Promise<DigitalTwinScene> {
		const request = await Service.fetch(ServiceList.digitalTwin.scene.get, null, null, {uuid: sceneUuid}, Session.session);
		return DigitalTwinScene.parse(request.response.scene);
	}

	/**
	 * Load the scene object tree from the API.
	 *
	 * @param sceneUuid - UUID of the scene.
	 */
	public async loadObjectTree(sceneUuid: UUID): Promise<DigitalTwinObject[]> {
		const request = await Service.fetch(ServiceList.digitalTwin.object.tree, null, null, {sceneUuid: sceneUuid}, Session.session);
		return DigitalTwinObjectLoader.parseArray(request.response.objects);
	}

	public ngOnDestroy(): void {
		this.editor.dispose();
	}
}
