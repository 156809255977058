import {UUID} from '../../../../../models/uuid';

export enum AtexInspectionFormFieldType {
	/**
	 * Atex Section title.
	 */
	TITLE = 42,

	/**
	 * Atex response field.
	 */
	FIELD = 18
}

/**
 * Specified a checklist field based on the regular form field.
 *
 * Adds inspection specific attributes, used to create the inspection fields and set its value depending o the form visualization and equipment details.
 */
export class AtexInspectionFormField {
	/**
	 * Type of the field.
	 */
	public type: AtexInspectionFormFieldType;
	
	/**
	 * Name of the object attribute to edit.
	 */
	public attribute: string;

	/**
	 * Label shown to the user in the form. If no label is specified the attribute value is used instead.
	 */
	public label: string;

	/**
	 * Required should be indicated if the field if obligatory.
	 */
	public required: boolean;

	/**
	 * If not empty indicates the sub-type of assets for which this row is applied.
	 *
	 * If the subtype is not present in the array the field notApplicable attribute is set to true by default (still can be changed by the user later).
	 */
	public onlyApplicable: UUID[] = [];

	/**
	 * Flag to indicate if this fields belongs to the field inspector form.
	 */
	public inspector: boolean = false;

	/**
	 * Flag to indicate if this fields belongs to the backoffice inspection.
	 */
	public backoffice: boolean = false;

	/**
	 * List of possible reasons to justify the response provided.
	 *
	 * These are specific to each checklist. Order is important dont change.
	 */
	public justifications: string[] = [];

	/**
	 * Parse JSON data into usable atex inspection form field.
	 * 
	 * @param data - Data received from API.
	 * @returns Atex inspection form field.
	 */
	public static parse(data: any): AtexInspectionFormField {
		const field = new AtexInspectionFormField();

		field.type = data.type;
		field.attribute = data.attribute;
		field.label = data.label;
		field.required = data.required === true;
		field.onlyApplicable = data.onlyApplicable || [];
		field.inspector = data.inspector === true;
		field.backoffice = data.backoffice === true;
		field.justifications = data.justifications || [];

		return field;
	}
}
