<ion-item class="ion-no-margin ion-no-padding" lines="none" style="--padding-end: 0px; --inner-padding-end: 0px;">
	<ion-select fill="outline" (ngModelChange)="this.writeValue($event);" [disabled]="this.disabled" [ngModel]="this.value" [ngModelOptions]="{standalone: true}" interface="{{app.device.isMobile() || this.displayMode === this.optionsDisplayMode.MODAL ? 'alert' : 'popover' }}" multiple="true">
		@for (option of this.options; track option) {
			<ion-select-option [value]="option.value">
				{{this.translate ? (option.label | translate) : option.label}}
			</ion-select-option>
		}
	</ion-select>

	<!-- Clear button -->
	@if (this?.value?.length > 0 && !this.disabled && this.showClear) {
		<ion-icon (click)="this.writeValue([]);" name="close-outline" slot="end" style="cursor: pointer;"></ion-icon>
	}
</ion-item>
