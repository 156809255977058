import {
	Mesh,
	MeshBasicMaterial,
	PlaneGeometry,
	Vector2,
	Scene,
	Raycaster,
	PerspectiveCamera,
	CanvasTexture, WebGLRenderer, Camera
} from 'three';
import {Viewport, ViewportAnchor, ViewportSizing} from '../camera/viewport';
import {CSSUtils} from '../../../../utils/css-utils';
import {Locale} from '../../../../locale/locale';
import {Mouse} from '../input/mouse';

/**
 * Sides of the orientation cube in 3 axis negative and positive direction.
 */
export enum OrientationCubeSide {
	X_POS = 0,
	X_NEG = 1,
	Y_POS = 2,
	Y_NEG = 3,
	Z_POS = 4,
	Z_NEG = 5
}

/** 
 * Orientation cube can be used to preview and change the rotation of an object.
 * 
 * Is used in the editor to preview and manipulate the camera perspective.
 */
export class OrientationCube {
	/**
	 * Orientation cube viewport.
	 */
	private viewport: Viewport;

	/**
	 * Cube visualization camera
	 */
	public camera: PerspectiveCamera = null;

	/**
	 * Raycaster for mouse interaction with the cube surfaces.
	 */
	public raycaster: Raycaster = null;

	/**
	 * Normalized coordinates for raycaster.
	 */
	public normalized: Vector2;

	/**
	 * Scene where the cube surfaces are stored.
	 */
	public scene: Scene = null;

	/**
	 * Face of the cube selected.
	 */
	public selected: any = null;

	/**
	 * X+ axis cube plane.
	 */
	public xPos: Mesh<PlaneGeometry, MeshBasicMaterial>;

	/**
	 * X- axis cube plane.
	 */
	public xNeg: Mesh<PlaneGeometry, MeshBasicMaterial>;

	/**
	 * Y+ axis cube plane.
	 */
	public yPos: Mesh<PlaneGeometry, MeshBasicMaterial>;

	/**
	 * Y- axis cube plane.
	 */
	public yNeg: Mesh<PlaneGeometry, MeshBasicMaterial>;

	/**
	 * Z+ axis cube plane.
	 */
	public zPos: Mesh<PlaneGeometry, MeshBasicMaterial>;

	/**
	 * Z- axis cube plane.
	 */
	public zNeg: Mesh<PlaneGeometry, MeshBasicMaterial>;

	public constructor() {
		this.viewport = new Viewport(ViewportSizing.ABSOLUTE);
		this.viewport.viewport.set(150, 150);
		this.viewport.offset.set(0, 0);
		this.viewport.anchor = ViewportAnchor.TOP_RIGHT;

		this.camera = new PerspectiveCamera(60, 1, 0.1, 10);
		this.camera.position.z = 2;

		this.raycaster = new Raycaster();
		this.normalized = new Vector2(0, 0);

		this.scene = new Scene();
		this.scene.matrixAutoUpdate = false;

		this.selected = null;

		const plane = new PlaneGeometry(1, 1);

		// Cube faces
		let texture = OrientationCube.createTexture(Locale.get('left'));
		this.xPos = new Mesh(plane, new MeshBasicMaterial({map: texture}));
		// @ts-ignore
		this.xPos.code = OrientationCubeSide.X_POS;
		this.xPos.position.set(0.5, 0, 0);
		this.xPos.rotation.set(0, Math.PI / 2, 0);
		this.xPos.matrixAutoUpdate = false;
		this.xPos.updateMatrix();
		this.scene.add(this.xPos);

		texture = OrientationCube.createTexture(Locale.get('right'));
		this.xNeg = new Mesh(plane, new MeshBasicMaterial({map: texture}));
		// @ts-ignore
		this.xNeg.code = OrientationCubeSide.X_NEG;
		this.xNeg.position.set(-0.5, 0, 0);
		this.xNeg.rotation.set(0, -Math.PI / 2, 0);
		this.xNeg.matrixAutoUpdate = false;
		this.xNeg.updateMatrix();
		this.scene.add(this.xNeg);

		texture = OrientationCube.createTexture(Locale.get('top'));
		this.yPos = new Mesh(plane, new MeshBasicMaterial({map: texture}));
		// @ts-ignore
		this.yPos.code = OrientationCubeSide.Y_POS;
		this.yPos.position.set(0, 0.5, 0);
		this.yPos.rotation.set(-Math.PI / 2, 0, -Math.PI / 2);
		this.yPos.matrixAutoUpdate = false;
		this.yPos.updateMatrix();
		this.scene.add(this.yPos);

		texture = OrientationCube.createTexture(Locale.get('bottom'));
		this.yNeg = new Mesh(plane, new MeshBasicMaterial({map: texture}));
		// @ts-ignore
		this.yNeg.code = OrientationCubeSide.Y_NEG;
		this.yNeg.position.set(0, -0.5, 0);
		this.yNeg.rotation.set(Math.PI / 2, 0, Math.PI / 2);
		this.yNeg.matrixAutoUpdate = false;
		this.yNeg.updateMatrix();
		this.scene.add(this.yNeg);

		texture = OrientationCube.createTexture(Locale.get('back'));
		this.zPos = new Mesh(plane, new MeshBasicMaterial({map: texture}));
		// @ts-ignore
		this.zPos.code = OrientationCubeSide.Z_POS;
		this.zPos.position.set(0, 0, 0.5);
		this.zPos.matrixAutoUpdate = false;
		this.zPos.updateMatrix();
		this.scene.add(this.zPos);

		texture = OrientationCube.createTexture(Locale.get('front'));
		this.zNeg = new Mesh(plane, new MeshBasicMaterial({map: texture}));
		// @ts-ignore
		this.zNeg.code = OrientationCubeSide.Z_NEG;
		this.zNeg.position.set(0, 0, -0.5);
		this.zNeg.rotation.set(0, Math.PI, 0);
		this.zNeg.matrixAutoUpdate = false;
		this.zNeg.updateMatrix();
		this.scene.add(this.zNeg);
	}

	/**
	 * Create canvas texture for the cube face.
	 */
	public static createTexture(text: string, size: number = 128): CanvasTexture {
		const canvas = document.createElement('canvas');
		canvas.width = size;
		canvas.height = size;

		const context = canvas.getContext('2d');
		context.fillStyle = CSSUtils.getVariable('--gray-7');
		context.fillRect(0, 0, size, size);
		context.font = size * 0.3 + 'px Arial';
		context.textBaseline = 'middle'; 
		context.textAlign = 'center';
		context.fillStyle = CSSUtils.getVariable('--gray-5');
		context.fillText(text, size / 2, size / 2);

		return new CanvasTexture(canvas);
	}

	/**
	 * Ray cast cube from mouse normalized coordinates.
	 */
	public update(mouse: Mouse, canvas: any, camera: Camera): any {
		// Cube orientation
		camera.getWorldQuaternion(this.scene.quaternion);
		this.scene.updateMatrix();
		this.scene.matrix.getInverse(this.scene.matrix);

		if (this.viewport.isInside(canvas, mouse)) {
			this.raycaster.setFromCamera(this.viewport.getNormalized(canvas, mouse), this.camera);

			const intersects = this.raycaster.intersectObjects(this.scene.children, true);
			if (intersects.length > 0) {
				this.selected = intersects[0].object;
				this.selected.material.color.set(0xBBBBBB);
				// @ts-ignore
				return intersects[0].object.code;
			}
		}

		return null;
	}

	/**
	 * Render cube to canvas using a renderer orientation.
	 */
	public render(renderer: WebGLRenderer): void {
		this.viewport.enable(renderer);

		renderer.render(this.scene, this.camera);

		if (this.selected !== null) {
			this.selected.material.color.set(0xFFFFFF);
			this.selected = null;
		}
	}
}

