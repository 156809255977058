import {APAssetSubType} from 'src/app/models/asset-portfolio/asset-sub-type';
import {AssetTypeSelectorField} from 'src/app/modules/inspections/data/field/inspection-form-fields';
import {InspectionFormUtils} from 'src/app/modules/inspections/data/form/inspection-form-utils';
import {SortDirection} from '../../../../utils/sort-direction';
import {Service} from '../../../../http/service';
import {ServiceList} from '../../../../http/service-list';
import {UnoFormField} from '../../../../components/uno-forms/uno-form/uno-form-field';
import {UnoFormFieldTypes} from '../../../../components/uno-forms/uno-form/uno-form-field-types';
import {Session} from '../../../../session';
import {Atex} from '../../../../models/atex/atex';
import {
	AtexExplosionGroups,
	AtexExplosionGroupsLabels,
	AtexTemperature,
	AtexTemperatureLabels,
	AtexZones,
	AtexZonesLabel
} from '../../../../models/atex/atex-enums';
import {UserPermissions} from '../../../../models/users/user-permissions';
import {
	InputOptionsMultipleBatchRequest,
	InputOptionsMultipleLazyPageRequest
} from '../../../../components/uno-input/uno-options-lazy/uno-options-lazy.component';
import {AssetFluidFamilyListParams, AssetFluidService, AssetFluidTypeListParams} from '../../services/asset-fluid.service';
import {AssetSubTypeListNameResponse, AssetSubTypeService} from '../../services/asset-subtype.service';

export const AssetBaseLayout: UnoFormField[] = [
	{
		label: 'uuid',
		attribute: 'uuid',
		type: UnoFormFieldTypes.UUID
	},
	{
		required: true,
		attribute: 'name',
		label: 'name',
		type: UnoFormFieldTypes.TEXT
	},
	{
		required: false,
		copy: true,
		attribute: 'tag',
		label: 'tag',
		type: UnoFormFieldTypes.TEXT
	},
	{
		required: false,
		attribute: 'description',
		label: 'description',
		type: UnoFormFieldTypes.TEXT_MULTILINE
	},
	{
		required: false,
		label: 'photos',
		attribute: 'pictures',
		type: UnoFormFieldTypes.IMAGE_RESOURCE_MULTIPLE
	},
	{
		required: false,
		label: 'documents',
		attribute: 'documents',
		type: UnoFormFieldTypes.DOCUMENT_RESOURCE_MULTIPLE
	},
	{
		required: false,
		label: 'position',
		attribute: 'position',
		type: UnoFormFieldTypes.GEO_POSITION
	},
	{
		required: false,
		label: 'qr',
		attribute: 'qr',
		type: UnoFormFieldTypes.QR,
		isActive: (object, row) => { return Session.hasPermissions(UserPermissions.ASSET_PORTFOLIO_QR); }
	},
	{
		required: false,
		label: 'nfc',
		attribute: 'nfc',
		type: UnoFormFieldTypes.NFC,
		isActive: (object, row) => { return Session.hasPermissions(UserPermissions.ASSET_PORTFOLIO_NFC); }
	},
	{
		required: false,
		label: 'nameplate',
		attribute: 'nameplate',
		type: UnoFormFieldTypes.IMAGE_RESOURCE_MULTIPLE
	}
];

export const AssetStructureLayout: UnoFormField[] = [
	InspectionFormUtils.cloneField(AssetTypeSelectorField, {
		attribute: 'typeUuid',
		label: 'assetType',
		required: true
	}),
	{
		attribute: 'subTypeUuid',
		label: 'subtype',
		type: UnoFormFieldTypes.OPTIONS_MULTIPLE_LAZY,
		showClear: true,
		required: false,
		editable: (object) => { return object.typeUuid !== null && object.typeUuid !== undefined; },
		multiple: false,
		identifierAttribute: 'uuid',
		fetchOptionsLazy: async function(request: InputOptionsMultipleLazyPageRequest, params: any): Promise<{options: any[], hasMore: boolean, id: number}> {
			const data = {
				from: request.from,
				count: request.count,
				search: request.search,
				typeUuid: params.typeUuid,
				sortField: '[ap_asset_sub_type].[name]',
				sortDirection: SortDirection.ASC
			};

			const req: AssetSubTypeListNameResponse = await AssetSubTypeService.listName(data);
			return {options: req.subTypes, hasMore: req.hasMore, id: req.id};

		},
		fetchOptionsBatch: async function(request: InputOptionsMultipleBatchRequest): Promise<{options: any[]}> {
			if (request.options.length >= 1) {
				const subTypes: APAssetSubType[] = await AssetSubTypeService.getBatch(request.options);
				return {options: subTypes};
			}

			return {options: []};
		},
		getOptionText: function(option: any): string {
			return option.name;
		}
	},
	{
		required: false,
		attribute: 'parentUuid',
		label: 'parentAsset',
		type: UnoFormFieldTypes.OPTIONS_MULTIPLE_LAZY,
		showClear: true,
		editable: (object) => { return object.typeUuid !== null && object.typeUuid !== undefined; },
		multiple: false,
		identifierAttribute: 'uuid',
		fetchOptionsLazy: async function(request: InputOptionsMultipleLazyPageRequest, params: any): Promise<{options: any[], hasMore: boolean, id: number}> {
			const data = {
				from: request.from,
				count: request.count,
				search: request.search,
				typeUuid: params.typeUuid,
				sortField: '[ap_asset].[name]',
				sortDirection: SortDirection.ASC
			};


			const req = await Service.fetch(ServiceList.assetPortfolio.asset.listNameAllowedParents, null, null, data, Session.session);
			return {options: req.response.assets, hasMore: req.response.hasMore, id: req.id};

		},
		fetchOptionsBatch: async function(request: InputOptionsMultipleBatchRequest): Promise<{options: any[]}> {
			if (request.options.length > 0) {
				const data = {assets: request.options};

				const req = await Service.fetch(ServiceList.assetPortfolio.asset.getBatch, null, null, data, Session.session);
				return {options: req.response.assets};
			}

			return {options: []};
		},
		getOptionText: function(option: any): string {
			return [option.name, option.tag].filter(Boolean).join(' - ');
		}
	}
];

export const AssetModelLayout: UnoFormField[] = [
	{
		required: false,
		attribute: 'manufacturer',
		label: 'manufacturer',
		type: UnoFormFieldTypes.TEXT
	},
	{
		required: false,
		attribute: 'manufacturingYear',
		label: 'manufacturingYear',
		type: UnoFormFieldTypes.NUMBER
	},
	{
		required: false,
		attribute: 'model',
		label: 'model',
		type: UnoFormFieldTypes.TEXT
	},
	{
		required: false,
		attribute: 'serialNumber',
		label: 'serialNumber',
		type: UnoFormFieldTypes.TEXT
	},
	{
		required: false,
		attribute: 'installationDate',
		label: 'installationDate',
		type: UnoFormFieldTypes.DATE
	}
];

export const AssetAtexLayout: UnoFormField[] = [
	{
		required: false,
		label: 'zoneClassification',
		attribute: 'atex.zone',
		type: UnoFormFieldTypes.OPTIONS_MULTIPLE,
		sort: false,
		showClear: true,
		options: Object.values(AtexZones).map(function(value) {
			return {value: value, label: AtexZonesLabel.get(value)};
		})
	},
	{
		required: false,
		label: 'explosionGroup',
		attribute: 'atex.zoneExplosion',
		type: UnoFormFieldTypes.OPTIONS,
		showClear: true,
		sort: false,
		options: Object.values(AtexExplosionGroups).map(function(value) {
			return {value: value, label: AtexExplosionGroupsLabels.get(value)};
		})
	},
	{
		required: false,
		label: 'temperatureClass',
		attribute: 'atex.zoneTemperature',
		type: UnoFormFieldTypes.NUMBER,
		isActive: (object, row) => {return !Atex.isGasZone(object.atex.zone);}
	},
	{
		required: false,
		label: 'temperatureClass',
		attribute: 'atex.zoneTemperature',
		type: UnoFormFieldTypes.OPTIONS,
		sort: false,
		showClear: true,
		options: Object.values(AtexTemperature).map(function(value) {
			return {value: value, label: AtexTemperatureLabels.get(value)};
		}),
		isActive: (object, row) => {return Atex.isGasZone(object.atex.zone);}
	},
	{
		required: false,
		label: 'atex',
		attribute: 'atex',
		type: UnoFormFieldTypes.ATEX_FIELD
	}
];

export const AssetFluidLayout: UnoFormField[] = [
	{
		required: false,
		label: 'fluidFamily',
		attribute: 'fluidFamilyUuid',
		type: UnoFormFieldTypes.OPTIONS_MULTIPLE_LAZY,
		identifierAttribute: 'uuid',
		multiple: false,
		fetchOptionsLazy: async(request: InputOptionsMultipleLazyPageRequest, object: any): Promise<{options: any[], hasMore: boolean, id: number}> => {
			const params: AssetFluidFamilyListParams = {
				from: request.from,
				count: request.count,
				search: request.search
			};
	
			const req = await AssetFluidService.listFamily(params);
			return {options: req.families, hasMore: req.hasMore, id: req.id};
	
		},
		fetchOptionsBatch: async(request: InputOptionsMultipleBatchRequest, object: any): Promise<{options: any[]}> => {
			if (request.options[0]) {
				object.fluidTypeUuid = null;

				return {options: [await AssetFluidService.getFamily(request.options[0])]};
			}
			return {options: []};
		},
		getOptionText: (option: any): string => {
			return option.label;
		},
		onChange: function(object: any, row: UnoFormField) {
			object.fluidTypeUuid = null;
		}
	},
	{
		required: false,
		label: 'fluidType',
		attribute: 'fluidTypeUuid',
		type: UnoFormFieldTypes.OPTIONS_MULTIPLE_LAZY,
		identifierAttribute: 'uuid',
		multiple: false,
		fetchOptionsLazy: async(request: InputOptionsMultipleLazyPageRequest, object: any): Promise<{options: any[], hasMore: boolean, id: number}> => {
			const params: AssetFluidTypeListParams = {
				from: request.from,
				count: request.count,
				search: request.search,
				assetFluidFamilyUuid: object.fluidFamilyUuid
			};
			
			const req = await AssetFluidService.listTypes(params);
			return {options: req.types, hasMore: req.hasMore, id: req.id};
		},
		fetchOptionsBatch: async(request: InputOptionsMultipleBatchRequest, object: any): Promise<{options: any[]}> => {
			if (request.options[0]) {
				return {options: [await AssetFluidService.getType(request.options[0])]};
			}

			return {options: []};
		},
		getOptionText: (option: any): string => {
			return option.label;
		},
		isActive: (object: any) => {return typeof object.fluidFamilyUuid === 'string';}
	}
];
