import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {IonicModule} from '@ionic/angular';
import {AtexInspection} from 'src/app/models/atex-inspections/inspections/atex-inspection';
import {UnoFormComponent} from '../../../../../components/uno-forms/uno-form/uno-form.component';
import {APAsset} from '../../../../../models/asset-portfolio/asset';
import {App} from '../../../../../app';
import {ScreenComponent} from '../../../../../components/screen/screen.component';
import {Service} from '../../../../../http/service';
import {ServiceList} from '../../../../../http/service-list';
import {Session} from '../../../../../session';
import {Modal} from '../../../../../modal';
import {Locale} from '../../../../../locale/locale';
import {ActionPlanFormLayout} from '../action-plan-form-layout';
import {ActionPlan} from '../../../../../models/atex-inspections/action-plan/action-plan';
import {ActionPlanState} from '../../../../../models/atex-inspections/action-plan/action-plan-state';
import {UnoFormModule} from '../../../../../components/uno-forms/uno-form.module';
import {UserPermissions} from '../../../../../models/users/user-permissions';
import {UUID} from '../../../../../models/uuid';
import {FFP} from '../../../../../models/atex-inspections/ffp/ffp';
import {AssetBaseLayout} from '../../../../asset-portfolio/screens/asset/asset-layout';
import {ObjectKeysPipe} from '../../../../../pipes/object-keys.pipe';
import {FormatDatePipe} from '../../../../../pipes/format-date.pipe';
import {UnoTitleComponent} from '../../../../../components/uno/uno-title/uno-title.component';
import {UnoButtonComponent} from '../../../../../components/uno/uno-button/uno-button.component';
import {UnoTabSectionComponent} from '../../../../../components/uno/uno-tab/uno-tab-section/uno-tab-section.component';
import {UnoTabComponent} from '../../../../../components/uno/uno-tab/uno-tab.component';
import {AtexInspectionFieldsService} from '../../../inspections/services/atex-inspection-fields.service';
import {AtexInspectionService} from '../../../inspections/services/atex-inspection.service';
import {UnoFormField} from '../../../../../components/uno-forms/uno-form/uno-form-field';
import {UnoFormFieldTypes} from '../../../../../components/uno-forms/uno-form/uno-form-field-types';
import {FFPLayout} from '../../../ffp/screens/ffp-layout';
import {PermissionsPipe} from '../../../../../pipes/permissions.pipe';

/**
 * Page used to edit and create action plan entries, should receive the UUID of the action plan to edit or a createMode value set to true.
 *
 * When on creation mode can also receive a list of ffp (as array of UUID) to be included in the action plan on creation.
 */
@Component({
	selector: 'action-plan-edit-page',
	templateUrl: './action-plan-edit.page.html',
	standalone: true,
	imports: [UnoTabComponent, UnoTabSectionComponent, UnoFormModule, UnoButtonComponent, UnoTitleComponent, IonicModule, TranslateModule, FormatDatePipe, ObjectKeysPipe, PermissionsPipe]
})
export class ActionPlanEditPage extends ScreenComponent implements OnInit {
	@ViewChild('mapContainer', {static: true})
	public mapContainer: ElementRef = null;

	@ViewChild('actionPlanForm', {static: false})
	public actionPlanForm: UnoFormComponent = null;

	@ViewChild('assetsTitleForm', {static: false})
	public assetsTitleForm: UnoFormComponent = null;

	public session: any = Session;

	public userPermissions: any = UserPermissions;

	public app: any = App;

	public actionPlanLayout: any = ActionPlanFormLayout;

	public state: any = ActionPlanState;

	public assetsLayout: any = AssetBaseLayout;

	public ffpLayout: UnoFormField[] = null;

	public permissions = [UserPermissions.ACTION_PLAN];

	/**
	 * Action plan being edited in this screen.
	 */
	public actionPlan: ActionPlan = null;

	/**
	 * Flag to indicate if the page is in create mode.
	 */
	public createMode: boolean = false;

	/**
	 * List of equipments hat compose the action plan.
	 */
	public assets: {[attr: UUID]: APAsset} = {};

	/**
	 * Object of the FFP's that compose this action plan, used for reference and for the reinspection step.
	 */
	public ffps: {[attr: UUID]: FFP} = {};

	/**
	 * Inspections that integrate the FFP's that compose this action plan, used for reference and for reinspection step.
	 */
	public inspections: {[attr: UUID]: AtexInspection & {forms: any}} = {};

	/**
	 * Edit history of the action plan.
	 */
	public history: any[] = [];

	public async ngOnInit(): Promise<void> {
		super.ngOnInit();

		this.assets = {};
		this.ffps = {};
		this.inspections = {};
		this.createMode = false;
		this.actionPlan = null;
		this.history = [];

		App.navigator.setTitle('actionPlan');

		const data = App.navigator.getData();
		if (!data || !data.uuid && !data.createMode) {
			App.navigator.pop();
			return;
		}

		if (data.uuid && data.createMode) {
			throw Error('UUID and createMode cannot be used simultaneously.');
		}

		if (data.createMode === true) {
			this.createMode = true;
			this.actionPlan = new ActionPlan();
			if (data.ffpUuids !== undefined) {
				this.actionPlan.ffpUuids = data.ffpUuids;
			}
		} else {
			await this.loadData(data.uuid);
		}
	}

	/**
	 * Get action plan data from the API.
	 *
	 * @param uuid - UUID of the action plan
	 */
	public async loadData(uuid: UUID): Promise<void> {
		const request = await Service.fetch(ServiceList.atex.actionPlan.get, null, null, {uuid: uuid}, Session.session);
		this.actionPlan = ActionPlan.parse(request.response.actionPlan);
		await this.getFFPInspectionAsset();

		this.loadHistory(uuid);
	}

	/**
	 * Load the edit history from database.
	 */
	public async loadHistory(uuid: UUID): Promise<void> {
		const request = await Service.fetch(ServiceList.atex.actionPlan.historyListUsers, null, null, {uuid: uuid}, Session.session);

		this.history = request.response.history;
	}

	/**
	 * Build a reinspection form used for the inspection to verify is every step in the action plan is done.
	 */
	public async getFFPInspectionAsset(): Promise<void> {
		if (!this.actionPlan) {
			return;
		}

		// Method to build a re-inspection form for the Action Plan
		const buildReinspectionForm = async(): Promise<void> => {
			if (this.actionPlan.state === ActionPlanState.WAITING_REINSPECTION) {
				const fields = await AtexInspectionFieldsService.get();

				for (const k in this.ffps) {
					const ffp = this.ffps[k];
					const inspection = ffp.inspection;

					if (!this.inspections[inspection].forms[ffp.form]) {
						this.inspections[inspection].forms[ffp.form] = [];
					}

					const field: UnoFormField = structuredClone(fields[ffp.field]);
					field.type = UnoFormFieldTypes.ATEX_INSPECTION_FIELD;

					this.inspections[inspection].forms[ffp.form].push(field);
				}
			}
		};

		// Get all FFP of the action plan
		let request = await Service.fetch(ServiceList.atex.ffp.getBatch, null, null, {uuid: this.actionPlan.ffpUuids}, Session.session);
		const ffps: FFP[] = request.response.ffp;

		const assetUuids: UUID[] = [];
		for (let i = 0; i < ffps.length; i++) {
			const ffp = FFP.parse(ffps[i]);


			const inspection: any = await AtexInspectionService.get(ffp.inspection);
			inspection.forms = {};
			this.inspections[inspection.uuid] = inspection;

			assetUuids.push(inspection.assetUuid);
			this.ffps[ffp.uuid] = ffp;
		}

		request = await Service.fetch(ServiceList.assetPortfolio.asset.getBatch, null, null, {assets: assetUuids}, Session.session);
		const assets = request.response.assets;
		for (let i = 0; i < assets.length; i++) {
			const asset = APAsset.parse(assets[i]);
			this.assets[asset.uuid] = asset;
		}

		for (const ffp in this.ffps) {
			// @ts-ignore
			this.ffps[ffp].asset = this.assets[this.inspections[this.ffps[ffp].inspection].assetUuid];
		}

		this.ffpLayout = [{
			type: UnoFormFieldTypes.SUB_FORM,
			label: (object, row) => {return object.field + ' - ' + object.asset.tag;},
			expanded: false,
			fields: FFPLayout
		}];

		await buildReinspectionForm();
	}

	/**
	 * Update the action plan in the API.
	 */
	public async update(state?: number, stayOnPage: boolean = false): Promise<void> {
		if (!this.actionPlanForm.requiredFilled()) {
			Modal.alert(Locale.get('error'), Locale.get('requiredFieldsError'));
			return;
		}

		const actionPlan = structuredClone(this.actionPlan);
		if (state !== undefined) {
			actionPlan.state = state;
		}

		if (this.actionPlan.state === ActionPlanState.WAITING_REINSPECTION && Session.hasPermissions([UserPermissions.ATEX_INSPECTION])) {
			for (const uuid in this.inspections) {
				this.updateInspection(uuid);
			}
		}

		await Service.fetch(ServiceList.atex.actionPlan.update, null, null, actionPlan, Session.session);
		if (!stayOnPage) {
			App.navigator.pop();
		}
		Modal.toast(Locale.get('updatedSuccessfully'));

		// Every time an update occurs, there is an history change.
		this.loadHistory(this.actionPlan.uuid);
	}

	/**
	 * Delete the action plan from the API.
	 */
	public async delete(): Promise<void> {
		const confirm = await Modal.confirm(Locale.get('confirm'), Locale.get('confirmDelete'));
		if (confirm) {
			await Service.fetch(ServiceList.atex.actionPlan.delete, null, null, {uuid: this.actionPlan.uuid}, Session.session);

			Modal.toast(Locale.get('deleteSuccessfully'));
			App.navigator.pop();
		}
	}

	/**
	 * Create a new action plan in the API.
	 */
	public async create(): Promise<void> {
		if (!this.actionPlanForm.requiredFilled()) {
			Modal.alert(Locale.get('error'), Locale.get('requiredFieldsError'));
			return;
		}

		await Service.fetch(ServiceList.atex.actionPlan.create, null, null, this.actionPlan, Session.session);

		Modal.toast(Locale.get('updatedSuccessfully'));
		App.navigator.pop();
	}

	/**
	 * Update the data of a specific inspection.
	 */
	public async updateInspection(uuid: UUID): Promise<void> {
		if (this.inspections[uuid]) {
			const inspection = structuredClone(this.inspections[uuid]);
			await Service.fetch(ServiceList.atex.inspection.update, null, null, inspection, Session.session);
		}
	}

	/**
	 * Copy documents attached to the action plan into the equipment data.
	 *
	 * @param uuid - UUID of the equipment
	 */
	public async copyDocuments(uuid: UUID): Promise<void> {
		if (!Session.hasPermissions([UserPermissions.ASSET_PORTFOLIO_ASSET_EDIT])) {
			throw new Error('Use does not have permissions to copy documents.');
		}

		const asset = structuredClone(this.assets[uuid]);
		asset.documents = asset.documents.concat(this.actionPlan.data.documents);

		await Service.fetch(ServiceList.assetPortfolio.asset.update, null, null, asset, Session.session);
		Modal.toast(Locale.get('updatedSuccessfully'));
	}
}
