import {Component, ElementRef, EventEmitter, forwardRef, Input, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {CdkOverlayOrigin, CdkConnectedOverlay} from '@angular/cdk/overlay';
import {NgClass} from '@angular/common';
import {UnoTextComponent} from '../uno-text/uno-text.component';

/**
 * The Options Selector Component
 */
@Component({
	selector: 'uno-options-search',
	templateUrl: './uno-options-search.component.html',
	styleUrls: ['./uno-options-search.component.css'],
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => { return UnoOptionsSearchComponent; }),
		multi: true
	}],
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [CdkOverlayOrigin, NgClass, UnoTextComponent, CdkConnectedOverlay, FormsModule, ReactiveFormsModule, TranslateModule]
})
export class UnoOptionsSearchComponent implements ControlValueAccessor {
	@Input('options')
	public options: { value: any, label: string }[];

	@Input()
	public disabled: boolean = false;

	/**
	 * The event emitter that sends the option selected to the parent.
	 */
	@Output()
	public sendResult = new EventEmitter<any>();

	/**
	 * Value stored in the input component.
	 */
	@Input()
	public value: any = null;

	/**
	 * Flag to indicate if the options selection is open.
	 */
	public expanded: boolean = false;

	/**
	 * Form control object, used for the search input.
	 */
	public searchForm: FormControl;

	@ViewChild('dropdownMenu')
	public dropdownMenu: ElementRef;

	public constructor() {
		this.searchForm = new FormControl('');
	}

	/**
	 * Get label from the value selected.
	 * 
	 * @param value - The value to be used to search for the right label
	 */
	public getLabel(value: any): string {
		const opt = this.options.filter((val: any) => {
			return val.value === value;
		});

		return opt.length === 0 ? '' : opt[0].label;
	}

	/**
	 * Send the selected option's value to the parent
	 * 
	 * @param value - The selected Option's value.
	 */
	public writeValue(value: any): void {
		this.searchForm.setValue('');
		this.sendResult.emit(value);

		this.value = value;
		this.onChange(value);
	}

	public onChange: (value: any)=> void = function(value) { };

	public registerOnChange(onChange: any): void {
		this.onChange = onChange;
	}

	public registerOnTouched(fn: any): void { }

	public setDisabledState(disabled: boolean): void {
		this.disabled = disabled;
	}

	/**
	 * Method used to show and hide the options when clicking the selector.
	 */
	public toggle(): void {
		this.expanded = !this.expanded;
	}

	/**
	 * Method used to clear the input selector.
	 */
	public clearInput(): void {
		this.searchForm.setValue('');
		this.writeValue(null);
	}

	/**
	 * Method used to allow for the animation when the mobile option list is closed.
	 */
	public slowToggle(): void {
		this.toggle();
		this.dropdownMenu.nativeElement.classList.add('uno-options-search-hide-fade');
		setTimeout(() => {
			this.dropdownMenu.nativeElement.classList.remove('uno-options-search-hide-fade');
		}, 700);
	}
}
