/**
 * List of priority levels considered for action plan entries.
 */
export const ActionPlanPriority = {
	LOW: 0,
	MEDIUM: 1,
	HIGH: 2
};

export const ActionPlanPriorityLabel: Map<number, string> = new Map([
	[ActionPlanPriority.LOW, 'low'],
	[ActionPlanPriority.MEDIUM, 'medium'],
	[ActionPlanPriority.HIGH, 'high']
]);
