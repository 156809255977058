import {UUID} from '../../uuid';
import {Geolocation} from '../../geolocation';

/**
 * CMP stands for corrosion monitor point, its the sensor/hardware component applied in the pipelines.
 */
export class CMP {

	/**
	 * UUID of the CMP.
	 */
	public uuid: UUID = null;

	/**
	 * Name of the CMP.
	 */
	public name: string = '';

	/**
	 * Description of the CMP.
	 */
	public description: string = '';

	/**
	 * Voltage of the CMP, range between 0V and 300V.
	 */
	public voltage: number = 0;

	/**
	 * GPS position of the CMP.
	 */
	public position: Geolocation = null;
	
	/**
	 * UUID of the segment associated.
	 */
	public segmentUuid: UUID = null;

	/**
	 * Parse the data of an API retrieved CMP object.
	 *
	 * @param data - CMP object to parse data
	 */
	public static parse(data: any): CMP {
		const cmp = new CMP();

		cmp.uuid = data.uuid;
		cmp.name = data.name;
		cmp.description = data.description;
		cmp.voltage = data.voltage;

		// GPS Position
		if (data.position) {
			cmp.position = Geolocation.parse(data.position);
		}

		cmp.segmentUuid = data.segmentUuid;

		return cmp;
	}
}
