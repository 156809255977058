import {UUID, UUIDIdentification} from '../uuid';
import {APAssetFormBlock} from './asset-form-block';

/**
 * Used to represent the content of a form tab card.
 *
 * This cards can be either from an asset type or a (sub-)sub-type. When it is a (sub-)sub-type card, the attribute subTypeUuid must be set.
 */
export class APAssetFormTabCard extends UUIDIdentification {
	/**
	 * The name shown on tab card component
	 */
	public name: string = '';

	/**
	 * The description of this tab card
	 */
	public description: string = '';

	/**
	 * Indicates if this card uses a local/private block (true) or reusable block from the list of available blocks (false). Private blocks were created on a specific type/sub-type form card and are not meant to be reused in any other place.
	 * 
	 * When "usesPrivateBlock" or "blockUuid" properties of a card are changed, card block object must be reset/reloaded.
	 * 
	 * Also, when it is changed back to use a private block, the blockUuid already stored on DB for this card must be fetch again to avoid using the one possibly selected from reusables.
	 */		
	public usesPrivateBlock: boolean = false;

	/**
	 * The UUID of the tab where this card is placed on
	 */
	public tabUuid: UUID = null;

	/**
	 * If this card was set on asset (sub-)sub-type, this attribute keeps the UUID of that asset (sub-)sub-type.
	 */
	public subTypeUuid: UUID = null;

	/**
	 * The UUID of the block used inside this card (whether it is private or reusable)
	 */
	public blockUuid: UUID = null;
	
	/**
	 * The indexes of this card, used to sort cards relative to each other.
	 * 
	 * Indexes are separated by a dot and start at "1" for asset types and "0" for sub-types (to allow sub-type cards to be placed before the type ones). Every dot on the index, represents a level deep from the types (lvl 0) to the sub-types (lvl 1 to infinity)
	 * 
	 * E.g.: Cards of a type can be "1" or "123". Cards of a sub-type can be "0.1" or "0.1 and 1.1 and 1.2 and 1.3" or "123.1". Cards of a sub-sub-sub-type can be "0.0.0.1" or "0.0.0.1 and 1.0.0.1 and 1.0.0.2 and 1.0.0.3" or "123.0.0.1"
	 */
	public indexes: string = '';

	/**
	 * The block object used on this card
	 */
	public block: APAssetFormBlock = new APAssetFormBlock();

	/**
	 * Parse JSON data received from the API into an object of the correct type.
	 * 
	 * @param data - Object received from API.
	 * @returns formTabCard object instance, built from the data received.
	 */
	public static parse(data: any): APAssetFormTabCard {
		const card = new APAssetFormTabCard();

		card.uuid = data.uuid;
		card.updatedAt = new Date(data.updatedAt);
		card.createdAt = new Date(data.createdAt);

		card.name = data.name;
		card.description = data.description;
		card.usesPrivateBlock = data.usesPrivateBlock === true;

		card.tabUuid = data.tabUuid;
		card.subTypeUuid = data.subTypeUuid;
		card.blockUuid = data.blockUuid;
		card.indexes = data.indexes;

		card.block = data.block ? APAssetFormBlock.parse(data.block) : data.block;
		
		return card;
	}
}
