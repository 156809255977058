import {UUID} from '../../uuid';
import {Geolocation} from '../../geolocation';

/**
 * A pipeline is divided in segments, each segment is a section of a pipeline.
 * 
 * A pipeline can have segments made of different materials or thicknesses.
 */
export class Segment {

	/**
	 * UUID of the segment.
	 */
	public uuid: UUID = null;

	/**
	 * Name of the segment.
	 */
	public name: string = '';

	/**
	 * Description of the segment.
	 */
	public description: string = '';

	/**
	 * Tag of the segment.
	 */
	public tag: string = '';

	/**
	 * Material of the segment.
	 */
	public material: string = '';

	/**
	 * Thickness of the segment in millimetres(mm).
	 */
	public thickness: number = 0;

	/**
	 * GPS position of the segment start.
	 */
	public startPoint: Geolocation = null;

	/**
	 * GPS position of the segment end.
	 */
	public endPoint: Geolocation = null;

	/**
	 * UUID of the pipeline associated.
	 */
	public pipelineUuid: UUID = null;

	/**
	 * Parse the data of an API retrieved segment object.
	 *
	 * @param data - Segment object to parse data
	 */
	public static parse(data: any): Segment {
		const segment = new Segment();

		segment.uuid = data.uuid;
		segment.name = data.name;
		segment.description = data.description;
		segment.tag = data.tag;
		segment.material = data.material;
		segment.thickness = data.thickness;
		segment.pipelineUuid = data.pipelineUuid;

		// GPS start Position
		if (data.startPoint) {
			segment.startPoint = Geolocation.parse(data.startPoint);
		}
		// GPS end Position
		if (data.endPoint) {
			segment.endPoint = Geolocation.parse(data.endPoint);
		}

		return segment;
	}
}
