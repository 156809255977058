
import {Locale} from 'src/app/locale/locale';
import {Modal} from 'src/app/modal';
import {InspectionReportTemplate} from 'src/app/models/inspections/project/inspection-report-template';
import {InspectionProjectService} from 'src/app/modules/inspections/services/inspection-project.service';
import {ProgressBar} from 'src/app/progress-bar';
import {ReportTemplateFormat} from 'src/app/utils/report-template-format';
import {UnoFormFieldTypes} from '../../app/components/uno-forms/uno-form/uno-form-field-types';
import {UnoFormField} from '../../app/components/uno-forms/uno-form/uno-form-field';

/**
 * Utils to manipulate inspection reports in ADN environment.
 */
export class InspectionWizardADN {
	/**
	 * Wizard to update the report of all projects in the current environment.
	 * 
	 * The user must select the reports for the two versions of the inspection report.
	 */
	public static async updateReportWizard(): Promise<void> {
		const layout: UnoFormField[] = [
			{
				required: false,
				attribute: 'reportv0',
				label: Locale.get('reportTemplate') + ' V0',
				type: UnoFormFieldTypes.DOCUMENT_RESOURCE,
				filter: '.docx'
			},
			{
				required: false,
				attribute: 'reportv1',
				label: Locale.get('reportTemplate') + ' V1',
				type: UnoFormFieldTypes.DOCUMENT_RESOURCE,

				filter: '.docx'
			}
		];

		const obj: any = {
			reportv0: null,
			reportv1: null
		};

		try {
			await Modal.form(Locale.get('reportTemplate'), obj, layout);
		} catch {
			return;
		}
		
		
		const reportv0 = new InspectionReportTemplate();
		reportv0.name = 'v0';
		reportv0.template = obj.reportv0;
		reportv0.formats = [ReportTemplateFormat.DOCX, ReportTemplateFormat.PDF];

		const reportv1 = new InspectionReportTemplate();
		reportv1.name = 'v1';
		reportv1.template = obj.reportv1;
		reportv1.formats = [ReportTemplateFormat.DOCX, ReportTemplateFormat.PDF];

		const projectsToIgnore = ['Ensaio EQ'];

		// List all projects in the current environment
		const count = await InspectionProjectService.count();
		let from = 0;
		const pageSize = 100;

		const progress = new ProgressBar();
		progress.show();
		
		while (true) {
			progress.update(Locale.get('processingData'), from / count);

			const list = await InspectionProjectService.list({from: from, count: pageSize});
			for (const project of list.projects) {
				if (projectsToIgnore.includes(project.name)) {
					continue;
				}

				project.reports = project.name.includes('v1') ? [reportv1] : [reportv0];
				try {
					await InspectionProjectService.update(project);
				} catch (e) {
					progress.destroy();
					Modal.alert(Locale.get('error'), Locale.get('errorProcessingData'));
					return;
				}
				
			}
			
			if (!list.hasMore) {
				break;
			}
			
			from += pageSize;
		}

		progress.destroy();

		Modal.toast(Locale.get('updatedSuccessfully'));
	}
}
