import {Injectable} from '@angular/core';
import {SwUpdate, VersionEvent} from '@angular/service-worker';
import {Environment} from '../../environments/environment';
import {Modal} from '../modal';
import {Locale} from '../locale/locale';

/**
 * Service used to monitor and update the application service worker.
 */
@Injectable()
export class ServiceWorkerUpdateService {
	/**
	 * Frequency at which the service should check for updates (ms).
	 *
	 * Set to check every 5 minute.
	 */
	public static updateCheckTime: number = 5 * 6e4;

	public constructor(public updates: SwUpdate) {
		this.updates.versionUpdates.subscribe((event: VersionEvent) => {
			if (!Environment.PRODUCTION) {
				console.log('EQS: Service worker update available, version comparison.', event);
			}

			if (event.type === 'VERSION_DETECTED') {
				Modal.confirm(Locale.get('warning'), Locale.get('updatedVersionFound')).then((confirm: boolean) => {
					if (confirm) {
						this.updates.activateUpdate().then(() => {
							return document.location.reload();
						});
					}
				});
			}
		});


		setInterval(async() => {
			await this.updates.checkForUpdate();
		}, ServiceWorkerUpdateService.updateCheckTime);
	}

	/**
	 * Method to unregister all service workers that registered for the current domain.
	 */
	public static async unregisterWorkers(): Promise<void> {
		if (window.navigator && navigator.serviceWorker) {
			const registrations = await navigator.serviceWorker.getRegistrations();
			for (const registration of registrations) {
				await registration.unregister();
			}
		}
	}
}
