<div class="ion-padding-vertical">
	@if (this.disabled) {
		@if (this.value) {
			<ion-img (click)="this.save()" src="{{this.preview}}" style="object-fit: contain; width: 120px; height: 120px; cursor: pointer;"></ion-img>
		}
		@if (!this.value) {
			<ion-icon name="qr-code-outline" style="color: var(--gray-8); font-size: 70px;"></ion-icon>
		}
	}
	@if (!this.disabled) {
		@if (this.value) {
			<div class="uno-qr-code-container">
				<ion-img (click)="this.save()" src="{{this.preview}}" style="object-fit: contain; width: 120px; height: 120px; cursor: pointer;"></ion-img>
				<div class="uno-qr-code-button-container">
					<uno-button icon="/assets/icons/uno/refresh.svg" (click)="this.select();" size="medium" type="border" width="120px" [fitContent]="false">{{'replace' | translate }}</uno-button>
					<uno-button icon="/assets/icons/uno/bin.svg" (click)="this.deleteValue();" size="medium" type="border" width="120px" [fitContent]="false">{{'delete' | translate }}</uno-button>
				</div>
			</div>
		}
		@if (!this.value) {
			<div class="uno-qr-code-no-qr-container" (click)="this.select();">
				<div class="uno-qr-code-read-qr">
					<uno-icon src="assets/components/menu/qrcode.svg" color="primary" width="32" height="32"></uno-icon>
					<span class="uno-qr-code-read-qr-text"><span class="uno-qr-code-read-qr-text-bold">{{'read' | translate }} </span>{{'qrCode' | translate }}</span>
				</div>
			</div>
		}
	}
</div>
