/**
 * Input debouncer can be used to debounce input entries.
 *
 * It only triggers change events after some time without inputs.
 */
export class InputDebouncer {
	/**
	 * Debounce time.
	 */
	public time: number = 100;

	/**
	 * Timer for input.
	 */
	public timer: any = null;

	public constructor(time: number, onInput: (value: any)=> void) {
		this.time = time;
		this.onInput = onInput;
	}

	/**
	 * Method called when new input is provided.
	 *
	 * Called only after the debounce time has elapsed.
	 */
	public onInput: (value: any)=> void;

	/**
	 * Update the input value.
	 *
	 * @param value - New value to be set
	 */
	public update(value: any): void {
		if (this.timer !== null) {
			clearTimeout(this.timer);
			this.timer = null;
		}

		this.timer = setTimeout(() => {
			this.onInput(value);
			this.timer = null;
		}, this.time);
	}
}
