import {Component, OnInit} from '@angular/core';
import {Service} from '../../../../../http/service';
import {ServiceList} from '../../../../../http/service-list';
import {App} from '../../../../../app';
import {ScreenComponent} from '../../../../../components/screen/screen.component';
import {Session} from '../../../../../session';
import {UserPermissions} from '../../../../../models/users/user-permissions';
import {MenuCardsGroup, MenuCardsGroupsComponent} from '../../../../../components/layout/menu-cards-groups/menu-cards-groups.component';
import {UnoContentComponent} from '../../../../../components/uno/uno-content/uno-content.component';

@Component({
	selector: 'structure-menu-page',
	templateUrl: 'asset-structure-menu.page.html',
	standalone: true,
	imports: [UnoContentComponent, MenuCardsGroupsComponent]
})
export class AssetStructureMenuPage extends ScreenComponent implements OnInit {
	public app: any = App;


	public session: any = Session;

	public permissions = [UserPermissions.ASSET_PORTFOLIO_STRUCTURE];

	public userPermissions: any = UserPermissions;

	/**
	 * List of options in this menu.
	 */
	public groups: MenuCardsGroup[] = [
		{
			label: '',
			permissions: [],
			options: [
				{
					label: 'formBlocks',
					data: {},
					permissions: [UserPermissions.ASSET_PORTFOLIO_FORM_BLOCK],
					icon: './assets/icons/assets/component.svg',
					route: '/menu/asset-portfolio/structure/form-block/list',
					badge: null
				}
			]
		}
	];

	public async ngOnInit(): Promise<void> {
		super.ngOnInit();

		App.navigator.setTitle('structure');

		this.groups.push(await this.buildAssetTypesMenu());
	}

	/**
	 * Dynamically build assets types and sub-types menus since it has some constraints of remote variables that change the content of group.
	 * 
	 * @returns a promise with a menu card group for the asset types and sub-types menu
	 */
	public async buildAssetTypesMenu(): Promise<MenuCardsGroup> {
		const assetTypesGroup: MenuCardsGroup = {
			label: 'assetTypes',
			permissions: [],
			options: [
				{
					label: 'types',
					data: {},
					permissions: [UserPermissions.ASSET_PORTFOLIO_ASSET_TYPE],
					icon: './assets/icons/assets/component.svg',
					route: '/menu/asset-portfolio/structure/asset-type/list',
					badge: null
				}
			]
		};

		// Asset sub-types card menu should only be presented if at least one asset type exists.
		const request = await Service.fetch(ServiceList.assetPortfolio.assetType.count, null, null, null, Session.session);
		if (request.response.count) {
			assetTypesGroup.options.push({
				label: 'subTypes',
				data: {},
				permissions: [UserPermissions.ASSET_PORTFOLIO_ASSET_TYPE],
				icon: './assets/icons/assets/component.svg',
				route: '/menu/asset-portfolio/structure/asset-sub-type/list',
				badge: null
			});
		}

		return assetTypesGroup;
	}
}
