<uno-content [borders]="true">
	@for (ndt of this.procedures; track ndt) {
		<div>
			<uno-form [layout]="this.layout" [object]="ndt"></uno-form>
			<div style="display: flex; flex-direction: row-reverse; gap: 5px;" class="ion-padding">
				<uno-button (click)="this.delete(ndt)" color="error">{{'delete' | translate}}</uno-button>
			</div>
		</div>
	}
	<div style="display: flex; flex-direction: row-reverse; gap: 5px;" class="ion-padding">
		<uno-button (click)="this.add()">{{'add' | translate}}</uno-button>
		<uno-button (click)="this.save()">{{'save' | translate}}</uno-button>
	</div>
</uno-content>
