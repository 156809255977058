import {InputOptionsMultipleLazyPageRequest, InputOptionsMultipleBatchRequest} from '../../uno-input/uno-options-lazy/uno-options-lazy.component';

/**
 * Possible types of filters.
 */
export enum UnoFilterBarOptionType {
    // Search filter
	SEARCH = 1,
    // Options filter
	OPTIONS = 2,
    // Options filter with lazy loading
	OPTIONS_LAZY = 3,
    // Checkbox filter
	CHECKBOX = 4,
    // Button
	BUTTON = 5
}

/**
 * UNO filter option, to define how the filter should behave.
 */
export class UnoFilterBarOption {
	/**
	 * Type of the bar option.
	 */
	public type: UnoFilterBarOptionType;

	/**
	 * Attribute to be manipulated.
	 */
	public attribute?: string;

	/**
	 * Indicate if labels should be translated.
	 */
	public translate?: boolean;

	/**
	 * Label to be presented in the interface.
	 */
	public label?: string;

	/**
	 * Default value for the filter field.
	 */
	public default?: any = null;

	/**
	 * List of options to present.
	 *
	 * Only applied to OPTIONS, OPTIONS_LAZY.
	 */
	public options?: {label: string, value: any}[] = [];

	/**
	 * Multiple flag to indicate if multiple options can be selected.
	 *
	 * Only applied to OPTIONS, OPTIONS_LAZY.
	 */
	public multiple?: boolean;

	/**
	 * Action to perform on button press.
	 *
	 * Only applied to BUTTON
	 */
	public onClick?: Function;

	/**
	 * Enabled flag to indicate if the filter should be presented.
	 */
	public enabled?: (filters: any)=> boolean = null;

	/**
	 * Lazy fetch options from the API for infinite scrollable list.
	 *
	 * Only applied to OPTIONS_LAZY
	 */
	public fetchOptionsLazy?: (request: InputOptionsMultipleLazyPageRequest, object?: any)=> Promise<{options: any[], hasMore: boolean, id: number}>;

	/**
	 * Fetch batch options from the API to fill already existing values.
	 *
	 * Only applied to OPTIONS_LAZY
	 */
	public fetchOptionsBatch?: (request: InputOptionsMultipleBatchRequest, object?: any)=> Promise<{options: any[]}>;

	/**
	 * Get the list options text.
	 *
	 * Only applied to OPTIONS_LAZY
	 */
	public getOptionText?: (obj: any)=> string;

	/**
	 * Get options from database.
	 * 
	 * Only applied to OPTIONS
	 */
	public fetchOptions?: (object?: any)=> Promise<any[]>;

	/**
	 * Attribute of the objects places in the form entry to consider as key.
	 *
	 * Only used for OPTIONS_LAZY
	 */
	public identifierAttribute?: string;

	/**
	 * Copy a existing filter bar option.
	 * 
	 * Allow to change the attribute and label.
	 * 
	 * @param options - The options to copy.
	 * @param override - List of attribute to override on the options.
	 * @returns The copied options.
	 */
	public static copy(options: UnoFilterBarOption, override?: any): UnoFilterBarOption {
		const copy = {...options};

		Object.assign(copy, override);

		return copy;
	}
}
