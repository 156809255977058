<uno-content [borders]="true">
	@if (this.fluidFamilies) {
		@for (family of this.fluidFamilies; track family.uuid) {
			<div>
				<uno-form [layout]="this.layout" [object]="family" #form></uno-form>
			</div>
		}
	} @else {
		<uno-no-data></uno-no-data>
	}
	<div class="asset-fluid-type-save-buttons-container">
		<uno-button (click)="this.save(true)">{{'update' | translate}}</uno-button>
		<uno-button (click)="this.save()" color="success">{{'save' | translate}}</uno-button>
	</div>
</uno-content>
