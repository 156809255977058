import {UUID} from '../../../../models/uuid';
import {XlsxUtils} from '../../../../utils/xlsx-utils';
import {Service} from '../../../../http/service';
import {ServiceList} from '../../../../http/service-list';
import {Session} from '../../../../session';
import {ProgressBar} from '../../../../progress-bar';
import {Locale} from '../../../../locale/locale';
import {Modal} from '../../../../modal';

/**
 * Utils to import data into an LDS sensor.
 */
export class LdsDataImport {
	/**
	 * Import LDS data from xlsx file.
	 *
	 * Data should be organized into columns with timestamp, channel, x, y.
	 */
	public static async importXlsx(lds: UUID): Promise<void> {
		const content = await XlsxUtils.chooseFileXLSX();

		const data = {
			timestamp: new Date(),
			ldsUuid: lds,
			channels: []
		};

		const channels = new Map<number, any>();
		const progress = new ProgressBar();
		progress.update(Locale.get('uploadingData'), 0);
		
		try {
			for (let i = 0; i < content.length; i++) {
				progress.update(Locale.get('uploadingData'), i / content.length);
				let channel: any = null;

				if (channels.has(content[i].channel)) {
					channel = channels.get(content[i].channel);
				} else {
					channel = {
						index: content[i].channel,
						data: []
					};
					channels.set(content[i].channel, channel);
					data.channels.push(channel);
				}

				channel.data.push([content[i].x, content[i].y]);
			}

			await Service.fetch(ServiceList.pipelineIntegrity.leakDetection.lds.data.add, null, null, data, Session.session, true);
		} catch (e) {
			Modal.alert(Locale.get('error'), Locale.get('errorImport'));
		}

		Modal.alert(Locale.get('success'), Locale.get('importSuccessful'));
		progress.destroy();
	}
}
