@if (this.scene !== null) {
	<uno-content [borders]="true">
		<!-- Base Form -->
		<uno-form [layout]="this.layout" [object]="this.scene" #form></uno-form>
		<!-- Submit buttons -->
		<div style="display: flex; flex-direction: row-reverse; gap: 5px;" class="ion-padding">
			<uno-button (click)="this.update();">{{'save' | translate}}</uno-button>
			<uno-button (click)="this.delete();" color="error">{{'delete' | translate}}</uno-button>
		</div>
	</uno-content>
}
