/**
 * List of directions available for experiments.
 */
export const ExperimentDirection = {
	LEFT: 1,
	RIGHT: 2
};

/**
 * Translations of options in the list of directions.
 */
export const ExperimentDirectionLabel: Map<number, string> = new Map([
	[ExperimentDirection.LEFT, 'left'],
	[ExperimentDirection.RIGHT, 'right']
]);
