import {Service} from '../../../../http/service';
import {ProgressBar} from '../../../../progress-bar';
import {ServiceList} from '../../../../http/service-list';
import {Modal} from '../../../../modal';
import {Locale} from '../../../../locale/locale';
import {UUID} from '../../../../models/uuid';
import {Session} from '../../../../session';
import {FileUtils} from '../../../../utils/file-utils';
import {InspectionForm} from '../../../../models/inspections/form/inspection-form';
import {InspectionFormService} from '../../services/inspection-form.service';
import {InspectionWorkflow} from '../../../../models/inspections/workflow/inspection-workflow';

export class InspectionWorkflowExport {
	/**
	 * Export workflow as a JSON file.
	 * 
	 * @param uuid - The UUID of the workflow to export
	 */
	public static async exportJSON(uuid: UUID): Promise<void> {
		const progress = new ProgressBar();
		progress.show();
		
		try {
			const request = await Service.fetch(ServiceList.inspection.workflow.get, null, null, {uuid: uuid}, Session.session);
			const workflow: InspectionWorkflow = InspectionWorkflow.parse(request.response.workflow);
		
			const forms: Map<UUID, InspectionForm> = new Map();

			// Loads the fields for a specific form UUID and stores both form and fields to be exported
			const loadFormAndFields: Function = async function(formUuid: UUID): Promise<void> {
				if (forms.get(formUuid)) {
					return;
				}

				const form: InspectionForm = await InspectionFormService.get(formUuid, true, false);
				
				for (let i = 0; i < form.fields.length; i++) {
					// Recursively call sub-form data
					if (form.fields[i].isSubform()) {
						if (!form.fields[i].subFormUuid) {
							throw new Error('Invalid subform UUID found in field \"' + form.fields[i].uuid + '\".');
						}
		
						await loadFormAndFields(form.fields[i].subFormUuid);
					}
				}

				forms.set(form.uuid, form);
			};

			for (let i = 0; i < workflow.steps.length; i++) {
				if (workflow.steps[i].formUuid?.length > 0) {
					await loadFormAndFields(workflow.steps[i].formUuid);
				}
			}

			FileUtils.writeFile('workflow.json', JSON.stringify({...workflow, forms: Array.from(forms.values())}, null, '\t'));
			
			progress.update(Locale.get('success'), 1);
		} catch (e) {
			Modal.alert(Locale.get('error'), Locale.get('errorExport'));
			console.error('EQS: Error exporting file.', e);
		}
		
		progress.destroy();
	}
}
