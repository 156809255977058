import {AlertInput, createAnimation} from '@ionic/angular';
import {Environment} from '../environments/environment';
import {Locale} from './locale/locale';
import {UnoFormModalComponent, UnoFormModalButton} from './components/uno-forms/uno-form-modal/uno-form-modal.component';
import {UnoFormField} from './components/uno-forms/uno-form/uno-form-field';
import {App} from './app';

/**
 * Represents a modal alert request, these are stored in a stack and shown one after another.
 *
 * Similar modal requests might be aggregated into a single alert to prevent showing repeated information to the user.
 */
class ModalRequest {
	/**
	 * Title of the modal window.
	 */
	public title: string = '';

	/**
	 * Message (content) of the modal window.
	 */
	public message: string = '';

	public constructor(title, message) {
		this.title = title;
		this.message = message;
	}
}

/**
 * Handles the creating and display of modal boxes with messages for the user.
 *
 * Can be used for alerts, errors, warning, etc.
 */
export class Modal {
	/**
	 * Queue of waiting modal requests to be dismissed.
	 */
	public static alertQueue: ModalRequest[] = [];

	/**
	 * Present a modal to prompt input data using a form component. The result is obtained through a Promise containing the object of the form.
	 *
	 * @param title - Title of the modal window.
	 * @param object - Input object to be edited in the prompt.
	 * @param layout - Form layout to display on the modal.
	 * @param buttons - Buttons to show on the modal, optional by default a ok and cancel buttons are displayed.
	 * @param editable - Indicates if the form is editable by the user.
	 * @returns A Promise containing the object of the form.
	 */
	public static async form(title: string, object: any, layout: UnoFormField[], buttons?: UnoFormModalButton[], editable: boolean = true): Promise<any> {
		return new Promise(async(resolve, reject) => {
			const componentProps: any = {
				title: title,
				object: object,
				layout: layout,
				editable: editable,
				onDone: resolve,
				onCancel: reject
			};

			if (buttons !== undefined) {
				componentProps.buttons = buttons;
			}

			const popover = await App.popover.create({
				component: UnoFormModalComponent,
				componentProps: componentProps,
				backdropDismiss: false,
				dismissOnSelect: false,
				keyboardClose: false,
				showBackdrop: true,
				cssClass: 'modal-container',
				mode: 'md',
				size: 'auto'
			});

			await popover.present();
		});
	}

	/**
	 * Show a modal container using a component. Can be used for inputs or showing any content in modal.
	 *
	 * To retrieve content from the modal component pass callback methods through the data object.
	 *
	 * @param component - Input component to be presented in the Modal object.
	 * @param data - Data passed to the modal component.
	 * @param cancelable - If true the component can be dismissed by clicking on the background or by pressing a keyboard button.
	 * @param dismissOnSelect - If true the component is dismissed when a choice is selected.
	 * @returns The popover element created, that can be used to interact with the element created.
	 */
	public static async component(component: any, data?: any, cancelable: boolean = true, dismissOnSelect: boolean = false, waitForDismiss: boolean = true): Promise<HTMLIonPopoverElement> {
		const popover = await App.popover.create({
			component: component,
			componentProps: data || {},
			backdropDismiss: cancelable,
			keyboardClose: cancelable,
			dismissOnSelect: dismissOnSelect,
			showBackdrop: true,
			cssClass: 'modal-container',
			mode: 'md',
			size: 'auto'
		});

		await popover.present();

		if (waitForDismiss) {
			await popover.onDidDismiss();
		}

		return popover;
	}

	/**
	 * Show alert box, with a title and message.
	 */
	public static async alert(title: string = '', message: string = ''): Promise<void> {
		if (Environment.TEST) {
			return;
		}

		let found = false;
		for (let i = 0; i < this.alertQueue.length; i++) {
			if (this.alertQueue[i].title === title && this.alertQueue[i].message === message) {
				found = true;
			}
		}

		if (found) {
			return;
		}

		const request = new ModalRequest(title, message);
		this.alertQueue.push(request);

		const alert: HTMLIonAlertElement = await App.alert.create({
			header: title,
			message: message.replace(/\n/g, '<br>'),
			buttons: [{
				text: Locale.get('ok'),
				handler: () => {
					const index = this.alertQueue.indexOf(request);
					if (index !== -1) {
						this.alertQueue.splice(index, 1);
					}
				}
			}]
		});

		await alert.present();

		await alert.onDidDismiss();
	}

	/**
	 * Show confirmation box, with a title and message.
	 *
	 * The user has to answer (yes or no), the response is received as boolean promise.
	 *
	 * @param title - The title for the confirm modal.
	 * @param message - The message for the confirm modal.
	 * @param buttons - The buttons for the confirm modal.
	 * @param backdropDismiss - If clicking the backdrop dismisses it or not.
	 * @returns
	 */
	public static async confirm(title: string = '', message: string = '', buttons: string[] = ['ok', 'cancel'], backdropDismiss: boolean = true): Promise<boolean> {
		return new Promise<boolean>(function(resolve, reject) {
			App.alert.create({
				header: title,
				message: message.replace(/\n/g, '<br>'),
				backdropDismiss: backdropDismiss,
				buttons: [
					{text: Locale.get(buttons[0]), handler: () => { resolve(true); }},
					{text: Locale.get(buttons[1]), handler: () => { resolve(false); }}
				]
			}).then(function(alert) {
				alert.present().catch(function(err) {
					reject(err);
				});
			});
		});
	}

	/**
	 * Show prompt modal to request input from the user, with a title and message.
	 *
	 * The modal contains an accept and cancel buttons that the user can to submit the values or cancel the action.
	 */
	public static async prompt(title: string, inputs: AlertInput[]): Promise<{confirm: boolean, data: any}> {
		return new Promise(function(resolve, reject) {
			App.alert.create({
				header: title,
				inputs: inputs,
				buttons: [
					{text: Locale.get('ok'), handler: (data) => { resolve({confirm: true, data: data}); }},
					{text: Locale.get('cancel'), handler: (data) => { resolve({confirm: false, data: data}); }}
				]
			}).then(function(alert) {
				alert.present();
			}).catch(function(e) {
				reject(e);
			});
		});
	}

	/**
	 * Show toast message that is displayed at the bottom of the screen for a moment.
	 *
	 * These messages should be used to provide non-intrusive feedback to the user.
	 */
	public static async toast(message: string, duration: number = 6000, color?: string, icon?: string): Promise<void> {
		if (Environment.TEST || !message) {
			return;
		}

		const toast = await App.toast.create({
			color: color,
			message: message.replace(/\n/g, '<br>'),
			duration: duration,
			mode: 'ios',
			position: 'bottom',
			layout: 'stacked',
			keyboardClose: true,
			icon: icon,
			leaveAnimation: App.device.isMobile() ? (element) => {
				const toastBox = element.shadowRoot.children[0];

				return createAnimation().addElement(toastBox).duration(200).fromTo('bottom', '75px', '-80px');
			} : undefined,
			enterAnimation: App.device.isMobile() ? (element) => {
				const toastBox = element.shadowRoot.children[0];
				toastBox.style.bottom = '75px';
				toastBox.style.transform = 'unset';

				return createAnimation().addElement(toastBox).duration(200).fromTo('bottom', '-80px', '75px');
			} : undefined
		});

		toast.onclick = async function() {
			await toast.dismiss();
		};

		await toast.present();
	}
}
