import {Component, OnInit, ViewChild} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {LDSService} from 'src/app/modules/pipeline-integrity/services/lds.service';
import {LDSAlarmHistoryService} from 'src/app/modules/pipeline-integrity/services/lds-alarm.service';
import {UnoFormComponent} from '../../../../../../components/uno-forms/uno-form/uno-form.component';
import {UnoFormModalButton} from '../../../../../../components/uno-forms/uno-form-modal/uno-form-modal.component';
import {LDSAlarm} from '../../../../../../models/pipeline-integrity/leak-detection/lds-alarm';
import {AlarmCondition} from '../../../../../../models/pipeline-integrity/leak-detection/lds-alarm-condition';
import {LDSAlarmHistory} from '../../../../../../models/pipeline-integrity/leak-detection/lds-alarm-history';
import {LDSAlarmLevelLabel} from '../../../../../../models/pipeline-integrity/leak-detection/lds-alarm-level';
import {ScreenComponent} from '../../../../../../components/screen/screen.component';
import {App} from '../../../../../../app';
import {UserPermissions} from '../../../../../../models/users/user-permissions';
import {Session} from '../../../../../../session';
import {Service} from '../../../../../../http/service';
import {ServiceList} from '../../../../../../http/service-list';
import {Modal} from '../../../../../../modal';
import {Locale} from '../../../../../../locale/locale';
import {UnoFormModule} from '../../../../../../components/uno-forms/uno-form.module';
import {UUID} from '../../../../../../models/uuid';
import {LDS} from '../../../../../../models/pipeline-integrity/leak-detection/lds';
import {LdsLayout} from '../../lds-layout';
import {LdsChannelLayout} from '../../lds-channel-layout';
import {RandomUtils} from '../../../../../../utils/random-utils';
import {Environment} from '../../../../../../../environments/environment';
import {LdsDataImport} from '../../../../data/leak-detection/lds-data-import';
import {LdsAlarmLayout} from '../../lds-alarm-layout';
import {FormatDatePipe} from '../../../../../../pipes/format-date.pipe';
import {UnoListItemIconComponent} from '../../../../../../components/uno/uno-list-item/uno-list-item-icon.component';
import {UnoListItemLabelComponent} from '../../../../../../components/uno/uno-list-item/uno-list-item-label.component';
import {UnoListItemComponent} from '../../../../../../components/uno/uno-list-item/uno-list-item.component';
import {UnoNoDataComponent} from '../../../../../../components/uno/uno-no-data/uno-no-data.component';
import {UnoTitleComponent} from '../../../../../../components/uno/uno-title/uno-title.component';
import {UnoButtonComponent} from '../../../../../../components/uno/uno-button/uno-button.component';
import {UnoTabSectionComponent} from '../../../../../../components/uno/uno-tab/uno-tab-section/uno-tab-section.component';
import {UnoTabComponent} from '../../../../../../components/uno/uno-tab/uno-tab.component';
import {PermissionsPipe} from '../../../../../../pipes/permissions.pipe';

@Component({
	selector: 'lds-edit-page',
	templateUrl: 'lds-edit.page.html',
	standalone: true,
	imports: [UnoTabComponent, UnoTabSectionComponent, UnoFormModule, UnoButtonComponent, UnoTitleComponent, UnoNoDataComponent, UnoListItemComponent, UnoListItemLabelComponent, UnoListItemIconComponent, TranslateModule, FormatDatePipe, PermissionsPipe]
})
export class LDSEditPage extends ScreenComponent implements OnInit {
	@ViewChild('form', {static: false})
	public form: UnoFormComponent = null;

	public app: any = App;

	public layout: any = LdsLayout;

	public channelLayout: any = LdsChannelLayout;

	public alarmLevelLabel: any = LDSAlarmLevelLabel;

	public userPermissions: any = UserPermissions;

	public session: any = Session;

	public environment: any = Environment;

	public permissions = [UserPermissions.PIPELINE_INTEGRITY];

	/**
	 * LDS being edited/created on this screen, data is fetched from the API.
	 */
	public lds: LDS = null;

	/**
	 * List of alarms associated with the LDS.
	 */
	public alarmConfig: LDSAlarm[] = [];

	/**
	 * Alarm history.
	 */
	public alarmHistory: LDSAlarmHistory[] = [];

	/**
	 * Flag to indicate if the screen is being used to create a new entry.
	 */
	public createMode: boolean = false;

	public async ngOnInit(): Promise<void> {
		super.ngOnInit();

		this.lds = null;
		this.createMode = false;

		const data = App.navigator.getData();
		if (!data || !data.ldsUuid && !data.createMode) {
			App.navigator.pop();
			return;
		}

		if (data.ldsUuid && data.createMode) {
			throw Error('UUID and createMode cannot be used simultaneously.');
		}

		App.navigator.setTitle(data.createMode ? 'create' : 'edit');
	
		// Create CMP
		if (data.createMode) {
			this.createMode = true;
			this.lds = new LDS();
			this.lds.pipelineUuid = data.pipelineUuid || null;
		// Edit CMP, load specific CMP
		} else {
			this.lds = await LDSService.get(data.ldsUuid);

			this.alarmConfig = await LDSAlarmHistoryService.listAlarms(data.ldsUuid);
			this.alarmHistory = await LDSAlarmHistoryService.listAlarmHistory(data.ldsUuid);
		}
	}


	/**
	 * Create LDS data in the API.
	 */
	public async create(): Promise<void> {
		if (!this.form.requiredFilled()) {
			Modal.alert(Locale.get('error'), Locale.get('requiredFieldsError'));
			return;
		}

		const lds = structuredClone(this.lds);
		delete lds.createdAt;
		delete lds.updatedAt;

		await Service.fetch(ServiceList.pipelineIntegrity.leakDetection.lds.create, null, null, lds, Session.session);
		Modal.toast(Locale.get('createdSuccessfully'));
		App.navigator.pop();
	}


	/**
	 * Update LDS data in the API.
	 * 
	 * Checks if all required form fields are filled. Display toast on success.
	 *
	 * @param stayOnPage - Flag to indicate if the system should stay on the page after changes are saved.
	 */
	public async update(stayOnPage: boolean = false): Promise<void> {
		if (!this.form.requiredFilled()) {
			Modal.alert(Locale.get('error'), Locale.get('requiredFieldsError'));
			return;
		}

		const lds = structuredClone(this.lds);
		delete lds.createdAt;
		delete lds.updatedAt;

		await Service.fetch(ServiceList.pipelineIntegrity.leakDetection.lds.update, null, null, lds, Session.session);
		if (!stayOnPage) {
			App.navigator.pop();
		}

		Modal.toast(Locale.get('updatedSuccessfully'));
	}

	/**
	 * Edit alarm configuration.
	 */
	public async editAlarmConfig(alarm: LDSAlarm): Promise<void> {
		await Modal.form(Locale.get('edit'), alarm, LdsAlarmLayout);

		if (alarm.condition === AlarmCondition.GREATER) {
			delete alarm.range.min;
		} else if (alarm.condition === AlarmCondition.LESS) {
			delete alarm.range.max;
		}

		await Service.fetch(ServiceList.pipelineIntegrity.leakDetection.alarm.update, null, null, alarm, Session.session);
		this.alarmConfig = await LDSAlarmHistoryService.listAlarms(this.lds.uuid);
	}

	/**
	 * View alarm configuration.
	 */
	public async viewAlarmConfig(history: LDSAlarmHistory): Promise<void> {
		const buttons: UnoFormModalButton[] = [{
			success: false,
			label: 'close',
			color: 'primary',
			callback: null
		}];
		
		let alarm: LDSAlarm;
		try {
			const request = await Service.fetch(ServiceList.pipelineIntegrity.leakDetection.alarm.get, null, null, {uuid: history.alarmUuid}, Session.session, false, false);
			alarm = request.response.alarm;

		} catch {
			await Modal.confirm(Locale.get('error'), Locale.get('alarmDeleted'), ['close', '']);
			return;
		}

		await Modal.form(Locale.get('alarm'), alarm, LdsAlarmLayout, buttons, false);
	}

	/**
	 * Add alarm configuration to LDS.
	 */
	public async addAlarmConfig(): Promise<void> {
		const alarm = new LDSAlarm();
		alarm.ldsUuid = this.lds.uuid;

		await Modal.form(Locale.get('create'), alarm, LdsAlarmLayout);

		if (alarm.condition === AlarmCondition.GREATER) {
			delete alarm.range.min;
		} else if (alarm.condition === AlarmCondition.LESS) {
			delete alarm.range.max;
		}

		await Service.fetch(ServiceList.pipelineIntegrity.leakDetection.alarm.create, null, null, alarm, Session.session);
		this.alarmConfig = await LDSAlarmHistoryService.listAlarms(this.lds.uuid);
	}


	/**
	 * Delete alarm configuration from LDS.
	 */
	public async deleteAlarmConfig(alarmUuid: UUID): Promise<void> {
		const confirm = await Modal.confirm(Locale.get('confirm'), Locale.get('confirmDelete'));
		if (confirm) {
			await Service.fetch(ServiceList.pipelineIntegrity.leakDetection.alarm.delete, null, null, {uuid: alarmUuid}, Session.session);
			this.alarmConfig = await LDSAlarmHistoryService.listAlarms(this.lds.uuid);
		}
	}
	
	/**
	 * Upload data to this LDS from XLSX file.
	 */
	public async uploadXlsx(): Promise<void> {
		await LdsDataImport.importXlsx(this.lds.uuid);
		this.alarmConfig = await LDSAlarmHistoryService.listAlarms(this.lds.uuid);
	}

	/**
	 * Generate random test data and send to the server.
	 *
	 * Uses the current timestamp for the data generate. Data is generated randomly.
	 *
	 * @param samples - Number of data entries to generate for each channel.
	 */
	public async generateTestData(samples: number = 10000): Promise<void> {
		const data = {
			timestamp: new Date(),
			ldsUuid: this.lds.uuid,
			channels: []
		};

		for (let i = 0; i < this.lds.channels.length; i++) {
			const channel = {
				index: this.lds.channels[i].index,
				data: []
			};

			for (let s = 0; s < samples; s++) {
				channel.data.push([s, RandomUtils.randomNumber(10, 35)]);
			}

			data.channels.push(channel);
		}


		await Service.fetch(ServiceList.pipelineIntegrity.leakDetection.lds.data.add, null, null, data, Session.session);
		this.alarmConfig = await LDSAlarmHistoryService.listAlarms(this.lds.uuid);
	}


	/**
	 * Delete LDS from API.
	 */
	public async delete(): Promise<void> {
		const confirm = await Modal.confirm(Locale.get('confirm'), Locale.get('confirmDelete'));
		if (confirm) {
			await Service.fetch(ServiceList.pipelineIntegrity.leakDetection.lds.delete, null, null, {uuid: this.lds.uuid}, Session.session);
			Modal.toast(Locale.get('deleteSuccessfully'));
			App.navigator.pop();
		}
	}

}
