import {Component, OnInit, ViewChild, ViewChildren, QueryList} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {IonicModule} from '@ionic/angular';
import {UnoFormField} from '../../../../../components/uno-forms/uno-form/uno-form-field';
import {UnoFormFieldTypes} from '../../../../../components/uno-forms/uno-form/uno-form-field-types';
import {UnoFormComponent} from '../../../../../components/uno-forms/uno-form/uno-form.component';
import {UnoFormUtils} from '../../../../../components/uno-forms/uno-form/uno-form-utils';
import {ScreenComponent} from '../../../../../components/screen/screen.component';
import {App} from '../../../../../app';
import {UserPermissions} from '../../../../../models/users/user-permissions';
import {Session} from '../../../../../session';
import {UnoFormModule} from '../../../../../components/uno-forms/uno-form.module';
import {Service} from '../../../../../http/service';
import {ServiceList} from '../../../../../http/service-list';
import {Modal} from '../../../../../modal';
import {Locale} from '../../../../../locale/locale';
import {InspectionForm} from '../../../../../models/inspections/form/inspection-form';
import {InspectionFormFieldLayout, InspectionFormLayout} from '../inspection-form-layout';
import {InspectionFormField} from '../../../../../models/inspections/form/inspection-form-field';
import {ArrayUtils} from '../../../../../utils/array-utils';
import {InspectionFormExport} from '../../../data/form/inspection-form-export';
import {UnoButtonComponent} from '../../../../../components/uno/uno-button/uno-button.component';
import {UnoTitleComponent} from '../../../../../components/uno/uno-title/uno-title.component';
import {UnoContentComponent} from '../../../../../components/uno/uno-content/uno-content.component';
import {InspectionFormService} from '../../../services/inspection-form.service';
import {UnoTabComponent} from '../../../../../components/uno/uno-tab/uno-tab.component';
import {UnoTabSectionComponent} from '../../../../../components/uno/uno-tab/uno-tab-section/uno-tab-section.component';
import {InspectionFormUtils} from '../../../data/form/inspection-form-utils';
import {PermissionsPipe} from '../../../../../pipes/permissions.pipe';

@Component({
	selector: 'inspection-form-edit-page',
	templateUrl: './inspection-form-edit.page.html',
	standalone: true,
	imports: [UnoContentComponent, UnoTitleComponent, UnoFormModule, UnoButtonComponent, IonicModule, TranslateModule, UnoTabComponent, UnoTabSectionComponent, PermissionsPipe]
})
export class InspectionFormEditPage extends ScreenComponent implements OnInit {
	public app: any = App;

	public inspectionFormExport: any = InspectionFormExport;

	public layout: any = InspectionFormLayout;

	public userPermissions: any = UserPermissions;

	public session: any = Session;

	/**
	 * Anchor reference to the inspection base form.
	 */
	@ViewChild('baseForm', {static: false})
	public baseForm: UnoFormComponent = null;

	/**
	 * Anchor reference to the inspection form fields form.
	 */
	@ViewChildren('fieldsForm') 
	public fieldsForm: QueryList<UnoFormComponent>;

	public permissions = [UserPermissions.INSPECTION_FORM_CREATE, UserPermissions.INSPECTION_FORM_EDIT];

	/**
	 * Inspection form being edited/created on this screen, data is fetched from the API.
	 */
	public form: InspectionForm = null;

	/**
	 * The layout to edit how a form field should be presented on a form.
	 */
	public formFieldLayout: UnoFormField[] = InspectionFormFieldLayout;

	/**
	 * Form layout used for previewing the form.
	 */
	public previewLayout: UnoFormField[] = [];

	/**
	 * Preview object to test the form layout.
	 */
	public previewObject: any = {};

	/**
	 * Flag to indicate if the screen is being used to create a new entry.
	 */
	public createMode: boolean = false;

	public async ngOnInit(): Promise<void> {
		super.ngOnInit();

		this.form = null;
		this.createMode = false;

		App.navigator.setTitle('form');

		const data = App.navigator.getData();
		if (!data || !data.uuid && !data.createMode) {
			App.navigator.pop();
			return;
		}

		if (data.uuid && data.createMode) {
			throw Error('UUID and createMode cannot be used simultaneously.');
		}

		if (data.createMode) {
			this.createMode = true;
			this.form = new InspectionForm();
			App.navigator.setTitle('create');
		} else {
			this.form = await InspectionFormService.get(data.uuid);
			App.navigator.setTitle(this.form.name);
		}
	}

	/**
	 * Create/update the inspection form alongside with its inspection fields.
	 *
	 * @param stayOnPage - Flag to indicate if the system should stay on the page after changes are saved.
	 */
	public async update(stayOnPage: boolean = false): Promise<void> {
		if (!this.baseForm.requiredFilled()) {
			Modal.alert(Locale.get('error'), Locale.get('requiredFieldsError'));
			return;
		}

		// Check required fields of all form fields content
		for (const field of this.fieldsForm.toArray()) {
			if (!field.requiredFilled()) {
				Modal.alert(Locale.get('error'), Locale.get('requiredFieldsError'));
				return;
			}
		}

		await Service.fetch(this.createMode ? ServiceList.inspection.form.create : ServiceList.inspection.form.update, null, null, this.form, Session.session);

		if (!stayOnPage) {
			App.navigator.pop();
		}

		Modal.toast(Locale.get('updatedSuccessfully'));
	}

	/**
	 * Delete the inspection form from the database.
	 */
	public async delete(): Promise<void> {
		const confirm = await Modal.confirm(Locale.get('confirm'), Locale.get('confirmDelete'));
		if (confirm) {
			await Service.fetch(ServiceList.inspection.form.delete, null, null, {uuid: this.form.uuid}, Session.session);
			Modal.toast(Locale.get('deleteSuccessfully'));
			App.navigator.pop();
		}
	}

	/**
	 * Clone this inspection form.
	 * 
	 * The clone can be performed in two modes. Deep mode, where every used sub-form will be cloned recursively and shallow mode where only the form is cloned and all its references are reused.
	 */
	public async clone(): Promise<void> {
		const data = {
			// Cloned form name
			name: this.form.name + ' (' + Locale.get('copy') + ')',
			// Clone mode
			deep: true
		};

		const layout: UnoFormField[] = [
			{
				label: 'name',
				attribute: 'name',
				type: UnoFormFieldTypes.TEXT
			},
			{
				label: 'cloneSubForms',
				attribute: 'deep',
				type: UnoFormFieldTypes.CHECKBOX
			}
		];

		try {
			await Modal.form(Locale.get('clone'), data, layout);

			try {
				await Service.fetch(ServiceList.inspection.form.clone, null, null, {uuid: this.form.uuid, name: data.name, deep: data.deep}, Session.session);
				Modal.toast(Locale.get('cloneSuccessfully'));
			} catch (e) {}
		} catch (e) {/* Do not perform any action on modal cancel */}
	}

	/**
	 * Move field up in the list of fields.
	 */
	public moveFieldUp(field: InspectionFormField): void {
		const index = this.form.fields.indexOf(field);
		if (index < this.form.fields.length && index !== -1) {
			ArrayUtils.move(this.form.fields, index, index + 1);
			this.form.updateFieldIndex();
		}
	}

	/**
	 * Move field down in the list of fields.
	 */
	public moveFieldDown(field: InspectionFormField): void {
		const index = this.form.fields.indexOf(field);
		if (index > 0) {
			ArrayUtils.move(this.form.fields, index, index - 1);
			this.form.updateFieldIndex();
		}
	}

	/**
	 * Add a field to the inspection form.
	 */
	public addField(): void {
		const field = new InspectionFormField();
		field.formUuid = this.form.uuid;
		field.data = {};
		this.form.fields.push(field);
		this.form.updateFieldIndex();
	}

	/**
	 * Remove a field from the inspection form.
	 *
	 * @param field - Field to be removed
	 */
	public removeField(field: InspectionFormField): void {
		const index = this.form.fields.indexOf(field);
		if (index !== -1) {
			this.form.fields.splice(index, 1);
			this.form.updateFieldIndex();
		}
	}

	/**
	 * Prepare the preview layout of the form.
	 *
	 * This method will load all sub-forms and build the preview layout of the form. 
	 */
	public async preparePreviewLayout(): Promise<void> {
		const forms = new Map();
		forms.set(this.form.uuid, this.form);

		// Load all sub-forms
		const loadSubForms = async(form: InspectionForm): Promise<void> => {
			for (const field of this.form.fields) {
				if (field.subFormUuid && !forms.has(field.subFormUuid)) {
					const f = await InspectionFormService.get(field.subFormUuid);
					forms.set(f.uuid, f);
					loadSubForms(f);
				}
			}
		};

		await loadSubForms(this.form);

		this.previewLayout = InspectionFormUtils.buildDynamicForm(forms, this.form.uuid, true);
		this.previewObject = UnoFormUtils.createObject(this.previewLayout);
	}
}
