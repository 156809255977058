<div class="geo-position-container">
	<div #mapContainer class="geo-position-map">
		<div style="display: flex; justify-content: space-between;">
			<div class="geo-position-buttons-container">
				@if (!this.disabled) {
					<div (click)="this.getGPSPosition()" class="geo-position-button">
						<ion-icon src="/assets/components/gps-input/position.svg"></ion-icon>
					</div>

					<div (click)="this.showModal()" class="geo-position-button">
						<ion-icon src="/assets/components/gps-input/coordinates.svg"></ion-icon>
					</div>

					<div (click)="this.writeValue(null)" class="geo-position-button">
						<ion-icon src="/assets/components/gps-input/clear.svg"></ion-icon>
					</div>
				}
			</div>

			<div class="geo-position-buttons-container">
				<div (click)="this.toggleFullscreen()" class="geo-position-button">
					<ion-icon [src]="fullscreen() ? '/assets/components/gps-input/fullscreen-exit.svg' : '/assets/components/gps-input/fullscreen-enter.svg'"></ion-icon>
				</div>
				<div (click)="this.switchStyle()" class="geo-position-button">
					<ion-icon src="/assets/components/gps-input/style.svg"></ion-icon>
				</div>
			</div>
		</div>

	</div>
</div>
