<uno-content [borders]="true">
	@for (type of this.types; track type) {
		<div>
			<uno-form [layout]="this.layout" [object]="type" #form></uno-form>
			<div class="repair-temporary-types-delete-button-container">
				<uno-button (click)="this.delete(type)" color="error">{{'delete' | translate}}</uno-button>
			</div>
		</div>
	}
	<uno-button (click)="this.add()">{{'add' | translate}}</uno-button>

	<div class="repair-temporary-types-save-buttons-container">
		<uno-button (click)="this.save(true)">{{'update' | translate}}</uno-button>
		<uno-button (click)="this.save()" color="success">{{'save' | translate}}</uno-button>
	</div>
</uno-content>
