import {Component, forwardRef, Input, OnChanges, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR, FormsModule} from '@angular/forms';
import {App} from 'src/app/app';
import {TranslateModule} from '@ngx-translate/core';
import {IonicModule} from '@ionic/angular';
import {UnoFormUtils} from '../../uno-forms/uno-form/uno-form-utils';
import {UnoFormField, OptionsDisplayMode} from '../../uno-forms/uno-form/uno-form-field';

export type OptionSelectorOption = {
	// The text to present for the option
	label: string,

	// The value of the option
	value: any
};

/**
 * Form input options box.
 *
 * Used to select a single option.
 */
@Component({
	selector: 'uno-options',
	templateUrl: './uno-options.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => { return UnoOptionsComponent; }),
		multi: true
	}],
	standalone: true,
	imports: [IonicModule, FormsModule, TranslateModule]
})
export class UnoOptionsComponent implements ControlValueAccessor, OnChanges {
	public optionsDisplayMode = OptionsDisplayMode;

	public app: any = App;

	public formUtils: any = UnoFormUtils;

	/**
	 * Allow the input to be disabled.
	 */
	@Input()
	public disabled: boolean = false;

	/**
	 * If set, sorts all the items alphabetically.
	 */
	@Input()
	public sort: boolean = false;

	/**
	 * If set, the options will be automatically translated
	 */
	@Input()
	public translate: boolean = true;

	/**
	 * Options to display for selection.
	 */
	@Input()
	public options: OptionSelectorOption[] = [];

	/**
	 * The display mode for the options component.
	 */
	@Input()
	public displayMode: number = OptionsDisplayMode.POPOVER;

	/**
	 * The row form field to fetch the options for.
	 */
	@Input()
	public row: UnoFormField = null;

	/**
	 * The the object to get the options for.
	 */
	@Input()
	public object: any = null;

	/**
	 * The selected option value.
	 */
	public value: any = null;

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.sort && this.sort) {
			this.options.sort((a: any, b: any) => { return a.label > b.label ? 1 : -1;});
		}
	}

	public onChange: (value: any)=> void = function(value) {};

	public registerOnChange(onChange: any): void {
		this.onChange = onChange;
	}

	public writeValue(value: any): void {
		this.value = value;
		this.onChange(this.value);
	}

	public setDisabledState(disabled: boolean): void {
		this.disabled = disabled;
	}

	public registerOnTouched(fn: any): void {}
}
