import {UnoFormField} from 'src/app/components/uno-forms/uno-form/uno-form-field';
import {UnoFormFieldTypes} from 'src/app/components/uno-forms/uno-form/uno-form-field-types';


export const AssetFluidTypeLayout: UnoFormField[] = [
	{
		type: UnoFormFieldTypes.SUB_FORM,
		label: (object, row) => {return object?.label;},
		expanded: true,
		required: false,
		fields:
			[{
				type: UnoFormFieldTypes.KEY_VALUE_ARRAY,
				label: 'fluidTypes',
				attribute: 'fluidTypes',
				expanded: false,
				required: true,
				isEmpty: function(object, row) {
					if (object.fluidTypes.length === 0 || !object.fluidTypes) {
						return false;
					}

					for (const type of object.fluidTypes) {
						if (!type.value) {
							return true;
						}
					}

					return false;
				}
			}]
	}
];
