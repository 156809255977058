import {APAssetFormBlockField} from '../models/asset-portfolio/asset-form-block-field';
import {APAssetFormTabCard} from '../models/asset-portfolio/asset-form-tab-card';
import {APAssetFormTab} from '../models/asset-portfolio/asset-form-tab';
import {UUID} from '../models/uuid';

/**
 * Form utils contains tools to manipulate form attributes.
 */
export class FormSortUtils {
	/**
	 * Separator character used to split between different elements.
	 */
	public static separator: string = '|';

	/**
	 * Parse a string encoded index list into a numeric array.
	 * 
	 * E.g Takes '0|1|10' and returns [0, 1, 10].
	 * 
	 * @param data - String to be decoded.
	 * @returns Array of indices obtained from data.
	 */
	public static parseIndex(data: string): number[] {
		return data.split(FormSortUtils.separator).map((v: string) => {return Number.parseInt(v);});
	}

	/**
	 * Sort between two form indexes, each form index is composed of many sub indexes.
	 *
	 * @param a - First index to be compared.
	 * @param b - Second index to be compared.
	 */
	public static compareIndexes(a: string, b: string): number {
		// Get the indexes of a and b as integers.
		const aIdx: number[] = FormSortUtils.parseIndex(a);
		const bIdx: number[] = FormSortUtils.parseIndex(b);

		// Check index by index
		for (let i = 0; i < aIdx.length && i < bIdx.length; i++) {
			if (aIdx[i] > bIdx[i]) {
				return 1;
			} else if (aIdx[i] < bIdx[i]) {
				return -1;
			}
		}

		// If they have the same indexes and different length display the smaller one first
		return aIdx.length > bIdx.length ? 1 : aIdx.length === bIdx.length ? 0 : -1;
	}

	/**
	 * Sort items by indexes.
	 * 
	 * @param items - items array that include a "indexes" attribute to sort.
	 * @returns an array of items sorted by the "indexes" attribute.
	 */
	public static sortByIndexes(items: {indexes: string}[]): any[] {
		return items.sort(function(a: {indexes: string}, b: {indexes: string}): number {
			return FormSortUtils.compareIndexes(a.indexes, b.indexes);
		});
	}

	/**
	 * Updates all elements indexes of an array beginning from 0 index value for root level.
	 * 
	 * If subTypeUuid and depth are provided, only sub-level elements which are owned by the editor sub-level indexes are updated. 
	 * 
	 * @param items - items array that include an "indexes" attribute to update.
	 * @param subTypeUuid - the UUID of the sub-type whose elements are meant to be updated.
	 * @param depth - the depth of the editor sub-level.
	 * @returns an array with all items with the "indexes" attributes of the elements "owned" by the provided editor sub-level UUID updated.
	 */
	public static updateIndexes(items: APAssetFormTab[] | APAssetFormTabCard[] | APAssetFormBlockField[], subTypeUuid?: UUID, depth?: number): any[] {
		return items.map((item: APAssetFormTab | APAssetFormTabCard | APAssetFormBlockField, idx: number) => {
			if (subTypeUuid && depth) {
				if (!(item instanceof APAssetFormBlockField) && item.subTypeUuid === subTypeUuid) {
					if (items.length > 1) {
						if (idx >= 1) {
							const prevIdx: string[] = items[idx - 1].indexes.split(FormSortUtils.separator);
							const lastLvl: number = prevIdx.length - 1;
	
							if (lastLvl === depth) {
								prevIdx[prevIdx.length - 1] = String(Number(prevIdx[prevIdx.length - 1]) + 1);
								item.indexes = prevIdx.join(FormSortUtils.separator);
							} else {
								item.indexes = items[idx - 1].indexes + (FormSortUtils.separator + '0').repeat(depth - 1 - lastLvl) + FormSortUtils.separator + '1';
							}
						} else {
							item.indexes = ('0' + FormSortUtils.separator).repeat(depth) + '1';
						}
					} else {
						item.indexes = ('0' + FormSortUtils.separator).repeat(depth) + '1';
					}
				}
			} else {
				item.indexes = String(1 + idx);
			}

			return item;
		});
	}
}
