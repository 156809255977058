import {UUID} from '../models/uuid';
import {ServiceMeta} from './service-meta';

/**
 * Service request error handler.
 *
 * If the handler is undefined it uses a default error handler, if it returns true the default handler is also run.
 */
export type ServiceRequestErrorHandler = (response: any, xhr: XMLHttpRequest, urlData: any, headerData: any, bodyData: any, meta?: ServiceMeta)=> (void |boolean| Promise<void> | Promise<boolean>);

/**
 * Service request handler used to get the data provided by the service call.
 */
export type ServiceRequestHandler = (response: any, xhr: XMLHttpRequest, id: number)=> void;

/**
 * Callback method called on progress events. Can be used to get the progress on multipart form file submissions.
 */
export type ServiceRequestProgressHandler = (event: ProgressEvent<EventTarget>)=> void;

/**
 * Service request queue entry, requests are managed internally by the service manager.
 *
 * Each API request creates one of these objects that can be handled by a promise.
 */
export class ServiceRequest {
	/**
	 * Meta data of the request.
	 */
	public meta: ServiceMeta = null;

	/**
	 * Data encoded into the URL of the request.
	 */
	public urlData: any = null;

	/**
	 * Data stored in the header.
	 */
	public headerData: any = null;

	/**
	 * Data to be send in the body of the request.
	 */
	public bodyData: any = null;

	/**
	 * Session identifier attached to the request.
	 */
	public session: UUID = null;

	/**
	 * Method to be called after the request finishes.
	 */
	public onLoad: ServiceRequestHandler = null;

	/**
	 * Method to be called when there is an error in the request.
	 */
	public onError: ServiceRequestErrorHandler = null;

	/**
	 * Method to be called on progress events.
	 */
	public onProgress: ServiceRequestProgressHandler = null;

	/**
	 * Indicates if the loading modal should be displayed or hidden during the request.
	 */
	public hideLoading: boolean = false;

	/**
	 * ID attached to the request, only attributed when the request is performed.
	 */
	public id: number = -1;

	/**
	 * XHR object used to perform the request.
	 */
	public xhr: XMLHttpRequest = null;

	/**
	 * Constructor for the service request.
	 *
	 * @param meta - Service metadata.
	 * @param urlData - Data to be added to the request URL.
	 * @param headerData - Data to be sent in the request header.
	 * @param bodyData - Object to be posted.
	 * @param session - Session ID to be sent to the server for this specific request.
	 * @param onLoad - On load callback onLoad(response, xhr, id).
	 * @param onError - On error callback onError(response, xhr, urlData, headerData, bodyData).
	 * @param onProgress - Method called on progress.
	 * @param hideLoading - If true the loading indicator will not be shown to the user.
	 */
	public constructor(meta: ServiceMeta, urlData: any, headerData: any, bodyData: any, session: UUID, onLoad: ServiceRequestHandler, onError: ServiceRequestErrorHandler, onProgress?: ServiceRequestProgressHandler, hideLoading?: boolean) {
		this.meta = meta;
		this.urlData = urlData;
		this.headerData = headerData;
		this.bodyData = bodyData;
		this.session = session;
		this.onLoad = onLoad;
		this.onError = onError;
		this.onProgress = onProgress;
		this.hideLoading = hideLoading;
	}
}
