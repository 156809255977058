/**
 * Possible alarm types for gap analysis action plans.
 */
export const GAActionPlanAlarmType = {
	EMAIL: 0,
	SMS: 1
};

/**
 * Possible alarm types labels for action plans.
 */
export const GAActionPlanAlarmTypeLabel: Map<number, string> = new Map([
	[GAActionPlanAlarmType.EMAIL, 'email'],
	[GAActionPlanAlarmType.SMS, 'sms']
]);
