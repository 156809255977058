/**
 * Image utils contains utils for image manipulation.
 *
 * These utils should be used to pre-process images before sending them to the APi.
 */
export class ImageUtils {
	/**
	 * Create a new image and wait for its data to be loaded asynchronously.
	 *
	 * The image is set to be decoded asynchronously.
	 *
	 * @param src - Source of the image.
	 */
	public static async createImageAsync(src: string): Promise<HTMLImageElement> {
		return new Promise<HTMLImageElement>(function(resolve, reject) {
			const image = new Image();
			image.decoding = 'async';
			image.src = src;
			image.onload = function() {
				image.height = image.naturalHeight;
				image.width = image.naturalWidth;
				resolve(image);
			};
			image.onerror = reject;
		});
	}


	/**
	 * Get the aspect ratio of an image from its URL or encoded data.
	 *
	 * @param src - Source of the image.
	 */
	public static getAspectRatio(src: string): Promise<number> {
		return new Promise<number>((resolve, reject) => {
			const img = document.createElement('img');
			img.decoding = 'async';
			img.src = src;
			img.onload = function() {
				if (img.naturalHeight !== 0 && img.naturalWidth !== 0) {
					resolve(img.naturalWidth / img.naturalHeight);
				} else {
					reject();
				}
			};
			img.onerror = reject;
		});
	}

	/**
	 * Get the resolution of an image from its URL or encoded data.
	 *
	 * @param src - Source of the image.
	 */
	public static async getImageSize(src: string): Promise<{width: number, height: number}> {
		return new Promise<{width: number, height: number}>(function(resolve, reject) {
			const img = new Image();
			img.decoding = 'async';
			img.src = src;
			img.onload = function() {
				if (img.naturalHeight !== 0 || img.naturalWidth !== 0) {
					resolve({height: img.naturalHeight, width: img.naturalWidth});
				} else {
					reject();
				}
			};
			img.onerror = reject;
		});
	}

	/**
	 * Compress a image file selected by the user before sending to the API.
	 *
	 * @param file - File to be read and compressed.
	 * @param maxSize - Max size of the image sides, limits booth the width and height but keeps aspect ratio.
	 * @param format - Format of the output image (png or jpeg).
	 * @param quality - Quality of the output image (only applicable if jpeg), from 0 to 1.
	 */
	public static async compressImage(file: File | Blob, maxSize: number = 640, format: string = 'jpeg', quality: number = 0.7): Promise<File> {
		return new Promise(function(resolve, reject) {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = (event) => {
				const img = document.createElement('img');
				// @ts-ignore
				img.src = event.target.result;
				img.onload = () => {
					let width = img.width;
					let height = img.height;

					// Calculate size of the image based on max size
					if (img.width >= img.height) {
						if (img.width > maxSize) {
							const ratio = img.height / img.width;
							width = maxSize;
							height = maxSize * ratio;
						}
					} else {
						if (img.height > maxSize) {
							const ratio = img.width / img.height;
							height = maxSize;
							width = maxSize * ratio;
						}
					}

					// Create canvas to resize the image
					const canvas = document.createElement('canvas');
					canvas.width = width;
					canvas.height = height;

					// Draw the image resized
					const context = canvas.getContext('2d');
					context.drawImage(img, 0, 0, width, height);

					// Get the image from the canvas.
					context.canvas.toBlob((blob) => {
						resolve(new File([blob], 'image.' + format));
					}, 'image/' + format, quality);
				};
				reader.onerror = (error) => {
					reject(error);
				};
			};
		});

	}
}
