import {TranslateService} from '@ngx-translate/core';
import {Injectable} from '@angular/core';
import {AlertController, PopoverController, ToastController} from '@ionic/angular';
import {Router} from '@angular/router';
import {Environment} from 'src/environments/environment';
import {Locale} from './locale/locale';
import {Navigation} from './services/navigation.service';
import {Session} from './session';
import {UrlUtils} from './utils/url-utils';
import {DeviceDetector} from './services/device-detector.service';
import {NetworkIndicatorService} from './services/network-indicator.service';
import {Modules} from './modules';

/**
 * Static element to control global status of the application.
 */
@Injectable({providedIn: 'root'})
export class App {
	/**
	 * Router object used through the hole page.
	 */
	public static navigator: Navigation;

	/**
	 * Service to detect device type and properties.
	 */
	public static device: DeviceDetector;

	/**
	 * Second window of the application for dual monitor usage.
	 *
	 * Is created only if the keepExternalWindow flag is set true on settings.
	 */
	public static window: Window = null;

	/**
	 * Popover controller user to create ionic popovers.
	 */
	public static popover: PopoverController;

	/**
	 * Popover controller user to create ionic toasts.
	 */
	public static toast: ToastController;

	/**
	 * Popover controller user to create ionic alerts.
	 */
	public static alert: AlertController;

	public constructor(public translate: TranslateService, public device: DeviceDetector, public navigator: Navigation, public popover: PopoverController, public toast: ToastController, public alert: AlertController, public router: Router, public indicator: NetworkIndicatorService) {
		Locale.initialize(this.translate);

		App.navigator = navigator;
		App.popover = popover;
		App.toast = toast;
		App.alert = alert;
		App.device = device;

		// Check modules registered in the environment.
		if (Environment.MODULES) {
			const modules = Object.values(Modules);
			for (const m of Environment.MODULES) {
				if (!modules.includes(m as any)) {
					console.error(`EQS: Module ${m} does not exist in the application, check config..`);
				}
			}
		}
	}

	/**
	 * Check if the client has a module available.
	 * 
	 * @param module - Module to check if is available.
	 * @returns True if the module is available, false otherwise.
	 */
	public static hasModule(module: string): boolean {
		return !module || Environment.MODULES.includes(module);
	}

	/**
	 * Open a URL in a new tab, it should be a route of the application.
	 *
	 * @param target - URL to open in a tab.
	 * @param data - Data object to be encoded in the URL.
	 * @param event - Event that triggered the tab open.
	 */
	public static openInTab(target: string, data: any, event?: Event): void {
		if (event) {
			event.preventDefault();
			event.stopPropagation();
		}

		let url = window.location.origin + target;
		const encoded = UrlUtils.encodeFormURL(data);
		if (encoded.length > 0) {
			url += '?' + encoded;
		}

		if (Session.settings.keepExternalWindow) {
			if (this.window !== null && !this.window.closed) {
				this.window.location.href = url;
			} else {
				this.window = window.open(url, '_blank');
			}
		} else {
			window.open(url, '_blank');
		}
	}
}
