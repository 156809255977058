import {Resource} from '../../../../models/resource';
import {Service} from '../../../../http/service';
import {ServiceList} from '../../../../http/service-list';
import {Session} from '../../../../session';
import {RepairSettingsLoader} from './repair-settings-loader';

/**
 * Stores settings applied to the asset, in master data.
 */
export class RepairSettings {
	/**
	 * Proposal template to be used for the repair.
	 */
	public proposalTemplate: Resource = null;

	/**
	 * Report template to be used for the repair.
	 */
	public reportTemplate: Resource = null;

	/**
	 * Report template to be used for the repair inspection.
	 */
	public inspectionReportTemplate: Resource = null;

	/**
	 * Get the master data settings from the API.
	 */
	public static async load(): Promise<RepairSettings> {
		const request = await Service.fetch(ServiceList.repairs.settingsGet, null, null, {}, Session.session, true);

		return RepairSettingsLoader.parse(request.response.settings);
	}
}
