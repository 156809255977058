export const AtexInspectionFieldResult = {
	NONE: -1,
	NOT_APPLICABLE: 0,
	OK: 1,
	OK_REVISED: 2,
	NOK: 3
};

export const AtexInspectionFieldResultLabel: Map<number, string> = new Map([
	[AtexInspectionFieldResult.NONE, 'none'],
	[AtexInspectionFieldResult.NOT_APPLICABLE, 'notApplicable'],
	[AtexInspectionFieldResult.OK, 'ok'],
	[AtexInspectionFieldResult.OK_REVISED, 'okRevised'],
	[AtexInspectionFieldResult.NOK, 'nok']
]);
