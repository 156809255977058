@if (!this.handler.hasItems) {
	<uno-no-data></uno-no-data>
}
@if (this.handler.hasItems) {
	<cdk-virtual-scroll-viewport #viewport (scrolledIndexChange)="this.nextPage($event)" [itemSize]="this.itemSize" style="height: 100%; width: 100%;">
		<ng-container *cdkVirtualFor="let item of this.handler.items; let last = last;">
			<ng-container [ngTemplateOutlet]="this.itemTemplate" [ngTemplateOutletContext]="{item: item, last: last}"></ng-container>
		</ng-container>
	</cdk-virtual-scroll-viewport>
}
