<uno-content [borders]="true">
	<!-- Company information -->
	<uno-form [editable]="true" [layout]="this.dl50CompanyLayout" [object]="this.settings"></uno-form>

	<!-- Submit Buttons -->
	<div style="display: flex; flex-direction: row-reverse; gap: 5px;" class="ion-padding">
		<uno-button (click)="this.app.navigator.pop();">{{'close' | translate}}</uno-button>
		<uno-button (click)="this.update();">{{'save' | translate}}</uno-button>
	</div>
</uno-content>
