import {Modules} from '../../../../modules';
import {MenuOption} from '../../data/menu-option';
import {UserPermissions} from '../../../../models/users/user-permissions';
import {App} from '../../../../app';
import {Environment} from '../../../../../environments/environment';
import {Dl50ClientModule} from '../../../../../client-custom/dl50/dl50-client.module';

/**
 * List of pages available from the menu.
 *
 * Used to create the page content for desktop and mobile.
 */
export const MenuOptions = function(): MenuOption[] {
	return [
		{
			route: '/menu/master-settings',
			icon: './assets/components/menu/settings.svg',
			label: 'masterSettings',
			available: true,
			module: null,
			permissions: [UserPermissions.MASTER_SETTINGS]
		},
		{
			route: '/menu/masterdata',
			icon: './assets/components/menu/master.svg',
			label: 'masterdata',
			available: true,
			module: null,
			permissions: [UserPermissions.MASTER_DATA]
		},
		{
			route: '/menu/companies',
			icon: './assets/components/menu/company.svg',
			label: 'companies',
			available: true,
			module: null,
			permissions: [UserPermissions.COMPANY]
		},
		{
			route: '/menu/users',
			icon: './assets/components/menu/user.svg',
			label: 'users',
			available: true,
			module: null,
			permissions: [UserPermissions.USER]
		},
		{
			route: '/menu/role',
			icon: './assets/components/menu/role.svg',
			label: 'userRoles',
			available: true,
			module: null,
			permissions: [UserPermissions.ROLES]
		},
		{
			route: '/menu/teams',
			icon: './assets/components/menu/team.svg',
			label: 'teams',
			available: true,
			module: null,
			permissions: [UserPermissions.TEAM]
		},
		{
			route: '/menu/data',
			icon: './assets/components/menu/export-import.svg',
			label: 'importExport',
			available: true,
			module: null,
			permissions: [UserPermissions.TOOLS]
		},
		{
			route: '/menu/dashboard',
			icon: './assets/components/menu/dashboard.svg',
			label: 'dashboard',
			available: true,
			module: null,
			permissions: [UserPermissions.DASHBOARD]
		},
		{
			route: '/menu/asset-portfolio',
			icon: './assets/components/menu/asset-portfolio.svg',
			label: 'assetPortfolio',
			available: true,
			highlight: (option: MenuOption) => {
				return !App.navigator.has('/menu/asset-portfolio/asset/map') && App.navigator.has(option.route);
			},
			module: null,
			permissions: [UserPermissions.ASSET_PORTFOLIO]
		},
		{
			route: '/menu/asset-planning',
			icon: './assets/components/menu/schedule.svg',
			label: 'assetPlanning',
			available: true,
			module: Modules.ASSET_PLANNING,
			permissions: [UserPermissions.CALENDAR_EVENT]
		},
		{
			route: '/menu/gap-analysis',
			icon: './assets/components/menu/gap-analysis.svg',
			label: 'gapAnalysis',
			available: true,
			module: Modules.GAP_ANALYSIS,
			permissions: [UserPermissions.GA, UserPermissions.GA_GAP, UserPermissions.GA_ACTION_PLAN]
		},
		{
			route: '/menu/atex',
			icon: './assets/components/menu/atex.svg',
			label: 'atex',
			available: true,
			module: Modules.ATEX_INSPECTIONS,
			permissions: [UserPermissions.ATEX_INSPECTION, UserPermissions.ACTION_PLAN, UserPermissions.FFP]
		},
		{
			route: '/menu/inspection',
			icon: './assets/components/menu/inspections.svg',
			label: 'inspections',
			highlight: (option: MenuOption) => {
				return !App.navigator.has('/menu/inspection/list/steps') && App.navigator.has(option.route);
			},
			available: !Dl50ClientModule.hasRole(),
			module: Modules.INSPECTIONS,
			permissions: [UserPermissions.INSPECTION]
		},
		{
			route: '/menu/dl50',
			icon: './assets/components/menu/dl-50.svg',
			label: 'DL50',
			available: true,
			module: Modules.DL50,
			permissions: [UserPermissions.DL50]
		},
		{
			route: '/menu/epi',
			icon: './assets/components/menu/csi.svg',
			label: 'epiShort',
			available: true,
			module: Modules.EPI,
			permissions: [UserPermissions.EPI]
		},
		{
			route: '/menu/repairs',
			icon: './assets/components/menu/repairs.svg',
			label: 'repairs',
			available: true,
			module: Modules.REPAIRS,
			permissions: [UserPermissions.REPAIR, UserPermissions.REPAIR_INSPECTIONS]
		},
		{
			route: '/menu/digital-twin',
			icon: './assets/components/menu/digitaltwin.svg',
			label: 'digitalTwin',
			available: Environment.DIGITAL_TWIN_SERVER !== null,
			module: Modules.DIGITAL_TWIN,
			permissions: [UserPermissions.DIGITAL_TWIN]
		},
		{
			route: '/menu/observations',
			icon: './assets/components/menu/observations.svg',
			label: 'observations',
			available: true,
			module: Modules.OBSERVATIONS,
			permissions: [UserPermissions.OBSERVATIONS]
		},
		{
			route: '/menu/pipeline-integrity',
			icon: './assets/components/menu/pipeline.svg',
			label: 'pipelineIntegrity',
			available: Environment.PIPELINE_INTEGRITY_SERVER !== null,
			module: Modules.PIPELINE_INTEGRITY,
			permissions: [UserPermissions.PIPELINE_INTEGRITY]
		},
		{
			route: '/menu/asset-portfolio/asset/map',
			icon: './assets/components/menu/map.svg',
			label: 'map',
			available: true,
			module: null,
			permissions: [UserPermissions.ASSET_PORTFOLIO_MAP]
		},
		{
			route: '/menu/qr',
			icon: './assets/components/menu/qrcode.svg',
			label: 'qr',
			available: App.device.hasCamera(),
			module: null,
			permissions: [UserPermissions.ASSET_PORTFOLIO_QR, UserPermissions.REPAIR_QR, UserPermissions.INSPECTION_QR]
		},
		{
			route: '/menu/nfc',
			icon: './assets/components/menu/nfc.svg',
			label: 'nfc',
			available: App.device.hasNFC(),
			module: null,
			permissions: [UserPermissions.ASSET_PORTFOLIO_NFC]
		},
		{
			route: '/menu/logs',
			icon: './assets/components/menu/logs.svg',
			label: 'logs',
			available: true,
			module: null,
			permissions: [UserPermissions.LOGS]
		},
		{
			route: '/menu/demo',
			icon: './assets/components/menu/bug.svg',
			label: 'demo',
			available: !Environment.PRODUCTION,
			module: null,
			permissions: []
		},
		{
			route: '/menu/dl50-client',
			icon: './assets/components/menu/inspections.svg',
			label: 'DL50',
			available: Dl50ClientModule.isClient(),
			module: null,
			permissions: []
		}
	];
};
