import {NodeSocket, Object2D, Text, Vector2} from 'escher.js/build/escher.module.js';
import {DestroyableNode} from './destroyable-node';

/**
 * Function node performs math functions with single argument.
 *
 * Example cos(a), sin(a), etc, these function have to be registered in GVal in the project API.
 */
export class FunctionNode extends DestroyableNode {
	/**
	 * Math function performed by this node.
	 */
	public func: string = '';

	/**
	 * Label shown to the user in th graph.
	 */
	public label: string = '';

	public text: Text = null;

	public a: NodeSocket = null;

	public r: NodeSocket = null;
	
	public constructor(func: string, label: string) {
		super();

		// @ts-ignore
		this.type = 'FunctionNode';

		this.func = func;
		this.label = label;

		// @ts-ignore
		this.box.set(new Vector2(-50, -22), new Vector2(50, 22));

		this.text = new Text();
		this.text.serializable = false;
		this.text.font = '16px Arial';
		this.text.layer = 2;
		// @ts-ignore
		this.add(this.text);
	}

	public registerSockets(): void {
		if (!this.a) {
			// @ts-ignore
			this.a = this.addInput('string', 'a');
		}

		if (!this.r) {
			// @ts-ignore
			this.r = this.addOutput('string', 'r');
			this.r.getValue = () => {
				return this.func + '(' + this.a.getValue() + ')';
			};
		}
	}

	public onUpdate(): void {
		super.onUpdate();

		this.text.text = this.label;
	}

	public serialize(recursive): void {
		// @ts-ignore
		const data = super.serialize(recursive);

		data.func = this.func;
		data.label = this.label;
		data.a = this.a !== null ? this.a.uuid : null;
		data.r = this.r !== null ? this.r.uuid : null;

		return data;
	}

	public parse(data, root): void {
		// @ts-ignore
		super.parse(data, root);

		this.func = data.func;
		this.label = data.label;

		if (data.a !== null) {
			this.a = root.getChildByUUID(data.a);
		}
		if (data.r !== null) {
			this.r = root.getChildByUUID(data.r);
			this.r.getValue = () => {
				return this.func + '(' + this.a.getValue() + ')';
			};
		}
	}
}

Object2D.register(FunctionNode, 'FunctionNode');

