import {Environment} from '../environments/environment';
import {LocalStorage} from './utils/local-storage';
import {Service} from './http/service';
import {ServiceList} from './http/service-list';
import {App} from './app';
import {Locale} from './locale/locale';
import {Settings} from './modules/settings/data/settings';
import {User} from './models/users/user';
import {UUID} from './models/uuid';
import {PrivacyPolicy} from './models/privacy-policy/privacy-policy';
import {Modal} from './modal';
import {PrivacyPolicyViewComponent} from './modules/privacy-policy/components/view/privacy-policy-view.component';
import {Role} from './models/roles/role';

/**
 * Handles the user session status, stores and retrieves data from the storage.
 */
export class Session {
	/**
	 * Cache of user roles available in the application used for faster access to role data.
	 */
	public static rolesCache: Map<UUID, Role> = new Map<UUID, Role>();

	/**
	 * Data of the current used stored.
	 */
	public static userCache: User = null;

	/**
	 * Session UUID stored in memory.
	 */
	public static sessionCache: UUID = null;

	/**
	 * Settings stored in the session.
	 */
	public static settingsCache: Settings = null;

	/**
	 * Load session data from local storage.
	 */
	public static async load(): Promise<void> {
		// Load from locale storage
		Session.sessionCache = Session.session;
		Session.userCache = Session.user;
		Session.settingsCache = Session.settings;

		if (Session.isValid()) {
			// Load the roles list from database
			await Session.loadRoles();

			// Update user data
			const request = await Service.fetch(ServiceList.users.get, null, null, {uuid: Session.user.uuid}, Session.session);
			Session.user = User.parse(request.response.user);
		}

		await Session.checkPrivacyPolicy();
	}

	/**
	 * Check the latest version of the privacy policy in the API.
	 *
	 * If the there is a newer privacy policy show it to the user.
	 */
	public static async checkPrivacyPolicy(): Promise<any> {
		if (Environment.TEST || !Session.user) {
			return;
		}

		const request = await Service.fetch(ServiceList.privacyPolicy.latest, null, null, null, Session.session, true);
		const policy = PrivacyPolicy.parse(request.response.policy);

		if (policy.version > Session.user.privacyPolicy) {
			await Modal.component(PrivacyPolicyViewComponent, {}, false);
		}
	}

	/**
	 * Login into the platform and set the session and user data.
	 *
	 * @param session - Session ID.
	 * @param user - User data received.
	 */
	public static async login(session: UUID, user: User): Promise<void> {
		Session.session = session;
		Session.user = user;

		await Session.loadRoles();
		await Session.checkPrivacyPolicy();
	}

	/**
	 * Load roles from the API, and store them in memory.
	 */
	public static async loadRoles(): Promise<void> {
		const request = await Service.fetch(ServiceList.roles.list, null, null, null, Session.session);
		const response = request.response;
		for (let i = 0; i < response.roles.length; i++) {
			Session.rolesCache.set(response.roles[i].uuid, response.roles[i]);
		}
	}

	/**
	 * Get role from the cache, if the role is not available its retrieved from the API.
	 */
	public static getRole(roleUuid: UUID): (Role | null) {
		if (this.rolesCache.has(roleUuid)) {
			return Session.rolesCache.get(roleUuid);
		}

		const request = Service.fetchSync(ServiceList.roles.get, null, null, {uuid: roleUuid}, Session.session);
		const role = Role.parse(request.response.role);
		this.rolesCache.set(roleUuid, role);
		return role;
	}

	/**
	 * Clear data from the user session.
	 */
	public static clear(): void {
		Session.user = null;
		Session.session = null;
	}

	/**
	 * Get the user settings value, if it is not available create data.
	 */
	public static get settings(): Settings {
		if (Session.settingsCache === null) {
			Session.updateSettings(LocalStorage.get('settings'));

			if (Session.settingsCache === null) {
				Session.updateSettings(Settings.create());
			}
		}

		return Session.settingsCache;
	}

	/**
	 * Set the user settings value, applies them and stored them in locale storage.
	 */
	public static updateSettings(settings: any): void {
		if (!settings) {
			settings = Settings.create();
		} else {
			settings = Settings.update(settings);
		}
		
		LocalStorage.set('settings', settings);
	
		Session.settingsCache = settings;

		Locale.use(Session.settingsCache.locale);
	}

	/**
	 * Set use data from object.
	 *
	 * Data can be received from the server or set after update.
	 */
	public static set user(user: User) {
		if (!user) {
			Session.userCache = null;
			LocalStorage.delete('user');
		} else {
			LocalStorage.set('user', user);
			Session.userCache = user;
		}
	}

	/**
	 * Get user data from the local storage.
	 *
	 * On the first call this method also updates the user data from the API.
	 */
	public static get user(): User {
		if (Session.userCache === null) {
			Session.userCache = LocalStorage.get('user');
			if (Session.userCache !== null) {
				Session.userCache = User.parse(Session.userCache);
			}
		}

		return Session.userCache;
	}

	/**
	 * Get the session ID.
	 */
	public static get session(): UUID {
		if (Session.sessionCache === null) {
			Session.sessionCache = LocalStorage.get('session');
		}

		return Session.sessionCache;
	}

	/**
	 * Set the session ID value.
	 */
	public static set session(session: UUID) {
		if (session === null || session === undefined) {
			Session.sessionCache = null;
			LocalStorage.delete('session');
		} else {
			LocalStorage.set('session', session);
			Session.sessionCache = session;
		}
	}

	/**
	 * Check if the user session exists and is a valid value.
	 */
	public static isValid(): boolean {
		const session = Session.session;
		if (session === null || session === undefined) {
			Session.clear();
			return false;
		}

		return true;
	}

	/**
	 * Logout from the application and remove data from the storage.
	 */
	public static logout(): void {
		if (LocalStorage.exists('session')) {
			Service.fetch(ServiceList.authentication.logout, null, null, null, Session.session, true, false);
		}

		App.navigator.navigate('login');
		Session.clear();
	}

	/**
	 * Check if the user has permissions from a list.
	 *
	 * @param permissions - Permission (or list of permissions) to be checked
	 * @param needsAll - If true all permissions are required.
	 */
	public static hasPermissions(permissions: number|number[], needsAll: boolean = false): boolean {
		if (Session.user === null) {
			return false;
		}

		return Session.userHasPermissions(Session.user, permissions, needsAll);
	}

	/**
	 * Check if the user has specific permissions from a list.
	 *
	 * @param user - User to check permissions
	 * @param permissions - Permission or list of permissions to be checked.
	 * @param needsAll - If true the user needs to have all of the permission in the list for the method to validate access.
	 */
	public static userHasPermissions(user: User, permissions: number|number[], needsAll: boolean = false): boolean {
		if (user.isAdmin) {
			return true;
		}

		if (!(permissions instanceof Array)) {
			permissions = [permissions];
		}

		if (permissions.length === 0) {
			return true;
		}

		// Get all roles of the user
		const roles: Role[] = [];
		if (user.roleUuids !== null) {
			for (let i = 0; i < user.roleUuids.length; i++) {
				roles.push(Session.getRole(user.roleUuids[i]));
			}
	
		}
		

		// Check if permissions exists in the role list
		let count: number = 0;

		for (let i = 0; i < permissions.length; i++) {
			// Check in user permissions
			if (user.permissions.includes(permissions[i])) {
				if (!needsAll) {
					return true;
				}

				count++;
				continue;
			}

			// Check in all roles
			for (let j = 0; j < roles.length; j++) {
				if (roles[j].permissions.includes(permissions[i])) {
					if (!needsAll) {
						return true;
					}

					count++;
					break;
				}
			}
		}

		return count === permissions.length;
	}
}

