<uno-tab style="width: 100%; height: 100%">
	@if (this.inspection) {
		<uno-tab-section [active]="true" title="{{'inspection' | translate}}">
			<!-- Rejected message -->
			@if (this.inspection.status === inspectionStatus.REJECTED) {
				<uno-title>{{'inspectionRejected' | translate}}</uno-title>
				<uno-form [editable]="false" [layout]="inspectionLayouts.rejectedMessage" [object]="this.inspection"></uno-form>
			}
			<!-- Supervision message -->
			@if (this.inspection.status === inspectionStatus.TODO_SUPERVISION || this.inspection.status === inspectionStatus.REVIEW_SUPERVISION) {
				<uno-title>{{'supervisor' | translate}}</uno-title>
				<uno-form [editable]="false" [layout]="inspectionLayouts.supervisorMessage" [object]="this.inspection"></uno-form>
			}

			<!-- Inspection selection -->
			@if (this.inspection.data && this.asset) {
				<uno-form [editable]="(this.inspection.status === inspectionStatus.TODO || this.inspection.status === inspectionStatus.SUPERVISION) && this.canEditInspection()" [layout]="this.inspectionSelectionFormLayout" [object]="this.inspection" [onChange]="this.updateInspectionForm"></uno-form>
			}

			<!-- Inspection forms -->
			@if (this.inspection.data && (this.inspectorFormLayout.length > 0 || this.backofficeFormLayout.length > 0)) {
				<!-- Inspector form -->
				@if (this.inspectorFormLayout.length > 0) {
					<uno-title>{{'inspectorForm' | translate}}</uno-title>
					<uno-form [editable]="this.canEditInspection() && this.inspection.status !== inspectionStatus.REVIEW" [layout]="this.inspectorFormLayout" [object]="this.inspection.data.responses.inspector" #inspectionForm></uno-form>
				}
				<!-- Backoffice form -->
				@if (this.backofficeFormLayout.length > 0) {
					<uno-title>{{'backofficeForm' | translate}}</uno-title>
					<uno-form [editable]="this.canEditInspection()" [layout]="this.backofficeFormLayout" [object]="this.inspection.data.responses.backoffice" #backofficeForm></uno-form>
				}
			}

			<!-- Export / Create Observation buttons -->
			@if (this.inspection.status === inspectionStatus.COMPLETED) {
				<div style="display: flex; flex-direction: row-reverse; gap: 5px;" class="ion-padding">
					<uno-button (click)="this.exportReportDOCX();">{{'exportReportDOCX' | translate}}</uno-button>
					<uno-button (click)="this.exportReportPDF();">{{'exportReportPDF' | translate}}</uno-button>
					@if (userPermissions.OBSERVATIONS_CREATE | hasPermissions) {
						<uno-button (click)="app.navigator.navigate('/menu/observations/create');">{{'createObservation' | translate}}</uno-button>
					}
				</div>
			}

			<!-- Result -->
			<uno-title>{{'result' | translate}}</uno-title>
			<uno-form [editable]="this.canEditInspection()" [layout]="this.inspectionLayout" [object]="this.inspection" #resultForm></uno-form>

			<!-- Revert inspection to previous state -->
			@if (userPermissions.ATEX_INSPECTION_REVERT_STATE | hasPermissions) {
				<div style="display: flex; flex-direction: row-reverse; gap: 5px;" class="ion-padding">
					@if (this.inspection.status === inspectionStatus.TODO_SUPERVISION || this.inspection.status === inspectionStatus.REJECTED || this.inspection.status === inspectionStatus.COMPLETED) {
						<uno-button (click)="save(inspectionStatus.TODO);" color="warning">{{'returnToTodo' | translate}}</uno-button>
					}
					@if (this.inspection.status === inspectionStatus.REVIEW_SUPERVISION || this.inspection.status === inspectionStatus.COMPLETED) {
						<uno-button (click)="save(inspectionStatus.REVIEW);" color="warning">{{'returnToRevision' | translate}}</uno-button>
					}
				</div>
			}

			<!-- Create button -->
			@if (this.createMode) {
				<div style="display: flex; flex-direction: row-reverse; gap: 5px;" class="ion-padding">
					<uno-button (click)="create();">{{'create' | translate}}</uno-button>
				</div>
			}
			@else if (this.canEditInspection()) {
				<div style="display: flex; flex-direction: row-reverse; gap: 5px;" class="ion-padding">
					<!-- Save-->
					<uno-button (click)="save();">{{'save' | translate}}</uno-button>
					<uno-button (click)="save(undefined, true);">{{'update' | translate}}</uno-button>
					<!-- Submit -->
					@if (this.inspection.status === inspectionStatus.TODO || this.inspection.status === inspectionStatus.REJECTED || this.inspection.status === inspectionStatus.TODO_SUPERVISION) {
						<uno-button (click)="submit();" color="success">{{'submit' | translate}}</uno-button>
					}
					<!-- Send to supervision -->
					@if (this.inspection.status === inspectionStatus.TODO || this.inspection.status === inspectionStatus.REJECTED || this.inspection.status === inspectionStatus.REVIEW) {
						<uno-button (click)="sendSupervision();" color="warning">{{'sendToSupervision' | translate}}</uno-button>
					}
					<!-- Backoffice Approve / Reject -->
					@if (this.inspection.status === inspectionStatus.REVIEW || this.inspection.status === inspectionStatus.REVIEW_SUPERVISION) {
						<uno-button (click)="approve();" color="success">{{'approve' | translate}}</uno-button>
						<uno-button (click)="reject();" color="warning">{{'reject' | translate}}</uno-button>
					}
					<!-- Delete -->
					@if (userPermissions.ATEX_INSPECTION_DELETE | hasPermissions) {
						<uno-button (click)="delete();" color="error">{{'delete' | translate}}</uno-button>
					}
				</div>
			}

		</uno-tab-section>
	}

	@if (this.asset) {
		<uno-tab-section title="{{'asset' | translate}}">
			<!-- Asset data -->
			<uno-form [editable]="[userPermissions.ASSET_PORTFOLIO_ASSET_EDIT] | hasPermissions" [onChange]="this.setAssetEdited" [layout]="assetLayout" [object]="this.asset"></uno-form>

			<!-- Model Identification -->
			<uno-title>{{'modelIdentification' | translate}}</uno-title>
			<uno-form [editable]="[userPermissions.ASSET_PORTFOLIO_ASSET_EDIT] | hasPermissions" [onChange]="this.setAssetEdited" [layout]="this.modelLayout" [object]="this.asset"></uno-form>

			<div style="display: flex; flex-direction: row-reverse; gap: 5px;" class="ion-padding">
				<!-- Save-->
				<uno-button (click)="save();">{{'save' | translate}}</uno-button>
				<uno-button (click)="save(undefined, true);">{{'update' | translate}}</uno-button>

				<!-- Open Asset -->
				<uno-button (click)="app.navigator.navigate('/menu/asset-portfolio/asset/edit', {uuid: this.asset.uuid});">{{'asset' | translate}}</uno-button>
			</div>
		</uno-tab-section>

		<uno-tab-section title="{{'atex' | translate}}">
			<!-- Atex data -->
			<uno-form [editable]="[userPermissions.ASSET_PORTFOLIO_ASSET_EDIT] | hasPermissions" [onChange]="this.setAssetEdited" [layout]="this.atexLayout" [object]="this.asset"></uno-form>

			<div style="display: flex; flex-direction: row-reverse; gap: 5px;" class="ion-padding">
				<!-- Save-->
				<uno-button (click)="save();">{{'save' | translate}}</uno-button>
				<uno-button (click)="save(undefined, true);">{{'update' | translate}}</uno-button>

				<!-- Open Asset -->
				<uno-button (click)="app.navigator.navigate('/menu/asset-portfolio/asset/edit', {uuid: this.asset.uuid});">{{'asset' | translate}}</uno-button>
			</div>
		</uno-tab-section>
	}

	<!-- Update actions history -->
	@if (this.history && this.history.length > 0) {
		<uno-tab-section title="{{'history' | translate}}">
			<ion-list class="ion-no-padding ion-no-margin">
				@for (option of this.history; track option; let i = $index) {
					<ion-item (click)="this.openHistoryEntry(this.history[i].id);" button class="ion-margin-horizontal ion-no-padding" lines="full">
						<ion-label>{{option.date | formatDate}}</ion-label>
						<div class="ion-text-end">
							<ion-label>{{option.user.name}}</ion-label>
							<ion-label color="medium" style="font-size: 13px;">{{option.user.email + (option.user.phone.length > 0 ? (' | ' + option.user.phone) : '')}}</ion-label>
						</div>
					</ion-item>
				}
			</ion-list>
		</uno-tab-section>
	}
</uno-tab>
