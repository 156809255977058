import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {AtexPage} from './screens/menu/atex.page';
import {AtexInspectionsRouterModule} from './inspections/atex-inspections-router.module';
import {FFPRouterModule} from './ffp/ffp-router.module';
import {ActionPlanRouterModule} from './action-plan/action-plan-router.module';

const routes: Routes = [
	{
		path: '',
		component: AtexPage
	},
	{
		path: 'inspections',
		loadChildren: () => {return AtexInspectionsRouterModule;}
	},
	{
		path: 'ffp',
		loadChildren: () => {return FFPRouterModule;}
	},
	{
		path: 'action-plan',
		loadChildren: () => {return ActionPlanRouterModule;}
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class AtexRouterModule { }
