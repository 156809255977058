import {Component, Input, ViewEncapsulation} from '@angular/core';
import {NgClass} from '@angular/common';
import {DashboardWidget} from 'src/app/models/dashboards/widget';

/**
 * The Dashboard widget component.
 */
@Component({
	selector: 'uno-widget',
	templateUrl: 'widget.component.html',
	styleUrls: ['widget.component.css'],
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [NgClass]
})
export class UnoWidgetComponent {
	/**
	 * The widget to show.
	 */
	@Input()
	public widget: DashboardWidget;
}
