import {Environment} from 'src/environments/environment';
import {Locale} from './locale/locale';

/**
 * Box to display information about an asynchronous task progress.
 *
 * Can also prevent the application from being closed while the task is running and can display the progress of that task.
 */
export class ProgressBar {
	/**
	 * Base container of the progress bar.
	 */
	public base: HTMLElement = null;

	/**
	 * Element to display progress message.
	 */
	public text: HTMLElement = null;

	/**
	 * Progress bar element to be scaled according to progress.
	 */
	public progress: HTMLElement = null;

	/**
	 * Create the DOM element to represent the Loading screen and attach it to the document.
	 */
	public constructor() {
		this.base = document.createElement('div');
		Object.assign(this.base.style, {
			alignContent: 'center',
			justifyContent: 'center',
			alignItems: 'center',
			display: 'flex',
			position: 'absolute',
			top: '0px',
			left: '0px',
			width: '100%',
			height: '100%',
			backgroundColor: 'rgba(0, 0, 0, 0.5)',
			zIndex: '1000'
		});

		const box = document.createElement('div');
		Object.assign(box.style, {
			pointerEvents: 'none',
			position: 'absolute',
			width: '450px',
			maxWidth: '90%',
			height: '90px',
			backgroundColor: 'var(--gray-9)',
			color: 'var(--gray-1)',
			fontSize: '16px',
			boxShadow: 'rgba(0, 0, 0, 0.4) 0px 16px 20px 0px;',
			borderRadius: '4px'
		});
		this.base.appendChild(box);

		this.text = document.createElement('div');
		Object.assign(this.text.style, {
			position: 'absolute',
			top: 'calc(50% - 20px)',
			left: '20px',
			width: 'calc(100% - 40px)'
		});
		this.text.innerText = Locale.get('loadingData');
		box.appendChild(this.text);

		const bar = document.createElement('div');
		Object.assign(bar.style, {
			pointerEvents: 'none',
			position: 'absolute',
			bottom: 'calc(50% - 20px)',
			right: '20px',
			width: 'calc(100% - 40px)',
			height: '8px',
			backgroundColor: 'var(--gray-8)'
		});
		box.appendChild(bar);

		this.progress = document.createElement('div');
		Object.assign(this.progress.style, {
			pointerEvents: 'none',
			position: 'absolute',
			bottom: '0px',
			left: '0px',
			width: '0%',
			height: '100%',
			backgroundColor: 'var(--brand-primary)'
		});
		bar.appendChild(this.progress);
	}

	/**
	 * Update the task progress box with a message and progress information.
	 *
	 * Message to display is followed by the progress percentage.
	 *
	 * @param message - Message to display in the modal.
	 * @param progress - Progress from 0.0 to 1.0.
	 */
	public update(message: string, progress: number): void {
		this.text.innerText = message + ' (' + Math.round(progress * 100) + '%)';
		this.progress.style.width = Math.round(progress * 100) + '%';
	}

	/**
	 * Update the task box with progress information and a new message.
	 */
	public show(): void {
		if (!Environment.TEST) {
			document.body.appendChild(this.base);
		}
	}

	/**
	 * Close the task box.
	 */
	public destroy(): void {
		if (!Environment.TEST && this.base.parentElement !== null) {
			this.base.remove();
		}
	}
}
