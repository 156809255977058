import {Service} from '../../../../http/service';
import {ServiceList} from '../../../../http/service-list';
import {Session} from '../../../../session';

/**
 * Object that represents a repair welding type.
 */
export class RepairWeldingType {
	/**
	 * Numeric ID.
	 */
	public id: number = 0;

	/**
	 * Label shown to the user.
	 */
	public label: string = null;

	/**
	 * List of all possible repair welding types.
	 */
	public static async load(): Promise<RepairWeldingType[]> {
		const request = await Service.fetch(ServiceList.repairs.weldingType.list, null, null, null, Session.session);

		return Promise.resolve(request.response.types);
	}
}
