import {Resource} from '../../resource';
import {UUID, UUIDIdentification} from '../../uuid';

export class GAGapRecommendation extends UUIDIdentification {
	/**
	 * The UUID of the Gap that this recommendation refers to.
	 */
	public gapUuid: UUID = null;

	/**
	 * Description of the gap recommendation.
	 */
	public description: string = '';

	/**
	 * The quantity of items (in the "units" scale) of the described type will be needed to resolve gap.
	 */
	public quantity: number = 0;
	
	/**
	 * The units of item recommended on this gap recommendation.
	 */
	public units: string = '';

	/**
	 * Potencial unitary cost in Euros of this gap recommendation item.
	 */
	public cost: number = 0;

	/**
	 * Documents related to the gap recommendation. Ex: photos, data sheets etc...
	 */
	public documents: Resource[] = [];

	/**
	 * Parse JSON data received from the API into an object of the correct type.
	 * 
	 * @param data - Object received from API.
	 * @returns Gap recommendation object instance built from the data received.
	 */
	public static parse(data: any): GAGapRecommendation {
		const recommendation = new GAGapRecommendation();

		recommendation.uuid = data.uuid;
		recommendation.updatedAt = new Date(data.updatedAt);
		recommendation.createdAt = new Date(data.createdAt);

		recommendation.gapUuid = data.gapUuid;
		recommendation.description = data.description;
		recommendation.quantity = data.quantity;
		recommendation.units = data.units;
		recommendation.cost = data.cost;
		recommendation.documents = data.documents;

		return recommendation;
	}
}
