/**
 * Possible flow modes used in the project.
 */
export enum InspectionProjectDisplayMode {
	// Each step is presented in a form section
	SECTIONS = 1,
	
	// Each step is presented in a tab
	TABS = 2
}

/**
 * Map of the flow modes and their corresponding label as stored in locale.
 */
export const InspectionProjectDisplayModeLabel: Map<InspectionProjectDisplayMode, string> = new Map([
	[InspectionProjectDisplayMode.SECTIONS, 'sections'],
	[InspectionProjectDisplayMode.TABS, 'tabs']
]);
