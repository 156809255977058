import {Service} from '../http/service';
import {ServiceList} from '../http/service-list';
import {Resource, ResourceType} from '../models/resource';
import {FileUtils} from './file-utils';
import {ImageUtils} from './image-utils';

/**
 * Resource utils used to manipulate resource elements.
 */
export class ResourceUtils {

	/**
	 * Get the aspect ratio of the resource calculated as X/Y of the image size.
	 *
	 * @param resource - Resource to calculate the aspect ratio of.
	 * @returns The aspect ratio of the image.
	 */
	public static getAspectRatio(resource: Resource): Promise<number> {
		return ImageUtils.getAspectRatio(ResourceUtils.getURL(resource, ''));
	}

	/**
	 * Get the URL to access a resource, also receives a fallback placeholder in case the resource is unavailable.
	 *
	 * @param resource - Resource to get URL for.
	 * @param placeholder - Placeholder value used when the resource is not valid.
	 */
	public static getURL(resource: Resource, placeholder: string = null): string {
		const valid = resource && resource.uuid && resource.format;

		if (valid) {
			return Service.getURL(ServiceList.resources[resource.type || ResourceType.IMAGE].get, {
				uuid: resource.uuid,
				format: resource.format
			});
		}

		return placeholder;
	}

	/**
	 * Download resource file into the browser.
	 *
	 * @param resource - Resource to be downloaded.
	 */
	public static download(resource: Resource): void {
		const url = ResourceUtils.getURL(resource);
		FileUtils.download(url);
	}

	/**
	 * Get the resource data as ArrayBuffer, the file is fetched from API and stored as binary data.
	 *
	 * @param resource - Resource to fetch.
	 * @returns Binary data of the resource as ArrayBuffer.
	 */
	public static async getArrayBuffer(resource: Resource): Promise<ArrayBuffer> {
		const url = Service.getURL(ServiceList.resources[resource.type || ResourceType.IMAGE].get, {
			uuid: resource.uuid,
			format: resource.format
		});

		return FileUtils.readFileArrayBuffer(url);
	}

	/**
	 * Get image data from resource in base64 using a canvas to draw and convert the resource.
	 *
	 * Returns a promise with the loaded image, if the image cannot be loaded it throws an error.
	 *
	 * @param resource - Resource object containing image data.
	 * @param format - Target format of the base64 encoded data (jpeg, png) by default png is used.
	 */
	public static async getBase64(resource: Resource, format: 'png' | 'jpeg' = 'png'): Promise<string> {
		return new Promise<string>((resolve: Function, reject: Function) => {
			if (!resource) {
				reject();
			}

			const img = document.createElement('img');
			img.crossOrigin = 'Anonymous';
			img.onload = function() {
				const canvas = document.createElement('canvas');
				// @ts-ignore
				canvas.height = this.naturalHeight;
				// @ts-ignore
				canvas.width = this.naturalWidth;

				const context = canvas.getContext('2d');
				// @ts-ignore
				context.drawImage(this, 0, 0);

				// Resolve promise
				resolve(canvas.toDataURL(format));
			};

			img.onerror = function() {
				reject();
			};

			img.src = Service.getURL(ServiceList.resources[resource.type].get, {
				uuid: resource.uuid,
				format: resource.format
			});
		});
	}

}
