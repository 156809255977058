import {UUID, UUIDIdentification} from '../uuid';
import {CalendarEventOccurrencePriority} from './calendar-event-occurrence-priority';
import {CalendarEventStatus} from './calendar-event-occurrence-status';

export class CalendarEventOccurrence extends UUIDIdentification {
	/**
	 * Uuid of the calendar event this occurence refers to.
	 */
	public calendarEventUuid: UUID = null;

	/**
	 * Date of the calendar event.
	 */
	public date: Date = null;

	/**
	 * If true, the alarms regarding this occurence were already sent.
	 */
	public alarmSent: boolean = false;

	/**
	 * The UUID of the repair inspection associated to this calendar event occurence.
	 */
	public repairInspectionUuid: UUID = null;
	
	/**
	 * The UUID of the Atex inspection associated to this calendar event occurence.
	 */
	public atexInspectionUuid: UUID = null;
	
	/**
	 * The UUID of the dynamic inspection associated to this calendar event occurence.
	 */
	public inspectionUuid: UUID = null;
	
	/**
	 * The UUID of the repair associated to this calendar event occurence.
	 */
	public repairUuid: UUID = null;

	/**
	 * The UUID of the DL50 inspection associated to this calendar event occurence.
	 */
	public dl50InspectionUuid: UUID = null;
	
	/**
	 * Status of the action associated with the calendar ocurrence.
	 */
	public status: CalendarEventStatus = CalendarEventStatus.NO_ACTION;

	/**
	 * Priority of the calendar ocurrence.
	 */
	public priority: CalendarEventOccurrencePriority = CalendarEventOccurrencePriority.MEDIUM;

	/**
	 * Parse object data into a proper object of this class.
	 *
	 * @param data - Object data to parse.
	 * @returns An object instance of this class, built from the received data.
	 */
	public static parse(data: any): CalendarEventOccurrence {
		const occurence = new CalendarEventOccurrence();

		occurence.uuid = data.uuid;
		occurence.createdAt = new Date(data.createdAt);
		occurence.updatedAt = new Date(data.updatedAt);
		occurence.calendarEventUuid = data.calendarEventUuid;
		occurence.date = data.date;
		occurence.alarmSent = data.alarmSent;
		occurence.repairInspectionUuid = data.repairInspectionUuid;
		occurence.atexInspectionUuid = data.atexInspectionUuid;
		occurence.inspectionUuid = data.inspectionUuid;
		occurence.dl50InspectionUuid = data.dl50InspectionUuid;
		occurence.repairUuid = data.repairUuid;
		occurence.priority = data.priority || CalendarEventOccurrencePriority.MEDIUM;
		occurence.status = data.status;

		
		return occurence;
	}
}
