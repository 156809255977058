import {Component, Input, ViewEncapsulation} from '@angular/core';
import {Locale} from 'src/app/locale/locale';
import {Modal} from 'src/app/modal';
import {ClipboardUtils} from 'src/app/utils/clipboard-utils';
import {UUID} from '../../../models/uuid';
import {UnoIconComponent} from '../../uno/uno-icon/uno-icon.component';

/**
 * UUID component, used for visualization only.
 *
 * Does not allow to edit the UUID values presented.
 */
@Component({
	selector: 'uno-uuid',
	templateUrl: './uno-uuid.component.html',
	styleUrls: ['./uno-uuid.component.css'],
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [UnoIconComponent]
})
export class UnoUuidComponent {
	/**
	 * The UUID to be displayed on the component.
	 */
	@Input('uuid')
	public uuid: UUID = null;

	/**
	 * Copies a certain value to the clipboard
	 */
	public async copy(): Promise<void> {
		await ClipboardUtils.copy(this.uuid);
		Modal.toast(Locale.get('copiedSuccessfully'), 3000, 'success');
	}
}
