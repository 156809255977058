import {Component, OnInit, ChangeDetectorRef, ViewChild} from '@angular/core';
import {SortDirection} from 'src/app/utils/sort-direction';
import {TranslateModule} from '@ngx-translate/core';
import {NgStyle} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {UnoFilterBarComponent} from 'src/app/components/uno/uno-filter-bar/uno-filter-bar.component';
import {UnoFilterBarOption, UnoFilterBarOptionType} from 'src/app/components/uno/uno-filter-bar/uno-filter-bar-option';
import {UnoTableColumnLayout, UnoTableColumnType} from 'src/app/components/uno/uno-table/uno-table.component';
import {UnoResponsiveTableListComponent} from 'src/app/components/uno/uno-responsive-table-list/uno-responsive-table-list.component';
import {InputOptionsMultipleBatchRequest, InputOptionsMultipleLazyPageRequest} from 'src/app/components/uno-input/uno-options-lazy/uno-options-lazy.component';
import {AssetSubtypeListParams, AssetSubTypeService} from 'src/app/modules/asset-portfolio/services/asset-subtype.service';
import {App} from '../../../../../app';
import {Session} from '../../../../../session';
import {Resource} from '../../../../../models/resource';
import {AtexInspectionStatus, AtexInspectionStatusLabel} from '../../../../../models/atex-inspections/inspections/atex-inspection-status';
import {ScreenComponent} from '../../../../../components/screen/screen.component';
import {AtexInspectionResultColor, AtexInspectionResultFilters} from '../../../../../models/atex-inspections/inspections/atex-inspection-result';
import {FormatDatePipe} from '../../../../../pipes/format-date.pipe';
import {UnoListComponent} from '../../../../../components/uno/uno-list/uno-list-component';
import {UnoContentComponent} from '../../../../../components/uno/uno-content/uno-content.component';
import {UnoListItemLabelComponent} from '../../../../../components/uno/uno-list-item/uno-list-item-label.component';
import {UnoListItemIconComponent} from '../../../../../components/uno/uno-list-item/uno-list-item-icon.component';
import {UnoListItemComponent} from '../../../../../components/uno/uno-list-item/uno-list-item.component';
import {UnoSearchbarComponent} from '../../../../../components/uno/uno-searchbar/uno-searchbar.component';
import {ResourceUtils} from '../../../../../utils/resource-utils';
import {AtexInspectionService, AtexInspectionListParams, AtexInspectionCountParams} from '../../services/atex-inspection.service';

/**
 * Possible values to the documents filter.
 */
export const AtexInspectionsDocumentFilter: any = {
	/**
	 * Display all Atex inspections independently on their waiting document status.
	 */
	ALL: 0,

	/**
	 * Display only Atex inspection that are not waiting documentation.
	 */
	WITH_DOCUMENTS: 1,

	/**
	 * Display Atex inspection that are waiting for documentation.
	 */
	WAITING_DOCUMENTS: 2
};

/**
 * Possible values for FFPS mode filter.
 */
export const AtexInspectionsHasFfpsFilter: any = {
	/**
	 * Display all Atex inspections.
	 */
	ALL: 0,

	/**
	 * Display only Atex inspections without FFPS.
	 */
	HAS_FFPS_NO: 1,

	/**
	 * Display only Atex inspections with FFPS.
	 */
	HAS_FFPS_YES: 2
};

@Component({
	selector: 'atex-inspections-list-page',
	templateUrl: 'atex-inspections-list.page.html',
	standalone: true,
	imports: [
		UnoSearchbarComponent,
		IonicModule,
		FormsModule,
		UnoListItemComponent,
		UnoListItemIconComponent,
		UnoListItemLabelComponent,
		NgStyle,
		UnoContentComponent,
		UnoListComponent,
		TranslateModule,
		FormatDatePipe,
		UnoResponsiveTableListComponent,
		UnoFilterBarComponent
	]
})

export class AtexInspectionsListPage extends ScreenComponent implements OnInit {

	@ViewChild(UnoResponsiveTableListComponent) 
	public table: UnoResponsiveTableListComponent;

	public inspectionResultColor: any = AtexInspectionResultColor;

	public inspectionStatusLabel: any = AtexInspectionStatusLabel;

	public app: any = App;

	public resource: any = Resource;

	public settings = Session.settings;

	public selfStatic: any = AtexInspectionsListPage;

	/**
	 * The maximum number of items to show on table component.
	 */
	public tableTotalItemsCount: number = 0;

	/**
	 * The number of items to show on table per page.
	 */
	public tablePageSize: number = 30;
	
	/**
	 * The layout to use on the Uno Table component.
	 */
	public tableLayout: UnoTableColumnLayout[] = [
		{header: 'image', type: UnoTableColumnType.IMAGE, attribute: 'image', visible: true, size: 'small'},
		{header: 'result', type: UnoTableColumnType.STATUS, attribute: 'resultColor', visible: true, size: 'small'},
		{header: 'assetName', type: UnoTableColumnType.TEXT, attribute: 'assetName', visible: true, size: 'small', sortBy: '[ap_asset].[name]'},
		{header: 'assetTag', type: UnoTableColumnType.TEXT, attribute: 'assetTag', visible: true, size: 'small', sortBy: '[ap_asset].[tag]'},
		{header: 'status', type: UnoTableColumnType.TEXT, attribute: 'status', visible: true, size: 'small', sortBy: '[atex_inspection].[status]'},
		{header: 'updatedAt', type: UnoTableColumnType.DATE, attribute: 'updatedAt', visible: true, size: 'small', sortBy: '[atex_inspection].[updated_at]'},
		{
			header: 'actions',
			type: UnoTableColumnType.ICONS,
			attribute: 'actions',
			visible: true,
			size: 'small',
			icons:
			[
				{
					src: './assets/icons/assets/expand-icon.svg',
					click: (row): void => {
						App.openInTab('/menu/atex/inspections/edit', {uuid: row.uuid});
					}
				}
			]
		}
	];

	public constructor(private ref: ChangeDetectorRef) {
		super();
	}

	/**
	 * Possible database filter to be used for ordering the inspections list.
	 */
	public static filterOptions: UnoFilterBarOption[] = [
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'sortDirection',
			label: 'direction',
			default: SortDirection.DESC,
			options: [
				{label: 'asc', value: SortDirection.ASC},
				{label: 'desc', value: SortDirection.DESC}
			]
		},
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'sortField',
			label: 'sortField',
			default: '[atex_inspection].[updated_at]',
			options: [
				{label: 'updatedAt', value: '[atex_inspection].[updated_at]'},
				{label: 'createdAt', value: '[atex_inspection].[created_at]'},
				{label: 'assetName', value: '[ap_asset].[name]'},
				{label: 'assetTag', value: '[ap_asset].[tag]'},
				{label: 'status', value: '[atex_inspection].[status]'}
			]
		},
		{
			type: UnoFilterBarOptionType.OPTIONS,
			multiple: true,
			attribute: 'searchFields',
			label: 'searchFields',
			default: ['[ap_asset].[name]', '[ap_asset].[tag]', '[ap_asset].[description]', '[parent_asset].[name]', '[atex_inspection].[id]'],
			options: [
				{label: 'assetUuid', value: '[ap_asset].[id]'},
				{label: 'assetName', value: '[ap_asset].[name]'},
				{label: 'assetTag', value: '[ap_asset].[tag]'},
				{label: 'description', value: '[ap_asset].[description]'},
				{label: 'parentUuid', value: '[parent_asset].[id]'},
				{label: 'parentName', value: '[parent_asset].[name]'},
				{label: 'inspectionUuid', value: '[atex_inspection].[id]'}
			]
		},
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'results',
			label: 'result',
			default: [AtexInspectionResultFilters.NONE, AtexInspectionResultFilters.APPROVED, AtexInspectionResultFilters.CONDITIONALLY_APPROVED, AtexInspectionResultFilters.FAILED],
			multiple: true,
			options: [
				{label: 'none', value: AtexInspectionResultFilters.NONE},
				{label: 'approved', value: AtexInspectionResultFilters.APPROVED},
				{label: 'conditionallyApproved', value: AtexInspectionResultFilters.CONDITIONALLY_APPROVED},
				{label: 'nonCompliant', value: AtexInspectionResultFilters.FAILED}
			]
		},
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'waitingDocuments',
			label: 'documents',
			default: AtexInspectionsDocumentFilter.ALL,
			options: [
				{label: 'all', value: AtexInspectionsDocumentFilter.ALL},
				{label: 'withDocuments', value: AtexInspectionsDocumentFilter.WITH_DOCUMENTS},
				{label: 'waitingDocuments', value: AtexInspectionsDocumentFilter.WAITING_DOCUMENTS}
			]
		},
		{
			type: UnoFilterBarOptionType.OPTIONS_LAZY,
			attribute: 'subtype',
			label: 'subtype',
			identifierAttribute: 'uuid',
			multiple: false,
			default: null,
			fetchOptionsLazy: async(request: InputOptionsMultipleLazyPageRequest): Promise<{options: any[], hasMore: boolean, id: number}> => {
				const data: AssetSubtypeListParams = {
					from: request.from,
					count: request.count,
					search: request.search
				};
		
				const req = await AssetSubTypeService.list(data);
				return {options: req.subTypes, hasMore: req.hasMore, id: req.id};
	
			},
			fetchOptionsBatch: async(request: InputOptionsMultipleBatchRequest): Promise<{options: any[]}> => {
				if (request.options.length > 0) {
					return {options: await AssetSubTypeService.getBatch(request.options)};
				}

				return {options: []};
			},
			getOptionText: (option: any): string => {
				return [option.name, option.tag].filter(Boolean).join(' - ');
			}
		},
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'hasFfps',
			label: 'hasFfp',
			default: AtexInspectionsHasFfpsFilter.ALL,
			options: [
				{label: 'all', value: AtexInspectionsHasFfpsFilter.ALL},
				{label: 'no', value: AtexInspectionsHasFfpsFilter.HAS_FFPS_NO},
				{label: 'yes', value: AtexInspectionsHasFfpsFilter.HAS_FFPS_YES}
			]
		}
	];

	public static filters = UnoFilterBarComponent.reset({
		/**
		 * Sort direction applied to the loaded list from database.
		 */
		sortDirection: '',

		/**
		 * Database attribute name used to sort the values.
		 */
		sortField: '',

		/**
		 * Text used to filter assets by their name, tag name or serial number.
		 */
		search: '',

		/**
		 * Filter if waiting for documents or not.
		 */
		waitingDocuments: AtexInspectionsDocumentFilter.ALL,

		/**
		 * Filter for FFPS.
		 */
		hasFfps: AtexInspectionsHasFfpsFilter.ALL,

		/**
		 * Result of the inspection to show on the list.
		 */
		results: [AtexInspectionResultFilters.NONE, AtexInspectionResultFilters.APPROVED, AtexInspectionResultFilters.CONDITIONALLY_APPROVED, AtexInspectionResultFilters.FAILED],

		/**
		 * Result of the inspection to show on the list.
		 */
		status: AtexInspectionStatus.ALL,

		/**
		 * Filter inspections by the asset subtype.
		 */
		subtype: null,

		/**
		 * Search fields to be considered.
		 */
		searchFields: []
	}, AtexInspectionsListPage.filterOptions);

	public ngOnInit(): void {
		super.ngOnInit();

		const data = App.navigator.getData();

		App.navigator.setTitle(AtexInspectionStatusLabel.has(data?.status) ? AtexInspectionStatusLabel.get(data.status) : 'list');

		// Apply params to filter
		if (data) {
			AtexInspectionsListPage.filters.results = data?.results !== undefined ? data.results : AtexInspectionsListPage.filters.results;
			AtexInspectionsListPage.filters.waitingDocuments = data?.waitingDocuments !== undefined ? data.waitingDocuments : AtexInspectionsListPage.filters.waitingDocuments;
			AtexInspectionsListPage.filters.hasFfps = data?.hasFfps !== undefined ? data.hasFfps : AtexInspectionsListPage.filters.hasFfps;
			AtexInspectionsListPage.filters.status = data?.status !== undefined ? data.status : AtexInspectionsListPage.filters.status;
		}
	}

	public loadTableItems = async(count: number, pageSize: number): Promise<any> => {
		const params: AtexInspectionListParams = {
			sortField: AtexInspectionsListPage.filters.sortField,
			sortDirection: AtexInspectionsListPage.filters.sortDirection,
			status: AtexInspectionsListPage.filters.status,
			waitingDocuments: AtexInspectionsListPage.filters.waitingDocuments,
			hasFfps: AtexInspectionsListPage.filters.hasFfps,
			results: AtexInspectionsListPage.filters.results,
			subtype: AtexInspectionsListPage.filters.subtype,
			noPictures: !Session.settings.showListPictures,
			from: count,
			count: pageSize,
			search: AtexInspectionsListPage.filters.search,
			searchFields: AtexInspectionsListPage.filters.searchFields
		};

		const list = await AtexInspectionService.list(params);
		const inspections = [];

		for (const atexInspection of list.inspections) {
			const inspection: any = {...atexInspection};
			inspection.image = atexInspection.asset.pictures.length > 0 ? atexInspection.asset.pictures[0] : './assets/placeholder/asset.png';
			inspection.assetName = atexInspection.asset.name;
			inspection.assetTag = atexInspection.asset.tag;
			inspection.resultColor = AtexInspectionResultColor.get(atexInspection.result);
			inspection.status = AtexInspectionStatusLabel.get(atexInspection.status);
			inspections.push(inspection);
		}

		return {
			elements: inspections,
			hasMore: list.hasMore
		};
	};

	/**
	 * Update filters and reload data from the API if required.
	 *
	 * @param search - Search value
	 */
	public async onSearchChange(search: string): Promise<void> {
		AtexInspectionsListPage.filters.search = search;

		await this.reset();
	}

	/**
	 * Update filters and reload data from the API if required.
	 *
	 * @param event - DOM event.
	 */
	public async onFilterChange(filters: any): Promise<void> {
		AtexInspectionsListPage.filters = filters;
		await this.reset();
	}

	/**
	 * Reset the table.
	 */
	public async reset(): Promise<void> {

		this.table.sortDirection = AtexInspectionsListPage.filters.sortDirection;
		this.table.sortField = AtexInspectionsListPage.filters.sortField;

		const params: AtexInspectionCountParams = {
			status: AtexInspectionsListPage.filters.status,
			waitingDocuments: AtexInspectionsListPage.filters.waitingDocuments,
			hasFfps: AtexInspectionsListPage.filters.hasFfps,
			results: AtexInspectionsListPage.filters.results,
			subtype: AtexInspectionsListPage.filters.subtype,
			search: AtexInspectionsListPage.filters.search,
			searchFields: AtexInspectionsListPage.filters.searchFields
		};

		this.tableTotalItemsCount = await AtexInspectionService.count(params);
		if (this.table) {
			await this.table.reset();
		}

	}

	/**
	 * When the user changes the sort using the table
	 * 
	 * @param sortBy - Attribute to sort by.
	 */
	public async sortChanged(sortBy: any): Promise<void> {
		// If the attribute is already the current one, change the sort direction.
		if (sortBy === AtexInspectionsListPage.filters.sortField) {
			AtexInspectionsListPage.filters.sortDirection = this.table.sortDirection;
		} else {
			AtexInspectionsListPage.filters.sortField = sortBy;
			AtexInspectionsListPage.filters.sortDirection = SortDirection.ASC;
		}

		if (this.table) {
			await this.table.reset();
		}
	}

	protected readonly ResourceUtils = ResourceUtils;
}
