<div style="width:100%; height:100%;">
	<uno-tab>
		@if (this.ffp !== null) {
			<uno-tab-section [active]="true" title="{{'ffp' | translate}}">
				<!-- FFP -->
				<uno-form [layout]="ffpLayout" [object]="this.ffp" #ffpForm></uno-form>
				<!-- Submit buttons -->
				<div style="display: flex; flex-direction: row-reverse; gap: 5px;" class="ion-padding">
					<uno-button (click)="this.update();">{{'save' | translate}}</uno-button>
					<uno-button (click)="this.update(true);">{{'update' | translate}}</uno-button>
					@if (this.ffp.state === ffpState.ARCHIVED) {
						<uno-button (click)="this.update(false, ffpState.ACTIVE);">{{'restore' | translate}}</uno-button>
					}
					@if ((userPermissions.FFP_ARCHIVE | hasPermissions) && this.ffp.state !== ffpState.ARCHIVED) {
						<uno-button (click)="this.update(false, ffpState.ARCHIVED);" color="warning">{{'archive' | translate}}</uno-button>
					}
					@if (userPermissions.FFP_DELETE | hasPermissions) {
						<uno-button (click)="this.delete();" color="error">{{'delete' | translate}}</uno-button>
					}
				</div>
				<!-- Create new Action plan-->
				<div style="display: flex; flex-direction: row-reverse; gap: 5px;" class="ion-padding">
					@if (userPermissions.ACTION_PLAN_CREATE | hasPermissions) {
						<uno-button (click)="this.createActionPlan();">{{'createActionPlan' | translate}}</uno-button>
					}
				</div>
			</uno-tab-section>
		}
		@if (this.inspection !== null) {
			<uno-tab-section title="{{'inspection' | translate}}">
				<!-- Inspection -->
				<uno-form [editable]="false" [layout]="this.inspectionFieldLayout" [object]="this.inspection.data.responses[this.ffp.form]"></uno-form>
				<div style="display: flex; flex-direction: row-reverse; gap: 5px;" class="ion-padding">
					<uno-button (click)="app.navigator.navigate('/menu/atex/inspections/edit',{uuid: this.inspection.uuid})">{{'inspection' | translate}}</uno-button>
				</div>
			</uno-tab-section>
		}

		@if (this.actionPlans.length > 0) {
			<uno-tab-section title="{{'actionPlans' | translate}}">
				<!-- Action Plans -->
				<ion-list class="ion-no-padding ion-no-margin">
					@for (option of this.actionPlans; track option) {
						<ion-item class="ion-margin-horizontal ion-no-padding" lines="full">
							<!-- Icon -->
							@if (option.state === actionPlanState.DONE) {
								<ion-icon color="success" name="checkmark-circle-outline" style="padding-right: 10px"></ion-icon>
							}
							@if (option.state !== actionPlanState.DONE) {
								<ion-icon name="time" style="padding-right: 10px"></ion-icon>
							}
							<!-- Data -->
							<ion-label>
								<h2>{{option.workOrder}}</h2>
								<h3>{{option.updatedAt | formatDate}}</h3>
								<h3>{{actionPlanStateLabel.get(option.state) | translate}}</h3>
								<p>{{option.description}}</p>
							</ion-label>
							<!-- Buttons -->
							@if ([userPermissions.ACTION_PLAN] | hasPermissions) {
								<uno-button (click)="app.navigator.navigate('/menu/atex/action-plan/edit', {uuid: option.uuid});">{{'open' | translate}}</uno-button>
							}
						</ion-item>
					}
				</ion-list>
			</uno-tab-section>
		}

		@if (this.history.length > 0) {
			<uno-tab-section title="{{'history' | translate}}">
				<!-- History -->
				<ion-list class="ion-no-padding ion-no-margin">
					@for (option of this.history; track option) {
						<ion-item class="ion-margin-horizontal ion-no-padding" lines="full">
							<ion-label>{{option.date | formatDate}}</ion-label>
							<div class="ion-text-end">
								<ion-label>{{option.user.name}}</ion-label>
								<ion-label color="medium" style="font-size: 13px;">{{option.user.email + (option.user.phone.length > 0 ? (' | ' + option.user.phone) : '')}}</ion-label>
							</div>
						</ion-item>
					}
				</ion-list>
			</uno-tab-section>
		}
	</uno-tab>
</div>
