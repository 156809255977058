import {Resource} from '../../resource';
import {AtexInspectionFieldResult} from './atex-inspection-field-result';

/**
 * Represents a field of the inspection forms, composed of a result, notes and files.
 *
 * Can have as a result OK, NOK or OK with revisions.
 */
export class AtexInspectionField {
	/**
	 * Indicates if the field can be skipped and its not applicable.
	 */
	public notApplicable: boolean = false;

	/**
	 * Result of the inspection.
	 */
	public result: number = AtexInspectionFieldResult.NONE;

	/**
	 * Text with notes about the inspection field.
	 */
	public notes: string = '';

	/**
	 * Photos attached to the inspection field.
	 */
	public photo: Resource[] = [];

	/**
	 * List of justifications from the default justification list.
	 *
	 * Stores the index of the justifications selected.
	 */
	public justifications: number[] = [];

	/**
	 * Parse Atex inspection field using JSON data retrieve from database.
	 *
	 * @param data - Data stored in database.
	 * @returns Parse Atex inspection field object.
	 */
	public static parse(data: any): AtexInspectionField {
		const field = new AtexInspectionField();

		field.notApplicable = data.notApplicable;
		field.result = data.result;
		field.notes = data.notes;
		field.photo = Resource.parseArray(data.photo);
		field.justifications = data.justifications || [];

		return field;
	}
}
