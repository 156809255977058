<uno-content>
	@if (this.content) {
		<div class="ion-padding">
			<img src="{{themes.logo}}" style="width: 35%; object-fit: contain;" />
			<h1>{{this.content.title}}</h1>
			@for (section of this.content.sections; track section) {
				<h2>{{section.title}}</h2>
				@for (content of section.content; track content) {
					<p>{{content}}</p>
				}
			}
		</div>
	}

	@if (!this.displayOnly) {
		<div class="ion-padding">
			<uno-button (click)="accept()">{{'accept' | translate}}</uno-button>
			<uno-button (click)="reject()">{{'logout' | translate}}</uno-button>
		</div>
	}
	@if (this.displayOnly) {
		<div class="ion-padding">
			<uno-button (click)="this.controller.dismiss()">{{'ok' | translate}}</uno-button>
		</div>
	}
</uno-content>
