import {UUIDIdentification} from '../../uuid';
import {DL50QuestionPossibleGap} from './dl50-question-possible-gap';

/**
 * Format of a checklist question.
 */
export class DL50Question extends UUIDIdentification {
	/**
	 * Label of the question.
	 */
	public label: string = '';

	/**
	 * Description of the question.
	 */
	public description: string = '';

	/**
	 * Article of the question.
	 */
	public article: string = '';

	/**
	 * Possible gaps associated with this question.
	 */
	public possibleGaps: DL50QuestionPossibleGap[] = [];

	/**
	 * The numeric index of the question in the checklist
	 */
	public index: number = null;
	
	/**
	* Parse JSON data received from the API into an object.
	*
	* @param data - Object received from API.
	* @returns Object of the correct type, built with parsed data received.
	*/
	public static parse(data: any): DL50Question {
		const question = new DL50Question();

		question.uuid = data.uuid;
		question.updatedAt = new Date(data.updatedAt);
		question.createdAt = new Date(data.createdAt);

		question.label = data.label;
		question.description = data.description;
		question.article = data.article;
		question.possibleGaps = data.possibleGaps;
		question.index = data.index;

		return question;
	}
}
