import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {UserListPage} from './screens/list/user-list-page';
import {UserEditPage} from './screens/edit/user-edit.page';

const routes: Routes = [
	{
		path: '',
		component: UserListPage
	},
	{
		path: 'edit',
		component: UserEditPage
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class UsersRouterModule { }
