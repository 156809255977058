/**
 * Possible actions to be performed when a step change occurs.
 */
export const InspectionWorkflowStepAction = {
	NONE: 0,
	EMAIL: 1
};

export const InspectionWorkflowStepActionLabel: Map<number, string> = new Map([
	[InspectionWorkflowStepAction.NONE, 'none'],
	[InspectionWorkflowStepAction.EMAIL, 'email']
]);
