import {Component, Input, ViewEncapsulation} from '@angular/core';
import {Object3D} from 'three';
import {NgStyle} from '@angular/common';
import {Modal} from '../../../../../../../modal';
import {Locale} from '../../../../../../../locale/locale';
import {DigitalTwinObjectLayout} from '../../../../digital-twin-object-layout';
import {DigitalTwinEditor} from '../../../editor/digital-twin-editor';
import {DigitalTwinObject} from '../../../../../data/digital-twin-object';
import {DigitalTwinObjectTypeIcon} from '../../../../../data/digital-twin-object-type';
import {App} from '../../../../../../../app';
import {UnoTextComponent} from '../../../../../../../components/uno/uno-text/uno-text.component';
import {UnoIconComponent} from '../../../../../../../components/uno/uno-icon/uno-icon.component';

@Component({
	selector: 'digital-twin-tree-object',
	templateUrl: './digital-twin-tree-object.component.html',
	encapsulation: ViewEncapsulation.None,
	styleUrls: ['./digital-twin-tree-object.component.css'],
	standalone: true,
	imports: [NgStyle, UnoIconComponent, UnoTextComponent]
})
export class DigitalTwinTreeObjectComponent {
	public app: any = App;

	public typeIcon: any = DigitalTwinObjectTypeIcon;

	/**
	 * Digital twin object.
	 */
	@Input()
	public object: Object3D = null;

	/**
	 * Level of the object in the tree.
	 *
	 * Will be used to change the object visual padding.
	 */
	@Input()
	public level: number = 0;

	/**
	 * Editor where this object is being edited.
	 */
	@Input()
	public editor: DigitalTwinEditor = null;

	/**
	 * Indicates if the cursor is hover the button.
	 */
	public hover: boolean = false;

	/**
	 * If true the digital twin object tree is expanded.
	 */
	public expanded: boolean = false;

	/**
	 * Toggle the expanded status of the tree item.
	 */
	public expand(): void {
		this.expanded = !this.expanded;
	}

	/**
	 * Change the visibility of the object.
	 */
	public async toggleVisibility(): Promise<void> {
		// @ts-ignore
		if (!this.object.locked) {
			this.object.visible = !this.object.visible;
			await this.editor.updateObject(this.object as DigitalTwinObject);
		}
	}

	/**
	 * Display modal to edit the object.
	 */
	public async showModal(): Promise<void> {
		if (this.object instanceof DigitalTwinObject) {
			const obj = this.object.toJSON();
			const apply = await Modal.form(Locale.get('digitalTwin'), obj, DigitalTwinObjectLayout);
			if (apply) {
				this.object.parse(obj);
				await this.editor.updateObject(this.object as DigitalTwinObject);
			}
		}
	}
}
