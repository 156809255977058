<uno-content #content>
	<!-- Base cards -->
	<menu-cards [options]="this.menuCards"></menu-cards>

	<!-- Asset Types -->
	<ion-title class="ion-margin" color="primary">{{'assetTypes' | translate}}</ion-title>

	<!-- Search bar -->
	<div style="margin: 10px;">
		<uno-searchbar style="width: 100%;" [value]="selfStatic.filters.search" (valueChange)="onFilterChange($event)"></uno-searchbar>
	</div>

	<!-- Asset cards -->
	<asset-type-cards #cards></asset-type-cards>
</uno-content>
