import {Routes} from '@angular/router';
import {Environment} from 'src/environments/environment';
import {Dl50ClientRouterModule} from 'src/client-custom/dl50/dl50-client-router.module';
import {MenuComponent} from './modules/menu/components/menu/menu.component';
import {InspectionsRouterModule} from './modules/inspections/inspections-router.module';
import {EPIRouterModule} from './modules/epi/epi-router.module';
import {RepairsRouterModule} from './modules/repairs/repairs-router.module';
import {ObservationsRouterModule} from './modules/observations/observations-router.module';
import {TeamsRouterModule} from './modules/teams/teams-router.module';
import {RolesRouterModule} from './modules/roles/roles-router.module';
import {UsersRouterModule} from './modules/users/users-router.module';
import {CompaniesRouterModule} from './modules/companies/companies-router.module';
import {MasterDataRouterModule} from './modules/master-data/master-data-router.module';
import {MasterSettingsRouterModule} from './modules/master-settings/master-settings-router.module';
import {LogsRouterModule} from './modules/logs/logs-router.module';
import {DigitalTwinRouterModule} from './modules/digital-twin/digital-twin-router.module';
import {DashboardRouterModule} from './modules/dashboard/dashboard-router.module';
import {DataToolsRouterModule} from './modules/data-tools/data-tools-router.module';
import {DemoRouterModule} from './modules/demo/demo-router.module';
import {QRRouterModule} from './modules/qr/qr-router.module';
import {NFCRouterModule} from './modules/nfc/nfc-router.module';
import {SettingsRouterModule} from './modules/settings/settings-router.module';
import {AssetPortfolioRouterModule} from './modules/asset-portfolio/asset-portfolio-router.module';
import {AtexRouterModule} from './modules/atex/atex-router.module';
import {PipelineIntegrityRouterModule} from './modules/pipeline-integrity/pipeline-integrity-router.module';
import {PrivacyPolicyRouterModule} from './modules/privacy-policy/privacy-policy-router.module';
import {AssetPlanningRouterModule} from './modules/asset-planning/asset-planning-router.module';
import {AuthenticationCodeBoxComponent} from './modules/login/login/components/authentication-code-box/authentication-code-box.component';
import {LoginBoxComponent} from './modules/login/login/components/login-box/login-box.component';
import {RecoverPasswordBoxComponent} from './modules/login/login/components/recover-password-box/recover-password-box.component';
import {ResetPasswordBoxComponent} from './modules/login/login/components/reset-password-box/reset-password-box.component';
import {LoginPage} from './modules/login/login/login.page';
import {Session} from './session';
import {GapAnalysisRouterModule} from './modules/gap-analysis/gap-analysis-router.module';
import {Dl50RouterModule} from './modules/dl50/dl50-router.module';
import {App} from './app';
import {Modules} from './modules';

/**
 * List of the AppRoutes available in the application.
 *
 * Can be used by the navigator to check if the AppRoutes exist before accessing them.
 */
export const AppRoutes: Routes = [
	{
		path: 'login',
		component: LoginPage,
		children: [
			{
				path: 'login',
				component: LoginBoxComponent
			},
			{
				path: 'code',
				component: AuthenticationCodeBoxComponent
			},
			{
				path: 'recover',
				component: RecoverPasswordBoxComponent
			},
			{
				path: 'reset',
				component: ResetPasswordBoxComponent
			},
			{
				path: '**',
				redirectTo: 'login'
			}
		]
	},
	{
		path: 'menu',
		component: MenuComponent,
		children: [
			{
				path: 'companies',
				loadChildren: () => {return CompaniesRouterModule;}
			},
			{
				path: 'users',
				loadChildren: () => {return UsersRouterModule;}
			},
			{
				path: 'role',
				loadChildren: () => {return RolesRouterModule;}
			},
			{
				path: 'teams',
				loadChildren: () => {return TeamsRouterModule;}
			},
			{
				path: 'masterdata',
				loadChildren: () => {return MasterDataRouterModule;}
			},
			{
				path: 'data',
				loadChildren: () => {return DataToolsRouterModule;}
			},
			{
				path: 'dashboard',
				loadChildren: () => {return DashboardRouterModule;}
			},
			{
				path: 'asset-portfolio',
				loadChildren: () => {return AssetPortfolioRouterModule;}
			},
			{
				path: 'atex',
				loadChildren: () => {return AtexRouterModule;},
				canMatch: [() => { return App.hasModule(Modules.ATEX_INSPECTIONS);}]
			},
			{
				path: 'inspection',
				loadChildren: () => {return InspectionsRouterModule;},
				canMatch: [() => { return App.hasModule(Modules.INSPECTIONS);}]
			},
			{
				path: 'epi',
				loadChildren: () => {return EPIRouterModule;},
				canMatch: [() => { return App.hasModule(Modules.EPI);}]
			},
			{
				path: 'observations',
				loadChildren: () => {return ObservationsRouterModule;},
				canMatch: [() => { return App.hasModule(Modules.OBSERVATIONS);}]
			},
			{
				path: 'nfc',
				loadChildren: () => {return NFCRouterModule;}
			},
			{
				path: 'qr',
				loadChildren: () => {return QRRouterModule;}
			},
			{
				path: 'repairs',
				loadChildren: () => {return RepairsRouterModule;},
				canMatch: [() => { return App.hasModule(Modules.REPAIRS);}]
			},
			{
				path: 'digital-twin',
				loadChildren: () => {return DigitalTwinRouterModule;},
				canMatch: [() => { return App.hasModule(Modules.DIGITAL_TWIN);}]
			},
			{
				path: 'privacy-policy',
				loadChildren: () => {return PrivacyPolicyRouterModule;}
			},
			{
				path: 'logs',
				loadChildren: () => {return LogsRouterModule;}
			},
			{
				path: 'demo',
				loadChildren: () => {return DemoRouterModule;}
			},
			{
				path: 'master-settings',
				loadChildren: () => {return MasterSettingsRouterModule;}
			},
			{
				path: 'settings',
				loadChildren: () => {return SettingsRouterModule;}
			},
			{
				path: 'pipeline-integrity',
				loadChildren: () => {return PipelineIntegrityRouterModule;},
				canMatch: [() => { return App.hasModule(Modules.PIPELINE_INTEGRITY);}]
			},
			{
				path: 'asset-planning',
				loadChildren: () => {return AssetPlanningRouterModule;},
				canMatch: [() => { return App.hasModule(Modules.ASSET_PLANNING);}]
			},
			{
				path: 'gap-analysis',
				loadChildren: () => {return GapAnalysisRouterModule;},
				canMatch: [() => { return App.hasModule(Modules.GAP_ANALYSIS);}]
			},
			{
				path: 'dl50-client',
				loadChildren: () => {return Dl50ClientRouterModule;}
			},
			{
				path: 'dl50',
				loadChildren: () => {return Dl50RouterModule;},
				canMatch: [() => { return App.hasModule(Modules.DL50);}]
			},
			{
				path: '**',
				redirectTo: Environment.DEFAULT_ROUTE || 'dashboard'
			}
		]
	},
	{
		path: '**',
		redirectTo: Session.isValid() ? 'login' : 'menu'
	}
];

