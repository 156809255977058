import {Component, Input, ViewEncapsulation} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {NgStyle} from '@angular/common';
import {CssNgStyle} from '../../../utils/css-ng-style';
import {UnoTextComponent} from '../uno-text/uno-text.component';
import {UnoIconComponent} from '../uno-icon/uno-icon.component';

/**
 * Component used to display a placeholder message when no data is available to display on components.
 */
@Component({
	selector: 'uno-no-data',
	templateUrl: './uno-no-data.component.html',
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [NgStyle, UnoIconComponent, UnoTextComponent, TranslateModule]
})

export class UnoNoDataComponent {
	/**
	 * Style of the element.
	 */
	@Input()
	public ngStyle: CssNgStyle = {};

	/**
	 * Icon to use
	 */
	@Input()
	public icon: string = './assets/components/no-data/items-not-found-icon.svg';

	/**
	 * Icon Color
	 */
	@Input()
	public iconColor: string = 'var(--gray-10)';

	/**
	 * Text to use under the icon
	 */
	@Input()
	public text: string = 'noDataOnServer';

	/**
	 * Text Color
	 */
	@Input()
	public textColor: string = 'var(--gray-7)';
}
