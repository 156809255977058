import {Service} from '../../../../http/service';
import {ServiceList} from '../../../../http/service-list';
import {Session} from '../../../../session';
import {Locale} from '../../../../locale/locale';
import {Modal} from '../../../../modal';
import {XlsxUtils} from '../../../../utils/xlsx-utils';
import {FFPActionPlanFilter, FFPRecommendationFilter} from '../screens/list/ffp-list.page';
import {ActionPlan} from '../../../../models/atex-inspections/action-plan/action-plan';
import {UnoFormField} from '../../../../components/uno-forms/uno-form/uno-form-field';
import {UnoFormFieldTypes} from '../../../../components/uno-forms/uno-form/uno-form-field-types';
import {ActionPlanState, ActionPlanStateLabel} from '../../../../models/atex-inspections/action-plan/action-plan-state';
import {ActionPlanPriority} from '../../../../models/atex-inspections/action-plan/action-plan-priority';
import {AtexInspectionFieldsService} from '../../inspections/services/atex-inspection-fields.service';
import {AtexFfpService} from '../services/atex-ffp.service';
import {FFPRecommendation} from '../../../../models/atex-inspections/ffp/ffp-recommendation';
import {FFPState, FFPStateLabel} from '../../../../models/atex-inspections/ffp/ffp-state';
import {FFPPriority} from '../../../../models/atex-inspections/ffp/ffp-priority';
import {FFP} from '../../../../models/atex-inspections/ffp/ffp';
import {ActionPlanActionService} from '../../action-plan/services/action-plan-action.service';

export class FFPWizard {
	/**
	 * Fill FFP data for a set of
	 */
	public static bulkActionPlansByTagFields(): void {
		const values = {
			// FFP data
			ffp: {
				// Inspections fields to look for in the FFP (only the FFP with one fields from this list will be considered).
				fields: [],
				// If true the FFP search will include the archived
				includeArchived: false,
				// Should include with or without recommendations
				includeWithRecommendations: FFPRecommendationFilter.NO_RECOMMENDATION,
				// Should inclue FFP that have or not action plans
				includeWithActionPlan: FFPActionPlanFilter.NO_ACTION_PLAN,
				// If true the FFP is not updated if already has recommendations
				skipHasRecommendations: false,
				// If the FFP should be deleted
				delete: false,
				// Priority of the FFP
				priority: FFPPriority.MEDIUM,
				// State to override the current FFP state
				state: -1,
				// Cost of the FFP
				cost: null,
				// Set of recommendations to add to the FFP
				recommendations: []
			},
			// Action plan data
			actionPlan: {
				// If false no action plan will be created
				create: true,
				// Work order of the action plan
				workOrder: null,
				// Team responsible
				team: null,
				// Company responsible
				company: null,
				// Priority level
				priority: ActionPlanPriority.MEDIUM,
				// Limit date
				limitDate: null,
				// State to set the action plan at
				state: -1,
				// Action to add to the action plan
				actions: [],
				// Documents of the action plan
				documents: [],
				// Photos of the action plan
				photos: []
			}
		};

		const layout: UnoFormField[] = [
			{
				label: 'ffp',
				attribute: 'ffp',
				type: UnoFormFieldTypes.SUB_FORM,
				expanded: true,
				fields: [
					{
						label: 'fields',
						attribute: 'fields',
						type: UnoFormFieldTypes.OPTIONS_MULTIPLE,
						options: [],
						required: true,
						fetchOptions: async function(object: any, row: UnoFormField) {
							row.options = [];
							const fields = await AtexInspectionFieldsService.get();
							for (const i in fields) {
								row.options.push({
									value: fields[i].attribute,
									label: fields[i].attribute + ' (' + fields[i].label + ')'
								});
							}
						}
					},
					{
						sort: false,
						label: 'includeWithRecommendations',
						attribute: 'includeWithRecommendations',
						type: UnoFormFieldTypes.OPTIONS,
						options: [
							{label: 'all', value: FFPRecommendationFilter.ALL},
							{label: 'noRecommendation', value: FFPRecommendationFilter.NO_RECOMMENDATION},
							{label: 'hasRecommendation', value: FFPRecommendationFilter.HAS_RECOMMENDATION}
						]
					},
					{
						sort: false,
						label: 'includeWithActionPlan',
						attribute: 'includeWithActionPlan',
						type: UnoFormFieldTypes.OPTIONS,
						options: [
							{label: 'all', value: FFPActionPlanFilter.ALL},
							{label: 'withoutActionPlan', value: FFPActionPlanFilter.NO_ACTION_PLAN},
							{label: 'withActionPlan', value: FFPActionPlanFilter.HAS_ACTION_PLAN}
						]
					},
					{
						label: 'includeArchived',
						attribute: 'includeArchived',
						type: UnoFormFieldTypes.CHECKBOX
					},
					{
						label: 'delete',
						attribute: 'delete',
						type: UnoFormFieldTypes.CHECKBOX
					},
					{
						label: 'skipHasRecommendations',
						attribute: 'skipHasRecommendations',
						type: UnoFormFieldTypes.CHECKBOX
					},
					{
						sort: false,
						label: 'status',
						attribute: 'state',
						type: UnoFormFieldTypes.OPTIONS,
						options: [
							{value: -1, label: 'dontChange'},
							{label: FFPStateLabel.get(FFPState.ACTIVE), value: FFPState.ACTIVE},
							{label: FFPStateLabel.get(FFPState.ARCHIVED), value: FFPState.ARCHIVED}
						],
						isActive: (object) => {return !object.ffp.delete;}
					},
					{
						required: false,
						attribute: 'cost',
						label: 'cost',
						inUnit: '€',
						unit: '€',
						type: UnoFormFieldTypes.NUMBER_UNITS,
						options: [
							{value: '€', label: '€'}
						],
						isActive: (object) => {return !object.ffp.delete;}
					},
					{
						required: true,
						sort: false,
						label: 'priority',
						attribute: 'priority',
						type: UnoFormFieldTypes.OPTIONS,
						options: [
							{label: 'low', value: FFPPriority.LOW},
							{label: 'medium', value: FFPPriority.MEDIUM},
							{label: 'high', value: FFPPriority.HIGH}
						],
						isActive: (object) => {return !object.ffp.delete;}
					},
					{
						required: true,
						label: 'recommendations',
						attribute: 'recommendations',
						type: UnoFormFieldTypes.OPTIONS_MULTIPLE,
						options: [],
						fetchOptions: async function(object: any, row: UnoFormField) {
							row.options = [];
							
							const recommendations: FFPRecommendation[] = await AtexFfpService.listRecommendations();
							recommendations.forEach((r: FFPRecommendation) => {
								row.options.push({
									value: r.id,
									label: r.label
								});
							});
						},
						isActive: (object) => {return !object.ffp.delete;}
					}
				]
			},
			{
				label: 'actionPlan',
				attribute: 'actionPlan',
				type: UnoFormFieldTypes.SUB_FORM,
				expanded: true,
				isActive: (object) => {return !object.ffp.delete;},
				fields: [
					{
						label: 'create',
						attribute: 'create',
						type: UnoFormFieldTypes.CHECKBOX,
						isActive: (object) => {return !object.ffp.delete;}
					},
					{
						required: true,
						sort: false,
						label: 'status',
						attribute: 'state',
						type: UnoFormFieldTypes.OPTIONS,
						options: [
							{value: ActionPlanState.TODO, label: ActionPlanStateLabel.get(ActionPlanState.TODO)},
							{value: ActionPlanState.WAITING_CLIENT_VALIDATION, label: ActionPlanStateLabel.get(ActionPlanState.WAITING_CLIENT_VALIDATION)},
							{value: ActionPlanState.CLIENT_ACCEPTED, label: ActionPlanStateLabel.get(ActionPlanState.CLIENT_ACCEPTED)},
							{value: ActionPlanState.CLIENT_REJECTED, label: ActionPlanStateLabel.get(ActionPlanState.CLIENT_REJECTED)},
							{value: ActionPlanState.BLOCKED, label: ActionPlanStateLabel.get(ActionPlanState.BLOCKED)},
							{value: ActionPlanState.WAITING_REINSPECTION, label: ActionPlanStateLabel.get(ActionPlanState.WAITING_REINSPECTION)},
							{value: ActionPlanState.DONE, label: ActionPlanStateLabel.get(ActionPlanState.DONE)}
						],
						isActive: (object) => {return !object.ffp.delete && object.actionPlan.create;}
					},
					{
						required: true,
						attribute: 'priority',
						label: 'priority',
						type: UnoFormFieldTypes.OPTIONS,
						sort: false,
						options: [
							{label: 'low', value: ActionPlanPriority.LOW},
							{label: 'medium', value: ActionPlanPriority.MEDIUM},
							{label: 'high', value: ActionPlanPriority.HIGH}
						],
						isActive: (object) => {return !object.ffp.delete && object.actionPlan.create;}
					},
					{
						required: true,
						label: 'workOrder',
						attribute: 'workOrder',
						type: UnoFormFieldTypes.TEXT,
						isActive: (object) => {return !object.ffp.delete && object.actionPlan.create;}
					},
					{
						required: true,
						label: 'team',
						attribute: 'team',
						type: UnoFormFieldTypes.TEAM_SELECTOR,
						isActive: (object) => {return !object.ffp.delete && object.actionPlan.create;}
					},
					{
						required: true,
						label: 'company',
						attribute: 'company',
						type: UnoFormFieldTypes.COMPANY_SELECTOR,
						isActive: (object) => {return !object.ffp.delete && object.actionPlan.create;}
					},
					{
						required: true,
						label: 'limitDate',
						attribute: 'limitDate',
						type: UnoFormFieldTypes.DATE,
						isActive: (object) => {return !object.ffp.delete && object.actionPlan.create;}
					},
					{
						required: true,
						label: 'actions',
						attribute: 'actions',
						type: UnoFormFieldTypes.OPTIONS_MULTIPLE,
						fetchOptions: async function(object: any, row: UnoFormField) {
							row.options = [];

							const actions = (await ActionPlanActionService.load()).actions;
							for (let i = 0; i < actions.length; i++) {
								row.options.push({
									label: actions[i].label,
									value: actions[i].id
								});
							}
						},
						isActive: (object) => {return !object.ffp.delete && object.actionPlan.create;}
					},
					{
						attribute: 'photos',
						label: 'photos',
						type: UnoFormFieldTypes.IMAGE_RESOURCE_MULTIPLE,
						isActive: (object) => {return !object.ffp.delete && object.actionPlan.create;}
					},
					{
						label: 'documents',
						attribute: 'documents',
						type: UnoFormFieldTypes.DOCUMENT_RESOURCE_MULTIPLE,
						isActive: (object) => {return !object.ffp.delete && object.actionPlan.create;}
					}
				]
			}
		];

		Modal.form(Locale.get('ffp'), values, layout).then((options: any) => {
			start(options);
		});

		function start(options: any): void {
			// Get input XLSX file to extract assets tags
			XlsxUtils.chooseFileXLSX().then((xlsx) => {
				for (let i = 0; i < xlsx.length; i++) {
					const tag = XlsxUtils.readRow(xlsx[i], ['Tag', 'Tags', 'Tag Number']);
					if (tag !== null) {
						assetsQueue.push(tag);
					}
				}

				if (assetsQueue.length > 0) {
					processNextAssetTag();
				} else {
					Modal.alert(Locale.get('error'), Locale.get('errorFileColumnRow', {attribute: 'Tag'}));
				}
			});

			// Assets waiting to be processed (by tag name)
			const assetsQueue = [];
			let processedSomething = false;

			// Method to process one asset and get its FFP
			function processNextAssetTag(): void {
				if (assetsQueue.length === 0) {
					if (!processedSomething) {
						// Warn the user if nothing was found
						Modal.alert(Locale.get('warning'), Locale.get('nothingUpdatedFFPNotFound'));
					}
					return;
				}

				const tag: string = assetsQueue.shift();

				const data = {
					state: options.ffp.includeArchived ? FFPState.ALL : FFPState.ACTIVE,
					filterActionPlan: options.ffp.includeWithActionPlan,
					filterRecommendations: options.ffp.includeWithRecommendations,
					searchFields: ['[ap_asset].tag'],
					search: tag,
					searchMode: 1 // Exact search mode
				};

				// Get all FFP for the asset tag to update its data and create action plan
				Service.call(ServiceList.atex.ffp.list, null, null, data, Session.session, (responseList) => {
					// List of FFP UUID that will be used to create a action plan
					const ffpList = [];

					// Count of ffp select and ffp retrieved from database
					let ffpSelected = 0;
					let ffpCount = 0;

					// Set flag if "something" to process was found
					if (responseList.atex.ffp.length > 0) {
						processedSomething = true;
					}

					// Iterate all the FFP retrieved
					for (let i = 0; i < responseList.atex.ffp.length; i++) {
						// Check if the ffp is for one of the inspection fields selected
						if (options.ffp.fields.indexOf(responseList.atex.ffp[i].field) !== -1) {
							ffpList.push(responseList.atex.ffp[i].uuid);
							ffpSelected++;

							Service.call(ServiceList.atex.ffp.get, null, null, {uuid: responseList.atex.ffp[i].uuid}, Session.session, (responseGet) => {
								// Parse FFP data
								const ffp = FFP.parse(responseGet.ffp);
								responseList.atex.ffp[i] = ffp;

								// Update the FFP counter and create action when all FFP are processed
								function processActionPlan(): void {
									ffpCount++;

									// All FFP of the asset processed create a new action plan
									if (ffpCount === ffpSelected && options.actionPlan.create) {
										const actionPlan = new ActionPlan();
										actionPlan.workOrder = options.actionPlan.workOrder;
										actionPlan.teamUuid = options.actionPlan.teamUuid;
										actionPlan.companyUuid = options.actionPlan.companyUuid;
										actionPlan.limitDate = options.actionPlan.limitDate;
										actionPlan.actions = options.actionPlan.actions;
										actionPlan.ffpUuids = ffpList;
										actionPlan.data.documents = options.actionPlan.documents;
										actionPlan.data.photos = options.actionPlan.photos;
										actionPlan.state = options.actionPlan.state;

										Service.call(ServiceList.atex.actionPlan.create, null, null, actionPlan, Session.session, () => {
											Modal.toast(Locale.get('createdActionPlanTag', {tag: tag}));
											processNextAssetTag();
										});
									}
								}

								// Delete the FFP from the server
								if (options.ffp.delete === true) {
									// If has recommendations (and skip if active) dont delete the FFP
									if (options.ffp.skipHasRecommendations && ffp.recommendations.length > 0) {
										processNextAssetTag();
									} else {
										// Delete the FFP from the API
										Service.call(ServiceList.atex.ffp.delete, null, null, {uuid: ffp.uuid}, Session.session, function() {
											Modal.toast(Locale.get('deleteSuccessfullyName', {tag: tag}));
											processNextAssetTag();
										});
									}
								} else {
									// If has recommendations (and skip if active) move along without updating FFP data
									if (options.ffp.skipHasRecommendations && ffp.recommendations.length > 0) {
										processActionPlan();
									} else {
										// Add recommendations (only new recommendations).
										for (let k = 0; k < options.ffp.recommendations.length; k++) {
											if (ffp.recommendations.indexOf(options.ffp.recommendations[k]) === -1) {
												ffp.recommendations.push(options.ffp.recommendations[k]);
											}
										}

										// Update the FFP data
										ffp.priority = options.ffp.priority;
										ffp.cost = options.ffp.cost;
										if (options.ffp.state !== -1) {
											ffp.state = options.ffp.state;
										}
										Service.call(ServiceList.atex.ffp.update, null, null, responseList.atex.ffp[i], Session.session, processActionPlan);
									}
								}
							});
						}
					}

					// If not FFP selected move on to next
					if (ffpSelected === 0) {
						processNextAssetTag();
					}
				});
			}
		}
	}
}
