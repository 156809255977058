<!-- Buttons to display on tabs-->
<ng-template #saveButtons>
	<div style="display: flex; flex-direction: row-reverse; gap: 5px; padding-top: 16px;">
		<!-- Create -->
		@if (this.createMode && ([userPermissions.DL50_SUPERVISOR, userPermissions.DL50_INSPECTIONS_CREATE] | hasPermissions)) {
			<uno-button (click)="this.save(false)">{{'create' | translate}}</uno-button>
		}
		<!-- In progress status adjacency buttons -->
		@if (this.createMode || this.inspection.status === this.inspectionStatus.IN_PROGRESS && ([userPermissions.DL50_SUPERVISOR, userPermissions.DL50_INSPECTIONS_EDIT] | hasPermissions)) {
			<uno-button (click)="this.save(false, this.inspectionStatus.VALIDATION)" color="success">{{ 'submit' | translate}}</uno-button>
		}
		@if (!this.createMode) {
			@if ([userPermissions.DL50_SUPERVISOR, this.userPermissions.DL50] | hasPermissions) {
				<!-- Validation status adjacency buttons -->
				@if (this.inspection.status === this.inspectionStatus.VALIDATION) {
					<uno-button color="warning" (click)="this.save(false, this.inspectionStatus.SUPERVISION_REJECTED)">{{('rejected') | translate}}</uno-button>
					<uno-button color="success" (click)="this.save(false, this.inspectionStatus.FINISHED)">{{('finished') | translate}}</uno-button>
				}

				<!-- Supervision rejected status adjacency buttons -->
				@if (this.inspection.status === this.inspectionStatus.SUPERVISION_REJECTED) {
					<uno-button color="success" (click)="this.save(false, this.inspectionStatus.VALIDATION)">{{('validation') | translate}}</uno-button>
				}

				<!-- Finished status adjacency buttons -->
				@if (this.inspection.status === this.inspectionStatus.FINISHED) {
					<uno-button color="warning" (click)="this.save(false, this.inspectionStatus.CLIENT_FEEDBACK)">{{('clientFeedback') | translate}}</uno-button>
				}

				<!-- Client feedback status adjacency buttons -->
				@if (this.inspection.status === this.inspectionStatus.CLIENT_FEEDBACK) {
					<uno-button color="success" (click)="this.save(false, this.inspectionStatus.FINISHED)">{{('finished') | translate}}</uno-button>
				}

				<!-- Save/Update -->
				@if ([userPermissions.DL50_SUPERVISOR, this.userPermissions.DL50_INSPECTIONS_EDIT] | hasPermissions) {
					<uno-button (click)="this.save()">{{('save') | translate}}</uno-button>
					<uno-button (click)="this.save(true)">{{'update' | translate}}</uno-button>
				}
			}
			@if (([userPermissions.DL50_SUPERVISOR, this.userPermissions.DL50_INSPECTIONS_DELETE] | hasPermissions) && this.inspection.status !== this.inspectionStatus.FINISHED) {
				<uno-button (click)="this.delete()" color="error">{{'delete' | translate}}</uno-button>
			}
		}
	</div>
</ng-template>

<!-- History item template -->
<ng-template #itemTemplate let-history="item">
	<uno-list-item (click)="this.openHistoryEntry(history);">
		<uno-list-item-label>{{history.updatedAt | formatDate}}</uno-list-item-label>
		<div style="width:200px">
			<uno-list-item-label>
				<h3>{{"status" | translate}}: {{dL50InspectionStatusLabel.get(history.status) | translate}}</h3>
			</uno-list-item-label>
			<uno-list-item-label>
				<h3>{{"userName" | translate}}: {{history.userName}}</h3>

			</uno-list-item-label>
			<uno-list-item-label>
				<p>{{history.userEmail}}</p>
			</uno-list-item-label>
		</div>
	</uno-list-item>
</ng-template>

<!-- Export report buttons -->
<ng-template #exportButtons>
	@if (this.inspection.status === this.inspectionStatus.FINISHED) {
		<div style="display: flex; flex-direction: row-reverse; gap: 5px; padding-top: 16px;">
			<uno-button (click)="this.exportReportDOCX(true);">{{'exportReportDOCX' | translate}}</uno-button>
			<uno-button (click)="this.exportReportPDF();">{{'exportReportPDF' | translate}}</uno-button>
		</div>
	}
</ng-template>

<div style="width:100%; height:100%;">
	<uno-tab>
		<!-- Inspection tab -->
		@if (this.inspection) {
			<uno-tab-section [active]="true" title="{{'inspection' | translate}}">
				<!-- Inspection base info -->
				<uno-form [onChange]="this.baseInfoEdited" [editable]="this.editable" [layout]="this.baseInfoLayout" [object]="this.inspection">
				</uno-form>

				<uno-form [onChange]="this.baseInfoEdited" [editable]="this.editable" [layout]="this.inspectionLayout.assetInfo" [object]="this.asset">
				</uno-form>

				<!-- Inspection general data info -->
				<uno-form [editable]="this.editable" [layout]="this.inspectionLayout.generalData" [object]="this.inspection">
				</uno-form>

				<!-- Inspection dynamic questions -->
				<uno-title>{{'safetyRequirements' | translate}}</uno-title>
				<uno-form [editable]="this.editable" [layout]="this.questionsLayout" [object]="this.questionResponses">
				</uno-form>

				<!-- Inspection final notes -->
				<uno-form [editable]="this.editable" [layout]="this.inspectionLayout.inspectionFinalNotes" [object]="this.inspection">
				</uno-form>

				@if (!this.createMode && this.inspection.status !== this.inspectionStatus.IN_PROGRESS) {
					<!-- Inspection validation -->
					<uno-form [editable]="this.inspection.status === this.inspectionStatus.VALIDATION && ([userPermissions.DL50_SUPERVISOR] | hasPermissions)" [layout]="this.inspectionLayout.inspectionValidation" [object]="this.inspection">
					</uno-form>

					@if (this.inspection.status === this.inspectionStatus.SUPERVISION_REJECTED || this.inspection.supervisionRejectedComments?.length > 0) {
						<!-- Supervision rejected -->
						<uno-title>{{'supervisionRejected' | translate}}</uno-title>
						<uno-form [editable]="this.inspection.status === this.inspectionStatus.VALIDATION && ([userPermissions.DL50_SUPERVISOR] | hasPermissions) && this.inspection.supervisionRejectedComments?.length > 0" [layout]="this.inspectionLayout.inspectionSupervisionRejected" [object]="this.inspection">
						</uno-form>
					}

					@if (this.inspection.status === this.inspectionStatus.FINISHED || this.inspection.status === this.inspectionStatus.CLIENT_FEEDBACK) {
						<!-- Client feedback -->
						<uno-form [editable]="this.inspection.status === this.inspectionStatus.FINISHED && ([userPermissions.DL50_SUPERVISOR, this.userPermissions.DL50_INSPECTIONS_EDIT] | hasPermissions)" [layout]="this.inspectionLayout.inspectionClientFeedback" [object]="this.inspection">
						</uno-form>
					}
				}

				<ng-container [ngTemplateOutlet]="exportButtons"></ng-container>
				<ng-container [ngTemplateOutlet]="saveButtons"></ng-container>
			</uno-tab-section>

			<!-- Inspection load tests tab -->
			@if (this.inspection?.hasLoadTests) {
				<uno-tab-section title="{{'loadTests' | translate}}">
					<uno-form [editable]="this.editable" [layout]="this.inspectionLayout.loadTestChecklist" [object]="this.inspection">
					</uno-form>

					<ng-container [ngTemplateOutlet]="exportButtons"></ng-container>
					<ng-container [ngTemplateOutlet]="saveButtons"></ng-container>
				</uno-tab-section>
			}

			<!-- Inspection electrical tests tab -->
			@if (this.inspection?.hasElectricalTests) {
				<uno-tab-section title="{{'electricalTests' | translate}}">
					<uno-form [editable]="this.editable" [layout]="this.inspectionLayout.electricalTestChecklist" [object]="this.inspection">
					</uno-form>

					<ng-container [ngTemplateOutlet]="exportButtons"></ng-container>
					<ng-container [ngTemplateOutlet]="saveButtons"></ng-container>
				</uno-tab-section>
			}
		}

		<!-- Asset tab -->
		@if (([this.userPermissions.ASSET_PORTFOLIO] | hasPermissions) && this.asset) {
			<uno-tab-section title="{{'asset' | translate}}">
				<!-- Asset data -->
				<uno-form [editable]="[this.userPermissions.ASSET_PORTFOLIO_ASSET_EDIT] | hasPermissions" [onChange]="this.setAssetEdited" [layout]="this.assetBaseLayout" [object]="this.asset"></uno-form>

				<!-- Model Identification -->
				<uno-title>{{'modelIdentification' | translate}}</uno-title>
				<uno-form [editable]="[this.userPermissions.ASSET_PORTFOLIO_ASSET_EDIT] | hasPermissions" [onChange]="this.setAssetEdited" [layout]="this.assetModelLayout" [object]="this.asset"></uno-form>

				<ng-container [ngTemplateOutlet]="saveButtons"></ng-container>
			</uno-tab-section>
		}

		<!-- History tab -->
		@if (!this.createMode && this.history.length > 0) {
			<uno-tab-section title="{{'history' | translate}}">
				<!-- History -->
				<uno-list [itemTemplate]="itemTemplate" [handler]="this.handler"></uno-list>
			</uno-tab-section>
		}
	</uno-tab>
</div>
