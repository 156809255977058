import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {RepairInspectionsRouterModule} from './repair-inspections/repair-inspections-router.module';
import {RepairsPage} from './screens/menu/repairs.page';
import {RepairWorksRouterModule} from './repair-work/repair-works-router.module';

const routes: Routes = [
	{
		path: '',
		component: RepairsPage
	},
	{
		path: 'works',
		loadChildren: () => {
			return RepairWorksRouterModule;
		}
	},
	{
		path: 'inspections',
		loadChildren: () => {
			return RepairInspectionsRouterModule;
		}
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class RepairsRouterModule { }
