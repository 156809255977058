<div class="ion-padding-vertical">
	@if (this.disabled) {
		<uno-icon src="assets/icons/uno/nfc/nfc.svg" color="gray" width="80" height="80"></uno-icon>
	}
	@else {
		@if(this.value) {
			<div class="uno-nfc-tag-container">
				<uno-icon src="assets/icons/uno/nfc/nfc.svg" color="contrast" width="80" height="80" style="cursor: pointer;"></uno-icon>
				<div class="uno-nfc-tag-button-container">
					<uno-button icon="/assets/icons/uno/refresh.svg" (click)="this.select();" size="medium" type="border" width="120px" [fitContent]="false">{{'replace' | translate }}</uno-button>
					<uno-button icon="/assets/icons/uno/bin.svg" (click)="this.deleteValue();" size="medium" type="border" width="120px" [fitContent]="false">{{'delete' | translate }}</uno-button>
				</div>
			</div>
		}
		@else {
			<div class="uno-nfc-tag-no-nfc-container" (click)="this.select();">
				<div class="uno-nfc-tag-read-nfc">
					<uno-icon src="assets/icons/uno/nfc/nfc-icon.svg" color="primary" width="32" height="32"></uno-icon>
					<span class="uno-nfc-tag-read-nfc-text"><span class="uno-nfc-tag-read-nfc-text-bold">{{'read' | translate }} </span>{{'nfc' | translate }}</span>
				</div>
			</div>
		}
	}
</div>
