<div style="display: flex; flex-direction: row; width: 100%; height: 100%;" #container>
	<!-- Rendering canvas -->
	<renderer-canvas #canvas style="width: 100%; height: 100%;">
		<!-- Tool bar -->
		<digital-twin-tool-bar [editor]="this.editor"></digital-twin-tool-bar>
	</renderer-canvas>

	<!-- Object tree -->
	@if (this.editor.content) {
		<digital-twin-tree style="z-index: 100;" [editor]="this.editor" [objects]="this.editor.content.children"></digital-twin-tree>
	}
</div>
