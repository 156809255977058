/**
 * Lazy list handler is used to retrieve pages of information from the API in pages.
 *
 * Can be configured to work with different object types.
 */
export class UnoListLazyLoadHandler<T> {
	/**
	 * Number of elements to fetch on each request when using lazy loading.
	 */
	public pageSize: number = 20;

	/**
	 * Number of element already loaded from the API, used to control lazy loading.
	 */
	public count: number = 0;

	/**
	 * List of elements received from the API.
	 */
	public items: T[] = [];

	/**
	 * Flag to block API list loading calls if waiting for API response.
	 */
	public loadingMore: boolean = false;

	/**
	 * Indicates if there is more information to load.
	 */
	public hasMore: boolean = true;

	/**
	 * Indicate if the loader is empty or has items loaded.
	 */
	public hasItems: boolean = false;

	/**
	 * Reset counter is used to let the loadMore() method.
	 */
	public resetCounter: number = 0;

	/**
	 * Method to load more elements.
	 */
	public loadMore: (count: number, pageSize: number)=> Promise<{elements: T[], hasMore: boolean}> = null;

	/**
	 * Transform values retrieved from API.
	 */
	public transform: (value: any)=> any = null;

	/**
	 * Method to refresh data after new elements are loaded.
	 */
	public onload: ()=> void = null;

	public constructor(pageSize: number = 20) {
		this.pageSize = pageSize;
	}

	/**
	 * Reset the lazy list status and reload the first page of information.
	 */
	public async reset(): Promise<void> {
		this.hasMore = true;
		this.loadingMore = false;

		this.count = 0;
		this.items = [];
		this.hasItems = false;

		this.resetCounter++;

		await this.nextPage();
	}

	/**
	 * Load the next page of information.
	 */
	public async nextPage(): Promise<void> {
		const resetCounter = this.resetCounter;

		// Check if there are more elements to load, or if it is already loading more elements.
		if (!this.hasMore || this.loadingMore) {
			return;
		}

		this.loadingMore = true;

		if (!this.loadMore) {
			throw new Error('LoadMore() needs to be defined.');
		}

		const result = await this.loadMore(this.count, this.pageSize);

		if (this.transform) {
			for (let i = 0; i < result.elements.length; i++) {
				result.elements[i] = this.transform(result.elements[i]);
			}
		}

		if (this.resetCounter !== resetCounter) {
			return;
		}

		this.items = this.items.concat(result.elements);
		this.hasItems = this.items.length > 0;
		this.count += result.elements.length;
		this.hasMore = result.hasMore;

		if (this.onload) {
			this.onload();
		}

		this.loadingMore = false;
	}
}
