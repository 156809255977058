import {Resource} from '../../../../../models/resource';

/**
 * Stores settings applied to the Atex Inspections settings components, in master data.
 */
export class AtexInspectionSettings {
	/**
	 * Inspectors count to display in dashboard graph.
	 */
	public inspectorsCount: number = 0;

	/**
	 * Report template to be used for inspections.
	 */
	public reportTemplate: Resource = null;

	/**
	 * Parse the data from API for a AtexInspectionSettings object.
	 *
	 * @param data - Data to be parsed.
	 * @returns - The parsed object.
	 */
	public static parse(data: any): AtexInspectionSettings {
		const settings = new AtexInspectionSettings();

		settings.inspectorsCount = data.inspectorsCount;
		settings.reportTemplate = Resource.parse(data.reportTemplate);

		return settings;
	}
}
