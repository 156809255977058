@if (this.expanded) {
	<div class="uno-options-search-blackout" (click)="this.slowToggle()"></div>
}

<div class="uno-options-search-dropdown">
	<div class="uno-options-search-container">
		<div class="uno-options-search-selector" [ngClass]="{'uno-options-search-active': this.expanded}" cdkOverlayOrigin #originOverlay="cdkOverlayOrigin">
			<!-- Selected Label -->
			<uno-text class="uno-options-search-selector-label" (click)="this.toggle()" [ngClass]="{'uno-options-search-hidden': this.expanded}"> {{this.getLabel(this.value) | translate}} </uno-text>
			<ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="originOverlay" [cdkConnectedOverlayOpen]="this.expanded">
				<input class="uno-options-search-input" [formControl]="this.searchForm" [ngClass]="{'uno-options-search-hidden': !this.expanded}">
			</ng-template>
			<!-- Arrow -->
			<div class="uno-options-search-arrow" [ngClass]="{'uno-options-search-rotate': this.expanded}" (click)="this.toggle()">
				<img src="./assets/components/options/down_arrow.svg" />
			</div>
		</div>

		<ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="originOverlay" [cdkConnectedOverlayOpen]="this.expanded">
			<div class="uno-options-search-dropdown-menu" #dropdownMenu [ngClass]="{'uno-options-search-hidden': !this.expanded}">
				<!-- Search box -->
				<button class="uno-options-search-button uno-options-search-mobile" (click)="this.slowToggle()">
					<img src="./assets/components/options/expandable.svg" />
				</button>
				<div class="uno-options-search-input-box uno-options-search-mobile">
					<input class="uno-options-search-drop-input" [formControl]="this.searchForm" placeholder="{{'search' | translate}}">
					<div class="uno-options-search-erase" (click)="this.clearInput()">
						<img class="uno-options-search-image" src="./assets/components/options/close.svg" />
					</div>
				</div>

				<!-- Options -->
				<div class="uno-options-search-option-items">
					@for (item of this.options; track item) {
						<p class="uno-options-search-p" (click)="this.writeValue(item.value); this.toggle();" [ngClass]="{'uno-options-search-hidden': this.searchForm.value !== '' && this.item.label.indexOf(this.searchForm.value) === -1}"> {{item.label | translate}}</p>
					}
				</div>
			</div>
		</ng-template>
	</div>
</div>
