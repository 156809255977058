import {Component, ViewEncapsulation} from '@angular/core';

@Component({
	selector: 'uno-list-item-label',
	templateUrl: './uno-list-item-label.component.html',
	encapsulation: ViewEncapsulation.None,
	styleUrls: ['uno-list-item-label.component.css'],
	standalone: true
})
export class UnoListItemLabelComponent {}
