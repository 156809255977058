import {Component, HostListener, OnInit, ViewEncapsulation} from '@angular/core';
import {ENTER} from '@angular/cdk/keycodes';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule} from '@angular/forms';
import {NgClass, NgStyle} from '@angular/common';
import {Modal} from '../../../../../modal';
import {Locale} from '../../../../../locale/locale';
import {Service} from '../../../../../http/service';
import {ServiceList} from '../../../../../http/service-list';
import {Session} from '../../../../../session';
import {App} from '../../../../../app';
import {UnoButtonComponent} from '../../../../../components/uno/uno-button/uno-button.component';
import {UnoInputComponent} from '../../../../../components/uno/uno-input/uno-input-component';
import {UnoTextComponent} from '../../../../../components/uno/uno-text/uno-text.component';

@Component({
	selector: 'authentication-code-box',
	templateUrl: './authentication-code-box.component.html',
	styleUrls: ['../login-box/login-box.component.scss'],
	standalone: true,
	imports: [UnoTextComponent, NgClass, NgStyle, UnoInputComponent, FormsModule, UnoButtonComponent, TranslateModule],
	encapsulation: ViewEncapsulation.None
})
export class AuthenticationCodeBoxComponent implements OnInit {
	/**
	 * Email of the user.
	 */
	public email: string = '';

	/**
	 * Hash of the user password received from login screen.
	 */
	public password: string = '';

	/**
	 * Authentication code for login.
	 */
	public code: string = '';

	/**
	 * On initialization prevent the page to be loaded if the query parameter token is missing from the URL.
	 */
	public ngOnInit(): void {
		const data = App.navigator.getData();
		if (!data) {
			App.navigator.pop();
			return;
		}

		this.email = data.email;
		this.password = data.password;
	}

	@HostListener('window:keydown', ['$event'])
	public keyDown(event: any): void {
		if (event.which === ENTER) {
			this.login();
		}
	}

	/**
	 * Login into the platform using the values input by the user.
	 *
	 * Performs some data validation before submission.
	 */
	public async login(): Promise<void> {
		const data = {
			email: this.email,
			password: this.password,
			code: this.code
		};

		const request = await Service.fetch(ServiceList.authentication.login, null, null, data, null);
		await Session.login(request.response.sessionID, request.response.user);

		App.navigator.navigate('/menu');
		Modal.toast(Locale.get('loginSuccess'));
	}

	public returnLogin(): void {
		App.navigator.navigate('login/login');
	}
}
