import {NFCHandler} from './nfc-handler';

export type NFCData = {
	// Serial number of the NFC tag.
	serialNumber: string;
	// Records read from the NFC tag.
	records: Array<{
		type: string;
		data: string;
	}>;
	// Event object from the NFC read.
	event: any;
};

/**
 * Class to represent and handle NFC communications.
 *
 * NFC communications are handled using the WebNFC API.
 */
export class NFC {
	/**
	 * Create a NFC tag reader.
	 *
	 * Provides data about the NFC tag and all sectors available as text.
	 *
	 * @param onread - Method to handle NFC reading events.
	 * @param timeout - Time to read NFC tag, after timeout the reader will be disabled.
	 * @returns Handler object to control the NFC reader.
	 */
	public static async read(onread?: (data: NFCData)=> void, timeout?: number): Promise<NFCHandler> {
		if ('NDEFReader' in window) {
			// @ts-ignore
			const ndef = new NDEFReader();

			const controller = new AbortController();

			// Enable NFC scanner
			await ndef.scan({signal: controller.signal});

			// Reading callback
			ndef.onreading = function(event) {
				const records = [];
				const decoder = new TextDecoder();

				// Parse records from the NFC device
				for (const record of event.message.records) {
					if (record.recordType === 'empty') {
						continue;
					}

					records.push({
						type: record.recordType,
						data: decoder.decode(record.data)
					});
				}

				if (onread) {
					onread({
						serialNumber: event.serialNumber,
						records: records,
						event: event
					});
				}
			};

			const handler = new NFCHandler(ndef, controller);

			if (timeout) {
				handler.timeout(timeout);
			}

			return handler;
		} else {
			throw new Error('WebNFC is not supported.');
		}
	}

	/**
	 * Write data into a NFC tag. Overwrites the content present in the NFC tag.
	 *
	 * Multiple records can be written if an array of strings is passed as argument.
	 *
	 * @param data - Data to be written into tag as string.
	 * @param timeout - Time to read NFC tag, after timeout the reader will be disabled.
	 * @returns Handler object to control the NFC reader.
	 */
	public static async write(data: string | string[], timeout?: number): Promise<NFCHandler> {
		if ('NDEFReader' in window) {
			// @ts-ignore
			const ndef = new NDEFReader();
			const controller = new AbortController();

			const records = [];

			if (data instanceof Array) {
				for (let i = 0; i < data.length; i++) {
					records.push({
						data: data[i],
						recordType: 'text'
					});
				}
			} else {
				records.push({
					data: data,
					recordType: 'text'
				});
			}

			await ndef.write({records: records}, {overwrite: true, signal: controller.signal});

			const handler = new NFCHandler(ndef, controller);

			if (timeout) {
				handler.timeout(timeout);
			}

			return handler;
		} else {
			throw new Error('WebNFC is not supported.');
		}
	}
}
